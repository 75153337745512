import { regionalClient } from "index"
import { Bill } from "models/Bill"
import { BillEndpoints } from "modules/api/endpoints/BillEndpoints"

export const list = (from?: string, to?: string) => {
  return regionalClient.get(BillEndpoints.list)
}
export const info = (id: string) => {
  return regionalClient.get<Bill>(BillEndpoints.info(id))
}
