import {useNavigate, useParams} from 'react-router-dom'
import PageTitle from 'components/common/PageTitle/PageTitle'
import invoiceStyle from './ReceiptDetailPage.module.css'
import { toAbsoluteUrl, toBRL } from 'utils/Functions'
import { RECEIPT_DOWNLOAD, toUrl } from 'utils/Links'
import { useQuery } from '@tanstack/react-query'
import { orderDetails } from '../Order/core/_requests'
import { SalesOrder } from 'models/SalesOrder'
import { useState } from 'react'
import { Invoice } from 'models/Invoice'
import moment from 'moment'
import { DATE_USUAL } from 'utils/Consts'

function ReceiptDetailPage() {
  const navigate = useNavigate()
  const { id : orderId } = useParams()
  const [order, setOrder] = useState<SalesOrder>()
  const [receipt, setReceipt] = useState<Invoice>()

  const { isLoading: isOrderDetailLoading } = useQuery({
    queryFn: async () => {
        const response = await orderDetails(orderId!!)
        setOrder(response.data)
        // CHANGE data.invoice TO data.receipt ONCE ITS WORKING 
        if (response.data.invoice) return (
            setReceipt(response.data.invoice)
        )
        else return response
    },
    queryKey: ['order', orderId],
    enabled: !!orderId,
})

  return (
    <>
      <PageTitle title={`Nota Fiscal ${order && '#' + order.number}`} />
      <div className='container padding-bottom-mobile'>
      {/* {order?.invoice?.map((invoice:any, idx:number) => ( */}
      {order?.invoice &&
        <div className={invoiceStyle.invoiceGroup} onClick={() => navigate(toUrl(RECEIPT_DOWNLOAD, order.id))}>
          <span className='font500 main-color'>
            {/* {order.invoice?.type} */}
            DANFE
          </span>
          <span className='font500 main-color'>
            {moment(order.invoice?.modification_date).format(DATE_USUAL)}
          </span>
          <span className='font500 main-color'>
            {toBRL(order.total_price)}
          </span>
          <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Right.svg')} alt=''/>
        </div>
      }
      {/* ))} */}
      </div>
    </>
  )
}

export default ReceiptDetailPage
