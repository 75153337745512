import React from 'react'
import authSteps from './AuthenticationSteps.module.css'
import {ImCheckboxChecked, ImCheckboxUnchecked} from 'react-icons/im'
import {useNavigate} from 'react-router-dom'
import {ACCOUNT_2_FACTOR, toUrl} from 'utils/Links'

function AuthenticationSteps({step}) {
  const navigate = useNavigate()

  return (
    <div className='row my-3'>
      <div className='d-flex justify-content-between col-12'>
        {step === 1 ? (
          <div onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 1))} className={authSteps.stepCol}>
            <div className={authSteps.arrowBox}>
              <ImCheckboxChecked />
              <div className={`${authSteps.step} ${authSteps.activeStep}`}>&nbsp; Passo 1 </div>
            </div>
          </div>
        ) : (
          <div
            onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 1))}
            disabled
            className={authSteps.stepCol}
          >
            <div
              className={`d-flex align-items-center justify-content-center ${authSteps.arrowBoxInactive}`}
            >
              <ImCheckboxChecked />
              <div className={`${authSteps.step} ${authSteps.inactiveStep}`}>&nbsp; Passo 1 </div>
            </div>
          </div>
        )}

        {step === 2 ? (
          <div onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 2))} className='float-right col-3'>
            <div className={authSteps.arrowBox}>
              <ImCheckboxChecked />
              <div className={`${authSteps.step} ${authSteps.activeStep}`}>&nbsp; Passo 2 </div>
            </div>
          </div>
        ) : step > 2 ? (
          <div
            onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 2))}
            disabled
            className={authSteps.stepCol}
          >
            <div
              className={`d-flex align-items-center justify-content-center; ${authSteps.arrowBoxInactive}`}
            >
              <ImCheckboxChecked />
              <div className={`${authSteps.step} ${authSteps.inactiveStep}`}>&nbsp; Passo 2 </div>
            </div>
          </div>
        ) : (
          <div disabled className={authSteps.stepCol}>
            <div
              className={`d-flex align-items-center justify-content-center ${authSteps.arrowBoxInactive}`}
            >
              <ImCheckboxUnchecked />
              <div className={`${authSteps.step} ${authSteps.inactiveStep}`}>&nbsp; Passo 2 </div>
            </div>
          </div>
        )}

        {step === 3 ? (
          <div onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 3))} className='float-right col-3'>
            <div className={authSteps.arrowBox}>
              <ImCheckboxChecked />
              <div className={`${authSteps.step} ${authSteps.activeStep}`}>&nbsp; Passo 3 </div>
            </div>
          </div>
        ) : step > 3 ? (
          <div
            onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 3))}
            disabled
            className={authSteps.stepCol}
          >
            <div
              className={`d-flex align-items-center justify-content-center ${authSteps.arrowBoxInactive}`}
            >
              <ImCheckboxChecked />
              <div className={`${authSteps.step} ${authSteps.inactiveStep}`}>&nbsp; Passo 3 </div>
            </div>
          </div>
        ) : (
          <div onClick='#!' disabled className={authSteps.stepCol}>
            <div
              className={`d-flex align-items-center justify-content-center ${authSteps.arrowBoxInactive}`}
            >
              <ImCheckboxUnchecked />
              <div className={`${authSteps.step} ${authSteps.inactiveStep}`}>&nbsp; Passo 3 </div>
            </div>
          </div>
        )}

        {step === 4 ? (
          <div onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 4))} className='float-right col-3'>
            <div className={authSteps.arrowBox}>
              <ImCheckboxChecked />
              <div className={`${authSteps.step} ${authSteps.activeStep}`}>&nbsp; Passo 4 </div>
            </div>
          </div>
        ) : (
          <div disabled className={authSteps.stepCol}>
            <div
              className={`d-flex align-items-center justify-content-center ${authSteps.arrowBoxInactive}`}
            >
              <ImCheckboxUnchecked />
              <div className={`${authSteps.step} ${authSteps.inactiveStep}`}>&nbsp; Passo 4 </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AuthenticationSteps
