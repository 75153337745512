import { SortDirection, flexRender } from "@tanstack/react-table";
import React, { Fragment } from "react";
import { toAbsoluteUrl } from "utils/Functions";
import { useNewDataTable } from "../NewDatatable";
import tableStyle from '../NewDatatable.module.css';

const BaseTable: React.FC = () => {
  const { table, renderSubComponent, placeHolder, isLoading } = useNewDataTable();

  const getSortDirectionIcon = ( isSorted : false | SortDirection ) => {
    if (!isSorted) return <></>

    if (isSorted === 'asc') return <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Up.svg')} alt='' className="ps-2"/>

    return <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Down.svg')} alt='' className="ps-2"/>
  }

  if (!table) return <></>;

  if (isLoading) return <div>Carregando...</div>

  if (table.getRowModel().rows.length === 0){
    if (placeHolder){
      return placeHolder
    }
    return <div>Nenhum dado encontrado</div>
  }

  const ExpandableComponent = ({row, renderSubComponent} : {row : any, renderSubComponent : any}) => {
     if (!renderSubComponent || !row.getIsExpanded()) return <></>
      return <tr>
        <td colSpan={row.getVisibleCells().length}>
          {renderSubComponent({row})}
        </td>
      </tr>
  }

  return (
    <table className="w-100">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className={tableStyle.tableTr}>
            {headerGroup.headers.map((header) => {
              return (
                <th className={tableStyle.tableTh} key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        {...{
                          className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}

                        {getSortDirectionIcon(header.column.getIsSorted())}
                      </div>
                    </>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <Fragment key={row.id}>
          <tr className={`${tableStyle.tableContent} text-center`}>
              {/* first row is a normal row */}
              {row.getVisibleCells().map(cell => {
                return (
                  <td key={cell.id}>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </td>
                )
              })}
          </tr>
          <ExpandableComponent row={row} renderSubComponent={renderSubComponent} />

          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default BaseTable;
