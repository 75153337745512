import { Region } from "./Region";

export enum StoreLocationStatus {
  FOUND_WITHOUT_INTERACTION,
  NOT_FOUND,
  FOUND,
  FROM_USER_LOCATION,
}

export type StoreLocation = {
  region: Region;
  // city: City,
  status: StoreLocationStatus;
};
