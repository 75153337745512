export enum SearchOperationType {
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN = 'LESS_THAN',
    GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
    LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
    NOT_EQUAL = 'NOT_EQUAL',
    EQUAL = 'EQUAL',
    MATCH = 'MATCH',
    MATCH_START = 'MATCH_START',
    MATCH_END = 'MATCH_END',
    IN = 'IN',
    NOT_IN = 'NOT_IN',
}
