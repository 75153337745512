import { useQuery } from '@tanstack/react-query'
import PageTitle from 'components/common/PageTitle/PageTitle'
import FaqGroupAccordion from 'components/mobile/FaqGroup/FaqGroupAccordion'
import SearchFAQ from 'components/mobile/SearchFAQ/SearchFAQ'
import * as diacriticless from 'diacriticless'
import { FaqGroup } from 'models/FaqGroup'
import { useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { toAbsoluteUrl, toNormalizeString } from 'utils/Functions'
import FAQ from '../../../../resources/data/FAQ.json'
import { list } from './core/_requests'
import Image from 'components/common/Image/Image'

function FaqPage() {
    const enumeratedArray = Array.from({ length: FAQ.length }, (_, index) => index);
    const [selected, setSelected] = useState<number[]>(enumeratedArray);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [faq, setFaq] = useState<any[]>([...FAQ]);
    const normalizedSearchTerm = toNormalizeString(searchTerm);
    const [faqGroups, setFaqGroups] = useState<FaqGroup[]>([]);

    const toggle = (idx: number) => {
        if (selected.includes(idx)) {
            const newSelected = selected.filter((item) => item !== idx)
            setSelected(newSelected)
        } else {
            return setSelected([...selected, idx])
        }
    }

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
    };

    const filteredData = useMemo(() => {
        if (normalizedSearchTerm.length > 4) {
            return FAQ.filter(item => {
                const normalizedTitle = toNormalizeString(item.title)


                const normalizedCollapsibles = item.collapsibles.map(c => ({
                    title: toNormalizeString(c.collapsible_title),

                    content: toNormalizeString(c.collapsible_content),
                }));

                return (
                    normalizedTitle.includes(normalizedSearchTerm) ||
                    normalizedCollapsibles.some(
                        c =>
                            c.title.includes(normalizedSearchTerm) ||
                            c.content.includes(normalizedSearchTerm)
                    )
                );
            });
        } else {
            return FAQ;
        }
    }, [normalizedSearchTerm]);

    const { isLoading: isFaqGroupsLoading } = useQuery({
        queryFn: async () => {
            const response = await list(debouncedSearchTerm)
            setFaqGroups(response.data.content)
            return response.data
        },
        queryKey: ['listFaqGroups', debouncedSearchTerm],
    })

    useEffect(() => {
        if (searchTerm.length <= 3) {
            setFaq(FAQ);
            return;
        }
        const updatedFaq = FAQ.filter((f) => {
            const existsInCollapsibles = f.collapsibles.find((c) => {
                const normalizedCollapsibleTitle = diacriticless(c.collapsible_title.toLowerCase());
                const normalizedCollapsibleContent = diacriticless(c.collapsible_content.toLowerCase());
                const normalizedSearchTerm = diacriticless(searchTerm.toLowerCase());
                return (
                    normalizedCollapsibleContent.includes(normalizedSearchTerm) ||
                    normalizedCollapsibleTitle.includes(normalizedSearchTerm)
                );
            });
            return !!existsInCollapsibles || diacriticless(f.title.toLowerCase()).includes(searchTerm.toLowerCase());
        }).map((f) => {
            const updatedCollapsibles = f.collapsibles.filter((c) => {
                const normalizedCollapsibleTitle = diacriticless(c.collapsible_title.toLowerCase());
                const normalizedCollapsibleContent = diacriticless(c.collapsible_content.toLowerCase());
                const normalizedSearchTerm = diacriticless(searchTerm.toLowerCase());
                return (
                    normalizedCollapsibleContent.includes(normalizedSearchTerm) ||
                    normalizedCollapsibleTitle.includes(normalizedSearchTerm)
                );
            });
            return { ...f, collapsibles: updatedCollapsibles };
        });
        setFaq(updatedFaq);
    }, [searchTerm]);

    return (
        <>
            <PageTitle title={`PERGUNTAS FREQUENTES - F.A.Q.`} />
            <SearchFAQ onChange={handleSearchChange} />
            <div className='container padding-bottom-cart-mobile'>
                {(!faqGroups || faqGroups.length === 0) &&
                    <div className='d-flex justify-content-center flex-column align-items-center mt-4'>
                        <Image src={toAbsoluteUrl('/media/Illustrations/Clipboard-Illustration.svg')} alt='not-found'/>
                        <span className='font500 mt-3'>
                            Nenhum resultado encontrado
                        </span>
                    </div>
                }
                {faqGroups && faqGroups.map((faqGroup, idx) => (
                    <FaqGroupAccordion key={idx} faqGroup={faqGroup} />
                ))}
            </div>
        </>
    )
}

export default FaqPage
