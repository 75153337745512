import { useFormik } from "formik";
import { Wishlist } from "models/Wishlist";
import { Modal } from "react-bootstrap";
import { CgClose } from "react-icons/cg";
import * as Yup from "yup";
import style from "./AccountWishlistEditNameModal.module.css";

type Props = {
  show: boolean;
  wishlist?: Wishlist;
  onHide: (updatedName?: string) => void;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().min(3, "Minimo de 3 símbolos").max(54, "Máximo de 54 símbolos"),
});

function AccountWishlistEditNameModal({ show, onHide, wishlist }: Props) {

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: wishlist?.name || "",
    },
    validationSchema: validationSchema,
    onSubmit: ({name}) => {
      onHide(name)
    },
  });

  return (
    <Modal size="sm" show={show} onHide={() => onHide()} centered className={style.modalRoot}>
      <Modal.Body className={`p-4`}>
        <div className="d-flex justify-content-between align-items-center font12rem font600">
          <span>Editar nome da lista</span>
          <CgClose onClick={() => onHide()} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="my-5">
            <div className={`d-flex flex-column my-4`}>
              <label className="d-flex flex-column">
                <span className="font11rem">Novo nome</span>
                <input type="text" className={`${style.nameImput} font12rem`} {...formik.getFieldProps("name")} />
              </label>
              {formik.touched.name && formik.errors.name && <span className="text-danger">{formik.errors.name}</span>}
            </div>
          </div>
          <div className="d-flex justify-content-evenly">
            <button className="button background-grey" type="button" onClick={() => onHide()}>
              Cancelar
            </button>

            <button className="button btn-second" type="submit">
              Atualizar
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AccountWishlistEditNameModal;
