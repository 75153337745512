import { Wishlist } from 'models/Wishlist'
import { Modal } from 'react-bootstrap'
import { CgClose } from 'react-icons/cg'
import { toAbsoluteUrl } from 'utils/Functions'
import style from './AccountWishlistDeleteModal.module.css'

type Props = {
  show: boolean
  wishlist?: Wishlist
  onHide: (remove?: boolean) => void
}

function AccountWishlistDeleteModal({show, wishlist, onHide}: Props) {

  return (
    <Modal
      size="sm"
      show={show}
      className={style.modalContainer}
      centered
    >
      <Modal.Body className={`p-4`}>
        <div className='d-flex justify-content-end main-color font12rem mb-3'>
          <CgClose onClick={() => onHide()} />
        </div>
        <div className='d-flex flex-column align-items-center font12rem font600 mb-4'>
          <img src={toAbsoluteUrl('/media/general/Red-Warning.svg')} alt='img' className={style.image}/>
          <span className='text-center'>
            Tem certeza que deseja remover a lista de compras {wishlist?.name}?
          </span>
        </div>
        <div className='d-flex justify-content-center'>
          <button className={`button background-grey mx-2`} onClick={() => onHide()}>
            Não
          </button>
          <button className={`${style.deleteButton} button btn-danger mx-2`} onClick={() => onHide(true)}>
            Sim
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AccountWishlistDeleteModal
