import trackStyle from './TrackingModal.module.css'

type Props = {
  currentStep : number;
  visualStep: number;
  imagePath: string;
  date?: string;
  stepDescription: string;
  stepDescriptionAlt?: string;
  deliveryStatus: string;
}

function TrackingComponent({currentStep, visualStep, imagePath, date, stepDescription, stepDescriptionAlt, deliveryStatus}: Props) {
  return (
    <div>
        {visualStep < currentStep &&
            <div className='d-flex flex-column align-items-center px-1'>
              <div className={`${trackStyle.iconBubble}
                ${deliveryStatus === 'cancelado' ? trackStyle.canceledBubble : trackStyle.iconBubblePassed}`}>
                <img src={imagePath} alt='' className={deliveryStatus === 'cancelado' ? 'canceled-filter' : 'main-filter'}/>
              </div>
              <div className='main-color pt-2'>{date}</div>
              <div className='main-color font500 text-center pt-1'>{stepDescription}</div>
            </div>
        }
        
        {visualStep === currentStep &&
          <div className='d-flex flex-column align-items-center px-1'>
            <div className={`${trackStyle.iconBubble}
              ${deliveryStatus === 'cancelado' ? trackStyle.canceledBubble : trackStyle.iconBubbleCurrent}`}>
              <img src={imagePath} alt='' className={deliveryStatus === 'cancelado' ? 'canceled-filter' : 'main-filter'}/>
            </div>
            <div className={`${deliveryStatus === 'cancelado' ? 'canceled-filter' : 'main-color'} pt-2`}>{date}</div>
            <div className={`${deliveryStatus === 'cancelado' ? 'canceled-filter' : 'main-color'} font500 text-center pt-1`}>
              {stepDescriptionAlt ? stepDescriptionAlt : stepDescription}
            </div>
          </div>
        }

        {visualStep > currentStep &&
          <div className='d-flex flex-column align-items-center px-1'>
            <div className={`${trackStyle.iconBubbleInnactive} ${trackStyle.iconBubble}`}>
              <img src={imagePath} alt='' className='p-3'/>
            </div>
            <div className={`${trackStyle.textFadded} pt-2`}>{date}</div>
            <div className={`${trackStyle.textFadded} pt-1`}>{stepDescription}</div>
          </div>
        }
    </div>
  )
}

export default TrackingComponent
