import AOCode from 'pages/desktop/Account/AccountOutlets/AOAuthentication/AOCode'
import AODisable from 'pages/desktop/Account/AccountOutlets/AOAuthentication/AODisable'
import AODownload from 'pages/desktop/Account/AccountOutlets/AOAuthentication/AODownload'
import AOFinalize from 'pages/desktop/Account/AccountOutlets/AOAuthentication/AOFinalize'
import AOStepEnable from 'pages/desktop/Account/AccountOutlets/AOAuthentication/AOStepEnable'
import React from 'react'
import {useRoutes} from 'react-router-dom'

function TwoAuthWraper() {
  const routes = useRoutes([
    {
      path: '1',
      element: <AOStepEnable />,
    },
    {
      path: '2',
      element: <AODownload />,
    },
    {
      path: '3',
      element: <AOCode />,
    },
    {
      path: '4',
      element: <AOFinalize />,
    },
    {
      path: 'desativar',
      element: <AODisable />,
    },
  ])

  return routes
}

export default TwoAuthWraper
