import { useQuery } from '@tanstack/react-query'
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'
import { Mission } from 'models/Mission'
import { CSSProperties, useMemo, useState } from 'react'
import { ACCOUNT_MISSIONS_PAGE, ACCOUNT_ORDERS_PAGE, toUrl } from 'utils/Links'
import { list } from './core/_requestsMission'
import { Paginator } from 'components/common/Paginator/Paginator'
import MissionCard from 'components/desktop/MissionCard/MissionCard'
import styled from 'styled-components'
import Loader from 'components/desktop/Loader/Loader'

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem 3rem;
`;

const override: CSSProperties = {
    borderWidth: '4px',
}

function AODiscounts() {
    const [missions, setMissions] = useState<Mission[]>([])
    const [currentPage, setCurrentPage] = useState(0)
    const [totalPages, setTotalPages] = useState(-1)
    const [totalElements, setTotalElements] = useState(0)

    const { isLoading: isMissionLoading } = useQuery({
        queryFn: async () => {
            const response = await list(currentPage)
            setMissions((prev) => {
                if (currentPage === 0) {
                    return response?.data.content
                }
                return [...prev, ...response?.data.content]
            })
            setTotalElements(response.data.total_elements)
            setTotalPages(response.data.total_pages)
            return response
        },
        queryKey: ['listMissions', currentPage],
        refetchOnWindowFocus: false,
    })

    const hasMoreContent = useMemo(() => {
        return totalPages <= 1 ? false : totalPages >= (currentPage + 1)
    }, [currentPage, totalPages])

    const breadcrumbs = useMemo(() => {
        const result = [
                {
                    title : 'Perfil',
                    path: toUrl(ACCOUNT_ORDERS_PAGE),
                },
                {
                    title : 'Missões e descontos',
                    path : toUrl(ACCOUNT_MISSIONS_PAGE),
                }, ]
        return result    
    }, [])

  return (
    <div>
        <div className='mb-4'>
            <Breadcrumbs breadcrumbs={breadcrumbs}/>
            <span className='font600 font15rem'>MISSÕES E DESCONTOS</span>
        </div>

        {!isMissionLoading && missions &&
            <>
                <Grid>
                    {missions?.map((mission, idx) =>
                        <div key={idx}>
                            <MissionCard mission={mission}/>
                        </div>
                    )}
                </Grid>

                {totalPages > 1 &&
                    <Paginator 
                    totalPages={totalPages}
                    onChange={setCurrentPage}
                    page={currentPage}
                    />
                } 
            </>
        }

        {isMissionLoading &&
            <Loader />
        }
    </div>
  )
}

export default AODiscounts