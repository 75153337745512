import {useParams} from 'react-router-dom'
// import {BsBoxArrowInDown, BsTruck} from 'react-icons/bs'
// import {IoIosCheckboxOutline} from 'react-icons/io'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faBrazilianRealSign} from '@fortawesome/free-solid-svg-icons'
import track from './TrackDetailPage.module.css'
import PageTitle from 'components/common/PageTitle/PageTitle'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { SalesOrder } from 'models/SalesOrder'
import { orderDetails } from '../Order/core/_requests'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrazilianRealSign } from '@fortawesome/free-solid-svg-icons'
import { BsBoxArrowInDown, BsTruck } from 'react-icons/bs'
import { IoIosCheckboxOutline } from 'react-icons/io'

function TrackDetailPage() {
  const [order, setOrder] = useState<SalesOrder>()
  const { id : orderId } = useParams()

  const { isLoading: isOrderDetailLoading } = useQuery({
    queryFn: async () => {
        const response = await orderDetails(orderId!!)
        setOrder(response.data)
        return response
    },
    queryKey: ['order', orderId],
    enabled: !!orderId,
  })

  return (
    <>
     {order && 
      <>
        <PageTitle title={`Acompanhe o pedido #${order.number}`} />
        <div className={`${track.deliveryPrediction} d-flex justify-content-between px-3 py-1`}>
          <span>{order.status.description === 'Entregue' ? 'ENTREGUE NA DATA' : 'PREVISÃO DE ENTREGA'}</span>
          <span>{order.date}</span>
        </div>

        <div className='d-flex justify-content-between mt-1 main-color font500 px-3 py-1'>
          <span>Situação</span>
          <span className={`${track.orderBubbleStatus} ${'bubbleStatus' + order.status.value}`}>
            {order.status.description}
          </span>
        </div>

        {/* NEEDS BETTER DATA FROM THE BD, order.status IS JUST A PLACEHOLDER */}
        <div className='container'>
          <div className='my-5'>
            {Number(order.status.value) === 4 ? (
              <div
                className={`d-flex justify-content-start align-items-center ${track.rastreioStepOne}`}
              >
                <IoIosCheckboxOutline className={track.icon} /> <div className={track.waiting}></div>
                <span className='font500 main-color'>Suas compras foram entregues</span>
              </div>
            ) : (
              <div
                className={`d-flex justify-content-start align-items-center ${track.rastreioStepOne}`}
              >
                <IoIosCheckboxOutline className={`${track.icon} faded-color`} />{' '}
                <div className={track.faded}></div>
                <span className='font500 faded-color'>Entregue</span>
              </div>
            )}

            {Number(order.status.value) >= 4 ? (
              <span className={track.rastreioLine}></span>
            ) : (
              <span className={track.rastreioLineFaded}></span>
            )}

            {Number(order.status.value) === 3 ? (
              <div
                className={`d-flex justify-content-start align-items-center ${track.rastreioStepOne}`}
              >
                <BsTruck className={track.icon} /> <div className={track.waiting}></div>
                <span className='font500 main-color'>Suas compras saíram para a entrega</span>
              </div>
            ) : Number(order.status.value) > 3 ? (
              <div
                className={`d-flex justify-content-start align-items-center ${track.rastreioStepOne}`}
              >
                <BsTruck className={track.icon} /> <div className={track.approved}></div>
                <span className='font500 main-color'>Suas compras já foram entregues</span>
              </div>
            ) : (
              <div
                className={`d-flex justify-content-start align-items-center ${track.rastreioStepOne}`}
              >
                <BsTruck className={`${track.icon} faded-color`} />{' '}
                <div className={track.faded}></div>
                <span className='font500 faded-color'>A caminho</span>
              </div>
            )}

            {Number(order.status.value) >= 3 ? (
              <span className={track.rastreioLine}></span>
            ) : (
              <span className={track.rastreioLineFaded}></span>
            )}

            {Number(order.status.value) === 2 ? (
              <div
                className={`d-flex justify-content-start align-items-center ${track.rastreioStep}`}
              >
                <BsBoxArrowInDown className={track.icon} /> <div className={track.waiting}></div>
                <div className='d-flex flex-column main-color'>
                  <span className='font500'>Suas Compras estão sendo embaladas</span>
                  <span>{order.date}</span>
                </div>
              </div>
            ) : Number(order.status.value) > 2 ? (
              <div
                className={`d-flex justify-content-start align-items-center ${track.rastreioStep}`}
              >
                <BsBoxArrowInDown className={track.icon} /> <div className={track.approved}></div>
                <div className='d-flex flex-column main-color'>
                  <span className='font500'>Compras embaladas</span>
                  <span>{order.date}</span>
                </div>
              </div>
            ) : (
              <div
                className={`d-flex justify-content-start align-items-center ${track.rastreioStep}`}
              >
                <BsBoxArrowInDown className={`${track.icon} faded-color`} />{' '}
                <div className={track.faded}></div>
                <div className='d-flex flex-column main-color'>
                  <span className='font500 faded-color'>Compras serão embaladas</span>
                  <span>{order.date}</span>
                </div>
              </div>
            )}

            {order.status.value >= '2' ? (
              <span className={track.rastreioLine}></span>
            ) : (
              <span className={track.rastreioLineFaded}></span>
            )}

            {order.status.value === '0' ? (
              <div
                className={`d-flex justify-content-start align-items-center ${track.rastreioStep}`}
              >
                <FontAwesomeIcon icon={faBrazilianRealSign} className={track.iconFaded} />{' '}
                <div className={track.canceled}></div>
                <div className='d-flex flex-column main-color'>
                  <span className='font500'>Pagamento cancelado!</span>
                  <span>{order.date}</span>
                </div>
              </div>
            ) : order.status.value === '1' ? (
              <div
                className={`d-flex justify-content-start align-items-center ${track.rastreioStep}`}
              >
                <FontAwesomeIcon icon={faBrazilianRealSign} className={track.icon} />{' '}
                <div className={track.waiting}></div>
                <div className='d-flex flex-column main-color'>
                  <span className='font500'>Seu pagamento está aguardando aprovação</span>
                  <span>{order.date}</span>
                </div>
              </div>
            ) : (
              <div
                className={`d-flex justify-content-start align-items-center ${track.rastreioStep}`}
              >
                <img
                  src={process.env.PUBLIC_URL + '/media/general/Reais.svg'}
                  alt='Caixa'
                  className={track.icon}
                />{' '}
                <div className={track.approved}></div>
                <div className='d-flex flex-column main-color'>
                  <span className='font500'>Seu pagamento foi aprovado!</span>
                  <span>{order.date}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
      }
    </>
  )
}

export default TrackDetailPage
