import ContentLoader from 'react-content-loader'

function HorizontalCardSkeleton(width:any) {
  return (
    <ContentLoader
    speed={2}
    width={335}
    height={120}
    viewBox={`0 0 335 120`}
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb"
    >
    <rect x="409" y="85" rx="0" ry="0" width="0" height="1" />
    <rect x="12" y="0" rx="8" ry="8" width="300" height="100" />
    <rect x="300" y="8" rx="8" ry="8" width="35" height="85" />
  </ContentLoader>
  )
}

export default HorizontalCardSkeleton
