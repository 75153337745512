import { KTSVG } from 'components/common/KTSVG'
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import fakeData from 'resources/data/fake-data'
import { ACCOUNT_CARDS_PAGE, ACCOUNT_ORDERS_PAGE, toUrl } from 'utils/Links'
import * as yup from 'yup'
import DataTableBase from '../../../../components/common/Datatable/DataTableBase'
import DateSelectionTableFilter from '../AccountBill/component/DateSelectionTableFilter'
import cardsStyle from './AOStyle.module.css'
import AddNewCardModal from './modals/ModalAddNewCard/AddNewCardModal'
import ChangeCardNameModal from './modals/ModalCardName/ModalCardName'
import DeleteCardModal from './modals/ModalDeleteCard/DeleteCardModal'

function AOCards() {
  const navigate = useNavigate()
  const {cards} = fakeData
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalRemoveOpen, setIsModalRemoveOpen] = useState(false)
  const [isModalAddOpen, setIsModalAddOpen] = useState(false)
  const [activeCard, setActiveCard] = useState({} as any)
  const [newCardsList, setNewCardsList] = useState(cards)

  const breadcrumbs = useMemo(() => {
    return(
      [
        {
          title : 'Perfil',
          path: toUrl(ACCOUNT_ORDERS_PAGE),
        },
        {
          title : 'Cartões',
          path : toUrl(ACCOUNT_CARDS_PAGE),
        }
      ]
    )
  }, [])

  function showEditModal() {
    setIsModalEditOpen(true)
  }
  function showRemoveModal() {
    setIsModalRemoveOpen(true)
  }
  function showAddModal() {
    setIsModalAddOpen(true)
  }

  function nicknameChanged() {
    toast.success('Apelido atualizado com sucesso', {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
    setIsModalEditOpen(false)
  }

  function onDismiss() {
    setIsModalEditOpen(false)
    setIsModalRemoveOpen(false)
    setIsModalAddOpen(false)
  }

  function deleteSelectedCard() {
    const newList = newCardsList.filter((card) => card.id !== activeCard.id)
    setNewCardsList(newList)
    onDismiss()
    toast.success('Cartão excluido', {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  }

  const initialNicknameValues = {
    nickname: '',
  }

  const initialCardValues = {
    newpass: '',
    newpass2: '',
  }

  const onSubmit = (values:any) => {
    console.log('onSubmit', values)
  }

  const onSubmitCard = () => {
    toast.success('Cartão adicionado', {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  }

  const validationSchema = yup.object({
    nickname: yup.string().required('Campo obrigatório'),
  })

  const columns = [
    {
      name: 'Apelido',
      selector: (row:any) => row.nickname,
      cell: (row:any) => <span>{row.nickname}</span>,
    },
    {
      name: 'Bandeira',
      selector: (row:any) => row.issuing_network,
      cell: (row:any) => <span className='font500'>{row.issuing_network}</span>,
    },
    {
      name: 'Vencimento',
      selector: (row:any) => row.exp_date,
      cell: (row:any) => <span>{row.exp_date}</span>,
    },
    {
      name: 'Final',
      selector: (row:any) => {
        row.card_number.slice(-4)
      },
      cell: (row:any) => <span>{row.card_number.slice(-4)}</span>,
    },
    {
      name: 'Situação',
      selector: (row:any) => row.status,
      cell: (row:any) => (
        <span className={`badgeStatus${row.status_slug} orderbadgeStatus`}>{row.status}</span>
      ),
    },
    {
      name: <img src={process.env.PUBLIC_URL + '/media/general/Edit-Filled-Icon.svg'} alt='Edit' />,
      selector: (row:any) => row.status,
      cell: (row:any) => (
        <img
          src={process.env.PUBLIC_URL + '/media/general/Edit-Outline-Icon.svg'}
          alt='Edit'
          className='cursor-pointer'
          onClick={() => {
            showEditModal()
            setActiveCard(row)
          }}
        />
      ),
    },
    {
      name: (
        <img src={process.env.PUBLIC_URL + '/media/general/Trash-Filled-Icon.svg'} alt='Trash' />
      ),
      selector: (row:any) => row.status,
      cell: (row:any) => (
        <img
          src={process.env.PUBLIC_URL + '/media/general/Trash-Outline-Icon.svg'}
          alt='Trash'
          className='cursor-pointer'
          onClick={() => {
            showRemoveModal()
            setActiveCard(row)
          }}
        />
      ),
    },
  ]


  // MODAL CONTROL
  function handleDateSelectorChange(value: string) {
    console.log(value)
  }

  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <div>
        <span className='font600 font15rem'>CARTÕES</span>
      </div>

      <div>
        <DateSelectionTableFilter/>
      </div>

      {cards.length === 0 ? (
        <div className='d-flex justify-content-center'>
          <div className={cardsStyle.emptyPage}>
            <div className='d-flex flex-column align-items-center'>
              <KTSVG path='/media/general/Big-Cards.svg' className='pb-5' />
              <span className='pb-3 main-color font500 font12rem'>
                Nenhum cupom de desconto até o momento. Você tem acesso aos cupons de desconto
                disponíveis para aplicar na hora da compra na etapa “Detalhes do seu pedido”.
              </span>
              <div
                className='button btn-main font500 cursor-pointer'
                onClick={() => showAddModal()}
              >
                Adicionar cartão +
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={cardsStyle.card}>
          <DataTableBase columns={columns} data={newCardsList} />
        </div>
      )}

      <ChangeCardNameModal
        show={isModalEditOpen}
        onHide={onDismiss}
        onConfirm={nicknameChanged}
        text={'Deseja alterar o apelido deste cartão?'}
      />

      <DeleteCardModal
        show={isModalRemoveOpen}
        onHide={onDismiss}
        onConfirm={deleteSelectedCard}
      />

      <AddNewCardModal
        show={isModalAddOpen}
        onHide={onDismiss}
        onConfirm={onSubmitCard}
      />
    </>
  )
}

export default AOCards
