import MenuIconStyle from './MenuIcon.module.css'
import MenuImageSkeleton from '../../mobile/Skeletons/MenuImageSkeleton'

type Props = {
  hasNotification: boolean
  path: string
  loading?: boolean
}

function MenuIcon({hasNotification, path, loading = false}: Props) {

  return (
    <>
    {loading &&
      <MenuImageSkeleton />}
    
    {!loading &&
      <div className={`${MenuIconStyle.smallIcon} d-flex flex-row justify-content-center position-relative`}>    
        <img src={path} alt='img' className={MenuIconStyle.profileIcon} />
        {hasNotification && (
          <div className={MenuIconStyle.active}>
            <img src={process.env.PUBLIC_URL + '/media/general/Green-Circle.svg'} alt='img' />
          </div>
        )}
      </div>}
    </>
  )
}

export default MenuIcon
