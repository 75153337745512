import React, { useEffect, useState } from 'react';
import ticketPage from './TicketPage.module.css';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import fakeData from 'resources/data/fake-data';
import { parse } from 'date-format-parse';
import DateSelector from 'components/common/DateSelector/DateSelector';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { toAbsoluteUrl } from 'utils/Functions';

interface Ticket {
  topic: string;
  channel: string;
  creation_date: string;
  updated_date: string;
  status_slug: string;
  status_color: string;
}

function TicketPage() {
  const { tickets } = fakeData;
  const [selected, setSelected] = useState<number | null>(null);
  const [sortedTickets, setSortedTickets] = useState<Ticket[]>([]);

  useEffect(() => {
    setSortedTickets(
      [...tickets].sort((a, b) => {
        const ticketDateA = parse(a.updated_date, 'DD/MM/YYYY');
        const ticketDateB = parse(b.updated_date, 'DD/MM/YYYY');
        return ticketDateA === ticketDateB ? 0 : ticketDateA > ticketDateB ? -1 : 1;
      })
    );
  }, []);

  const toggle = (index: number) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  // MODAL CONTROL
  function handleDateSelectorChange(value: string) {
    console.log(value)
  }

  return (
    <>
      <PageTitle title={`Histórico de solicitações`} />
      <div className='container'>
        <DateSelector onChange={handleDateSelectorChange} />
        {sortedTickets.map((ticket, index) => (
          <div
            key={index}
            className={`${ticketPage.orderBubble} d-flex ${selected === index ? 'flex-column' : 'flex-row px-2 py-3'} 
            justify-content-between align-items-center my-3`}
            onClick={() => toggle(index)}
          >
            {selected === index ? (
              <>
                <div className={`d-flex flex-column justify-content-between col-12 px-3 py-1`}>
                  <div className='d-flex justify-content-end'>
                    {selected === index ? (
                      <MdOutlineKeyboardArrowUp
                        onClick={() => toggle(index)}
                        className='me-2 chevron-size'
                      />
                    ) : (
                      <MdOutlineKeyboardArrowDown className='chevron-size' />
                    )}{' '}
                  </div>
                  <div className=''>
                    <div className='d-flex justify-content-between'>
                      <span>Situação</span>
                      <div className={ticketPage.bubbleContainerFull}>
                        <div>
                          <span className={`${ticketPage.orderBubbleTicket} ${ticket.status_color}`}>
                            {ticket.status_slug}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between my-2'>
                      <span>Assunto</span>
                      <span className='font600'>{ticket.topic}</span>
                    </div>
                    <div className='d-flex justify-content-between my-2'>
                      <span>Canal</span>
                      <span  className='font600'>{ticket.channel}</span>
                    </div>
                    <div className='d-flex justify-content-between my-2'>
                      <span>Solicitação feita em</span>
                      <span className='font600'>{ticket.creation_date}</span>
                    </div>
                    <div className='d-flex justify-content-between my-2'>
                      <span>Última atualização</span>
                      <span className='font600'>{ticket.updated_date}</span>
                    </div>
                  </div>
                </div>

                {/* THERE IS NO ROUTE FOR THIS BUTTON YET */}
                <div className={`${ticketPage.ticketBottom} d-flex justify-content-center col-12`} onClick={() => ('')}>
                  <span className='py-1 pe-1'>Acompanhar atendimento</span>
                  <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Arrow-Right.svg')} alt='' className='main-filter'/>
                </div>
              </>
            ) : (
              <>
                <span style={{width:'20%'}}>{ticket.topic}</span>
                <div className={ticketPage.bubbleContainer}>
                  <div className=''>
                    <span className={`${ticketPage.orderBubbleTicket} ${ticket.status_color}`}>
                      {ticket.status_slug}
                    </span>
                  </div>
                </div>
                <span style={{ fontSize: '0.9rem' }}>atualizado: {ticket.updated_date}</span>
                <MdOutlineKeyboardArrowDown className='col-1 chevron-size' />
              </>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default TicketPage;
