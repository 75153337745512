const SelectYear = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
  
    for (let i = 0; i < 15; i++) {
      const year = currentYear + i;
      years.push(year);
    }
  
    return years.map((year) => (
      <option key={year} value={year}>{year}</option>
    ));
  };
  
  export default SelectYear;