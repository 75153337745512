export const GroceryEndpoints = {
    details: '/grocery/details',
    preferences: '/grocery/preference',
    updateDetails: '/grocery/details/edit',
    mainContactDetails: '/grocery/contact/main',
    listSecundaryContacts: '/grocery/contact/secundary/list',
    updateContact: (contactId: string) => `/grocery/edit/contact/${contactId}`,
    createContact: '/grocery/add/contact',
    contactDetails: (contactId: string) => `/grocery/contact/${contactId}`,
}
