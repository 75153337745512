import { KTSVG } from 'components/common/KTSVG';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AccountDonationTablePlaceholder: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex flex-column align-items-center">
        {/* <img
      src={toAbsoluteUrl("/media/Illustrations/Clipboard-Illustration.svg")}
      alt="rights reserved by Freepik Company"
    /> */}

        <KTSVG path="/media/general/Big-Heart.svg" className="pb-5" />
        <span className="pb-3 main-color font500 font12rem"></span>

        <span className="pb-3 font500 font11rem mt-4">Nenhuma doação por enquanto...</span>
        <span className="pb-4 font12rem">Você poderá fazer doações no ato das suas compras.</span>
      </div>
    </div>
  );
}

export default AccountDonationTablePlaceholder;
