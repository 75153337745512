import AuthenticationSteps from 'components/desktop/AuthenticationSteps/AuthenticationSteps'
import React, { useMemo } from 'react'
import {useNavigate} from 'react-router-dom'
import {
  ACCOUNT_2_FACTOR,
  ACCOUNT_ORDERS_PAGE,
  toUrl,
} from 'utils/Links'
import enableStyle from './AOAuthentication.module.css'
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'

function AOStepEnable() {
  const navigate = useNavigate()

  const breadcrumbs = useMemo(() => {
    return(
      [          
        {
          title : 'Perfil',
          path: toUrl(ACCOUNT_ORDERS_PAGE),
        },
        {    
          title : 'Autenticação',
          path : toUrl(ACCOUNT_2_FACTOR, 3),
        }
      ]
    )
  }, [])

  return (
    <>
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className='font600 font15rem'>AUTENTICAÇÃO DE DOIS FATORES</span>
      </div>

      <AuthenticationSteps step={1} />
      <div className='py-3'>
        <h4 className='mb-4'>Habilitar autenticação</h4>
        <p>A autenticação de dois fatores garante ainda mais segurança ao acessar a sua conta.</p>
        <p>
          Depois de habilitar a autenticação de dois fatores, a cada acesso realizado em sua conta
          Vupty, você irá precisar do código fornecido pelo aplicativo{' '}
          <strong>“Google Authenticator”</strong>.
        </p>
        <button
          className={`${enableStyle.authenticationButton}`}
          onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 2))}
        >
          {' '}
          Habilitar 2FA{' '}
        </button>
      </div>
    </>
  )
}

export default AOStepEnable
