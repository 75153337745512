import CookiesModalDesktop from 'components/desktop/CoockiesModal/CookiesModalDesktop';
import CookiesModalMobile from 'components/mobile/CookiesModal/CookiesModalMobile';

function CookiesModal() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
  return (
    <>
        {isMobile && 
            <CookiesModalMobile />
        }
        {!isMobile &&
            <CookiesModalDesktop />
        }
    </>
  )
}

export default CookiesModal