import ProgressRewardBarModal from "layouts/common/ProgressRewardBarModal/ProgressRewardBarModal";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import { Product } from "models/Product";
import { FC, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { CgClose } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { sortByItemCreationDate, toAbsoluteUrl, toBRL } from "utils/Functions";
import { STORE_FRONT_CART_PAGE, STORE_FRONT_PAGE, toUrl } from "utils/Links";
import ModalCartItem from "../ModalCartItem/ModalCartItem";
import styles from "./ModalCart.module.css";

type Props = {
  open: boolean;
  onHide: () => void;
};

const ModalCart: FC<Props> = ({ open, onHide }) => {
  const {
    deleteItemFromCart,
    addItemCart,
    removeItemCart,
    updateCartItem,
    cart: { items, items_total, total_items_discount, total_mission_discount },
  } = useStoreCart();
  const navigate = useNavigate();

  const cartItems = useMemo(() => {
    if (!items) return [];
    const result = [...items];
    //Sort by item creation date
    result.sort(sortByItemCreationDate);
    return result;
  }, [items]);

  const handleFullShoppingCartBtnClick = () => {
    navigate(toUrl(STORE_FRONT_CART_PAGE));
    onHide();
  };

  const handleEmptyShoppingCartBtnClick = () => {
    navigate(toUrl(STORE_FRONT_PAGE));
    onHide();
  };

  const handleDeleteItem = (item: any) => {
    deleteItemFromCart(item);
    // toast.warn('Produto excluido', {
    //   position: 'top-right',
    //   autoClose: 4000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: 'light',
    // })
  };

  const handleProductAdd = (product: Product) => {
    addItemCart(product, 1);
  };

  const handleProductRemove = (product: Product) => {
    removeItemCart(product, 1);
  };

  const handleProductChange = (product: Product, quantity: number) => {
    updateCartItem(product, quantity);
  };

  return (
    <Modal
      size={`lg`}
      show={open}
      onHide={onHide}
      dialogClassName={styles.customAnimation}
      className={styles.modal}
      style={{ position: "absolute", right: "0px" }}
    >
      <Modal.Body className={styles.carrinhoBody}>
        <div className="d-flex justify-content-between align-items-center p-3">
          <CgClose onClick={onHide} className="cursor-pointer" />
          <div className="d-flex">
            <img src={toAbsoluteUrl("/media/general/Logo100px.svg")} alt="Caixa" className={styles.cartIcon} />
            <span className={styles.carrinhoTitle}>MEU CARRINHO</span>
          </div>
          <div></div>
        </div>

        {cartItems.length === 0 && (
          <div className="d-flex flex-column align-items-center mt-4">
            <img src={toAbsoluteUrl("/media/general/Plus-Magnifing.svg")} alt="magnifying-glass" />
            <div className="d-flex flex-column align-items main-color font500 font12rem my-4">
              <span>Seu carrinho está vazio por enquanto... </span>
              <span>Adicione os primeiros itens na nossa loja!</span>
            </div>
            <button className={`${styles.cartBtn} mb-3`} onClick={handleEmptyShoppingCartBtnClick}>
              <img src={toAbsoluteUrl("/media/ChevronsAndArrows/Arrow-Left.svg")} alt="" />
              <span className="ps-2">Voltar para a loja</span>
            </button>
          </div>
        )}
        {cartItems.length > 0 && (
          <>
            <ProgressRewardBarModal className={styles.missionContainer} />
            <div className={`${styles.container}`}>
              {cartItems.map((item, idx) => (
                <ModalCartItem
                  key={idx}
                  item={item}
                  handleProductRemove={handleProductRemove}
                  handleProductChange={handleProductChange}
                  handleProductAdd={handleProductAdd}
                  handleDeleteItem={handleDeleteItem}
                />
              ))}
            </div>

            <div className={`${styles.footer} container`}>
              <div className="d-flex align-items-center justify-content-around">
                <div className={styles.textPartialRight}>
                  <span className="font09rem">SUBTOTAL</span>
                  <span className="font600">{toBRL(items_total - total_items_discount - total_mission_discount)}</span>
                </div>
                <button className={`${styles.cartBtn}`} onClick={handleFullShoppingCartBtnClick}>
                  <span className="pe-2">Ir para o carrinho</span>
                  <img src={toAbsoluteUrl("/media/ChevronsAndArrows/Arrow-Right.svg")} alt="" />
                </button>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default ModalCart;
