import React, { CSSProperties, useEffect, useState } from 'react'
import loaderStyle from './Loader.module.css'
import ClipLoader from 'react-spinners/ClipLoader';

const override: CSSProperties = {
    borderWidth: '4px',
}

function Loader() {
    const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount(prevCount => (prevCount % 3) + 1);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='d-flex flex-column justify-content-center align-items-center mt-3'>
        <ClipLoader
            color="#033858"
            size={42}
            cssOverride={override}
        />
            <span className='main-color font500 mt-3'>
                Carregando<span className={loaderStyle.ellipsis}>{'.'.repeat(dotCount)}</span>
            </span>
    </div>
  );
};

export default Loader