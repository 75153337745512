import { useFormik } from 'formik'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { CgClose } from 'react-icons/cg'
import * as Yup from 'yup';
import style from './ModalCardName.module.css'

type Props = {
    show: boolean
    onHide: () => void
    onConfirm: () => void
    text: string
}

function ChangeCardNameModal({show, onHide, onConfirm, text}: Props) {

    const nicknameSchema = Yup.object().shape({
        nickName: Yup.string()
        .min(3, 'Minimo de 3 símbolos')
        .max(64, 'Máximo de 50 símbolos')
        .required('Digite um e-mail válido'),
    })    

    const formik = useFormik({
        initialValues: {
          nickName: '',
        },
        validationSchema: nicknameSchema,
        onSubmit: async (values, formikHelpers) => {
        //   handleFormSubmit(values,formikHelpers)
        },
    })

  return (
        <Modal
            size='sm'
            show={show}
            onHide={onHide}
            centered
            className={style.modalRoot}>
            <Modal.Body className={`p-4`}>
              <div className='d-flex justify-content-end'>
                <CgClose onClick={onHide} />
              </div>  
              <div className='d-flex justify-content-center align-items-center font12rem font600'>
                <span>{text}</span>
              </div>
                  <form>
                    <div className='my-5'>
                      <div className={`d-flex flex-column my-4`}>
                        <label className='d-flex flex-column'>
                            <span className='font11rem'>Novo nome</span>
                            <input name='nickname' className={`${style.nicknameImput} font12rem`} />
                        </label>
                        {formik.touched.nickName && formik.errors.nickName && (
                            <span className='text-danger'>{formik.errors.nickName}</span>
                        )}
                      </div>
                    </div>
                    <div className='d-flex justify-content-evenly'>
                      <button
                        className='button background-grey'
                        type='button'
                        onClick={onHide}>
                        Cancelar
                      </button> 

                      <button
                        className='button btn-second'
                        type='submit'
                        onClick={onConfirm}>
                        Atualizar
                      </button>
                    </div>
                  </form>
            </Modal.Body>
          </Modal>
  )
}

export default ChangeCardNameModal