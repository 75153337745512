
import { useQuery } from '@tanstack/react-query'
import { useGrocery } from 'layouts/common/core/GroceryProvider'
import { useStoreLocation } from 'layouts/common/core/StoreLocationProvider'
import { useAuth } from 'modules/auth/core/AuthProvider'
import { forwardRef, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from 'utils/Functions'
import { ACCOUNT_NOTIFICATIONS_PAGE, CITY_LOCALIZER_PAGE, toUrl } from 'utils/Links'
import navbar from './Navbar.module.css'
import { list } from './core/_requests'

function Navbar(props: any, ref: any) {
    const { auth } = useAuth()
    const { groceryDetails } = useGrocery()
    const navigate = useNavigate()
    const [notifications, setNotifications] = useState<Notification[]>([])
    const [totalElements, setTotalElements] = useState(0)
    const { storeLocation } = useStoreLocation();

    const companyName = useMemo(() => {
        if (!groceryDetails) return
        return groceryDetails.company_name?.toUpperCase()
    }, [groceryDetails])

    const { isFetching: isNotificationFetching } = useQuery({
        queryFn: async () => {
            const response = await list()
            setNotifications(response?.data.content)
            setTotalElements(response.data.total_elements)
            return response
        },
        queryKey: ['listNotification'],
        enabled: !!auth,
        refetchOnWindowFocus: false,
    })

    return (
        <header className={navbar.container} >
            {auth &&
                <div className={`${navbar.marketInfoContainer} container`} ref={ref}>
                    <div className='d-flex' onClick={() => navigate(toUrl(CITY_LOCALIZER_PAGE))}>
                        <img src={toAbsoluteUrl('/media/general/Another-Profile-Icon.svg')} alt='Profile' className={navbar.profileIcon} />
                        <span className={navbar.marketName} >
                            <span>{companyName}</span>
                            <div className={navbar.cityName}>
                                <span>{storeLocation?.region.name}</span>
                            </div>
                        </span>
                    </div>
                    <div className='d-flex' onClick={() => navigate(toUrl(ACCOUNT_NOTIFICATIONS_PAGE))}>
                        <img src={toAbsoluteUrl('/media/general/Bell-Small.svg')} alt='Profile' />
                        {notifications.length > 0 &&
                            <div className={navbar.notificationAlert} />
                        }
                    </div>
                </div>
            }
        </header>
    );
}

export default forwardRef(Navbar)
