import { Modal } from 'react-bootstrap'
import { toAbsoluteUrl } from 'utils/Functions'
import style from './AccountWishlistAddAllModal.module.css'

type Props = {
    show: boolean
    onHide: (add?: boolean) => void
}

function AccountWishlistAddAllModal({show, onHide}: Props) {
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onHide}
      className={style.modalContainer}
      centered>

      <Modal.Body className={`p-4`}>
        <div className='d-flex justify-content-end main-color font12rem mb-3'>
            <img
            src={toAbsoluteUrl('/media/general/X-icon.svg')} alt=''
            onClick={() => onHide()}
            height={'14px'}
            className='cursor-pointer' />
        </div>
        <div className='text-center mb-3 d-flex flex-column align-items-center font11rem font500'>
          <img src={toAbsoluteUrl('/media/general/Check-Icon.svg')} alt='img' className={style.image}/>
          <span className='mb-3'>
            Ao confirmar, todos os itens dessa lista irão para o seu carrinho de compras. Deseja continuar?
          </span>
        </div>
        <div className='d-flex justify-content-evenly'>
          <button className='button background-grey' onClick={() => onHide()}>
            Não
          </button>
          <button className='button btn-second' onClick={() => onHide(true)}>
            Sim
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AccountWishlistAddAllModal
