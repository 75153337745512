export const getCookieItem = (item : any) => {
        const cookies : any = document.cookie
            .split(';')
            .map(cookie => cookie.split('='))
            .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
        return cookies[item];
    }


export const setCookieItem = (item : any, value : any) => {
        document.cookie = `${item}=${value};`
    }