
// export const WishlistEndpoints = {
//     list : () => {
//         return get('/account/wishlist/list')
//     },
//     create: (wishlist : Wishlist) => {
//         return post('/account/wishlist/add', wishlist)
//     },
//     update: (wishlist : Wishlist, id : string) => {
//         return put('/account/wishlist/edit/' + id, wishlist)
//     },
//     remove: (id : string ) => {
//         return destroy('/account/wishlist/del/' + id)
//     },
//     info: (id : string) => {
//         return get('/account/wishlist/' + id)
//     }
// }

export const WishlistEndpoints = {
    list: '/wishlist/list',
    create: '/wishlist/add',
    update: (id: string) => `/wishlist/edit/${id}`,
    remove: (id: string) => `/wishlist/del/${id}`,
    info: (id: string) => `/wishlist/${id}`
}
