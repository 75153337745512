import React from 'react'
import AuthenticationSteps from 'components/mobile/AuthenticationSteps/AuthenticationSteps'
import {useNavigate} from 'react-router-dom'
import {ACCOUNT_2_FACTOR, toUrl} from 'utils/Links'
import PageTitle from 'components/common/PageTitle/PageTitle'
import downloadStyle from './StepEnablePage.module.css'
import { toAbsoluteUrl } from 'utils/Functions'

function StepDownloadPage() {
  const navigate = useNavigate()

  return (
    <>
      <PageTitle title={`AUTENTICAÇÃO DE DOIS FATORES`} />
      <AuthenticationSteps altStep={2} />
      <div className='container py-4 px-4 mb-5'>
        <div className='font600 font11rem'>Download do aplicativo Google Authentication</div>
        <div className='d-flex justify-content-center my-3'>
          <img
            src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Google_Authenticator_for_Android_icon.svg/296px-Google_Authenticator_for_Android_icon.svg.png?20201101110526'
            alt='google.logo'
            style={{width: '65px'}}
          />
        </div>
        <p className={downloadStyle.authenticatorParagraph}>
          Veja como fazer o download do aplicativo Google Authentication
        </p>
        <ol type='1'>
          <li>
            Vá até a loja de aplicativos do seu celular e na caixa de pesquisa, digite{' '}
            <strong>“Google Authenticator”</strong>;
          </li>
          <li>
            Entre os aplicativos que irão aparecer na busca, procure pelo que corresponde ao ícone
            acima e selecione;
          </li>
          <li>
            Em seguida, na página principal do aplicativo, pressione a opção de
            baixar/download/obter;
          </li>
          <li>
            Aguarde até que a instalação seja completada e, por fim, abra o aplicativo para seguir
            com a ativação da autenticação de dois fatores.
          </li>
        </ol>
        <button
          className={`${downloadStyle.authenticationButton} col-12`}
          onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 3))}
        >
          Próxima etapa{' '}
          <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Arrow-Right.svg')} alt='' className='transform-1px'/>
        </button>
      </div>
    </>
  )
}

export default StepDownloadPage
