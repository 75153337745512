import { useRoutes } from 'react-router-dom'
import DisablePage from './DisablePage'
import StepCodePage from './StepCodePage'
import StepDownloadPage from './StepDownloadPage'
import StepEnablePage from './StepEnablePage'
import StepErrorConclusion from './StepErrorConclusion'
import StepFinalizePage from './StepFinalizePage'
import StepSuccessConclusion from './StepSuccessConclusion'

function TwoFactorAuthWrapper() {
  const routes = useRoutes([
    {
      path: '1',
      element: <StepEnablePage />,
    },
    {
      path: '2',
      element: <StepDownloadPage />,
    },
    {
      path: '3',
      element: <StepCodePage />,
    },
    {
      path: '4',
      element: <StepFinalizePage />,
    },
    {
      path: 'sucesso',
      element: <StepSuccessConclusion />,
    },
    {
      path: 'erro',
      element: <StepErrorConclusion />,
    },
    {
      path: 'desativar',
      element: <DisablePage />,
    },
  ])

  return routes
}

export default TwoFactorAuthWrapper
