import { useQuery } from '@tanstack/react-query';
import PageTitle from 'components/common/PageTitle/PageTitle';
import StoreCartBar from 'components/mobile/StoreCartBar/StoreCartBar';
import { useStoreCart } from 'layouts/common/core/StoreCartProvider';
import ProductCardWishlist from 'layouts/mobile/ProductCard/ProductCardWishlist';
import { ProductWithQuantity } from 'models/StoreCart';
import { WishlistItem, WishlistItemGroup } from 'models/Wishlist';
import { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useIsFirstRender } from 'usehooks-ts';
import { getActualProductUnitPrice, getRootCategory, toAbsoluteUrl, toBRL } from 'utils/Functions';
import { ACCOUNT_SUGGESTED_WHISHLIST_PAGE, toUrl } from 'utils/Links';
import ListDetailStyle from './SuggestedList.module.css';
import { detail } from './core/_requests';

function SuggestedListDetailPage() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { addItemCart, addItemsCart, cart: { items } } = useStoreCart()
    const [currentPage, setCurrentPage] = useState(0)
    const [totalPages, setTotalPages] = useState(-1)
    const [isModalRemoveWishlistOpen, setIsModalRemoveWishlistOpen] = useState(false)
    const [isModalAddWishlistToCartOpen, setIsModalAddWishlistToCartOpen] = useState(false)
    const [itemsGrouped, setItemsGrouped] = useState<WishlistItemGroup[]>()
    const initialRender = useIsFirstRender()
    const videoRef = useRef<HTMLVideoElement>(null);

    const { data: wishlist, isLoading: isWishlistLoading } = useQuery({
        queryFn: async () => {
          const response = await detail(id!!);
          return response.data;
        },
        queryKey: ["wishlist", id],
        enabled: id !== undefined,

        // queryFn: async () => {
        //     const response = await detail(id!!)
        //     setListDetails((prev) => {
        //         if (currentPage === 0) {
        //             return response?.data.content
        //         }
        //         return [...prev, ...response?.data.content]
        //     })
        //     setTotalElements(response.data.total_elements)
        //     setTotalPages(response.data.total_pages)
        //     setIsLoadingMore(false)
        //     return response
        // },
        // queryKey: ['listNotification', currentPage],
        // refetchOnWindowFocus: false,
    });

    const toggleItemsGroupVisibility = (position: number) => {
        if (!itemsGrouped) return
        const updatedItemsGrouped = itemsGrouped.map((itemsGroup: any, itemGroupIdx: number) => {
            if (itemGroupIdx === position) {
                return { ...itemsGroup, open: !itemsGroup.open }
            }
            return itemsGroup
        })
        setItemsGrouped(updatedItemsGrouped);
    }
    //mount items grouped
    useEffect(() => {

        if (!wishlist || !wishlist.items) return

        const result: WishlistItemGroup[] = []

        wishlist.items.forEach((item) => {
            const rootCategory = getRootCategory(item.product.category)
            const itemGroupIdx = result.findIndex((itemGroup) => {
                return itemGroup.category === rootCategory
            })
            const bestUnitPrice = getActualProductUnitPrice(item.product, item.quantity)

            if (itemGroupIdx === -1) {
                result.push({
                    category: rootCategory,
                    items: [item],
                    total: item.quantity * bestUnitPrice,
                    total_quantity: item.quantity,
                    open: true,
                })
            } else {
                result[itemGroupIdx].items.push(item)
                result[itemGroupIdx].total = result[itemGroupIdx].items.reduce((total, item) => {
                    const bestUnitPrice = getActualProductUnitPrice(item.product, item.quantity)
                    return total + (item.quantity * bestUnitPrice)
                }, 0)
                result[itemGroupIdx].total_quantity = result[itemGroupIdx].items.reduce((total, obj) => {
                    return total + obj.quantity
                }, 0)
            }
        })

        const updatedItemsGroup = result.sort((a, b) => {
            //ordena por ordem alfabética
            return a.category.localeCompare(b.category)
        })
        setItemsGrouped(updatedItemsGroup);
    }, [wishlist])

    const handleAddWishlistItemsToCartClick = () => {
        setIsModalAddWishlistToCartOpen(false)
        if (!wishlist || !wishlist.items) return

        const itemsToAdd : ProductWithQuantity[] = wishlist.items.map((item) => ({
            product: item.product,
            quantity: item.quantity,
        }))
        addItemsCart(itemsToAdd)
    }

    const hasMoreContent = useMemo(() => {
        return totalPages <= 1 ? false : totalPages >= (currentPage + 1)
    }, [currentPage, totalPages])

    const onLoadMoreContent = (page: number) => {
        // if (isProductLoading || currentPage >= totalPages - 1) return
        // setCurrentPage((prev) => prev + 1)
        // setIsLoadingMore(true)
    }

    const handleWishlistItemAddToCart = (item: WishlistItem) => {
        addItemCart(item.product, item.quantity)
        toast.success('Produto adicionado ao carrinho', {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        })
    }

    const handleWilistItemChange = (item: WishlistItem) => {
        if (!itemsGrouped) return
        const updatedItemsGrouped = itemsGrouped?.map((itemGroup) => {
            const updatedItemGroupItems = itemGroup.items.map((wishlistItem) => {
                if (wishlistItem.id === item.id) {
                    return item
                }
                return wishlistItem
            })
            return {
                ...itemGroup, items: updatedItemGroupItems
            }
        })
        setItemsGrouped(updatedItemsGrouped)
    }

  return (
    <>
        {(wishlist?.items && wishlist.items.length > 1) &&
            <div className='padding-bottom-cart-mobile'>
                <PageTitle title={`LISTA `} />
                <InfiniteScroll
                    className='w-100'
                    pageStart={0}
                    initialLoad={false}
                    loadMore={onLoadMoreContent}
                    hasMore={hasMoreContent}
                >
                    {itemsGrouped?.map((itemGroup, idx) => (
                        <div key={idx} className={`${ListDetailStyle.container} container`}>
                            <div className={`${ListDetailStyle.cartItemClassDesktop}`} onClick={() => toggleItemsGroupVisibility(idx)}>
                                <div className='d-flex justify-content-between w-100 mb-2 mt-3'>
                                    <span className={ListDetailStyle.itemGroupName}>{itemGroup.category}</span>
                                    <div>
                                        <button className={ListDetailStyle.cartItemClassButton}>
                                            {itemGroup.items.length} &nbsp;
                                            {itemGroup.items.length > 1 ? 'itens' : 'item'} - {toBRL(itemGroup.total)}
                                        </button>
                                        <img src={'/media/ChevronsAndArrows/Chevron-Down.svg'} alt='' className={`${itemGroup.open ? 'chevronDown' : 'chevronUp'} ms-1`} />
                                    </div>
                                </div>
                            </div>
                            {itemGroup.open && itemGroup.items.map((item, idx) => (
                                <ProductCardWishlist
                                key={item.id}
                                item={item}
                                onAddToCart={handleWishlistItemAddToCart}
                                onChange={handleWilistItemChange}
                                />
                            ))}
                        </div>
                    ))}
                </InfiniteScroll>
                <StoreCartBar />
            </div>
        }

        {!wishlist &&
            <div className='container d-flex flex-column justify-content-center'>
                <img src={'/media/Illustrations/Doubt-Illustration-Ballon.svg'} alt=''/>
                <span>
                    Não encontramos a lista de número:
                    <span className='font500'>#{id}</span> retorne a página de listas
                    <span className={ListDetailStyle.linkStyle} onClick={toUrl(ACCOUNT_SUGGESTED_WHISHLIST_PAGE)}>clicando aqui</span>
                </span>
            </div>
        }

        {isWishlistLoading &&
            <div className='d-flex flex-column align-items-center'>
                <video ref={videoRef} autoPlay loop muted>
                    <source src={toAbsoluteUrl('/media/Animations/Animação-Carrinho.webm')} type="video/webm" />
                </video>
                    <span className='main-color font500 font12rem mt-4'>Carregando...</span>
            </div>
        }
    </>
  )
}

export default SuggestedListDetailPage
