/* eslint-disable react-hooks/exhaustive-deps */
import { useStoreConfig } from 'layouts/common/core/StoreConfigProvider'
import { useEffect } from 'react'

type Props = {
    title?: string
    backButton?: boolean
    backButtonAction?: () => void
}

function PageTitle({ title = '', backButton, backButtonAction }: Props) {
    const { updateTopbarConfig } = useStoreConfig()

    useEffect(() => {
        updateTopbarConfig({
            title: title,
            visible: title.length > 0,
            backButtonAction: backButtonAction,
            backButtonEnabled: backButton === true || typeof backButton === 'undefined'
        })
    }, [title, backButton])

    return <></>
}

export default PageTitle
