import { useMutation, useQuery } from '@tanstack/react-query';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { useFormik } from 'formik';
import { GroceryPersonOfContact, initialGroceryMainContact } from 'models/PersonOfContact';
import { ApiResponseError } from 'modules/api/core/_models';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from 'utils/Functions';
import * as Yup from 'yup';
import { getGroceryMainContact, updateGroceryMainContact } from './core/_requests';
import styles from './ProfileContactStyle.module.css';

const mainContactValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Digite o nome do contato'),
    telephone_number: Yup.string()
        .required('Digite um telefone valido')
        .test(
            'Digite um telefone valido',
            (value: any) => /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/g.test(value)
        ),
    email: Yup.string()
        .email('Digite um e-mail válido')
        .min(3, 'Minimo de 3 símbolos')
        .max(50, 'Máximo de 50 símbolos')
        .required('Digite um e-mail válido'),
})


function ProfileOwnerPage() {
    const navigate = useNavigate()
    const [contact, setContact] = useState<GroceryPersonOfContact>(initialGroceryMainContact)

    const partialUpdate = (fieldsToUpdate: Partial<GroceryPersonOfContact>) => {
        const updatedContact = { ...contact, ...fieldsToUpdate }
        setContact(updatedContact)
    }

    const { isLoading: isContactLoading } = useQuery({
        queryFn: async () => {
            const response = await getGroceryMainContact()
            partialUpdate(response.data)
            return response
        },
        queryKey: ['mainContactDetails'],
    })

    const { mutate: sendUpdateRequest } = useMutation({
        mutationFn: async (contact: GroceryPersonOfContact) => {
            const response = await updateGroceryMainContact(contact)
            return response
        },
        mutationKey: ['mainContactDetails'],
        onSuccess: () => {
            toast.success('Dados atualizados com sucesso')
            navigate(-1)
        },
        onError: (error) => {
            if (error instanceof ApiResponseError) {
                toast.error(error.getErrorMessage())
            } else {
                toast.error('Erro ao atualizar dados')
            }
        }
    })

    const formik = useFormik<GroceryPersonOfContact>({
        initialValues: contact,
        validationSchema: mainContactValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            sendUpdateRequest(values)
        },
    })

    return (
        <>
            <PageTitle title={`Contato do proprietário(a)`} />
            <form
                onSubmit={formik.handleSubmit}
                noValidate
                id='contact_form'
                className='container'>
                <div className='mb-3'>
                    <label htmlFor='name' className='sr-only'>
                        Nome*
                    </label>
                    <input
                        type='text'
                        className='form-control'
                        {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                        <span className='text-danger'>{formik.errors.name}</span>
                    )}
                </div>

                <div className='mb-3'>
                    <label htmlFor='text' className='sr-only'>
                        Telefone*
                    </label>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='(+55) 83 ***** ****'
                        {...formik.getFieldProps('telephone_number')}
                    />
                    {formik.touched.telephone_number && formik.errors.telephone_number && (
                        <span className='text-danger'>{formik.errors.telephone_number}</span>
                    )}
                </div>

                <div className='mb-3'>
                    <label htmlFor='text' className='sr-only'>
                        E-mail*
                    </label>
                    <input
                        type='text'
                        className='form-control'
                        {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <span className='text-danger'>{formik.errors.email}</span>
                    )}
                </div>
            </form>

            <div className={`${styles.addButton}`} onClick={() => formik.handleSubmit()}>
                <span>
                    Salvar Informações
                    <img src={toAbsoluteUrl('/media/general/Check-Green-Icon.svg')} alt='' className={styles.checkicon} />
                </span>
            </div>
        </>
    )
}

export default ProfileOwnerPage
