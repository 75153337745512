import { regionalClient } from "index";
import { SearchCriteria } from "models/SearchCriteria";
import { DonationEndpoints } from "modules/api/endpoints/DonationEndpoints";
import qs from "qs";

export const list = (filters: SearchCriteria[], page: number, perPage: number = 10) => {
    return regionalClient.get(DonationEndpoints.list, {
      params: {
        search: "",
        filters: filters,
        page: page,
        per_page: perPage,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { allowDots: true });
      },
    })
}
