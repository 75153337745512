import { Product } from "models/Product";
import { ChangeEvent, useState } from "react";
import ProductCounterSkeleton from "../Skeletons/ProductCounterSkeleton";
import styles from "./ProductWishlistCounterDesktop.module.css";

type Props = {
  product: Product;
  quantity?: number;
  loading?: boolean;
  title?: string;
  style?: string;
  onAdd: (product: Product) => void;
  onRemove: (product: Product) => void;
  onChange: (product: Product, quantity: number) => void;
  editable?: boolean;
};

function ProductWishlistCounterDesktop({
  product,
  quantity = 0,
  loading = false,
  title = "ADICIONAR",
  onChange,
  onAdd,
  onRemove,
  editable = false,
}: Props) {
  const [actualQuantity, setActualQuantity] = useState<number>(quantity);

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Check if the value is a valid number using a regular expression
    const isValidNumber = /^-?\d+$/.test(value);

    let newQty = 1;
    if (isValidNumber) {
      newQty = parseInt(value, 10);
    }

    setActualQuantity(newQty);

    if (newQty <= 0) {
      onRemove(product);
      return;
    }
    onChange(product, newQty);
  };

  const handleAddClick = () => {
    setActualQuantity((prev) => prev + 1);
    onAdd(product);
  };

  const handleRemoveClick = () => {
    setActualQuantity((prev) => prev - 1);
    onRemove(product);
  };

  return (
    <>
      {loading && <ProductCounterSkeleton />}
      {!loading && actualQuantity === 0 && (
        <button onClick={handleAddClick} className={styles.addNew}>
          <div className={styles.addToCart}>
            <img src={process.env.PUBLIC_URL + "/media/general/Cart.svg"} alt="img" />
            <p>{title}</p>
          </div>
        </button>
      )}
      {!loading && actualQuantity > 0 && (
        <div className={styles.itemCardCounter}>
          <button onClick={handleRemoveClick} className={styles.remove} disabled={actualQuantity <= 1 || !editable}>
            -
          </button>
          <input
            type="number"
            className={styles.barCounter}
            value={actualQuantity}
            onChange={handleQuantityChange}
            disabled={!editable}
          />
          <button onClick={handleAddClick} className={styles.add} disabled={!editable}>
            +
          </button>
        </div>
      )}
    </>
  );
}

export default ProductWishlistCounterDesktop;
