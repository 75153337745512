import { useQuery } from "@tanstack/react-query"
import { createColumnHelper } from "@tanstack/react-table"
import SimpleTable from "components/common/SimpleTable/SimpleTable"
import Breadcrumbs from "components/common/breadcrumbs/Breadcrumbs"
import { InvoiceItem } from "models/InvoiceItem"
import { SalesOrder } from "models/SalesOrder"
import moment from "moment"
import { useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import fakeData from "resources/data/fake-data"
import { DATE_USUAL } from "utils/Consts"
import { toAbsoluteUrl, toBRL } from "utils/Functions"
import { ACCOUNT_ORDERS_PAGE, INVOICE_PAGE, INVOICE_PRINT, toUrl } from "utils/Links"
import invoiceStyle from "./AOStyle.module.css"
import { orderDetails } from "./core/_requestsOrder"
import Loader from "components/desktop/Loader/Loader"


function AOInvoice() {
    const {users} = fakeData;
    const { id : orderId } = useParams()
    const navigate = useNavigate()
    // MENTIONS OF RECEIPT NEEDS TO CHANGE AFTER BD UPDATE
    const [order, setOrder] = useState<SalesOrder>()


    const { isLoading: isOrderDetailLoading } = useQuery({
      queryFn: async () => {
          const response = await orderDetails(orderId!!)
          setOrder(response.data)
          return response
      },
      queryKey: ['order', orderId],
      enabled: !!orderId,
    })

    const delivery = 15
    const donation = 20

    // NEEDS FIXING
    const breadcrumbs = useMemo(() => {
        const result =  [
            {
                title : 'Perfil',
                path: toUrl(ACCOUNT_ORDERS_PAGE),
            },
            {
                title : 'Pedidos',
                path : toUrl(ACCOUNT_ORDERS_PAGE),
            },
            {
                title : `Fatura #${order?.number}`,
                path : toUrl(INVOICE_PAGE, order?.id),
            } ]
        return result
    }, [order])

    const columnHelper = createColumnHelper<InvoiceItem>();

    const columns = [        
        columnHelper.accessor('product.description', {
            header: () => 'DESCRIÇÃO',
            cell: (info) => {
                const productDescription = info.getValue();
                const productId = info.row.original.product?.id;
                return (
                    <div className="d-flex flex-column">
                        <span className="font500">{productDescription}</span>
                        <span className="font08rem faded-color ellipsis">Cód: {productId}</span>
                    </div>
                )
            },
        }),
        columnHelper.accessor('unit_price', {
            header: () => 'UNIDADE',
            cell: (info) => {
                return (
                    <span>
                        {toBRL(info.row.original.unit_price)}/un
                    </span>
                )
            },
        }),
        columnHelper.accessor('quantity', {
            header: () => 'QTD',
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor('total_price', {
            header: () => 'TOTAL',
            cell: (info) => {
                return (
                    <span>
                        {toBRL(info.row.original.total_price)}
                    </span>
                )
            },
        }),
    ]

    return (
        <div>
            <div>
                <Breadcrumbs breadcrumbs={breadcrumbs}/>
                <span className='font600 font15rem'>FATURA</span>
            </div>

            {order &&
                <>
                    <div className={invoiceStyle.invoiceContainer}>

                        <div className={invoiceStyle.invoiceLogo}>
                            <img src={toAbsoluteUrl('/media/logos/Vupty-Logo-160px.svg')} alt=''/>
                        </div>

                        <div className="d-flex flex-column mt-3">
                            <div>
                                <span className="font500">CNPJ:</span> {users[0].cnpj}
                            </div>
                            <div className="d-flex flex-column">
                                <div><span className="font500">Endereço: </span>{users[0].address.street}, {users[0].address.number}</div>
                                <span>{users[0].address.neighbourhood}</span>
                                <span>{users[0].address.city} - {users[0].address.state.toUpperCase()}, {users[0].address.zip_code}</span>
                            </div>
                            <div>
                                <span className="font500">Telefone comercial:</span> {users[0].phone}
                            </div>
                            <div>
                                <span className="font500">E-mail:</span> {users[0].email}
                            </div>
                        </div>

                        <div className="d-flex justify-content-between font600 font12rem bottom-line mt-3">
                            <span>Resumo da fatura atual</span>
                            <span>ID {order.number}</span>
                        </div>
                        <div>
                            <div className="d-flex justify-content-between font500 font11rem">
                                <span>PRODUTOS - {toBRL(order.total_items_price)}</span>
                                <span>TOTAL - {toBRL(order.total_price)}</span>
                            </div>
                            <div className="d-flex flex-column">
                                <div>
                                    <span className="font500">Data de emissão:</span> {moment(order.modification_date).format(DATE_USUAL)}
                                </div>
                                <div>
                                    <span className="font500">Fatura referente ao pedido de N°:</span> {order.number}
                                </div>
                                <div>
                                    <span className="font500">Método de pagamento:</span> 
                                    {/* GET IT BACK LATER */}
                                    {/* {order.form_of_payment.description} */}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between font600 font12rem bottom-line mt-3">
                            <span>Sobre a cobrança</span>
                        </div>
                        <div>
                            <div className="d-flex flex-column">
                                <div>
                                    <span className="font500">Empresa:</span> {users[0].company_name}
                                </div>
                                <div>
                                    <span className="font500">CNPJ:</span> {users[0].cnpj}
                                </div>
                                <div className="d-flex flex-column">
                                    <div><span className="font500">Endereço: </span>{users[0].address.street}, {users[0].address.number}</div>
                                    <span>{users[0].address.neighbourhood}</span>
                                    <span>{users[0].address.city} - {users[0].address.state.toUpperCase()}, {users[0].address.zip_code}</span>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between font600 font12rem bottom-line mt-3">
                            <span>Entrega</span>
                            <span>{toBRL(delivery)}</span>
                        </div>

                        <div>
                            <div className="d-flex flex-column">
                                <div>
                                    <span className="font500">Empresa:</span> {users[0].company_name}
                                </div>
                                <div>
                                    <span className="font500">CNPJ:</span> {users[0].cnpj}
                                </div>
                                <div className="d-flex flex-column">
                                    <div><span className="font500">Endereço: </span>{users[0].address.street}, {users[0].address.number}</div>
                                    <span>{users[0].address.neighbourhood}</span>
                                    <span>{users[0].address.city} - {users[0].address.state.toUpperCase()}, {users[0].address.zip_code}</span>
                                </div>
                            </div>
                        </div>

                        {/* TABLE */}
                        <div className={`${invoiceStyle.tablesContainer} mt-3`}>
                            {order.items &&
                                <>
                                    <span className={invoiceStyle.tableName}>RECEBIDOS</span>                                   
                                    <SimpleTable data={order.items} columns={columns}/>
                                </>
                            }
                            {order.items_canceld &&
                                <>
                                    <span className={invoiceStyle.tableName}>NÃO RECEBIDOS</span>    
                                    <SimpleTable data={order.items_canceld} columns={columns} customStyle={['text-decoration-line-through', 'faded-color']}/>
                                </>
                            }
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className={`${invoiceStyle.invoiceDownloadButton} my-5`} onClick={() => navigate(toUrl(INVOICE_PRINT, order?.id))}>
                                Imprimir fatura
                                <img src={toAbsoluteUrl('/media/general/Printer-Icon.svg')} alt='' className={invoiceStyle.invoiceDownloadIcon}/>
                            </div>
                        </div>    

                        <div className="font500 font11rem">
                            <div className="mb-3">
                                <div className="d-flex justify-content-between">
                                    <span>SUBTOTAL (R$)</span>
                                    <span>{order.total_items_price}</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span> ENTREGA (R$)</span>
                                    <span>{delivery}</span>
                                </div>
                                {donation &&
                                    <div className="d-flex justify-content-between">
                                        <span>DOAÇÃO (R$)</span>
                                        <span>{donation}</span>
                                    </div>
                                }
                                {order.total_mission_discount !== 0 &&
                                    <div className="d-flex justify-content-between">
                                        <span>DESCONTO - {order?.mission?.title}</span>
                                        <span>{order?.total_mission_discount}</span>
                                    </div>
                                }
                            </div>
                            <div className={invoiceStyle.invoiceTotal}>
                                <span>TOTAL (R$)</span>
                                <span>{order?.total_price}</span>
                            </div>
                        </div>
                    </div>
                </>
            }

            {isOrderDetailLoading &&
                <Loader />
            }

            {(!order && !isOrderDetailLoading) &&
                <div className="d-flex flex-column align-items-center">
                    <img src={toAbsoluteUrl('/media/Illustrations/Empty-Store.svg')} alt='' style={{width: '45%'}}/>
                    <span className="main-color">Não encontramos a fatura de número: <strong>{orderId}</strong></span>
                </div>
            }
        </div>
    )
}

export default AOInvoice


{/* <div className={`${invoiceStyle.tables} mt-3`}>
<span className={invoiceStyle.tablesTitle}>RECEBIDOS</span>
<table className="w-100">
    <thead>
        <tr className={invoiceStyle.tdStyles}>
            <td className="ps-5">DESCRIÇÃO</td>
            <td>UNIDADE</td>
            <td>QTD</td>
            <td>TOTAL</td>
        </tr>
    </thead>
    <tbody>
    {order.invoice?.items.map((row : any) => (
    <tr key={row.id} >
        <td className={invoiceStyle.itemDescription}>
            <div className="d-flex flex-column">
                <span>{row.description}</span>
                <span className="font08rem faded-color">{row.bar_code}</span>
            </div>
        </td>
        <td className={invoiceStyle.tableContent}>{toBRL(row.value)}</td>
        <td className={invoiceStyle.tableContent}>{row.quantity}</td>
        <td className={invoiceStyle.tableContent}>{toBRL((row.value * row.quantity).toFixed(2))}</td>
    </tr>
    ))}
    </tbody>
</table>

<span className={`${invoiceStyle.tablesTitle} ${invoiceStyle.borderTop}`}>NÃO ENVIADOS</span>
<table className="w-100">
    <thead>
        <tr className={invoiceStyle.tdStyles}>
            <td className="ps-5">DESCRIÇÃO</td>
            <td>UNIDADE</td>
            <td>QTD</td>
            <td>TOTAL</td>
        </tr>
    </thead>
    <tbody>
        {order?.invoice?.items_canceled?.map((row : any) => (
        <tr key={row.id}>
            <td className={invoiceStyle.itemDescription}>
                <div className="d-flex flex-column">
                <span>{row.description}</span>
                <span className="font08rem faded-color">{row.bar_code}</span>
                </div>
            </td>
            <td className={invoiceStyle.tableContent}>{toBRL(row.value)}</td>
            <td className={invoiceStyle.tableContent}>{row.quantity}</td>
            <td className={invoiceStyle.tableContent}>{toBRL((row.value * row.quantity).toFixed(2))}</td>
        </tr>
        ))}
    </tbody>
</table>
</div> */}

{/* <div className={`${invoiceStyle.tables} mt-3`}>
<span className={invoiceStyle.tablesTitle}>RECEBIDOS</span>
<DataTableBase columns={columns} data={order.invoice?.items} />
<span className={`${invoiceStyle.tablesTitle} ${invoiceStyle.borderTop}`}>NÃO ENVIADOS</span>
<DataTableBase columns={columns} data={order.invoice?.items_canceled} />
</div> */}

{/* <table>
<thead>
    {/* {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
        {headerGroup.headers.map(header => (
            <th key={header.id}>
            {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                )}
            </th>
        ))}
        </tr>
    ))} 
</thead>

<tbody>
    {/* {table.getRowModel().rows.map(row => (
        <tr key={row.id}>
        {row.getVisibleCells().map(cell => (
            <td key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
        ))}
        </tr>
    ))} 
</tbody>
</table> */}