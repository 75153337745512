/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { centralClient } from "index";
import { StoreLocation, StoreLocationStatus } from "models/StoreLocation";
import { LocationEndpoints } from "modules/api/endpoints/LocationEndpoints";
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { LOCAL_STORAGE_STORE_LOCATION_KEY } from "utils/Consts";
import { useStoreConfig } from "./StoreConfigProvider";

type StoreContextModel = {
  storeLocation: StoreLocation | null;
  updateStoreLocation: (storeLocation: StoreLocation | null, refresh?: boolean) => void;
  isLocatingProcess: boolean;
  setIsLocatingProcess: (isLocatingProcess: boolean) => void;
};

const initialStoreProviderContext: StoreContextModel = {
  storeLocation: null,
  updateStoreLocation: () => {},
  isLocatingProcess: false,
  setIsLocatingProcess: () => {},
};

const StoreContext = createContext(initialStoreProviderContext);

const StoreLocationProvider: FC<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const [storeLocation, setStoreLocation] = useLocalStorage<StoreLocation | null>(
    LOCAL_STORAGE_STORE_LOCATION_KEY,
    null
  );
  const [isLocatingProcess, setIsLocatingProcess] = useState(false);

  const updateStoreLocation = useCallback((storeLocation: StoreLocation | null, refresh : boolean = true) => {
    setStoreLocation(storeLocation);
    // Invalidate every query in the cache
    queryClient.invalidateQueries();
    //reload the page to update the store location
    if (refresh){
      window.location.href = "/";
    }
  }, []);

  return (
    <StoreContext.Provider
      value={{
        storeLocation,
        updateStoreLocation,
        isLocatingProcess,
        setIsLocatingProcess,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

const StoreLocationInit: FC = () => {
  const { isLayoutReady } = useStoreConfig();
  const { storeLocation, updateStoreLocation, setIsLocatingProcess } = useStoreLocation();

  const {isFetching } = useQuery({
    queryFn: async () => {
      const { data: location } = await centralClient.get(LocationEndpoints.locationByIpAddress);
      updateStoreLocation({ ...location, status: StoreLocationStatus.FOUND_WITHOUT_INTERACTION });
      return location;
    },
    queryKey: ["store-location"],
    enabled: !storeLocation && isLayoutReady,
    retry: false,
  });

  useEffect(() => {
    setIsLocatingProcess(isFetching);
  }, [isFetching]);

  return null;
};

const useStoreLocation = () => {
  return useContext(StoreContext);
};

export { StoreLocationInit, StoreLocationProvider, useStoreLocation };
