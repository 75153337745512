type Thumbnail = {
  url: string; 
  title: string;
}
export type Category = {
  id?: string;
  name: string;
  slug: string;
  description?: string;
  meta_tag_title?: string;
  meta_tag_description?: string;
  active: boolean;
  parent?: Category;
  children?: Category[];
  thumbnail?: Thumbnail;
};

export const determineIfIsCategory = (category: any): category is Category => {
  return category && category.id && category.name;
}


















