import React, { useEffect, useMemo, useState } from 'react'
import { toAbsoluteUrl } from 'utils/Functions'
import PaginationStyle from './Paginator.module.css'

type Props = {
    totalPages: number
    onChange: (rpageNumbe: number) => void
    page?: number
  }

  type PageNumber = number | string;

const Paginator: React.FC<Props> = ({totalPages, onChange, page = 0}) => {   
    const [currentButton, setCurrentButton] = useState<number>(page + 1)
    const [arryOfCurrentButtons, setArryOfCurrentButtons] = useState<PageNumber[]>([])

    const handleClick = (item : any) => {
      if (item.toString() !== '...') {
        setCurrentButton(Number(item));
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }

    const pagesArray = useMemo(() => {
      if (totalPages < 0) return []
      return [...Array(totalPages).keys()]
    },[totalPages])

    useEffect(() => { 
      //novo
        let tempNumberOfPages: PageNumber[] = [...arryOfCurrentButtons];

        const dotsInitial: PageNumber = '...';
        const dotsLeft: PageNumber = '...';
        const dotsRight: PageNumber = '...';
    
        if (totalPages < 5) {
          tempNumberOfPages = pagesArray.map(item => item + 1);
        } else if (currentButton >= 1 && currentButton <= 2) {
          tempNumberOfPages = [1, 2, 3, dotsInitial, totalPages];
        } else if (currentButton === 3) {
          const sliced = pagesArray.slice(1, 5);
          tempNumberOfPages = [...sliced, dotsInitial, totalPages];
        } else if (currentButton > 3 && currentButton < (totalPages - 2)) {
          const sliced1 = pagesArray.slice(currentButton - 1, currentButton);
          const sliced2 = pagesArray.slice(currentButton, currentButton + 2);
          tempNumberOfPages = [
            1,
            dotsLeft,
            ...sliced1,
            ...sliced2,
            dotsRight,
            totalPages,
          ];
        } else if (currentButton >= totalPages - 2) {
          const sliced = pagesArray.slice(totalPages - 2);
          tempNumberOfPages = [1, dotsLeft, ...sliced, totalPages];
        } else if (currentButton === Number(dotsInitial)) {
          setCurrentButton(arryOfCurrentButtons[arryOfCurrentButtons.length - 3] as number + 1);
        } else if (currentButton === Number(dotsRight)) {
          setCurrentButton(arryOfCurrentButtons[3] as number + 2);
        } else if (currentButton === Number(dotsLeft)) {
          setCurrentButton(arryOfCurrentButtons[3] as number - 2);
        }
    
        setArryOfCurrentButtons(tempNumberOfPages);
      }, [currentButton, pagesArray, page]);

      useEffect( ()=> {
        onChange(currentButton - 1 )
      },[currentButton])

      return (
        <div className={totalPages > 5 ? PaginationStyle.containerLong : PaginationStyle.containerShort}>
          <div
            className={`${currentButton === 1 ? PaginationStyle.pageCounterDisabled : PaginationStyle.pageCounter}`}
            onClick={() => setCurrentButton(prev => prev <= 1 ? prev : prev - 1)}
          >
            <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Right.svg')} alt='' className='chevronDown'/>
          </div>
    
          {arryOfCurrentButtons.map(((item, index) => {
            return <div
              key={index}
              className={`${currentButton === item ? PaginationStyle.pageCounterActive : PaginationStyle.pageCounter}`}
              onClick={() => handleClick(item)}
            >
              {item}
            </div>
          }))}
    
          <div
            className={`${currentButton === totalPages ? PaginationStyle.pageCounterDisabled : PaginationStyle.pageCounter}`}
            onClick={() => setCurrentButton(prev => prev >= totalPages ? prev : prev + 1)}
          >
            <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Right.svg')} alt=''/>
          </div>
        </div>
      );
}

export { Paginator }
