import creditStyle from './AddCreditCardPage.module.css'
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_CARDS_PAGE, toUrl } from 'utils/Links';
import {toast} from 'react-toastify'
import * as Yup from 'yup';
import { toAbsoluteUrl } from 'utils/Functions';
import PageTitle from 'components/common/PageTitle/PageTitle';
import SelectMonth from 'components/common/CardDatePicker/SelectMonth';
import { useState } from 'react';
import SelectYear from 'components/common/CardDatePicker/SelectYear';

function AddCreditCardPage() {
    const navigate = useNavigate();
    // THERE IS STILL NO VALIDATION FOR THESE VALUES
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [visebleCvv, setVisebleCvv] = useState(false)
    
    const cardSchema = Yup.object().shape({
        cardNumber: Yup.string()
        .required('Digite o número do cartão')
        .test(
            'Digite um número válido de cartão',
            (value:any) => /(?<!\d)\d{16}(?!\d)|(?<!\d[ _-])(?<!\d)\d{4}(?:[_ -]\d{4}){3}(?![_ -]?\d)/gm.test(value)
        ),
        cardName: Yup.string()
        .required('Digite o nome impresso no cartão')
        .test(
            'Digite o nome completo como impresso no cartão',
            (value:any) => /^([a-zA-Z]{2,}\s{1}[a-zA-Z\s]+)/g.test(value)
        ),
        expirationDate: Yup.string()
        .required('Escolha a data correta'),
        cvv: Yup.string()
        .min(3, 'Minimo de 3 caracteres')
        .max(3, 'Máximo de 4 caracteres'),
        nickname: Yup.string(),
    }) 

    const formik = useFormik({
        initialValues: {
            cardNumber: '',
            cardName: '',
            expirationDate: '',
            cvv: '',
            nickname: '',
        },
        validationSchema: cardSchema,
        onSubmit: async (values, formikHelpers) => {
        //   handleFormSubmit(values,formikHelpers)
        },
    })

    // const handleFormSubmit = () => {
    //     if (validPassword) {
    //       navigate(-1);
    //       formik.setFieldValue('password', password)
    //       toast.success('Senha alterada com sucesso', {
    //         position: 'top-right',
    //         autoClose: 4000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: 'light',
    //       });
    //     }
    //   }

    function addCardHandler() {
        navigate(toUrl(ACCOUNT_CARDS_PAGE))
        toast.success('Cartão adicionado', {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }

  return (
    <>
    <PageTitle title={`Adicionar cartão`} />
    
    <div className='d-flex justify-content-center mt-2'>
        <img src={toAbsoluteUrl('/media/warnings/Warning-Main.svg')} 
        alt='' 
        className='d-flex justify-content-center black-filter me-1 transform-1px'/>
        As informações com * são de preenchimento obrigatório
    </div>

    <form className='p-3 container'>
        <div className='mb-3 row'>
            <div className='col-12 d-flex flex-column mb-3'>
                <label htmlFor='exampleInputCardNumber' className={creditStyle.formLabel}>
                Número do cartão*
                </label>
                <div className={creditStyle.cardNumberForm}>
                    <input
                    type='text'
                    className={creditStyle.formControl}
                    id='exampleInputCardNumber'
                    placeholder='1234 5678 9012 3456 '
                    />
                    <div className={creditStyle.cardIcon}>
                        <img src={toAbsoluteUrl('/media/general/Credit-Card-Icon.svg')} alt=''/>
                    </div>
                </div>
            </div>
            <div className='col-12 d-flex flex-column '>
                <label htmlFor='exampleInputCardName' className={creditStyle.formLabel}>
                Nome no cartão*
                </label>
                <input
                type='text'
                className={creditStyle.formControl}
                id='exampleInputCardName'
                placeholder='Nome no cartão'
                />
            </div>
        </div>

        <div className='mb-3 d-flex flex-column'>

            <label htmlFor='exampleInputDate' className={creditStyle.formLabel}>
                Data de expiração*
            </label>

            <div className='row d-flex justify-content-around'>
                <div className='col-6'>
                    <div className='col-12'>
                        <select
                        className={creditStyle.formControl}
                        id='month'
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        required>
                            <option value='' disabled selected>
                                <span>Mês</span>
                            </option>
                            {SelectMonth()}
                        </select>
                    </div>
                </div>

                <div className='col-6'>
                    <div className='col-12'>
                        <select
                        className={creditStyle.formControl}
                        id='year'
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        required>
                            <option value='' disabled selected>
                                <span>Ano</span>
                            </option>
                            {SelectYear()}
                        </select>
                    </div>
                </div>
            </div>

        </div>

        <div className='mb-3 d-flex flex-column'>
            <label htmlFor='exampleInputCode' className={creditStyle.formLabel}>
            Código de segurança (CVV)*
            </label>
            <div className={creditStyle.cardNumberForm}>
                <input
                type={visebleCvv ? 'text' : 'password'}
                className={creditStyle.formControl}
                id='exampleInputCode'
                placeholder={visebleCvv ? '***' : '123'}
                maxLength={3}
                />
                <div className={creditStyle.cardIcon} onClick={() => setVisebleCvv(!visebleCvv)}>
                    <img src={toAbsoluteUrl(visebleCvv ? '/media/general/Eye-Opened.svg' : '/media/general/Eye-Closed.svg')} alt=''/>
                </div>
            </div>
        </div>

        <div className='d-flex flex-column'>
            <label htmlFor='exampleInputNickname' className={creditStyle.formLabel}>
            Apelido
            </label>
            <input
            type='text'
            className={`${creditStyle.formControl} col-11`}
            id='exampleInputNickname'
            placeholder='Dê um nome ao seu cartão'
            />
        </div>

    </form>

    <div className=''>
        <div className={creditStyle.addButton} onClick={() => addCardHandler()}>
            Adicionar cartão
            <img src={toAbsoluteUrl('/media/general/Check-Simple-White.svg')} alt='' className='transform-1px ms-2'/>
        </div>
    </div>
    </>
  )
}

export default AddCreditCardPage