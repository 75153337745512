
export const SearchEndpoints = {
    // searchProductByTerm: (term: string, sort : string, filters : FacetFilter[], page : number) => post<ProductSearch>('/store/search/product', {
    //     search: term,
    //     sort: sort,
    //     filters: filters,
    //     page: page
    // }),
    // searchProductByCategorySlug: (categorySlug: string, sort : string, filters : FacetFilter[], page : number) => post<ProductSearch>(`/store/search/product/category`, {
    //     search: categorySlug,
    //     sort: sort,
    //     filters: filters,
    //     page: page
    // }),
    // listProductSuggestions: (query : string) => {
    //     return get<SearchSuggestion>('/store/search/product/suggestion', {
    //         params: {
    //             search: query,
    //         }
    //     })
    // }
    listProductSuggestions:  `/store/search/product/suggestion`,
    searchProductByTerm: '/store/search/product',
    searchProductByCategorySlug: '/store/search/product/category',
    searchProductByTagSlug: '/store/search/product/tag',
    searchProductByBrandSlug: '/store/search/product/brand',
}
