import ContentLoader from 'react-content-loader'

function VerticalCardSkeleton(width:any) {
  return (
    <ContentLoader
    speed={2}
    width={width || 0}
    height={285}
    viewBox={`0 0 ${width || 0} 285`}
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb"
  >
    <rect x="409" y="85" rx="0" ry="0" width="0" height="1" />
    <rect x="0" y="0" rx="8" ry="8" width='100%' height="285" />
  </ContentLoader>
  )
}

export default VerticalCardSkeleton
