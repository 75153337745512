import { regionalClient } from "index"
import { Mission } from "models/Mission"
import { Product } from "models/Product"
import { SortOption } from "models/SortOptionEnum"
import { PageableResponse } from "modules/api/core/_models"
import { MissionEndpoints } from "modules/api/endpoints/MissionEndpoints"
import { getEnumKeyByEnumValue } from "utils/Functions"

export const list = (page : number = 0) => {
    return regionalClient.get(MissionEndpoints.list, {
        params : {
            page,
            per_page : 10
        }
    })
}

export const searchMissionProductsByTerm = (missionId: string, query: string, sort: SortOption, page: number, perPage: number = 10) => {
    return regionalClient.get<PageableResponse<Product>>(MissionEndpoints.searchProductByTerm(missionId), {
        params : {
            search: query,
            sort: getEnumKeyByEnumValue(SortOption, sort),
            page: page,
            per_page: perPage,
        }
    })
}

export const detail = (id: string) => {
    return regionalClient.get(MissionEndpoints.info(id))
}


export const listMissions = () => {
    return regionalClient.get<Mission[]>(MissionEndpoints.list, {})
}