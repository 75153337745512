import { regionalClient } from "index";
import { SalesOrder } from "models/SalesOrder";
import { SearchCriteria } from "models/SearchCriteria";
import { OrderEndpoints } from "modules/api/endpoints/OrderEndpoints";
import qs from "qs";

export const listOrders = (term: string = "", filters: SearchCriteria[], page: number, perPage: number = 10) => {
  return regionalClient.get(OrderEndpoints.list, {
    params: {
      search: term,
      filters: filters,
      page: page,
      per_page: perPage,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { allowDots: true });
    },
  });
};

export const orderDetails = (id: string) => {
  return regionalClient.get<SalesOrder>(OrderEndpoints.detail(id));
};
