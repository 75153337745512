import { FC } from "react";
import { useRoutes } from "react-router-dom";
import CheckoutFinishPage from "./CheckoutFinishPage";
import CheckoutPage from "./CheckoutPage";

const CheckoutWrapper: FC = () => {
  const routes = useRoutes([
    {
      index: true,
      element: <CheckoutPage />,
    },
    {
      path: "/finalizado/:orderId",
      element: <CheckoutFinishPage />,
    },
    // {
    //   path: "*",
    //   element: <Navigate to={STORE_FRONT_PAGE} />,
    // },
    {
      path: "/",
      element: <CheckoutPage/>
    },
  ]);

  return routes;
};

export default CheckoutWrapper;
