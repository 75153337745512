import { FC } from 'react'
import clsx from 'clsx'
import authSteps from './AuthenticationStep.module.css'
import { useNavigate } from 'react-router-dom'
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'

type Props = {
    active: boolean
    checked: boolean
    label: string
    link?: string
  }

  const AuthenticationStep: FC<Props> = ({checked, active, label, link}) => {
  const navigate = useNavigate()

  const handleLinkClick = () => {
    if (link) {
      navigate(link)
    }
  }

  return (
    <div className='float-right col-3' onClick={handleLinkClick}>
        <div className={clsx(
            'd-flex flex-column align-items-center',
            {[authSteps.arrowBox]: active},
            {[authSteps.arrowBox]: checked},
            {[authSteps.arrowBoxInactive]: !active},
            {[authSteps.hasLink] : !!link},
        )}>

            <div className={clsx({'main-filter' : checked})}> 
                {checked ? <ImCheckboxChecked/> : <ImCheckboxUnchecked/>}
            </div>

            <div className={clsx(authSteps.step, {[authSteps.activeStep]: checked}, {[authSteps.inactiveStep]: !active})}>
                {label}
            </div>

        </div>
    </div>
  )
}

export default AuthenticationStep