import React, { useEffect, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { useNewDataTable } from '../NewDatatable';

const BaseSearchFilter: React.FC = () => {

  const { table } = useNewDataTable()
  const [value, setValue] = useState('')
  const [debouncedValue] = useDebounceValue(value, 500)

  useEffect(() => {
    if (!table) return
    table.setGlobalFilter(debouncedValue)
  }, [debouncedValue, table])

  return <>
    <input type="text" value={value} onChange={(e) => setValue(e.target.value)} />
  </>;
}

export default BaseSearchFilter;
