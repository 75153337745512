import clsx from 'clsx'
import { useStoreCart } from 'layouts/common/core/StoreCartProvider'
import { Product } from 'models/Product'
import { ChangeEvent, useMemo } from 'react'
import { toAbsoluteUrl } from 'utils/Functions'
import ProductCounterSkeleton from '../../../desktop/Skeletons/ProductCounterSkeleton'
import style from './ProductCounterShort.module.css'

type Props = {
    product: Product
    loading?: boolean
    itemType?: string
    title?: string
    onAdd: (product: Product) => void
    onRemove: (product: Product) => void
    onChange: (product: Product, quantity: number) => void
    onRemoveAll: (product: Product) => void
}

function ProductCounterShort({
    product,
    loading = false,
    title = 'ADICIONAR',
    onChange,
    onAdd,
    onRemove,
    onRemoveAll,
}: Props) {

    const { getItemCart } = useStoreCart()
    const cartItem = useMemo(() => getItemCart(product.id), [getItemCart, product])
    const enabled = useMemo(() => {
        if (!product.sell_price) return false
        return true
    }, [product])

    const handleAddClick = () => {
        if (!enabled) return
        onAdd(product)
    }

    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      // Check if the value is a valid number using a regular expression
      const isValidNumber = /^-?\d+$/.test(value);

      let newQty = 1;
      if (isValidNumber) {
        newQty = parseInt(value, 10);
      }

      if (newQty <= 0){
        onRemove(product);
        return;
      }

      if (newQty !== cartItem?.quantity) {
        onChange(product, newQty);
      }
    }

    return (
        <>
            {loading && <ProductCounterSkeleton />}
            {!loading && !cartItem && (
                <div className={clsx(style.itemCardCounter)}>
                    <button onClick={handleAddClick} className={clsx(style.addNew, !enabled && style.disabled)}>
                        <div className={clsx(style.addToCart, !enabled && style.labelDisabled)}>
                            <img src={toAbsoluteUrl('/media/general/Mobile-Card-Addition.svg')} alt='img' />
                            <span>{title}</span>
                        </div>
                    </button>
                </div>
            )}
            {!loading && cartItem && (
                <div className={`${style.itemCardCounter}`}>
                    <div className='d-flex'>
                        <div className={style.removeTrash}>
                            <img
                                src={toAbsoluteUrl('/media/general/Trash-Outline-Icon.svg')}
                                alt='Trash'
                                onClick={() => onRemoveAll(product)}
                            />
                        </div>
                        <div className='d-flex'>
                            <button onClick={() => onRemove(product)} className={style.remove}>-</button>
                            <input type='number' className={style.barCounter} value={cartItem?.quantity} onChange={handleQuantityChange} />
                            <button onClick={() => onAdd(product)} className={style.add}>+</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ProductCounterShort
