import { PageLink } from 'models/Breadcrumbs'
import { Link } from 'react-router-dom'
import { STORE_FRONT_PAGE, toUrl } from 'utils/Links'
import breadStyle from './Breadcrumbs.module.css'

type Props = {
    breadcrumbs: PageLink[]
}

function Breadcrumbs({breadcrumbs}: Props) {
    return (
        <div className={breadStyle.breadcrumbs}>
            <Link to={toUrl(STORE_FRONT_PAGE)} className={breadStyle.crumb}>
                Início
            </Link>
            <span className='main-color'> {' > '} </span>
            {breadcrumbs.map((breadcrumb, idx) => (
                <div key={idx}>
                    {breadcrumb.path && (
                      <Link to={breadcrumb.path} className={breadStyle.crumb}>{breadcrumb.title}</Link>
                    )}
                    {!breadcrumb.path && (
                        <span className={breadStyle.crumb}>{breadcrumb.title}</span>
                    )}
                    {idx !== breadcrumbs.length - 1 &&
                        <span className='main-color'> {' > '} </span>
                    }
                </div>
            ))}
        </div>
    )
}

export default Breadcrumbs
