import { Product } from 'models/Product'
import { FC } from 'react'
import ProductCardShortSkeleton from 'components/desktop/Skeletons/ProductCardShortSkeleton'
import ProductCardShort from '../ProductCard/ProductCardVertical'
import sugestionStyle from './ProductSugestion.module.css'

type Props = {
  products: Product[]
  title?: string
  loading?: boolean
}

const ProductSugestionSkeleton = (): JSX.Element => {
  return (
    <>
      {[...Array(2)].map((_, idx) => (
        <ProductCardShortSkeleton key={idx} />
      ))}
    </>
  );
};

const ProductSugestion: FC<Props> = ({products, loading = true}) => {
  return (
    <div className='col-12 mt-3'>
        <div className='row-cols-xxl-5 row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-xs-1'>
            {loading && <ProductSugestionSkeleton />}
            <div className={sugestionStyle.verticalGrid}>
              {products.map((product, idx) => (
                <ProductCardShort key={idx} product={product} loading={loading}/>
              ))}
            </div>
        </div>
    </div>
  )
}

export default ProductSugestion
