import { useModalContext } from 'layouts/common/core/StoreModalProvider';
import { Product } from 'models/Product';
import { useAuth } from 'modules/auth/core/AuthProvider';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from 'utils/Functions';
import { LOGIN_PAGE, toUrl } from 'utils/Links';
import favoriteStyle from './AddToFavorites.module.css';

type Props = {
    product: Product;
    quantity?: number;
}

function AddToFavorites({ product, quantity = 1 }: Props) {
    const [isWishlisted, setIsWishlisted] = useState(product.is_wishlist_product);
    const { showWishlistModal } = useModalContext();
    const navigate = useNavigate();
    const { auth } = useAuth();

    const handleOnChange = (isWishlisted: boolean) => {
        setIsWishlisted(isWishlisted)
    }

    function handleClick() {
        if (!auth) {
            navigate(toUrl(LOGIN_PAGE))
            return
        }
        showWishlistModal(product, quantity, handleOnChange);
    }

    return (
        <div>
            <img src={toAbsoluteUrl(isWishlisted ? '/media/footmenu/Clipboard-Filled.svg' : '/media/general/Clipboard-Thin.svg' )} alt='list'
                onClick={handleClick}
                className={favoriteStyle.listed}
            />
        </div>
    )
}

export default AddToFavorites
