import { ColumnDef } from "@tanstack/table-core";
import NewDatatable from "components/common/NewDatatable/NewDatatable";
import BasePagination from "components/common/NewDatatable/components/BasePagination";
import BaseTable from "components/common/NewDatatable/components/BaseTable";
import Breadcrumbs from "components/common/breadcrumbs/Breadcrumbs";
import { Donation } from "models/Donation";
import { SalesOrder } from "models/SalesOrder";
import { DonationEndpoints } from "modules/api/endpoints/DonationEndpoints";
import moment from "moment";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toBRL } from "utils/Functions";
import { ACCOUNT_DONATIONS_PAGE, ACCOUNT_ORDERS_PAGE, ACCOUNT_ORDER_DETAILS_MODAL, toUrl } from "utils/Links";
import DateSelectionTableFilter from "../AccountBill/component/DateSelectionTableFilter";
import AccountDonationTablePlaceholder from "./component/AccountDonationTablePlaceholder";

const breadcrumbs = [
  {
    title: "Perfil",
    path: toUrl(ACCOUNT_ORDERS_PAGE),
  },
  {
    title: "Doações",
    path: toUrl(ACCOUNT_DONATIONS_PAGE),
  },
];

function AccountDonationPage() {
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<Donation, any>[]>(
    () => [
      {
        header: "Data",
        accessorKey: "date",
        id: "date",
        cell: (cell) => moment(cell.getValue()).format("DD/MM/YYYY"),
      },
      {
        header: "Instituição",
        accessorKey: "institution",
        id: "institution",
        cell: (cell) => <span className="font500">{cell.getValue()}</span>,
      },
      {
        header: "Valor",
        accessorKey: "value",
        id: "value",
        cell: (cell) => toBRL(cell.getValue()),
      },
      {
        header: "Pedido",
        accessorKey: "sales_order",
        id: "salesOrder",
        cell: (cell) => {
          const salesOrder = cell.getValue() as SalesOrder;
          if (!salesOrder) return <span>Avulso</span>;

          return (
            <div>
              <span className="font500">{salesOrder.number}</span>
              <span
                style={{ cursor: "pointer" }}
                className="text-underline"
                onClick={() => navigate(toUrl(ACCOUNT_ORDER_DETAILS_MODAL, salesOrder.id))}
              >
                ver pedido
              </span>
            </div>
          );
        },
      },
    ],
    [navigate]
  );

  function handleDateSelectorChange(value: string) {
    console.log(value);
  }

  const filter = [
    {
      key: "date",
      value: moment().subtract(3, "days").format("YYYY-MM-DD"),
      operation: "GREATER_THAN_EQUAL",
    },
  ];

  return (
    <div>
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className="font600 font15rem">DOAÇÕES</span>
      </div>

      <div className="d-flex justify-content-end mb-4">
        <DateSelectionTableFilter />
      </div>

      <div className="row">
        <NewDatatable
          columns={columns}
          endpoint={DonationEndpoints.list}
          filter={filter}
          placeHolder={<AccountDonationTablePlaceholder />}
        >
          <div className="row">{/* <DateSelecionTableFilter /> */}</div>
          <div className="row">
            <BaseTable />
          </div>
          <div className="row">
            <BasePagination />
          </div>
        </NewDatatable>
      </div>

      {/* {donations.length === 0 ? (
        <div className="d-flex justify-content-center mt-4">
          <div className={`${donationStyle.emptyPage}  w-100`}>
            <div className="d-flex flex-column align-items-center">
              <KTSVG path="/media/general/Big-Heart.svg" className="pb-5" />
              <span className="pb-3 main-color font500 font12rem">
                Você poderá fazer doações no ato das suas compras.
              </span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <div className="w-100">
              <div className={donationStyle.alert}>
                <img src={"/media/general/Heart-Big.svg"} alt="alert" className={donationStyle.heartIcon} />
                <span>
                  As doções são destinadas mensalmente, às instituições de caridade da cidade de Campina Grande -
                  Paraíba.
                </span>
              </div>
            </div>
          </div>

          <div className={donationStyle.card}></div>
        </>
      )} */}
    </div>
  );
}

export default AccountDonationPage;
