import React from 'react'
import { Modal } from 'react-bootstrap'
import { CgClose } from 'react-icons/cg'
import cardsStyle from './DeleteCardMoral.module.css'

type Props = {
    show: boolean
    onHide: () => void
    onConfirm: () => void
}

function DeleteCardModal({show, onHide, onConfirm}: Props) {
  return (
    <Modal
        size={`sm`}
        show={show}
        className={cardsStyle.authenticationConfirmationSheet}
        onHide={onHide}
        centered>
        <Modal.Body className={`${cardsStyle.modalBodyDisabling} p-4`}>
        <div className='d-flex justify-content-between font12rem font600 main-color mb-4'>
            <span className='text-center'>Tem certeza que deseja excluir o cartão?</span>
            <CgClose onClick={onHide} />
        </div>
        <div className='d-flex justify-content-center'>
            <button className={`${cardsStyle.noButton} mx-2`} onClick={onHide}>
            Não
            </button>
            <button
            className='button btn-danger mx-2'
            onClick={onConfirm}
            >
            Sim
            </button>
        </div>
        </Modal.Body>
    </Modal>
  )
}

export default DeleteCardModal