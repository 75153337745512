import { useQuery } from "@tanstack/react-query";
import { KTSVG } from "components/common/KTSVG";
import Breadcrumbs from "components/common/breadcrumbs/Breadcrumbs";
import WishlistCartLayout from "layouts/desktop/WishlistLayout/WishlistCardLayout";
import { Wishlist } from "models/Wishlist";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "utils/Functions";
import {
  ACCOUNT_ORDERS_PAGE,
  ACCOUNT_SUGGESTED_WHISHLIST_DETAIL_PAGE,
  ACCOUNT_WHISHLIST_PAGE,
  STORE_FRONT_PAGE,
  toUrl,
} from "utils/Links";
import listStyle from "./AccountSuggestWishlistPage.module.css";
import { list } from "./core/_requests";

const breadcrumbs = [
  {
    title: "Perfil",
    path: toUrl(ACCOUNT_ORDERS_PAGE),
  },
  {
    title: "Listas",
    path: toUrl(ACCOUNT_WHISHLIST_PAGE),
  },
];

function AccountSuggestWishlistPage() {
  const navigate = useNavigate();

  const { data: wishlists, isLoading: isWishlistLoading } = useQuery({
    queryFn: async () => {
      const response = await list();
      return response.data;
    },
    queryKey: ["wishlists"],
  });

  const handleListClick = (list: Wishlist) => {
    navigate(toUrl(ACCOUNT_SUGGESTED_WHISHLIST_DETAIL_PAGE, list.id));
  };

  return (
    <>
      <div className="d-flex flex-column">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className="font600 font15rem mb-4">LISTAS SUGERIDAS</span>
      </div>

      <div className={listStyle.suggestionHeader}>
        <img src={toAbsoluteUrl("/media/Illustrations/Clipboard-Illustration.svg")} alt="" />
        <span>
          Nossas listas sugeridas são criadas levando em consideração as compras realizadas em nosso site, os produtos
          mais vendidos de cada marca, assim como aqueles itens que são os mais comprados nos supermercados.
        </span>
      </div>

      {(!wishlists || wishlists.length === 0) && (
        <div className="d-flex justify-content-center mt-4">
          <div className={`${listStyle.emptyPage}  w-100`}>
            <div className="d-flex flex-column align-items-center">
              <KTSVG path="/media/general/List-Drawing.svg" className="pb-5" />
              <span className="pb-3 main-color font500 font12rem">Ainda nao temos nenhuma sugestao para voce...</span>
              <span className="pb-3 main-color font500 font12rem">
                Mas é bem fácil criar uma! Basta seguir o passo a passo abaixo:
              </span>
              <ol className={listStyle.list}>
                <li>Escolha qualquer um dos produtos disponíveis para criar a sua primeira lista</li>
                <li>
                  Clique neste ícone{" "}
                  <img
                    src={process.env.PUBLIC_URL + "/media/general/Small-List-Outline-Icon.svg"}
                    alt=""
                    className={listStyle.listIcon}
                  />{" "}
                  para criar a lista de compras
                </li>
                <li>
                  Assim que o ícone mudar de cor e ficar preenchido{" "}
                  <img
                    src={process.env.PUBLIC_URL + "/media/general/Small-List-Filled-Icon.svg"}
                    alt=""
                    className={listStyle.listIcon}
                  />{" "}
                  significa que a sua primeira lista foi criada com sucesso
                </li>
                <li>
                  Para conferir a lista que você acabou de criar, clique na opção “Listas” no canto superior da sua tela
                </li>
                <li>E pronto! Você já pode continuar fazendo suas compras ou até mesmo criar mais listas!</li>
              </ol>

              <div
                className={`${listStyle.outlineButton} button my-3`}
                onClick={() => navigate(toUrl(STORE_FRONT_PAGE))}
              >
                Adicione os primeiros itens +
              </div>
            </div>
          </div>
        </div>
      )}

      {wishlists && wishlists.length > 0 && (
        <div className={listStyle.listsGrid}>
          {wishlists.map((wishlist, idx) => (
            <WishlistCartLayout
              key={idx}
              loading={isWishlistLoading}
              wishlist={wishlist}
              onClick={() => handleListClick(wishlist)}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default AccountSuggestWishlistPage;
