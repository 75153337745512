import React from "react";
import { Navigate, Route, Routes } from "react-router";
import AccountWishlistDetailPage from "./AccountWishlistDetailPage";
import AccountWishlistPage from "./AccountWishlistPage";

const AccountWishlistWrapper: React.FC = () => {
  return (
    <Routes>
      <Route path="lista" element={<AccountWishlistPage />} />
      <Route path=":id" element={<AccountWishlistDetailPage />} />
      <Route path="*" element={<Navigate to="lista" />} />
    </Routes>
  );
};

export default AccountWishlistWrapper;
