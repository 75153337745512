import { EnumType } from "modules/api/core/_models"

export const ITEM_TYPE_BOX : EnumType = {
    value: '2',
    description: 'Caixa'
}

export const ITEM_TYPE_UNIT : EnumType = {
    value: '1',
    description: 'Unidade'
}
export const TYPE_CARD_STORE = 'card-store'
export const TYPE_CARD_SEARCH = 'card-search'

export const GOOGLE_RECAPTCHA_KEY = '6Lcs5JYkAAAAAP18DAeI3LNb-eLl3_6alRM1Mvsf'

export const FIRST_SMS_CODE_DURATION = 60
export const SECOND_SMS_CODE_DURATION = 120

export const LOCAL_STORAGE_AUTH_KEY = 'store-auth'
export const LOCAL_STORAGE_CART_KEY = 'store-cart'
export const LOCAL_STORAGE_CART_OLD_KEY = 'store-cart-old'
export const LOCAL_STORAGE_STORE_CONFIG_KEY = 'store-config'
export const LOCAL_STORAGE_COOKIE_CONSET_KEY = 'store-cookie-consent'

export const LOCAL_STORAGE_STORE_LOCATION_KEY = 'store-location'

// export const CONSENT_PROPERTY_NAME = 'vupty_consent';

export const PARTIAL_CART_VISIBLE_ITEMS_COUNT = 5

export const DATE_TIME_FORMAT = `yyyy-MM-DDTHH:mm:ss`
export const DATE_FORMAT = `yyyy-MM-DD`
export const DATE_USUAL = `DD/MM/yyyy`
export const DATE_USUAL_FULL = `DD/MM/yyyy HH:mm`
