import ThumbnailSkeleton from "components/desktop/Skeletons/ThumbnailSkeleton";
import { Wishlist } from "models/Wishlist";
import { FC, useMemo } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { toAbsoluteUrl } from "utils/Functions";
import styles from "./WishlistLayout.module.css";

type Props = {
  wishlist: Wishlist;
  loading: boolean;
  onClick: (wishlist: Wishlist) => void;
  onDelete?: (wishlist: Wishlist) => void;
  onEditTitle?: (wishlist: Wishlist) => void;
};

const WishlistCartLayout: FC<Props> = ({ loading, wishlist, onDelete, onEditTitle, onClick }) => {

  const handleDelete = ( ) => {
    if (!onDelete) return
    onDelete(wishlist);
  }

  const handleEdit = () => {
    if (!onEditTitle) return
    onEditTitle(wishlist);
  }

  const handleClick = () => {
    onClick(wishlist);
  }

  const imageUrl = useMemo(() => {

    if (!wishlist || !wishlist.items || wishlist.items.length === 0) {
      return "https://fakeimg.pl/70x80/cccccc/033858";
    }

    if (wishlist.image) {
      return wishlist.image;
    }
    return wishlist.items[0].product.thumbnail?.url || "https://fakeimg.pl/70x80/cccccc/033858";

  }, [wishlist])

  if (loading) {
    return <ThumbnailSkeleton />;
  }

  return (
    <div className={styles.listCard}>
      <img src={imageUrl} alt="" />
      <div className=" d-flex mt-2">
        <span>{wishlist.name}</span>
        {onDelete && <img
          src={toAbsoluteUrl("/media/general/Edit-Outline-Icon.svg")}
          alt=""
          onClick={handleEdit}
          className={styles.editButton}
        />}
      </div>
      <span>{wishlist.items.length} produtos</span>
      <div className="d-flex align-items-center">
        {onDelete && <div className={`${styles.trashCanList}`} onClick={handleDelete}>
          <IoTrashOutline style={{ fontSize: "1.2rem" }} />
        </div>}

        <div className={styles.listButton} onClick={handleClick}>
          Ver produtos
          <img src={toAbsoluteUrl("/media/ChevronsAndArrows/Arrow-Right.svg")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default WishlistCartLayout;
