import { useQuery } from '@tanstack/react-query'
import PageTitle from 'components/common/PageTitle/PageTitle'
import { Wishlist } from 'models/Wishlist'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsFirstRender } from 'usehooks-ts'
import { toAbsoluteUrl } from 'utils/Functions'
import { ACCOUNT_WHISHLIST_DETAIL_PAGE, HOME_PAGE, toUrl } from 'utils/Links'
import wishlistStyle from './WishlistPage.module.css'
import { list } from './core/_requests'

function WishlistPage() {
    const navigate = useNavigate()
    const [wishlist, setWishlist] = useState<Wishlist>();
    const initialRender = useIsFirstRender()
    const videoRef = useRef<HTMLVideoElement>(null);

    const { data: wishlists, isLoading: isWishlistLoading } = useQuery({
        queryFn: async () => {
          const response = await list();
          return response.data;
        },
        queryKey: ["wishlists"],
      });

    const handleListClick = (list: Wishlist) => {
        navigate(toUrl(ACCOUNT_WHISHLIST_DETAIL_PAGE, list.id))
    };

    // const isEmptyPlaceholderVisible = useMemo(() => {
    //     return !isWishlistLoading && !isWishlistFetching && wishlists?.length === 0
    // }, [isWishlistLoading, isWishlistFetching, wishlists])

    // const isSearchingPlaceholderVisible = useMemo(() => {
    //     return  isWishlistFetching && initialRender
    // }, [initialRender, isWishlistFetching])

    return (
        <>
            <PageTitle title={`MINHAS LISTAS`} />
            <div className='mb-4'>
                <div className='padding-bottom-mobile'>
                    {isWishlistLoading && 
                        <div className='d-flex flex-column align-items-center'>
                            <video ref={videoRef} autoPlay loop muted>
                                <source src={toAbsoluteUrl('/media/Animations/Animação-Carrinho.webm')} type="video/webm" />
                            </video>
                                <span className='main-color font500 font12rem mt-4'>Carregando...</span>
                        </div> 
                    }
                    {(!wishlists || wishlists.length === 0) && !isWishlistLoading && (
                        <>
                            <div className={`${wishlistStyle.noListContainer} main-color container`}>
                                <img src={toAbsoluteUrl('/media/general/Clipboard-Big.svg')} alt='' className='py-3' />
                                <p className='font500 my-2'>Você ainda não tem nenhuma lista...</p>
                                <div className={wishlistStyle.newButton} onClick={() => navigate(toUrl(HOME_PAGE))}>
                                    Nova lista <img src={toAbsoluteUrl('/media/icons/Plus-Icon.svg')} alt='' className='transform-1px'/>
                                </div>
                            </div>

                            <div className={wishlistStyle.addButton} onClick={() => navigate(toUrl(HOME_PAGE))}>
                                <span>
                                    Adicione os primeiros itens +
                                </span>
                            </div>
                        </>
                    )}

                    {(wishlists && wishlists.length > 0) &&
                        <div className='container mt-3'>
                            {wishlists.map((list: Wishlist, idx: number) => (
                                <div key={idx}
                                    className={`${wishlistStyle.listBubble} mb-3`}
                                    onClick={() => handleListClick(list)}>
                                    <span>
                                        {idx + 1}. {list.name}
                                    </span>
                                    <div>
                                        <span className='me-2'>
                                            {list.items.length > 0 ? `${list.items.length} item(s)` : 'vazia'}
                                        </span>
                                        <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Right.svg')} alt='' />
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default WishlistPage
