/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { Product } from "models/Product";
import { ProductWithQuantity, StoreCart, initialStoreCart } from "models/StoreCart";
import { StoreItem } from "models/StoreItem";
import { useAuth } from "modules/auth/core/AuthProvider";
import moment from "moment";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocalStorage } from "usehooks-ts";
import { DATE_TIME_FORMAT, LOCAL_STORAGE_CART_KEY } from "utils/Consts";
import {
  calculateCartTotals,
  generateUniqueID,
  getActualProductProviderDiscountValue,
  getActualProductUnitPrice,
} from "utils/Functions";
import { CITY_LOCALIZER_PAGE, LOGOUT_PAGE, toUrl } from "utils/Links";
import useRemoteCartHelper from "./RemoteCartHelper";
import { pullStoreCart, pushStoreCart } from "./_requests";

type StoreCartContextModel = {
  cart: StoreCart;
  saveCart: (cart: StoreCart) => void;
  addItemCart: (product: Product, quantity?: number) => void;
  removeItemCart: (product: Product, quantity?: number) => void;
  deleteItemFromCart: (product: Product) => void;
  getItemCart: (productId: string) => StoreItem | undefined;
  clearCart: () => void;
  updateCartItem: (product: Product, quantity: number) => void;
  addItemsCart: (items: ProductWithQuantity[]) => void;
  createCartItem: (product: Product, quantity: number) => StoreItem;
  setFingerprint: (fingerprint: string) => void;
  pushCart: (cart: StoreCart) => void;
  isStoreCartSyncPending: boolean;
  isStoreCartValid: boolean;
};

const whiteList = [
  toUrl(LOGOUT_PAGE),
  toUrl(CITY_LOCALIZER_PAGE),
  // toUrl(STORE_FRONT_CHECKOUT_PAGE)
];

const initialStoreCartProviderContext: StoreCartContextModel = {
  cart: initialStoreCart,
  saveCart: () => {},
  addItemCart: () => {},
  removeItemCart: () => {},
  deleteItemFromCart: () => {},
  getItemCart: () => undefined,
  clearCart: () => {},
  updateCartItem: () => {},
  addItemsCart: () => {},
  setFingerprint: () => {},
  createCartItem: () => ({} as StoreItem),
  pushCart: () => {},
  isStoreCartSyncPending: false,
  isStoreCartValid: false,
};

const StoreCartContext = createContext<StoreCartContextModel>(initialStoreCartProviderContext);

const StoreCartProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cartLS, setCartLS] = useLocalStorage<StoreCart>(LOCAL_STORAGE_CART_KEY, initialStoreCart);
  const [cart, setCart] = useState<StoreCart>(cartLS);
  const storeCartPushTimerRef = useRef<NodeJS.Timeout>();
  const latestMutationId = useRef<string>();

  useEffect(() => {
    if (!cart) return;
    setCartLS(cart);
  }, [cart, setCartLS]);

  const isStoreCartValid = useMemo(() => {
    if (!cart || !cart.items) return false;

    const hasInvalidItems = cart.items.some((item) => {
      return !item.product || !item.product.id || !item.quantity || item.quantity < 0;
    });

    const hasValidItems = cart.items.some((item) => !item.unavailable);

    return !hasInvalidItems && hasValidItems;
  }, [cart]);

  const { mutate: mutateStoreCart } = useMutation({
    onMutate: (variables) => {
      return { latestMutationId: latestMutationId.current };
    },
    mutationFn: async (cart: StoreCart) => {
      return await pushStoreCart(cart);
    },
    mutationKey: ["pushStoreCart", cart],
    onSuccess: (response, variables, context) => {
      // Verifica se a identificação da mutação corresponde à última mutação enviada
      if (
        (context && context.latestMutationId !== latestMutationId.current) ||
        !response.data ||
        !response.data.items
      ) {
        // Descarta a resposta, pois não é a mais recente
        return;
      }

      setCart({
        ...response.data,
        items: response.data.items,
        sync: true,
      });
    },
  });

  const isStoreCartSyncPending = useMemo(() => !cart.sync, [cart]);

  const pushCart = useCallback(
    (cart: StoreCart) => {
      clearTimeout(storeCartPushTimerRef.current);

      // if (cart.items.length === 0) {
      //   setCart(initialStoreCart);
      // }

      // Gera um identificador único para a mutação
      latestMutationId.current = generateUniqueID();
      storeCartPushTimerRef.current = setTimeout(() => {
        mutateStoreCart(cart);
      }, 1000);
    },
    [setCart, mutateStoreCart]
  );

  const addItemsCart = (items: ProductWithQuantity[]) => {
    let updatedCartItems = cart.items || [];
    for (const item of items) {
      const existentItem = getItemCart(item.product.id);
      if (existentItem) {
        updatedCartItems = updateItemList(updatedCartItems, item.product, existentItem.quantity + item.quantity);
      } else {
        updatedCartItems = addItemToList(updatedCartItems, item.product, item.quantity);
      }
    }
    const cartTotals = calculateCartTotals(updatedCartItems, cart.total_delivery_fee, cart.missions);
    const updatedCart = {
      ...cart,
      ...cartTotals,
      items: updatedCartItems,
      modification_date: moment().format(DATE_TIME_FORMAT),
      sync: false,
    };

    setCart(updatedCart);
    pushCart(updatedCart);
  };

  const addItemCart = (product: Product, quantity: number = 1) => {
    let updatedCartItems: StoreItem[] = [];
    const existentItem = getItemCart(product.id);

    if (existentItem) {
      updatedCartItems = updateItemList(cart.items, product, existentItem.quantity + quantity);
    } else {
      updatedCartItems = addItemToList(cart.items, product, quantity);
    }
    const cartTotals = calculateCartTotals(updatedCartItems, cart.total_delivery_fee, cart.missions);
    const updatedCart = {
      ...cart,
      ...cartTotals,
      items: updatedCartItems,
      modification_date: moment().format(DATE_TIME_FORMAT),
      sync: false,
    };

    setCart(updatedCart);
    pushCart(updatedCart);
  };

  const updateCartItem = (product: Product, quantity: number = 1) => {
    const existentItem = getItemCart(product.id);
    if (!existentItem) return;

    const updatedCartItems = updateItemList(cart.items, product, quantity);
    const cartTotals = calculateCartTotals(updatedCartItems, cart.total_delivery_fee, cart.missions);
    const updatedCart = {
      ...cart,
      ...cartTotals,
      items: updatedCartItems,
      modification_date: moment().format(DATE_TIME_FORMAT),
      sync: false,
    };

    setCart(updatedCart);
    pushCart(updatedCart);
  };

  const removeItemCart = (product: Product, quantity: number = 1) => {
    const item = getItemCart(product.id);

    if (!item) return;

    if (item.quantity <= quantity) {
      deleteItemFromCart(product);
    } else {
      updateCartItem(product, item.quantity - quantity);
    }
  };

  const deleteItemFromCart = (product: Product) => {
    const updatedCartItems = cart.items.filter((item) => item.product.id !== product.id);

    const cartTotals = calculateCartTotals(updatedCartItems, cart.total_delivery_fee, cart.missions);
    const updatedCart = {
      ...cart,
      ...cartTotals,
      items: updatedCartItems,
      modification_date: moment().format(DATE_TIME_FORMAT),
      sync: false,
    };

    setCart(updatedCart);
    pushCart(updatedCart);
  };

  const addItemToList = (items: StoreItem[], product: Product, quantity: number): StoreItem[] => {
    return [...(items || []), createCartItem(product, quantity)];
  };

  const createCartItem = (product: Product, quantity: number): StoreItem => {
    let updatedQuantity = quantity;
    if (product.quantity_available && product.quantity_available > 0 && quantity > product.quantity_available) {
      updatedQuantity = product.quantity_available;
    }

    const bestUnitPrice = getActualProductUnitPrice(product, updatedQuantity);
    const discountValue = getActualProductProviderDiscountValue(product, updatedQuantity);
    const total = updatedQuantity * bestUnitPrice;
    const totalDiscount = updatedQuantity * discountValue;

    const result = {
      product: product,
      quantity: updatedQuantity,
      total: total,
      total_discount: totalDiscount,
      creation_date: moment().format(DATE_TIME_FORMAT),
      modification_date: moment().format(DATE_TIME_FORMAT),
    };

    return result;
  };

  const updateItemList = (items: StoreItem[], product: Product, quantity: number = 1): StoreItem[] => {
    return items.map((item) => {
      if (item.product.id !== product.id) return item;

      let updatedQuantity = quantity;
      if (product.quantity_available && product.quantity_available > 0 && quantity > product.quantity_available) {
        updatedQuantity = product.quantity_available;
      }

      const bestUnitPrice = getActualProductUnitPrice(product, updatedQuantity);
      const discountValue = getActualProductProviderDiscountValue(product, updatedQuantity);
      const total = updatedQuantity * bestUnitPrice;
      const totalDiscount = updatedQuantity * discountValue;

      return {
        ...item,
        product: product,
        quantity: updatedQuantity,
        total: total,
        total_discount: totalDiscount,
        modification_date: moment().format(DATE_TIME_FORMAT),
      };
    });
  };

  const clearCart = () => {
    setCart(initialStoreCart);
  };

  const getItemCart = (productId: string) => {
    if (!cart.items) return undefined;
    return cart.items.find((item) => item.product.id === productId);
  };

  const setFingerprint = (fingerprint: string) => {
    setCart((prevState) => {
      return { ...prevState, fingerprint: fingerprint };
    });
  };

  const saveCart = (cart: StoreCart) => {
    setCart({ ...cart });
  };

  return (
    <StoreCartContext.Provider
      value={{
        cart,
        saveCart,
        addItemCart,
        removeItemCart,
        deleteItemFromCart,
        getItemCart,
        clearCart,
        updateCartItem,
        addItemsCart,
        setFingerprint,
        createCartItem,
        pushCart,
        isStoreCartSyncPending,
        isStoreCartValid,
      }}
    >
      {children}
    </StoreCartContext.Provider>
  );
};

const StoreCartInit: FC = () => {
  const { auth } = useAuth();
  const { cart, clearCart, pushCart, isStoreCartSyncPending } = useStoreCart();
  const { mergeCart } = useRemoteCartHelper();
  const { state, pathname } = useLocation();

  const isPullEnabled = useMemo(() => {
    return !!auth && !whiteList.includes(pathname) && state?.doNotSync !== true;
  }, [pathname, state, auth, cart]);

  useEffect(() => {
    // Se o carrinho não estiver com o id do mercadinho e o usuário estiver autenticado, envie o carrinho para o servidor
    if (!!auth && !cart.grocery_id && cart.items.length > 0 && state?.doNotSync !== true){
      pushCart(cart);
    }
  }, [cart, auth])

  const {
    isLoading: isCartLoading,
    error: cartError,
  } = useQuery({
    queryFn: async () => {
      const response = await pullStoreCart();

      if (response.data) {
        const updatedStoreCart = mergeCart(response.data);
        if (updatedStoreCart !== null) {
          toast.success("Os itens do seu carrinho foram atualizados!");
          pushCart(updatedStoreCart);
        }
      }

      return response.data;
    },
    queryKey: ["pullStoreCart", pathname],
    enabled: isPullEnabled,
    staleTime: 0, // Indique que o dado nunca é considerado "fresco" após ser buscado
    retry: 0, // Não tente novamente em caso de falha
  });

  useEffect(() => {
    const onError = (error: any) => {
      //check if exists a cart in local storage
      //then push it to the server
      // if (cart.items.length > 0 && !cart.sync) {
      //   pushCart(cart);
      // }
      console.error("Error on pullStoreCart", error);
    };
    if (!isCartLoading && cartError) {
      onError(cartError);
    }
  }, [cartError, isCartLoading]);

  useEffect(() => {
    if (!cart) return;
    //carrinho com mais de 2 dias desde a ultima modificação é apagado
    if (moment(cart.modification_date).diff(moment(), "days") > 2) {
      clearCart();
    }
    // if (!cart.fingerprint) {
    //     getCurrentBrowserFingerPrint().then((fingerprint) => {
    //         setFingerprint(fingerprint)
    //     })
    // }
  }, []);

  return null;
};

const useStoreCart = () => {
  return useContext(StoreCartContext);
};

export { StoreCartInit, StoreCartProvider, useStoreCart };
