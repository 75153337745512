import { FaqGroup } from 'models/FaqGroup'
import faqStyle from './FAQConstructor.module.css'
import Accordion from 'react-bootstrap/Accordion'
import { Faq } from 'models/Faq';
import { FC } from 'react'

type Props = {
    faqGroup : FaqGroup
    key : any
}

const FAQConstructor: FC<Props> = ({faqGroup, key}) => {
  return (
    <>
        <div className={`${faqStyle.faqTitle} col-12`}> {faqGroup.name} </div>
        <div className={`container ${faqStyle.faqContentTitle}`}>
            {faqGroup.faqs.map((faq: Faq, idx: number) => (
                <div key={idx} className='py-2'>
                    <Accordion>                  
                        <Accordion.Item eventKey={key}>
                            <Accordion.Header>
                            <span className={faqStyle.faqContentTitle}>
                            {faq.question}
                            </span>
                            </Accordion.Header>
                            <Accordion.Body>
                            <span className={faqStyle.faqContentText}>
                                {faq.answer}
                            </span>
                            </Accordion.Body>
                        </Accordion.Item>               
                    </Accordion>
                </div>
            ))}
        </div>
    </>
  )
}

export default FAQConstructor