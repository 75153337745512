import { BackButtonListenerEvent, App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from '@capacitor/core';
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import CookiesModal from "components/common/CookiesModal/CookiesModal";
import ScrollToTop from "components/common/ScrollToTop/ScrollToTop";
import { SplashScreenProvider } from "components/common/SplashScreen/SplashScreenProvider";
import { GroceryInfoInit, GroceryProvider } from "layouts/common/core/GroceryProvider";
import { StoreConfigInit, StoreConfigProvider } from "layouts/common/core/StoreConfigProvider";
import { StoreLocationProvider } from "layouts/common/core/StoreLocationProvider";
import { AuthInit, AuthProvider } from "modules/auth/core/AuthProvider";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.error(`Algo deu errado: ${error.message}`)
      console.error(error)
    },
  }),
});

const App = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const isNative = useMemo(() => Capacitor.isNativePlatform(), []);

  useEffect(() => {

    if (isNative) {
      CapacitorApp.addListener('backButton', (event: BackButtonListenerEvent
      ) => {
        if (location.pathname === '/') {
          CapacitorApp.exitApp();
          return;
        }
        navigate(-1)
      }
      );
    }

    return () => {
      if (isNative) {
        CapacitorApp.removeAllListeners();
      }
    }
  }, [location, navigate, isNative]);

  return (
    <QueryClientProvider client={queryClient}>
      <SplashScreenProvider>
        {/* <Suspense fallback={<>carregando?</>}> */}
        <StoreConfigProvider>
          <StoreLocationProvider>
            <GroceryProvider>
              <AuthProvider>
                <StoreConfigInit />
                <GroceryInfoInit />
                <AuthInit />
                <Outlet />
              </AuthProvider>
            </GroceryProvider>
          </StoreLocationProvider>
        </StoreConfigProvider>
        <ScrollToTop />
        <ToastContainer />
        {!isNative && <CookiesModal />}
        {/* </Suspense> */}
      </SplashScreenProvider>
    </QueryClientProvider>
  );
};

export default App;
