import { regionalClient } from "index"
import { GroceryPersonOfContact } from "models/PersonOfContact"
import { GroceryEndpoints } from "modules/api/endpoints/GroceryEndpoints"
import { GroceryDetails } from "modules/auth"

export const getGroceryDetails = () => {
    return regionalClient.get<GroceryDetails>(GroceryEndpoints.details)
}
export const updateGroceryDetails = (groceryDetails : GroceryDetails) => {
    return regionalClient.put<GroceryDetails>(GroceryEndpoints.updateDetails, groceryDetails)
}
export const getGroceryMainContact = () => {
    return regionalClient.get<GroceryPersonOfContact>(GroceryEndpoints.mainContactDetails)
}
export const updateGroceryMainContact = (contact: GroceryPersonOfContact) => {
    return regionalClient.put<GroceryPersonOfContact>(GroceryEndpoints.mainContactDetails, contact)
}
export const listSecundaryContacts = () => {
    return regionalClient.get<GroceryPersonOfContact[]>(GroceryEndpoints.listSecundaryContacts)
}
export const updateGroceryContact = (id: string, contact: GroceryPersonOfContact) => {
    return regionalClient.put<GroceryPersonOfContact>(GroceryEndpoints.updateContact(id), contact)
}
export const getGroceryContact = (id: string) => {
    return regionalClient.get<GroceryPersonOfContact>(GroceryEndpoints.contactDetails(id))
}
export const createGroceryContact = (contact : GroceryPersonOfContact) => {
    return regionalClient.post<GroceryPersonOfContact>(GroceryEndpoints.createContact, contact)
}
