import { useEffect } from 'react'

import { useStoreConfig } from 'layouts/common/core/StoreConfigProvider'

function StoreCartBar() {
    const { setStoreCartVisibility } = useStoreConfig()

    useEffect(() => {
        setStoreCartVisibility(true)
        return (() => {
            setStoreCartVisibility(false)
        })
    }, [])

  return null
}

export default StoreCartBar
