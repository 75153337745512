import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { KTSVG } from "components/common/KTSVG";
import Breadcrumbs from "components/common/breadcrumbs/Breadcrumbs";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import WishlistCartLayout from "layouts/desktop/WishlistLayout/WishlistCardLayout";
import { Wishlist } from "models/Wishlist";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "utils/Functions";
import {
  ACCOUNT_ORDERS_PAGE,
  ACCOUNT_SUGGESTED_WHISHLIST_PAGE,
  ACCOUNT_WHISHLIST_DETAIL_PAGE,
  ACCOUNT_WHISHLIST_PAGE,
  STORE_FRONT_PAGE,
  toUrl,
} from "utils/Links";
import listStyle from "./AccountWishlistPage.module.css";
import { list, remove, update } from "./core/_requests";
import AccountWishlistDeleteModal from "./modal/AccountWishlistDeleteModal/AccountWishlistDeleteModal";
import AccountWishlistEditNameModal from "./modal/AccountWishlistEditNameModal/AccountWishlistEditNameModal";

const breadcrumbs = [
  {
    title: "Perfil",
    path: toUrl(ACCOUNT_ORDERS_PAGE),
  },
  {
    title: "Listas",
    path: toUrl(ACCOUNT_WHISHLIST_PAGE),
  },
];

function AccountWishlistPage() {
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState<Wishlist>();
  const queryClient = useQueryClient();
  const { addItemCart } = useStoreCart();
  const [loading, setLoading] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalAddToCartOpen, setIsModalAddToCartOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const { data: wishlists, isLoading: isWishlistLoading } = useQuery({
    queryFn: async () => {
      const response = await list();
      return response.data;
    },
    queryKey: ["wishlists"],
  });

  const handleDeleteModalClose = (remove: boolean = false) => {
    if (remove && wishlist?.id) {
      sendRemoveWishlistRequest(wishlist.id);
    }
    setIsModalDeleteOpen(false);
    setWishlist(undefined);
  };

  const handleEditTitle = (wishlist: Wishlist) => {
    sendUpdateWishlist(wishlist);
  };

  const handleEditModalClose = (updatedName?: string) => {
    if (updatedName && wishlist) {
      sendUpdateWishlist({ ...wishlist, name: updatedName });
    }
    setIsModalEditOpen(false);
    setWishlist(undefined);
  };

  // function showEditModal(wishlist: Wishlist) {
  //   setActiveWishlist(wishlist);
  //   setIsModalEditOpen(true);
  // }

  // function showAddModal() {
  //   setIsModalAddToCartOpen(true);
  // }

  function showDeleteModal(wishlist: Wishlist) {
    setWishlist(wishlist);
    setIsModalDeleteOpen(true);
  }

  function showEditTitleModal(wishlist: Wishlist) {
    setWishlist(wishlist);
    setIsModalEditOpen(true);
  }

  // function addAllItemsCart() {
  //   onDismiss();
  //   toast.success("Todos os itens foram adicionados ao carrinho", {
  //     position: "top-right",
  //     autoClose: 4000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "light",
  //   });
  // }

  const { mutate: sendRemoveWishlistRequest } = useMutation({
    mutationFn: async (id: string) => {
      const response = remove(id);
      return response;
    },
    mutationKey: ["removeWishlist", wishlist?.id],
    onSuccess: () => {
      toast.success("Lista de compras removida");
      queryClient.invalidateQueries({ queryKey: ["wishlists"] });
    },
    onError: (error) => {
      toast.error("Erro ao tentar remover a lista de compras");
    },
  });

  const { mutate: sendUpdateWishlist } = useMutation({
    mutationFn: async ({ id, ...wishlist }: Wishlist) => {
      const response = update(id!!, wishlist);
      return response;
    },
    mutationKey: ["updateWishlist", wishlist?.id],
    onSuccess: () => {
      toast.success("Lista de compras atualizada");
      queryClient.invalidateQueries({ queryKey: ["wishlists"] });
    },
    onError: (error) => {
      toast.error("Erro ao atualizar lista de compras");
    },
  });

  // TROCAR LINK
  const handleListClick = (list: Wishlist) => {
    navigate(toUrl(ACCOUNT_WHISHLIST_DETAIL_PAGE, list.id));
  };

  return (
    <>
      <AccountWishlistDeleteModal show={isModalDeleteOpen} wishlist={wishlist} onHide={handleDeleteModalClose} />
      <AccountWishlistEditNameModal show={isModalEditOpen} wishlist={wishlist} onHide={handleEditModalClose} />

      {/* <AddToCartModal show={isModalAddToCartOpen} onHide={onDismiss} onConfirm={addAllItemsCart} /> */}

      <div className="d-flex flex-column">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className="font600 font15rem mb-4">MINHAS LISTAS</span>
      </div>

      <img
        src={toAbsoluteUrl("/media/general/Lists-Banner.svg")}
        alt=""
        onClick={() => navigate(toUrl(ACCOUNT_SUGGESTED_WHISHLIST_PAGE))}
        className="cursor-pointer w-100"
      />

      {(!wishlists || wishlists.length === 0) && (
        <div className="d-flex justify-content-center mt-4">
          <div className={`${listStyle.emptyPage}  w-100`}>
            <div className="d-flex flex-column align-items-center">
              <KTSVG path="/media/general/List-Drawing.svg" className="pb-5" />
              <span className="pb-3 main-color font500 font12rem">Você ainda não tem nenhuma lista...</span>
              <span className="pb-3 main-color font500 font12rem">
                Mas é bem fácil criar uma! Basta seguir o passo a passo abaixo:
              </span>
              <ol className={listStyle.list}>
                <li>Escolha qualquer um dos produtos disponíveis para criar a sua primeira lista</li>
                <li>
                  Clique neste ícone{" "}
                  <img
                    src={process.env.PUBLIC_URL + "/media/general/Small-List-Outline-Icon.svg"}
                    alt=""
                    className={listStyle.listIcon}
                  />{" "}
                  para criar a lista de compras
                </li>
                <li>
                  Assim que o ícone mudar de cor e ficar preenchido{" "}
                  <img
                    src={process.env.PUBLIC_URL + "/media/general/Small-List-Filled-Icon.svg"}
                    alt=""
                    className={listStyle.listIcon}
                  />{" "}
                  significa que a sua primeira lista foi criada com sucesso
                </li>
                <li>
                  Para conferir a lista que você acabou de criar, clique na opção “Listas” no canto superior da sua tela
                </li>
                <li>E pronto! Você já pode continuar fazendo suas compras ou até mesmo criar mais listas!</li>
              </ol>

              <div
                className={`${listStyle.outlineButton} button my-3`}
                onClick={() => navigate(toUrl(STORE_FRONT_PAGE))}
              >
                Adicione os primeiros itens +
              </div>
            </div>
          </div>
        </div>
      )}

      {wishlists && wishlists.length > 0 && (
        <div className={listStyle.listsGrid}>
          {wishlists.map((wishlist, idx) => (
            <WishlistCartLayout
              key={idx}
              loading={isWishlistLoading}
              wishlist={wishlist}
              onDelete={showDeleteModal}
              onEditTitle={showEditTitleModal}
              onClick={() => handleListClick(wishlist)}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default AccountWishlistPage;
