import { useQuery } from "@tanstack/react-query";
import Image from "components/common/Image/Image";
import Breadcrumbs from "components/common/breadcrumbs/Breadcrumbs";
import CartItemGroupWishlist from "components/desktop/CartItemGroup/CartItemGroupWishlist";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import { WishlistItemGroup } from "models/Wishlist";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getActualProductUnitPrice, getRootCategory, toAbsoluteUrl } from "utils/Functions";
import {
    ACCOUNT_ORDERS_PAGE,
    ACCOUNT_SUGGESTED_WHISHLIST_DETAIL_PAGE,
    ACCOUNT_SUGGESTED_WHISHLIST_PAGE,
    ACCOUNT_WHISHLIST_PAGE,
    STORE_FRONT_PAGE,
    toUrl
} from "utils/Links";
import suggestedStyles from "./AccountSuggestWishlistPage.module.css";
import { detail } from "./core/_requests";
import AccountWishlistAddAllModal from "./modal/AccountWishlistAddAllModal/AccountWishlistAddAllModal";

function AccountSuggestWishlistDetailPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isModalAddAllOpen, setIsModalAddAllOpen] = useState(false);
  const { addItemsCart } = useStoreCart();

  const { data: wishlist, isLoading: isWishlistLoading } = useQuery({
    queryFn: async () => {
      const response = await detail(id!!);
      return response.data;
    },
    queryKey: ["wishlist", id],
    enabled: id !== undefined,
  });

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "Perfil",
        path: toUrl(ACCOUNT_ORDERS_PAGE),
      },
      {
        title: "Minhas listas",
        path: toUrl(ACCOUNT_WHISHLIST_PAGE),
      },
      {
        title: wishlist?.name || "Não encontrado",
        path: toUrl(ACCOUNT_SUGGESTED_WHISHLIST_DETAIL_PAGE, wishlist?.id),
      },
    ];
  }, [wishlist]);

  //mount items grouped
  const itemsGrouped = useMemo(() => {
    if (!wishlist || !wishlist.items) return;

    const result: WishlistItemGroup[] = [];

    wishlist.items.forEach((item) => {
      const rootCategory = getRootCategory(item.product.category);
      const itemGroupIdx = result.findIndex((itemGroup) => {
        return itemGroup.category === rootCategory;
      });
      const bestUnitPrice = getActualProductUnitPrice(item.product, item.quantity);

      if (itemGroupIdx === -1) {
        result.push({
          category: rootCategory,
          items: [item],
          total: item.quantity * bestUnitPrice,
          total_quantity: item.quantity,
          open: true,
        });
      } else {
        result[itemGroupIdx].items.push(item);
        result[itemGroupIdx].total = result[itemGroupIdx].items.reduce((total, item) => {
          const bestUnitPrice = getActualProductUnitPrice(item.product, item.quantity);
          return total + item.quantity * bestUnitPrice;
        }, 0);
        result[itemGroupIdx].total_quantity = result[itemGroupIdx].items.reduce((total, obj) => {
          return total + obj.quantity;
        }, 0);
      }
    });

    return result.sort((a, b) => {
      //ordena por ordem alfabética
      return a.category.localeCompare(b.category);
    });
  }, [wishlist]);

  const handleAddAllModalClose = (add: boolean = false) => {
    if (add && wishlist) {
      const itemsToAdd = wishlist.items.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
        };
      });
      addItemsCart(itemsToAdd);
    }
    setIsModalAddAllOpen(false);
  };

  return (
    <>
      <AccountWishlistAddAllModal show={isModalAddAllOpen} onHide={handleAddAllModalClose} />

      <div className="container">
        <div className="mb-4">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <span className="font600 font15rem">{wishlist?.name}</span>
        </div>

        {itemsGrouped && itemsGrouped.length === 0 && (
          <div className={`${suggestedStyles.listItems} d-flex flex-column align-items-center`}>
            <img src={toAbsoluteUrl("/media/Illustrations/Clipboard-Illustration.svg")} alt="" />
            <span className="mt-4">Essa lista está vazia</span>
            <div
              className={`${suggestedStyles.backToListsButton} mt-4`}
              onClick={() => navigate(toUrl(STORE_FRONT_PAGE))}
            >
              Ir para loja
            </div>
          </div>
        )}

        {itemsGrouped && itemsGrouped.length > 0 && (
          <>
            <div className="d-flex justify-content-between px-3 pb-2">
              <div className="cursor-pointer" onClick={() => setIsModalAddAllOpen(true)}>
                <span className="text-decoration-underline pe-2">Adicionar lista completa ao carrinho</span>
                <Image src={toAbsoluteUrl("/media/icons/Plus-Icon.svg")} alt="" className="black-filter " />
              </div>
            </div>
            <div className={suggestedStyles.groupedList}>
              {itemsGrouped.map((itemGroup, idx) => (
                <CartItemGroupWishlist key={idx} itemGroup={itemGroup} wishlist={wishlist!!} />
              ))}
            </div>
          </>
        )}

        {!wishlist && (
          <div className={`${suggestedStyles.listItems} d-flex flex-column align-items-center`}>
            <img src={toAbsoluteUrl("/media/Illustrations/Clipboard-Illustration.svg")} alt="" />
            <span className="mt-4">Não encontramos a lista</span>
            <span>veja todas as listas sugeridas clicando no botão abaixo:</span>
            <div
              className={`${suggestedStyles.backToListsButton} mt-4`}
              onClick={() => navigate(toUrl(ACCOUNT_SUGGESTED_WHISHLIST_PAGE))}
            >
              <img src={"/media/ChevronsAndArrows/Arrow-Left.svg"} alt="" />
              Listas sugeridas
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AccountSuggestWishlistDetailPage;
