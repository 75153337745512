import { useQuery } from '@tanstack/react-query';
import DateSelector from 'components/common/DateSelector/DateSelector';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { Bill } from 'models/Bill';
import { FilterOption } from 'models/FilterOption';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import fakeData from 'resources/data/fake-data';
import checkPaymentMethods from 'utils/CheckPaymentMethod';
import { DATE_USUAL } from 'utils/Consts';
import { toAbsoluteUrl, toBRL } from 'utils/Functions';
import { ACCOUNT_PAYMENT_DETAIL_PAGE, toUrl } from 'utils/Links';
import paymentPage from './PaymentPage.module.css';
import { listPayments } from './core/_request';
import ClipLoader from 'react-spinners/ClipLoader';
// import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
// import { ACCOUNT_PAYMENT, toUrl } from 'utils/Links';

interface Props {
  id?: string;
  date?: string;
  status?: string;
  status_slug?: string;
  type?: string;
  updated_date?: string;
}

function PaymentsPage(props: Props) {
  const {users} = fakeData;
  const navigate = useNavigate();
  // const [sortedPayments, setSortedPayments] = useState<Props[]>([]);
  const [payments, setPayments] = useState<Bill[]>([])
  const [isFilterContentVisible, setIsFilterContentVisible] = useState(false)
  const [filterOption, setFilterOption] = useState<FilterOption>()
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(-1)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  // useEffect(() => {
  //   const sorted = [...payments].sort((a, b) => {
  //     const orderDateA = parse(a.updated_date, 'DD/MM/YYYY');
  //     const orderDateB = parse(b.updated_date, 'DD/MM/YYYY');
  //     return orderDateA === orderDateB ? 0 : orderDateA > orderDateB ? -1 : 1;
  //   });
  //   setSortedPayments(sorted);
  // }, [payments]);

  function handleDateFilterChange(filterOption: FilterOption) {
    setFilterOption(filterOption)
    setCurrentPage(0)
    setIsFilterContentVisible(false)
  }

  const hasMoreContent = useMemo(() => {
    return totalPages <= 1 ? false : totalPages > (currentPage + 1)
  }, [currentPage, totalPages])

  const { data: billData, isLoading: isBillLoading, isFetching: isBillFetching } = useQuery({ 
    queryFn: async () => {
        const filters = filterOption?.value ? [filterOption.value] : []
        const response = await listPayments(filters, currentPage)
        setPayments((prev) => {
            if (currentPage === 0) {
                return response?.data.content
            }
            return [...prev, ...response?.data.content]
        })

        setTotalPages(response.data.total_pages)
        setIsLoadingMore(false)

        return response
    },
    queryKey: ['payments', filterOption, currentPage],
  })

  const checkPaymentStatus = (bill: Bill) => {
    let count = 0;

    bill.installments?.forEach(installment => {
        if (installment.status?.value === "1") {
            count++;
        }
    });
    return count;
  }

  return (
    <>
      <PageTitle title={`PAGAMENTOS`} />
      <div className='container padding-bottom-mobile'>

        <div className={`${paymentPage.balanceDisplay} d-flex flex-column p-3 mb-3`}>
          <span className='font12rem font600'>Saldo</span>
          <span className='pb-2'>Atualizado pela última vez em {users[0].updated_date}</span>
          <h3>{toBRL(users[0].balance)}</h3>
        </div>

        <div className='d-flex justify-content-between align-items-center'>
          <span className='main-color font12rem font600'>Histórico</span>
          <DateSelector onChange={() => {}}/>
        </div>

        {payments.map((payment, index) => (
          <div key={index}>
            <div onClick={() => navigate(toUrl(ACCOUNT_PAYMENT_DETAIL_PAGE, payment.id))} className={paymentPage.payment}>
              <div
                className={`${paymentPage.paymentBubble} ${paymentPage.bubbleClosed} d-flex flex-row justify-content-between align-items-center p-3 my-3`}
              >
                <span className={`${paymentPage.leserDetails} ${paymentPage.idStyle}`}>
                  {/* TO BE CHANGED TO payment.number LATER */}
                  # 000214
                </span>
                <span className={`${paymentPage.leserDetails}`}> 
                  {moment(payment.modification_date).format(DATE_USUAL)} 
                </span>

                {payment.installments &&
                  <div className={`${paymentPage.paymentBubbleStatus} ${'bubbleStatus' + payment.installments[payment.installments.length - 1]?.status?.value}`}>
                      <span>{payment.installments?.length}/{checkPaymentStatus(payment)}</span>
                      <span className='ms-1'>{payment.installments[payment.installments.length - 1]?.status?.description}</span>
                  </div>
                }

                <span className={`${paymentPage.leserDetails}`}>
                  {checkPaymentMethods(payment)}
                </span>
                <MdOutlineKeyboardArrowRight />
              </div>
            </div>
          </div>
        ))}

        {payments.length < 1 && !isBillFetching &&
          <div className='d-flex flex-column justify-content-center my-3'>
            <img src={toAbsoluteUrl('/media/Illustrations/Doubt-Illustration-Ballon.svg')} alt='' height={'220px'}/>
            <span className='text-center mt-4'>Nenhum histórico de pagamento foi encontrado na sua conta</span>
          </div>
        }

        {isBillFetching &&
          <div className='container main-color d-flex flex-column align-items-center p-4 my-3'>
            <ClipLoader
                color="#033858"
                size={20}
            />
            <span className='font500 mt-3'>
                Carregando...
            </span>
          </div>
        }
      </div>
    </>
  );
}

export default PaymentsPage;