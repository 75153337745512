import { StoreLocation } from "models/StoreLocation"
import { LOCAL_STORAGE_STORE_LOCATION_KEY } from "utils/Consts"

const getStoreLocation = (): StoreLocation | undefined => {
    if (!localStorage) {
        return
    }

    const lsValue: string | null = localStorage.getItem(LOCAL_STORAGE_STORE_LOCATION_KEY)
    if (!lsValue) {
        return
    }

    try {
        const storeLocation: StoreLocation = JSON.parse(lsValue) as StoreLocation

        return storeLocation
    } catch (error) {
        console.error('STORE_LOCATION LOCAL STORAGE PARSE ERROR', error)
    }
}

const saveStoreLocation = (storeLocation: StoreLocation) => {
    if (!localStorage) {
        return
    }
    try {
        const lsValue: string | null = localStorage.getItem(LOCAL_STORAGE_STORE_LOCATION_KEY)
        if (!lsValue) {
            localStorage.setItem(LOCAL_STORAGE_STORE_LOCATION_KEY, JSON.stringify(storeLocation))
        } else {
            let storeLocation: StoreLocation = JSON.parse(lsValue) as StoreLocation
            const updatedLsValue = JSON.stringify({ ...storeLocation, ...storeLocation })
            localStorage.setItem(LOCAL_STORAGE_STORE_LOCATION_KEY, updatedLsValue)
        }
    } catch (error) {
        console.error('STORE_LOCATION LOCAL STORAGE SAVE ERROR', error)
    }
}

const clearStoreLocation = () => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.removeItem(LOCAL_STORAGE_STORE_LOCATION_KEY)
    } catch (error) {
        console.error('STORE_LOCATION LOCAL STORAGE REMOVE ERROR', error)
    }
}

export { clearStoreLocation, getStoreLocation, saveStoreLocation }
