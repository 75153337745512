import { regionalClient } from "index";
import { Bill } from "models/Bill";
import { SearchCriteria } from "models/SearchCriteria";
import { BillEndpoints } from "modules/api/endpoints/BillEndpoints";
import qs from "qs";

export const listPayments = ( filters: SearchCriteria[], page: number, perPage: number = 10) => {
    return regionalClient.get(BillEndpoints.list, {
      params: {
        filters: filters,
        page: page,
        per_page: perPage,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { allowDots: true });
      },
    });
  };
  
  export const paymentFullDetails = (id: string) => {
    return regionalClient.get<Bill>(BillEndpoints.info(id));
  };