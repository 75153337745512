import { regionalClient } from "index";
import { SalesOrder } from "models/SalesOrder";
import { SearchCriteria } from "models/SearchCriteria";
import { OrderEndpoints } from "modules/api/endpoints/OrderEndpoints";

export const listOrders = (term: string = '', filters: SearchCriteria[], page: number, perPage: number = 10) => {
    return regionalClient.post(OrderEndpoints.list, {
        search: term,
        filters: filters,
        page: page,
        per_page: perPage,
    })
}

export const orderDetails = (id: string) => {
    return regionalClient.get<SalesOrder>(OrderEndpoints.detail(id))
}