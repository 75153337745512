import { useAuth } from 'modules/auth/core/AuthProvider';
import { forwardRef, useMemo } from 'react';
import DeliveryNotificationLayout from './layouts/DeliveryNotificationLayout';
import SignInWithLocationNotificationLayout from './layouts/SignInWithLocationNotificationLayout';

const NotificationBar = forwardRef((props: any, ref: any) => {

    const { auth } = useAuth();

    const delivery = useMemo(() => {
        if (!auth) return;
        return {
            day: 'Monday',
            date: '12/12/2021'
        }
    }, [auth]);

    return (<div ref={ref}>
        {delivery && <DeliveryNotificationLayout />}
        {!auth && <SignInWithLocationNotificationLayout />}
    </div>

    );
});

// Name the component for debugging purpose and prevent eslint warning.
NotificationBar.displayName = 'NotificationBar';

export default NotificationBar;
