import { useMemo } from 'react';

type Props = {
    deliveryDate?: string
}

const daysOfWeek = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];

function TimeDeliveryCalculator({ deliveryDate }: Props) {

    const currentDayofWeek = useMemo(() => {
        return new Date().toLocaleDateString('pt-BR', { weekday: 'long' })
    }, [])

    const dayOfWeek = useMemo(() => {
        if (!deliveryDate) return
        return new Date(deliveryDate).toLocaleDateString('pt-BR', { weekday: 'long' })
    }, [deliveryDate])

    const formattedDate = useMemo(() => {
        if (!deliveryDate) return
        const currentDate = new Date(deliveryDate)
        const day = dayOfWeek === currentDayofWeek ? 'Hoje' : daysOfWeek[currentDate.getDay()]
        const date = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getDate().toString().padStart(2, '0')}/${currentDate.getFullYear()}`
        const time = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}`
        return `${day}(${date}) até as ${time}`
    }, [deliveryDate, dayOfWeek, currentDayofWeek])

    return (
        <>
            {deliveryDate && formattedDate}
        </>
    )
}

export default TimeDeliveryCalculator
