import React from 'react'
import enableStyle from './StepEnablePage.module.css'
import AuthenticationSteps from 'components/mobile/AuthenticationSteps/AuthenticationSteps'
import {ACCOUNT_2_FACTOR, toUrl} from 'utils/Links'
import {useNavigate} from 'react-router-dom'
import PageTitle from 'components/common/PageTitle/PageTitle'
import { toAbsoluteUrl } from 'utils/Functions'

function StepEnablePage() {
  const navigate = useNavigate()

  return (
    <>
      <PageTitle title={`AUTENTICAÇÃO DE DOIS FATORES`} />
      <AuthenticationSteps altStep={1} />
      <div className='container py-4 px-4'>
        <h4>Habilitar autenticação</h4>
        <p>A autenticação de dois fatores garante ainda mais segurança ao acessar a sua conta.</p>
        <p>
          Depois de habilitar a autenticação de dois fatores, a cada acesso realizado em sua conta
          Vupty, você irá precisar do código fornecido pelo aplicativo <strong>“Google Authenticator”.</strong>
        </p>
        <button
          className={`${enableStyle.authenticationButton} col-12`}
          onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 2))}
        >
          Próxima etapa{' '}
          <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Arrow-Right.svg')} alt='' className='transform-1px'/>
        </button>
      </div>
    </>
  )
}

export default StepEnablePage
