import ContentLoader from 'react-content-loader';

type Props = {
    width: any;
    height: any;
    onClick: () => void
};

function ImageSkeleton({ width, height, onClick }: Props) {
    return (
        <ContentLoader
            speed={2}
            width={width || 0}
            height={height || 0}
            viewBox={`0 0 ${width || 0} ${height || 0}`}
            backgroundColor="#f5f5f5"
            foregroundColor="#dbdbdb"
            onClick={onClick}>

            <rect x="0" y="0" rx="8" ry="8" width={width} height={height} />
            <rect x="409" y="85" rx="0" ry="0" width="0" height="1" />
        </ContentLoader>
    )
}

export default ImageSkeleton
