import CardBRLStyle from "components/common/CardBRLStyle/CardBRLStyle";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import { Product } from "models/Product";
import { useMemo } from "react";
import { toBRL } from "utils/Functions";
import ProductButtonSkeleton from "../Skeletons/ProductButtonSkeleton";
import btnBox from "./BtnCard.module.css";

type Props = {
  product: Product;
  selected: boolean;
  loading?: boolean;
  onClick?: () => void;
};

function BtnBox({ product, loading = true, selected, onClick }: Props) {
  const { getItemCart } = useStoreCart();
  const itemCart = useMemo(() => getItemCart(product.id), [product, getItemCart]);
  const isDisabled = useMemo(() => {
    if (!product || !product.supply_type) return true;
    return product.supply_type.value === "1";
  }, [product]);

  // const getPackagePrice = useCallback((product: Product) => {
  //   if (product.price_ranges) {
  //     const actualPriceRange = getActualProductPriceRange(product, itemCart?.quantity);
  //     if (actualPriceRange) {
  //       return actualPriceRange.unit_price * (itemCart?.quantity || 1);
  //     }
  //   }
  //   return product.package_sell_price ;
  // }, [itemCart]);

  const leftValue = useMemo(() => {
    // const packagePrice = getPackagePrice(product);

    const value = (!isDisabled || !product.package_sell_price) ? product.package_sell_price : null;

    return (
      <>
        <span className="text-start font09rem text-uppercase">{product.package_measurement_unit}</span>
        <span className={btnBox.price}>
          <CardBRLStyle value={value} />
        </span>
      </>
    );
  }, [product, isDisabled]);

  const rightValue = useMemo(() => {
    return (
      <>
        <span className="font07rem text-uppercase">{product.quantity_per_package} {`${product.measurement_unit}s`}</span>
        <span className="font08rem">{product.package_unit_price ? `${toBRL(product.package_unit_price)}/${product.measurement_unit}` : '--'}</span>
      </>
    );
  }, [product]);

  const handleOnClick = () => {
    if (!isDisabled && onClick) {
      onClick();
    }
  };

  return (
    <>
      {loading && <ProductButtonSkeleton />}
      {!loading && (
        <div className={selected && !isDisabled ? btnBox.btnSelected : btnBox.btnItemFaded} onClick={handleOnClick}>
          <button className={btnBox.btnPrimary} type="button" disabled={isDisabled}>
            <div className="d-flex flex-row px-2 py-1 justify-content-between">
              {leftValue && (
                <div className="d-flex flex-column align-items-start justify-content-between">{leftValue}</div>
              )}
              {!isDisabled && !!rightValue && (
                <div className="d-flex flex-column align-items-end justify-content-between">{rightValue}</div>
              )}
            </div>
          </button>
        </div>
      )}
    </>
  );
}

export default BtnBox;
