import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Product } from 'models/Product';
import { Wishlist } from 'models/Wishlist';
import { ApiResponseError } from 'modules/api/core/_models';
import { useEffect, useRef, useState } from "react";
import Sheet, { SheetRef } from 'react-modal-sheet';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from "utils/Functions";
import listStyle from './WishlistModal.module.css';
import { create, list, update } from './core/_requests';

type Props = {
    onDismiss: () => void
    open: boolean
    product: Product
    quantity: number
    onChange: (isWishlisted: boolean) => void
}

enum DisplayMode {
    LIST,
    NEW_LIST
}

function WishlistModal({ open, onDismiss, product, quantity, onChange }: Props) {
    const ref = useRef<SheetRef>();
    const queryClient = useQueryClient();
    const [checkedCheckboxes, setCheckedCheckboxes] = useState<boolean[]>([]);
    const [wishlists, setWishlists] = useState([] as Wishlist[]);
    const [wishlistName, setWishlistName] = useState('' as string);
    const [displayMode, setDisplayMode] = useState(DisplayMode.LIST);

    useEffect(() => {
        if (wishlists) {
            const newChecked = [...checkedCheckboxes];
            wishlists.forEach((wishlist, idx) => {
                const isProductPresent = wishlist.items?.some((item) => item.product.id === product.id);
                if (isProductPresent) {
                    newChecked[idx] = true;
                }
            });
            setCheckedCheckboxes(newChecked);
        }
    }, [wishlists]);

    const updateCheckedCheckboxes = (whList: Wishlist[]) => {
        const updatedCheckedboxes = whList?.map((wishlist) => {
            return wishlist.items?.some((item) => item.product.id === product.id);
        });
        setCheckedCheckboxes(updatedCheckedboxes);
    }

    const { isLoading: isWishlistLoading } = useQuery({
        queryFn: async () => {
            const response = await list()
            setWishlists(response.data)
            // updateDisplayMode(response.data.content)
            updateCheckedCheckboxes(response.data)
            return response
        },
        queryKey: ['wishlists']
    })

    const { mutate: sendUpdateRequest } = useMutation({
        mutationFn: async ({ id, ...wishlist }: Wishlist) => update(id!!, wishlist),
        mutationKey: ['updateWishlist'],
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['wishlists'] })
        }
    })

    const { mutate: sendCreateRequest } = useMutation({
        mutationFn: async (wishlist: Wishlist) => create(wishlist),
        mutationKey: ['createWishlist'],
        onSuccess: ({ data }) => {
            queryClient.invalidateQueries({ queryKey: ['wishlists'] });
            toast.success("Produto salvo na lista", {
                position: 'top-right',
                autoClose: 2000,
                theme: 'light',
            });
            setWishlistName('');
            onChange(true);
            onDismiss();
        },
        onError: (error) => {
            //mostrar mensagem de erro
            if (error instanceof ApiResponseError) {
                toast.error(error.getErrorMessage())
            } else {
                toast.error('Erro ao salvar produto na lista')
            }
        }
    })

    const handleCheckboxChange = (idx: number) => {
        const newChecked = [...checkedCheckboxes];
        const checkboxStatus = !newChecked[idx];
        newChecked[idx] = checkboxStatus;
        setCheckedCheckboxes(newChecked);

        //notify parent
        if (newChecked.filter((checked) => checked).length === 0) {
            onChange(false);
        } else {
            onChange(true);
        }

        const updatedWhishlist = wishlists[idx];
        if (checkboxStatus) {
            //create new item
            if (!updatedWhishlist.items) {
                updatedWhishlist.items = [{
                    quantity: quantity,
                    product: product,
                    active: true
                }]
            } else {
                //update existing item
                updatedWhishlist.items.push({
                    quantity: quantity,
                    product: product,
                    active: true
                })
            }
        } else {
            //remove item
            updatedWhishlist.items = updatedWhishlist.items?.filter(item => item.product.id !== product.id)
        }
        //update wishlist
        sendUpdateRequest(updatedWhishlist)
    };

    const handleNewWishlistInputChange = (event: any) => {
        setWishlistName(event.target.value)
    };

    const handleNewWishlistSubmit = () => {
        const newWishlist = {
            name: wishlistName,
            active: true,
            items: [{
                quantity: quantity,
                product: product,
                active: true
            }]
        };
        sendCreateRequest(newWishlist)
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter' && e.currentTarget.value !== '') {
            handleNewWishlistSubmit()
        }
    };

    return (
        <div>
            <Sheet
                ref={ref}
                isOpen={open}
                onClose={onDismiss}
                initialSnap={0}
                snapPoints={[-50, 100, 0]}
                detent="content-height"
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Scroller>
                        <Sheet.Content className='mb-3'>
                            {isWishlistLoading &&
                            <div className='d-flex flex-column justify-content-center'>
                            <ClipLoader
                                color="#033858"
                                size={42}
                            />
                                Carregando...
                            </div>}
                            {!isWishlistLoading && displayMode === DisplayMode.NEW_LIST && (<div className={listStyle.container}>
                                <div className={listStyle.title}>
                                    <span> Criar lista </span>
                                </div>
                                <div className={listStyle.newList}>
                                    <label className='font500'>Nome da lista:</label>
                                    <input type="text" onChange={handleNewWishlistInputChange} value={wishlistName} onKeyDown={handleKeyDown} />
                                </div>
                                <div className='d-flex justify-content-center pt-2 pb-3'>
                                    <div className={listStyle.newListButton}
                                        onClick={handleNewWishlistSubmit}>
                                        Criar
                                    </div>
                                </div>
                            </div>)}

                            {!isWishlistLoading && displayMode === DisplayMode.LIST && (<>
                                <div className={listStyle.container}>
                                    <div className={listStyle.title}>
                                        <span> Salvar item na lista </span>
                                        <img src={toAbsoluteUrl('/media/general/X-icon.svg')} alt='' onClick={onDismiss} />
                                    </div>
                                    <div className='mt-3'>
                                        {wishlists.map((list, idx) => (
                                            <label key={idx} className={listStyle.check}>
                                                <span className={checkedCheckboxes[idx] ? listStyle.checkedListName : listStyle.uncheckedListName}>
                                                    {list.name}
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-${idx}`}
                                                    name={`checkbox-${idx}`}
                                                    checked={checkedCheckboxes[idx] || false}
                                                    onChange={() => handleCheckboxChange(idx)}
                                                />
                                            </label>
                                        ))}
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center pb-3 my-3'>
                                    <div onClick={() => setDisplayMode(DisplayMode.NEW_LIST)} className={listStyle.newListButton}>
                                        Nova lista +
                                    </div>
                                </div>

                            </>)}
                        </Sheet.Content>
                    </Sheet.Scroller>
                </Sheet.Container>
                <Sheet.Backdrop onTap={onDismiss} />
            </Sheet>
        </div>
    )
}

export default WishlistModal
