/* eslint-disable react-hooks/exhaustive-deps */
import { useNewDataTable } from "components/common/NewDatatable/NewDatatable";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import filterStyle from './DateSelection.module.css'

const DateSelectionTableFilter: React.FC = () => {

  const { filter, updateFilter } = useNewDataTable()
  const [value, setValue] = useState<string>("3")
  const [isfilterOpen, setFilterOpen] = useState(false)

  useEffect(() => {
    updateCompetenceDateFilterValue(Number(value))
  },[value])

  const updateCompetenceDateFilterValue = (days : number) => {
    const updatedDate = moment().subtract(days, 'days').format('YYYY-MM-DD')
    let updatedFilter = filter.filter((item) => item.key !== 'competence_date')
    if (updatedDate) {
      updateFilter([...updatedFilter, {key: 'competence_date', value: updatedDate, operation: 'GREATER_THAN_EQUAL'}])
    }
  }

  const useClickOutside = (handler : any) => {
    const domNode = useRef<HTMLDivElement>(null)

    useEffect(() => {
      let maybeHandler = (event : any) => {
        if (domNode.current && !domNode.current.contains(event.target)) {
          handler()
        }
      }

      document.addEventListener('mousedown', maybeHandler)

      return () => {
        document.removeEventListener('mousedown', maybeHandler)
      }
    })

    return domNode
  }
  const domNode = useClickOutside(() => {
    setFilterOpen(false)
  })

  return (
    <div ref={domNode} className="d-flex justify-content-end position-relative mb-4">
      <div className={isfilterOpen ? filterStyle.ordersFormSelectorOpen : filterStyle.ordersFormSelector} onClick={() => setFilterOpen(!isfilterOpen)}>
        Filtrar por:
        <img
          src={process.env.PUBLIC_URL + '/media/general/Filter-Icon.svg'}
          alt="arrows"
          className="cursor-pointer ms-2"
        />
      </div>

      {isfilterOpen &&
        <div className={filterStyle.buttonContainer}>
          <div 
            // value={value} 
            // onChange={(e) => setValue(e.target.value)}
            className={isfilterOpen ? filterStyle.buttonOpen : filterStyle.buttonClosed}
          >
            <option value="3" onClick={() => { setValue("3"); setFilterOpen(false); }}>Últimos 3 dias</option>
            <option value="7" onClick={() => { setValue("7"); setFilterOpen(false); }}>Últimos 7 dias</option>
            <option value="15" onClick={() => { setValue("15"); setFilterOpen(false); }}>Últimos 15 dias</option>
            <option value="30" onClick={() => { setValue("30"); setFilterOpen(false); }}>Últimos 30 dias</option>
            <option value="60" onClick={() => { setValue("60"); setFilterOpen(false); }}>Últimos 60 dias</option>
            <option value="90" onClick={() => { setValue("90"); setFilterOpen(false); }}>Últimos 90 dias</option>
          </div>
        </div>
      }
    </div>
  );
};

export default DateSelectionTableFilter;
