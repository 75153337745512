import { regionalClient } from "index"
import { Banner } from "models/Banner"
import { Brand } from "models/Brand"
import { Category } from "models/Category"
import { Product } from "models/Product"
import { PageableResponse } from "modules/api/core/_models"
import { BannerEndpoints } from "modules/api/endpoints/BannerEndpoints"
import { BrandEndpoints } from "modules/api/endpoints/BrandEndpoints"
import { CategoryEndpoints } from "modules/api/endpoints/CategoryEndpoints"
import { ProductEndpoints } from "modules/api/endpoints/ProductEndpoints"
import qs from 'qs'

export const listBanner = () => {
  return regionalClient.get<Banner[]>(BannerEndpoints.list, {
    params: {
      filters: [{
        key: 'type',
        value: 'PRINCIPAL',
        operation: 'EQUAL'
      },{
        key: 'device_type',
        value: 'MOBILE',
        operation: 'EQUAL'
      }]
    },
    paramsSerializer: (params) => {
        return qs.stringify(params, { allowDots: true })
    },
  })
}
export const listPopularCategories = () => {
    return regionalClient.get<Category[]>(CategoryEndpoints.listTop)
}

export const listCategories = (page : number, perPage : number = 28) => {
    return regionalClient.get<PageableResponse<Category>>(CategoryEndpoints.list,{
        params: {
            per_page: perPage,
            page: page,
        }
    })
}

export const listBestBrands = () => {
    return regionalClient.get<PageableResponse<Brand>>(BrandEndpoints.list)
}

export const listBrands = (term : string = '', page : number, perPage : number = 28) => {
    return regionalClient.get<PageableResponse<Brand>>(BrandEndpoints.list,{
        params: {
            search: term,
            per_page: perPage,
            page: page,
        }
    })
}

export const listBestSellers = (page : number, perPage : number = 10) => {
    return regionalClient.get<PageableResponse<Product>>(ProductEndpoints.listBestSellers,{
        params: {
            per_page: perPage,
            page: page,
        }
    })
}
