import PageTitle from 'components/common/PageTitle/PageTitle'
import AuthenticationSteps from 'components/mobile/AuthenticationSteps/AuthenticationSteps'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router'
import 'react-spring-bottom-sheet/dist/style.css'
import { toAbsoluteUrl } from 'utils/Functions'
import { ACCOUNT_2_FACTOR, toUrl } from 'utils/Links'
import finalizeStyle from './StepEnablePage.module.css'

function StepFinalizePage() {
  const navigate = useNavigate();
  // NEEDS A VALUE AND THE IMPLEMENTATION TO ACTUALY COPY THE CODE
  const onSuccess = true

  const handleFinal = () => {
    if (onSuccess) {
      navigate(toUrl(ACCOUNT_2_FACTOR, 'sucesso'))
    }
    else {
      navigate(toUrl(ACCOUNT_2_FACTOR, 'erro'))
    }  
  }

  return (
    <>
      <PageTitle title={`AUTENTICAÇÃO DE DOIS FATORES`} />
      <AuthenticationSteps altStep={4} />
      <div className='container py-3'>
        <div className='font600 font11rem mb-3'>Finalizar a habilitação da autenticação de dois fatores</div>
        <div className={`${finalizeStyle.inputBar} d-flex flex-column`}>
          <span>Código que enviamos para o seu e-mail cadastrado</span>
          <input
            type='text'
            placeholder='000 000'
            className={finalizeStyle.finalizeAuthenticationInput}
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = '000 000')}
            required
          ></input>
        </div>

        <div className={`${finalizeStyle.inputBar} d-flex flex-column`}>
          <span>Código de verificação disponível no aplicativo Google Authenticator</span>
          <input
            type='text'
            placeholder='000 000'
            className={finalizeStyle.finalizeAuthenticationInput}
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = '000 000')}
            required
          ></input>
        </div>

        <div className={`${finalizeStyle.authenticationAlert} my-4`}>
          <div className='d-flex flex-row p-2'>
            <IoAlertCircleOutline style={{fontSize: '60px'}} />
            <span className='p-2'>
              Os códigos têm duração de 30 segundos cada. Você irá perceber que no canto direito da
              tela, um temporizador está sempre ativado para indicar se o código está próximo ou não
              de expirar.
            </span>
          </div>
        </div>

        <button className={`${finalizeStyle.finalButton} col-12`} onClick={handleFinal}>
          Finalizar
          <img src={toAbsoluteUrl('/media/general/Check-Simple-White.svg')} alt='' className='transform-2px ms-2'/>
        </button>
      </div>
    </>
  )
}

export default StepFinalizePage
