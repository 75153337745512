import ModalCart from 'components/desktop/ModalCart/ModalCart'
import { useStoreCart } from 'layouts/common/core/StoreCartProvider'
import { FC, useState } from 'react'
import { toAbsoluteUrl, toBRL } from 'utils/Functions'
import navbarCartStyle from './NavbarCartLayout.module.css'

const NavbarCartLayout: FC = () => {
  const { cart : {items_quantity, items_total, total_items_discount, total_mission_discount, items}} = useStoreCart()
  const [isCartModalOpen, setCartModalOpen] = useState(false)

  const handleCartModalClose = () => {
    setCartModalOpen(false)
  }

  function showCartModal() {
    setCartModalOpen(true)
  }

  return (
    <>
      <ModalCart open={isCartModalOpen} onHide={handleCartModalClose} />
      <div className='d-flex mx-1 ps-1 cursor-pointer' onClick={showCartModal}>
        <div className={navbarCartStyle.navBarLinks}>
          <div className={navbarCartStyle.cartArea}>
            <img src={toAbsoluteUrl('/media/general/Logo100px.svg')} alt='cart' className={navbarCartStyle.cartIcon} />
            {items_quantity > 0 && (
              <>
                <button className={items.length > 99 ? navbarCartStyle.CartCounterLong : navbarCartStyle.CartCounter}>
                  {items.length}
                </button>
                <span className={navbarCartStyle.priceBubble}>{toBRL(items_total - total_mission_discount)}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default NavbarCartLayout
