import { Mission } from 'models/Mission'
import { toAbsoluteUrl } from "utils/Functions"
import missionStyle from './MissionCard.module.css'

type Props = {
    mission: Mission
}

function MissionCard({mission}: Props) {

  function handleButton() {

  }  

  return (
    <div className={missionStyle.container}>
        <div className={missionStyle.header}>
            <div className="font500">
                <img src={toAbsoluteUrl('/media/icons/Reward-Icon.svg')} alt="" className="transform-2px main-filter me-2"/>
                <span>MISSÃO: {mission.title}</span>
            </div>
            <span className="font600 font12rem">{}</span>
        </div>
        <div className="d-flex flex-column mt-3">
            <span className="font500">Recompensa</span>
            <span>{mission.discount_value}</span>
        </div>
        <div className={missionStyle.missionContent}>
            <span className="font500">Missão</span>
            <span className={missionStyle.missionDescription}>{mission.description}</span>
        </div>
        <div className={missionStyle.button} onClick={handleButton}>
            Eu quero!
        </div>
    </div>
  )
}

export default MissionCard