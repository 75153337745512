import PageTitle from 'components/common/PageTitle/PageTitle'
import brandStyle from './BrandsPageMobile.module.css'
import { STORE_FRONT_SEARCH_BRAND_PAGE, toUrl } from 'utils/Links'
import { Link } from 'react-router-dom'
import { useMemo, useState } from 'react'
import { IoIosClose } from 'react-icons/io'
import { Brand } from 'models/Brand'
import { useQuery } from '@tanstack/react-query'
import { listBrands } from '../StoreFront/core/_requests'
import { toAbsoluteUrl } from 'utils/Functions'
import InfiniteScroll from 'react-infinite-scroller'
import { useDebounce } from 'usehooks-ts'

function BrandsPageMobile() {
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [brands, setBrands] = useState<Brand[]>([])
    const [currentPage, setCurrentPage] = useState(0)
    const [totalPages, setTotalPages] = useState(-1)
    const [isLoadingMore, setIsLoadingMore] = useState(false)

    const handleSearch = (searchTerm : string) => {
      setSearchTerm(searchTerm)
      setCurrentPage(0)
    }

    const clearInput = () => {
      setSearchTerm('')
    }

    const { data: brandData, isLoading: isBrandLoading, isFetching: isBrandFetching, isError : isBrandError } = useQuery({
      queryFn: async () => {     
        const response = await listBrands(debouncedSearchTerm, currentPage)

        setBrands((prev) => {
          if(currentPage === 0){
            return response.data.content
          }
          return [...prev, ...response.data.content]
        })
        setTotalPages(response.data.total_pages)
        setIsLoadingMore(false)
        return response
      },
      queryKey: ['listBrands', currentPage, debouncedSearchTerm],
    })

    const hasMoreContent = useMemo(() => {
      return totalPages <= 1 ? false : totalPages >= (currentPage + 1)
    }, [currentPage, totalPages])

    const onLoadMoreContent = (page: number) => {
      if (isBrandLoading || currentPage >= totalPages - 1) return
      setCurrentPage((prev) => prev + 1)
      setIsLoadingMore(true)
    }

  return (
    <>
        <PageTitle title={`MARCAS`} />

        <div className={brandStyle.completeGrid}>
            <div className={`${brandStyle.searchbar} container pb-3`}>
              <input
                type='text'
                placeholder='Pesquise aqui a marca'
                value={searchTerm}
                onChange={(event) => handleSearch(event.target.value)}
                className={brandStyle.searchInput}
              />
              <div className={brandStyle.searchIcon}>
                {searchTerm.length === 0 && 
                  <img
                    src={process.env.PUBLIC_URL + '/media/general/Search-Desktop-Icon-Faded.svg'}
                    alt='img'
                    className={brandStyle.magnifyingGlass}
                  />
                }
                {searchTerm.length > 0 && 
                  <IoIosClose id='clearBtn' onClick={clearInput} />
                }
              </div>
            </div>
            {/* {!isBrandLoading && brands && (filteredBrands.length < 1) &&
              <div className='d-flex flex-column justify-content-center align-items-center main-color'>
                <img src={toAbsoluteUrl('/media/Illustrations/Doubt-Illustration.svg')} alt=''/>
                <span>Não há nenhuma marca aqui</span>
              </div>
            } */}

            {brands && (brands.length > 0) &&
                <InfiniteScroll
                    className='w-100'
                    pageStart={0}
                    initialLoad={false}
                    loadMore={onLoadMoreContent}
                    hasMore={hasMoreContent}
                    loader={<div className="loader d-flex justify-content-center" key={0}>
                    </div>}
                >
              <div className={`${brandStyle.gridContainer} container`}>
                  {brands.map((brand, idx) => (
                      <div key={idx}  className={`${brandStyle.completeCardBrand}`}>
                        <Link to={toUrl(STORE_FRONT_SEARCH_BRAND_PAGE, brand.slug)} className='category-modal-link w-100'>
                            <div className={brandStyle.completebBrandImage}>
                              <img src={toAbsoluteUrl(brand.logo || 'https://fakeimg.pl/42x42/cccccc/033858')} className={`ps-4`} height='42px' width='60px' alt='icon' />
                              <div className={`main-color px-2 font500 w-100`}>
                                  {brand.name.length > 28
                                  ? `${brand.name.substring(0, 28)}...`
                                  : brand.name}
                              </div>
                            </div>
                            {/* SE O NOME DA CLASSE FOR MAIOR QUE 28, APOS 28 CARACTERES ENTRUNCAR A STRING */}
                        </Link>
                      </div>
                  ))}
              </div>
                </InfiniteScroll>

            }
        </div>
    </>
  )
}

export default BrandsPageMobile