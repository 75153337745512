import clsx from 'clsx';
import AddToFavorites from 'components/common/AddToFavorites/AddToFavorites';
import Image from 'components/common/Image/Image';
import ProductBadge from 'components/common/ProductBadge/ProductBadge';
import ProductCounterShort from 'components/mobile/ProductCounter/Short/ProductCounterShort';
import VerticalCardSkeleton from 'components/mobile/Skeletons/VerticalCardSkeleton';
import { useStoreCart } from 'layouts/common/core/StoreCartProvider';
import { Product } from 'models/Product';
import { EnumType } from 'modules/api/core/_models';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ITEM_TYPE_BOX, ITEM_TYPE_UNIT } from 'utils/Consts';
import { getActualProductPriceRange, getNextProductPriceRange, toBRL, toBRLShort } from 'utils/Functions';
import { STORE_FRONT_PRODUCT_DETAIL_PAGE, STORE_FRONT_SEARCH_TAG_PAGE, toUrl } from 'utils/Links';
import styles from './ProductCardVertical.module.css';

type Props = {
    product: Product;
    loading?: boolean;
};

const ProductCardVertical: FC<Props> = ({ product, loading = true }) => {
    const [itemType, setItemType] = useState<EnumType>();
    const navigate = useNavigate();
    const { addItemCart, removeItemCart, updateCartItem, deleteItemFromCart, getItemCart } = useStoreCart();
    const itemCart = useMemo(() => getItemCart(product.id), [product, getItemCart]);
    // const [cardWidth, setCardWidth] = useState<number>(0);
    const divRef = useRef<HTMLDivElement>(null);

    const nextPriceRange = useMemo(() => {
        if (itemType === ITEM_TYPE_BOX) return;
        return getNextProductPriceRange(product, itemCart?.quantity);
    }, [itemCart?.quantity, product, itemType]);

    const btnUnitEnabled = useMemo(() => {
        if (!product.supply_type) return false
        return product.supply_type.value === '3' || product.supply_type.value === '1' ? true : false
    }, [product])

    const btnBoxEnabled = useMemo(() => {
        if (!product.supply_type) return false
        return product.supply_type.value === '3' || product.supply_type.value === '2' ? true : false
    }, [product])

    const displayRightPriceValue = useMemo(() => {
        if (itemType === ITEM_TYPE_BOX) {
            return `${toBRLShort(product.package_unit_price)}/${product.measurement_unit}`;
        }

        if (nextPriceRange) {
            return toBRL(nextPriceRange.unit_price);
        }

        if (product.original_price) {
            return toBRL(product.original_price);
        }
    }, [itemType, nextPriceRange, product]);

    const displayRightPriceLabel = useMemo(() => {
        if (itemType === ITEM_TYPE_BOX) return;

        if (nextPriceRange) {
            return `A PARTIR DE ${nextPriceRange.min_quantity} ${product.measurement_unit}`;
        }
    }, [itemType, nextPriceRange, product]);

    const displayLeftPriceValue = useMemo(() => {
        if (!btnBoxEnabled && !btnUnitEnabled) return;

        if (itemType === ITEM_TYPE_BOX) {
            return toBRL(product.package_sell_price);
        }

        const priceRange = getActualProductPriceRange(product, itemCart?.quantity);

        if (priceRange)
            return toBRL(priceRange.unit_price);

        return toBRL(product.sell_price);
    }, [itemType, product, itemCart, btnBoxEnabled, btnUnitEnabled]);

    useEffect(() => {
        if (!product.supply_type) return
        setItemType(product.supply_type.value === '3' || product.supply_type.value === '1' ? ITEM_TYPE_UNIT : ITEM_TYPE_BOX);
    }, [product]);

    // useEffect(() => {
    //     const handleResize = () => {
    //       if (divRef.current) {
    //         const newWidth = divRef.current.getBoundingClientRect().width;
    //         setCardWidth(newWidth);
    //       }
    //     };

    //     window.addEventListener('resize', handleResize);

    //     handleResize();

    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    const handleProductAdd = (product: Product) => {
        const quantity = itemType === ITEM_TYPE_UNIT ? 1 : product.quantity_per_package;
        addItemCart(product, quantity);
    };

    const handleProductRemove = (product: Product) => {
        removeItemCart(product);
    };

    const handleProductChange = (product: Product, quantity: number) => {
        updateCartItem(product, quantity);
    };

    const handleProductRemoveAll = (product: Product) => {
        deleteItemFromCart(product);
    };

    const handleTypeChange = (type: EnumType) => {
        setItemType(type);
    }

    return (
        <>
            {loading && <VerticalCardSkeleton/>}
            {!loading && (
                <>
                    <div ref={divRef} className={`g-0 ${styles.itemCardMobile}`} key={product.id} >
                        <div className={`${styles.cardMobile} position-relative category border-0 col-12`}>

                            <div className={`d-flex position-absolute w-100 ${product.tags ? 'justify-content-between' : 'justify-content-end'}`}>
                                <div className=''>
                                    {product.tags && product.tags?.slice(0, 2).map((tag, idx) => (
                                        <ProductBadge key={idx} label={tag.name} handleClick={() => navigate(toUrl(STORE_FRONT_SEARCH_TAG_PAGE, tag.slug))}/>
                                    ))}
                                </div>
                                <div className={styles.whishListIcon}>
                                    <AddToFavorites product={product} quantity={itemCart?.quantity} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                <Image
                                    src={product.thumbnail?.url}
                                    alt={product.thumbnail?.title}
                                    className={styles.productCardImage}
                                    height={80}
                                    width={80}
                                    placeholderColor="#ffffff"
                                    fallbackSrc="https://fakeimg.pl/120x120/cccccc/003858"
                                    onClick={() => navigate(toUrl(STORE_FRONT_PRODUCT_DETAIL_PAGE, product.id))}
                                />
                            </div>

                            <div className={`g-0 ${styles.itemCardAddition}`}>
                                <ProductCounterShort
                                    loading={loading}
                                    product={product}
                                    onAdd={handleProductAdd}
                                    onRemove={handleProductRemove}
                                    onChange={handleProductChange}
                                    onRemoveAll={handleProductRemoveAll}
                                />
                            </div>

                            <div className={styles.cardProductTitle}>
                                <span
                                    className={styles.cardProductName}
                                    onClick={() => navigate(toUrl(STORE_FRONT_PRODUCT_DETAIL_PAGE, product.id))}
                                >
                                    {product.description}
                                </span>
                            </div>

                            <div className={styles.prices}>
                                {displayLeftPriceValue && (
                                    <span className={styles.mainPrice}> {displayLeftPriceValue} </span>
                                )}
                                {displayRightPriceLabel && displayRightPriceValue && (
                                    <div className={styles.unitComparative}>
                                        <span className="font07rem black-color">{displayRightPriceLabel}</span>
                                        <span className="text-end black-color">{displayRightPriceValue}</span>
                                    </div>
                                )}
                                {displayRightPriceValue && !displayRightPriceLabel && (
                                    <span className="text-end black-color">{displayRightPriceValue}</span>
                                )}
                            </div>
                            <div className={styles.productBody}>
                                <div className={`${styles.btns} d-flex flex-direction-row align-item-center g-0`}>
                                    <div
                                        className={clsx(
                                            styles.btnUnitM,
                                            "d-flex justify-content-center align-items-center col-6",
                                            itemType === ITEM_TYPE_UNIT && styles.btnUnitMActive
                                        )}
                                    >
                                        <button className="btn btn-sm d-flex justify-content-center align-item-start border-0"
                                            disabled={!btnUnitEnabled}
                                            onClick={() => handleTypeChange(ITEM_TYPE_UNIT)}>
                                            <span className={clsx(btnUnitEnabled && styles.unavailable, "text-uppercase ")}>
                                                {btnUnitEnabled ? product.measurement_unit : "INDISPONÍVEL"}
                                            </span>
                                        </button>
                                    </div>

                                    <div
                                        className={clsx(
                                            styles.btnBoxM,
                                            "col-6 d-flex justify-content-center ",
                                            itemType === ITEM_TYPE_BOX && styles.btnBoxMActive
                                        )}
                                    >
                                        <button className="btn btn-sm border-0 px-0"
                                            disabled={!btnBoxEnabled}
                                            onClick={() => handleTypeChange(ITEM_TYPE_BOX)}>
                                            <span className={clsx(btnUnitEnabled === false && styles.unavailable, "text-uppercase transform1px")}>
                                                {btnBoxEnabled ? `${product.package_measurement_unit}/${product.quantity_per_package} ${product.measurement_unit}` : "INDISPONÍVEL"}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>

    );
};

export default ProductCardVertical;
