import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { fuzzyFilter } from '../NewDatatable/core/_utils';
import tableStyle from './SimpleTable.module.css'

interface Props {
    data: any[]
    columns: any[]
    customStyle?: string[]
  }
  
  const SimpleTable: React.FC<Props> = ({ data, columns, customStyle }) => {
    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        getCoreRowModel: getCoreRowModel(),
      });
    
      return (
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={tableStyle.headers}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={`${customStyle}`}>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className={`${tableStyle.bodyContent} col`}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={`${tableStyle.cellContent} col-3`}>
                    <div>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}

                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
  };

export default SimpleTable