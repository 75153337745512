import { regionalClient } from "index"
import { Wishlist } from "models/Wishlist"
import { WishlistEndpoints } from "modules/api/endpoints/WishlistEndpoints"

export const list = () => {
    return regionalClient.get(WishlistEndpoints.list)
}
export const update = (id: string, wishlist : Wishlist) => {
    return regionalClient.put(WishlistEndpoints.update(id), wishlist)
}
export const create = (wishlist : Wishlist) => {
    return regionalClient.post(WishlistEndpoints.create, wishlist)
}
export const remove = (id : string) => {
    return regionalClient.delete(WishlistEndpoints.remove(id))
}
