import { StoreCartInit, StoreCartProvider } from "layouts/common/core/StoreCartProvider";
import { StoreLocationInit } from "layouts/common/core/StoreLocationProvider";
import { StoreModalProvider } from "layouts/common/core/StoreModalProvider";
import StoreLayoutDesktop from "layouts/desktop/StoreLayout/StoreLayout";
import StoreLayoutMobile from "layouts/mobile/StoreLayout/StoreLayout";
import { FC, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { isMobileScreen } from "utils/Functions";

const StoreApp: FC = () => {
  const StoreLayout = useMemo(() => (isMobileScreen() ? StoreLayoutMobile : StoreLayoutDesktop), []);

  return (
    <StoreCartProvider>
      <StoreModalProvider>
        <StoreLayout>
          <StoreCartInit />
          <StoreLocationInit />
          <Outlet />
        </StoreLayout>
      </StoreModalProvider>
    </StoreCartProvider>
  );
};

export default StoreApp;
