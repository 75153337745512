import React from "react";
import { Navigate, Route, Routes } from "react-router";
import AccountSuggestWishlistDetailPage from "./AccountSuggestWishlistDetailPage";
import AccountSuggestWishlistPage from "./AccountSuggestWishlistPage";

const AccountSuggestWishlistWrapper: React.FC = () => {
  return (
    <Routes>
      <Route path="lista" element={<AccountSuggestWishlistPage />} />
      <Route path=":id" element={<AccountSuggestWishlistDetailPage />} />
      <Route path="*" element={<Navigate to="lista" />} />
    </Routes>
  );
};

export default AccountSuggestWishlistWrapper;
