import Image from 'components/common/Image/Image';
import { useModalContext } from 'layouts/common/core/StoreModalProvider';
import { Product } from 'models/Product';
import { WishlistItem } from 'models/Wishlist';
import { FC, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoTrashOutline } from 'react-icons/io5';
import { calculateItemWishlistTotalValue, getActualProductUnitPrice, toAbsoluteUrl, toBRL } from 'utils/Functions';
import ProductWishlistCounterDesktop from '../ProductWishlistCounterDesktop/ProductWishlistCounterDesktop';
import styles from './WishlistProductComponent.module.css';

type Props = {
    item: WishlistItem;
    onAddToCart: (item: WishlistItem) => void;
    onChange: (item: WishlistItem) => void;
    onRemove: (item: WishlistItem) => void;
    onDismiss: () => void
    editable?: boolean
};

const WishlistProductComponent: FC<Props> = ({ item, onChange, onAddToCart, onRemove, editable = false }) => {
    const { product, quantity } = item
    const {showProductModal} = useModalContext()
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)

    const handleAddProductToCart = () => {
        onAddToCart(item)
    }

    const handleRemoveProductFromWishlist = () => {
        onRemove(item)
        setIsModalDeleteOpen(false)
    }

    const handleDecreaseProductQuantity = (product: Product) => {
        if (quantity - 1 === 0) {
            onRemove(item)
            return
        }
        onChange({
            ...item,
            quantity: quantity - 1
        })
    }

    const handleIncreaseProductQuantity = (product: Product) => {
        onChange({
            ...item,
            quantity: quantity + 1
        })
    }

    const handleProductChange = (product: Product, quantity: number) => {
        onChange({...item, quantity})
    }

    const hasOnePackageOrMore = useMemo(() => {
        if (!product.quantity_per_package) return false
        return Math.floor(quantity / product.quantity_per_package) > 0
    }, [quantity, product])

  return (
    <>
        <div className={styles.productContainer}>
            <div style={{width: '8%'}}>
                <Image
                  src={item.product.thumbnail?.url}
                  alt={item.product.thumbnail?.title}
                  fallbackSrc='https://fakeimg.pl/70x80/cccccc/033858'
                  onClick={() => showProductModal(item.product.id)}
                  className={styles.imageStyle}
                />
            </div>
            <div onClick={() => showProductModal(item.product.id)} style={{width: '40%'}}>
              {item.product.description}
              <div className={styles.productId}>
                <span>{item.product.sku}</span>
                <div>{item.product.brand?.name.toLocaleUpperCase()}</div>
              </div>
            </div>
            <div style={{width: '14%'}}>{toBRL(getActualProductUnitPrice(item.product, item.quantity))}/{item.product.measurement_unit}</div>
            <div style={{width: '16%'}}>
                <ProductWishlistCounterDesktop
                    product={item.product}
                    quantity={item.quantity}
                    onAdd={handleIncreaseProductQuantity}
                    onRemove={handleDecreaseProductQuantity}
                    onChange={handleProductChange}
                    editable={editable}
                />
            </div>
            <div style={{textAlign: 'center', width: '12%'}}>
              {toBRL(calculateItemWishlistTotalValue(item))}
            </div>
            <div onClick={handleAddProductToCart} style={{width: '4%'}} className='d-flex justify-content-center'>
                <img src={toAbsoluteUrl('/media/general/Cart-Addition.svg')} alt='' />
            </div>
            {editable && <div className={styles.trashCan} onClick={() => setIsModalDeleteOpen(!isModalDeleteOpen)} style={{width: '4%'}}>
              <IoTrashOutline style={{fontSize: '1.5rem'}}/>
            </div>}
          </div>
          <Modal show={isModalDeleteOpen} size='sm' className={styles.modalContainer} onHide={() => setIsModalDeleteOpen(false)} centered>
            <Modal.Body className={`p-4`}>
                <div className='d-flex justify-content-end main-color font12rem mb-3'>
                  <img
                  src={toAbsoluteUrl('/media/general/X-icon.svg')} alt=''
                  onClick={() => setIsModalDeleteOpen(false)}
                  height={'14px'}
                  className='cursor-pointer' />
                </div>
                <div className='d-flex flex-column align-items-center mb-4'>
                  <img src={'/media/general/X-Sign.svg'} alt='' style={{width: '56px'}} className='mb-4'/>
                  <span className='text-center font500'>Deseja deletar o item: <strong>{item.product.description}</strong> da lista?</span>
                </div>
                <div className='d-flex justify-content-evenly w-100 mt-3'>
                  <div onClick={() => setIsModalDeleteOpen(!isModalDeleteOpen)}
                  className={styles.cancelButton}>
                    NÃO
                  </div>
                  <div onClick={handleRemoveProductFromWishlist}
                  className={styles.deleteButton}>
                    SIM
                  </div>
                </div>
            </Modal.Body>
          </Modal>
    </>
  )
}

export default WishlistProductComponent
