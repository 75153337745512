import { centralClient } from "index"
import { FaqGroup } from "models/FaqGroup"
import { PageableResponse } from "modules/api/core/_models"
import { FaqGroupEndpoints } from "modules/api/endpoints/FaqGropupEndpoints"

export const list = (term : string) => {
    return centralClient.get<PageableResponse<FaqGroup>>(FaqGroupEndpoints.list, {
        params: {
            search: term
        }
    })
}
