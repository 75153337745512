import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import WishSuggestionLayout from 'layouts/desktop/WishlistModalSuggestions/WishlistModalSuggestions';
import { Product } from 'models/Product';
import { Wishlist } from 'models/Wishlist';
import { ApiResponseError } from 'modules/api/core/_models';
import { CSSProperties, useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from "utils/Functions";
import listStyle from './WishlistModalDesktop.module.css';
import { create, list, update } from './core/_requests';

type Props = {
    onDismiss: () => void
    open: boolean
    product: Product
    quantity: number
    onChange: (isWishlisted: boolean) => void
}

const override: CSSProperties = {
    borderWidth: '4px',
}

enum DisplayMode {
    LIST,
    NEW_LIST
}

function WishlistModalDesktop({ open, onDismiss, product, quantity, onChange }: Props) {
    const queryClient = useQueryClient()
    const [products, setProducts] = useState<Product[]>([]);
    const [checkedCheckboxes, setCheckedCheckboxes] = useState<boolean[]>([]);
    const [wishlists, setWishlists] = useState([] as Wishlist[]);
    const [wishlistName, setWishlistName] = useState('' as string);
    const [displayMode, setDisplayMode] = useState(DisplayMode.LIST);

    useEffect(() => {
        const newChecked = [...checkedCheckboxes];
        wishlists?.forEach((wishlist, idx) => {
            const isProductPresent = wishlist.items?.some((item) => item.product.id === product.id);
            if (isProductPresent) {
                newChecked[idx] = true;
            }
        });
        setCheckedCheckboxes(newChecked);
    }, [wishlists]);

    const updateCheckedCheckboxes = (whList: Wishlist[]) => {
        const updatedCheckedboxes = whList?.map((wishlist) => {
            return wishlist.items?.some((item) => item.product.id === product.id);
        });
        setCheckedCheckboxes(updatedCheckedboxes);
    }

    const { isLoading: isWishlistLoading } = useQuery({
        queryFn: async () => {
            const response = await list()
            setWishlists(response.data)
            updateCheckedCheckboxes(response.data)
            return response
        },
        queryKey: ['wishlists']
    })

    const { mutate: sendUpdateRequest } = useMutation({
        mutationFn: async ({ id, ...wishlist }: Wishlist) => update(id!!, wishlist),
        mutationKey: ['updateWishlist'],
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['wishlists'] })
        }
    })

    const { mutate: sendCreateRequest } = useMutation({
        mutationFn: async (wishlist: Wishlist) => create(wishlist),
        mutationKey: ['createWishlist'],
        onSuccess: ({ data }) => {
            queryClient.invalidateQueries({ queryKey: ['wishlists'] });
            toast.success("Produto salvo na lista", {
                position: 'top-right',
                autoClose: 2000,
                theme: 'light',
            });
            setWishlistName('');
            onChange(true);
            onDismiss();
        },
        onError: (error) => {
            //mostrar mensagem de erro
            if (error instanceof ApiResponseError) {
                toast.error(error.getErrorMessage())
            } else {
                toast.error('Erro ao salvar produto na lista')
            }
        }
    })

    const handleCheckboxChange = (idx: number) => {
        const newChecked = [...checkedCheckboxes];
        const checkboxStatus = !newChecked[idx];
        newChecked[idx] = checkboxStatus;
        setCheckedCheckboxes(newChecked);

        //notify parent
        if (newChecked.filter((checked) => checked).length === 0) {
            onChange(false);
        } else {
            onChange(true);
        }

        const updatedWhishlist = wishlists[idx];
        if (checkboxStatus) {
            //create new item
            if (!updatedWhishlist.items) {
                updatedWhishlist.items = [{
                    quantity: quantity,
                    product: product,
                    active: true
                }]
            } else {
                //update existing item
                updatedWhishlist.items.push({
                    quantity: quantity,
                    product: product,
                    active: true
                })
            }
        } else {
            //remove item
            updatedWhishlist.items = updatedWhishlist.items?.filter(item => item.product.id !== product.id)
        }
        //update wishlist
        sendUpdateRequest(updatedWhishlist)
    };

    const handleNewWishlistInputChange = (event: any) => {
        setWishlistName(event.target.value);
    };

    const handleNewWishlistSubmit = () => {
        const newWishlist = {
            name: wishlistName,
            active: true,
            items: [{
                quantity: quantity,
                product: product,
                active: true
            }]
        };
        sendCreateRequest(newWishlist)
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter' && e.currentTarget.value !== '') {
            handleNewWishlistSubmit()
        }
    };

    // console.log(displayMode)

    return (
        <Modal size={`lg`} show={open} onHide={() => onDismiss()} centered>
            <Modal.Body className={listStyle.modalContainer}>
                {isWishlistLoading &&
                    <div className={listStyle.loadingContent}>
                        <ClipLoader
                            color="#033858"
                            size={42}
                            cssOverride={override}
                        />
                        <span className='font500 pt-3'>Carregando...</span>
                    </div>
                }

                {!isWishlistLoading && displayMode === DisplayMode.NEW_LIST &&
                    <div className='d-flex flex-column align-items-center w-100 p-3'>
                        <div className={listStyle.title}>
                            <span> Criar lista </span>
                            <img src={toAbsoluteUrl('/media/general/X-icon.svg')} alt='' onClick={onDismiss} height={'16px'} />
                        </div>
                        <div className={listStyle.newList}>
                            <label className='font500 mb-1'>Nome da nova lista:</label>
                            <input type="text" onChange={handleNewWishlistInputChange} value={wishlistName} onKeyDown={handleKeyDown} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type='button' onClick={handleNewWishlistSubmit} className={listStyle.buttonNewList}>
                                Criar
                            </button>
                        </div>
                    </div>
                }

                {!isWishlistLoading && displayMode === DisplayMode.LIST && (
                    <>
                        <div className='w-100 p-3'>
                            <div className={listStyle.title}>
                                <span> Salvar item na lista </span>
                                <img src={toAbsoluteUrl('/media/general/X-icon.svg')} alt='' onClick={onDismiss} className='ms-5' />
                            </div>

                            <div className='d-flex'>
                                <div className={`${listStyle.wishlistList} col-7`}>
                                    {wishlists.length === 0 &&
                                        <div className='d-flex flex-column mt-3'>
                                            <img src={toAbsoluteUrl('/media/general/List-Icon.svg')} alt='' style={{height:'80px'}}/>
                                            <span className='mt-3 text-center'>
                                                Você ainda não possui nenhuma lista, clique no botão <br></br>
                                                <span className='main-color font600'> Nova lista +</span> para criar uma.
                                            </span>
                                        </div>
                                    }
                                    {wishlists.map((list, idx) => (
                                        <label key={idx} className={listStyle.check}>
                                            <span className={checkedCheckboxes[idx] ? listStyle.checkedListName : listStyle.uncheckedListName}>
                                                {list.name}
                                            </span>
                                            <input
                                                type="checkbox"
                                                id={`checkbox-${idx}`}
                                                name={`checkbox-${idx}`}
                                                checked={checkedCheckboxes[idx] || false}
                                                onChange={() => handleCheckboxChange(idx)}
                                            />
                                        </label>
                                    ))}
                                </div>

                                <div className='d-flex flex-column col-5 ps-4'>
                                    <span className=''>
                                        Selecione uma das suas listas de comprar para adicionar o produto com a quantidade selecionada. Caso queira criar uma nova lista, basta clicar no botão abaixo!
                                    </span>
                                    <button
                                        type='button'
                                        onClick={() => setDisplayMode(DisplayMode.NEW_LIST)}
                                        className={listStyle.buttonNewList}
                                    >
                                        Nova lista +
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <WishSuggestionLayout sale={'Veja também estes produtos sugeridos'} products={products} />
                        </div>
                    </>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default WishlistModalDesktop
