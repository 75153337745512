import {FC} from 'react'
import {Link} from 'react-router-dom'

type Props = {
  links?: any[]
}

const NoDataComponent: FC<Props> = ({links}) => {
  return (
    <div className='card card-flush w-md-650px py-5'>
      <div className='card-body py-15 py-lg-20 text-center'>
        {/* <!--begin::Logo--> */}
        <div className='mb-7'>
          <i className='bi bi-file-earmark-plus-fill fs-5x'></i>
        </div>
        {/* <!--end::Logo--> */}
        {/* <!--begin::Title--> */}
        <h1 className='fw-bolder text-gray-900 mb-5'>Nenhum item registrado</h1>
        {/* <!--end::Title--> */}
        {/* <!--begin::Text--> */}
        <div className='fw-semibold fs-6 text-gray-500 mb-7'>Ainda não existem cadastros no seu sistema, comece agora mesmo</div>
        {/* <!--end::Text--> */}
        {/* <!--begin::Link--> */}
        {links && (
          <div className='mb-0'>
            {links.map((link, index) => (
              <Link to={link.to} key={index}>
                <button className='btn btn-primary'>{link.label}</button>
              </Link>
            ))}
          </div>
        )}
        {/* <!--end::Link--> */}
      </div>
    </div>
  )
}

export default NoDataComponent
