import AuthenticationSteps from 'components/desktop/AuthenticationSteps/AuthenticationSteps'
import React, { useMemo } from 'react'
import {useNavigate} from 'react-router-dom'
import {ACCOUNT_2_FACTOR, ACCOUNT_ORDERS_PAGE, toUrl} from 'utils/Links'
import downloadStyle from './AOAuthentication.module.css'
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'

function AODownload() {
  const navigate = useNavigate()

  const breadcrumbs = useMemo(() => {
    return(
      [          
        {
          title : 'Perfil',
          path: toUrl(ACCOUNT_ORDERS_PAGE),
        },
        {    
          title : 'Autenticação',
          path : toUrl(ACCOUNT_2_FACTOR, 2),
        }
      ]
    )
  }, [])

  return (
    <>
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className='font600 font15rem'>AUTENTICAÇÃO DE DOIS FATORES</span>
      </div>

      <AuthenticationSteps step={2} />

      <div className='py-3'>
        <h4 className='mb-4'>Download do aplicativo Google Authenticator</h4>
        <p className={downloadStyle.authenticatorParagraph}>
          Veja como fazer o download do aplicativo Google Authenticator:
        </p>
        <div className='d-flex justify-content-start my-4'>
          <img
            src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Google_Authenticator_for_Android_icon.svg/296px-Google_Authenticator_for_Android_icon.svg.png?20201101110526'
            alt='google.logo'
            style={{width: '120px'}}
          />
        </div>
        <ol type='1'>
          <li>
            Vá até a loja de aplicativos do seu celular e na caixa de pesquisa, digite{' '}
            <strong>“Google Authenticator”</strong>;
          </li>
          <li>
            Entre os aplicativos que irão aparecer na busca, procure pelo que corresponde ao ícone
            acima e selecione;
          </li>
          <li>
            Em seguida, na página principal do aplicativo, pressione a opção de
            baixar/download/obter;
          </li>
          <li>
            Aguarde até que a instalação seja completada e, por fim, abra o aplicativo para seguir
            com a ativação da autenticação de dois fatores.
          </li>
        </ol>
        <button
          className={`${downloadStyle.authenticationButton}`}
          onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 3))}
        >
          {' '}
          Continuar autenticação de dois fatores{' '}
        </button>
      </div>
    </>
  )
}

export default AODownload
