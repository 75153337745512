import { centralClient } from 'index'
import { AccountEndpoints } from 'modules/api/endpoints/AccountEndpoints'
import { AuthEndpoints } from 'modules/api/endpoints/AuthEndpoints'
import { AuthModel, LoginRequestModel } from './_models'

export function doLogin(payload: LoginRequestModel) {
    return centralClient.post<AuthModel>(AuthEndpoints.login, payload)
}

export function requestPassword(email: string) {
    return centralClient.post(AuthEndpoints.forgotPassword, {
        email,
    })
}

// export function getAccountPreferences() {
//   return apiClient.get(GET_ACCOUNT_PREFERENCES_ENDPOINT)
// }

export function getAccountDetails() {
    return centralClient.get(AccountEndpoints.details)
}

export function refreshToken(refreshToken: string) {
    return centralClient.post(AuthEndpoints.refreshToken, { refresh_token: refreshToken })
}

export function doLogout() {
    return centralClient.post(AuthEndpoints.logout)
}
