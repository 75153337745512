export interface IStorageItem {
    key: string;
    value: any;
}

export class StorageItem {
    key: string;
    value: any;

    constructor(data: IStorageItem) {
        this.key = data.key;
        this.value = data.value;
    }
}

// add value to storage
export function addLsItem(key: string, item: string) {

    if (!localStorage) {
        return
    }
    localStorage.setItem(key, item);
}

// get all values from storage (all items)
export function getAllItems(): Array<StorageItem> {
    const list = new Array<StorageItem>();

    if (localStorage) {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i) as string;
            const value = localStorage.getItem(key);

            list.push(new StorageItem({
                key: key,
                value: value
            }));
        }
    }

    return list;
}

// get only all values from localStorage
export function getAllValues(): Array<any> {
    const list = new Array<any>();
    if (localStorage) {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i) as string;
            const value = localStorage.getItem(key);

            list.push(value);
        }
    }

    return list;
}

// get one item by key from storage
export function getLsItem<T = string>(key: string): T | null {
    if (localStorage){
        const value = localStorage.getItem(key);
        if (value) {
            return JSON.parse(value) as T;
        }
    }
    return null;
}

// remove value from storage
export function remove(key: string) {
    if (localStorage){
        localStorage.removeItem(key);
    }
}

// clear storage (remove all items from it)
export function clear() {
    if (localStorage){
        localStorage.clear();
    }
}
