import { Category } from 'models/Category';
import { Product } from 'models/Product';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toAbsoluteUrl } from 'utils/Functions';
import liveSearchStyles from './LiveSearch.module.css';

interface Props {
  suggestions?: string[];
  products?: Product[];
  categories?: Category[];
  renderProduct?(item: Product): JSX.Element;
  renderCategory?(item: Category): JSX.Element;
  renderSuggestion?(item: string): JSX.Element;
  onChange?: React.ChangeEventHandler;
  onEnter?: (term: string) => void;
  onSelect?: (item: any) => void;
  value?: string;
  loading: boolean;
  placeholder: string;
}

const MAX_CATEGORIES_VISIBLE = 5;

const LiveSearch = ({
  suggestions = [],
  products = [],
  categories = [],
  renderProduct = (product: Product) => <p>{product.description}</p>,
  renderCategory = (category: Category) => <p>{category.name}</p>,
  renderSuggestion = (suggestion: string) => <p>{suggestion}</p>,
  value,
  onChange,
  onSelect,
  onEnter,
  loading = true,
  placeholder = '',
}: Props): JSX.Element => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const resultContainer = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [showsuggestions, setShowsuggestions] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');

  // const { data: categoryData, isLoading: isCategoryLoading } = useQuery({
  //   queryFn: () => listTopCategories(),
  //   queryKey: ['topCategories']
  // })

  const visibleItems = useMemo(() => {

    if (suggestions.length > 0 || products.length > 0){
      return suggestions.length + products.length;
    }
    return categories.length > 0 ? MAX_CATEGORIES_VISIBLE : 0
  }, [defaultValue, categories.length, suggestions.length, products.length]);

  const handleSelection = (selectedIndex: number) => {
    const suggestionsAndProducts = [...suggestions, ...products];
    const selectedItem =
      defaultValue.trim().length === 0
        ? categories[selectedIndex]
        : suggestionsAndProducts[selectedIndex];
    if (!selectedItem) {
      defaultValue.length > 0 && onEnter && onEnter(defaultValue);
      return resetSearchComplete();
    }
    resetSearchComplete();
    onSelect && onSelect(selectedItem);
  };

  const handleFocus = () => {
    // if(defaultValue.length > 3) {
    //   setShowsuggestions(true);
    //   return
    // }
    setShowsuggestions(true);
  };

  const resetSearchComplete = useCallback(() => {
    setFocusedIndex(-1);
    setShowsuggestions(false);
  }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const { key } = e;
    let nextIndexCount = 0;

    // move down
    if (key === 'ArrowDown') {
      nextIndexCount = (focusedIndex + 1) % visibleItems;
      setFocusedIndex(nextIndexCount);
    }

    // move up
    if (key === 'ArrowUp') {
      nextIndexCount = (focusedIndex + visibleItems - 1) % visibleItems;
      setFocusedIndex(nextIndexCount);
    }

    // hide search suggestions
    if (key === 'Escape') {
      resetSearchComplete();
    }

    // select the current item
    if (key === 'Enter') {
      resetSearchComplete();
      e.preventDefault()
      handleSelection(focusedIndex)
    }
  };

  type changeHandler = React.ChangeEventHandler<HTMLInputElement>;
  const handleChange: changeHandler = (e) => {
    setDefaultValue(e.target.value);
    onChange && onChange(e);
  };

  useEffect(() => {
    if (document.activeElement === searchInputRef.current) {
      if (!defaultValue && categories.length > 0) setShowsuggestions(true);
      if (defaultValue.length && (suggestions.length > 0 || products.length > 0)) setShowsuggestions(true);
      return;
    }
    setShowsuggestions(false);
  }, [suggestions, products, categories]);

  useEffect(() => {
    setDefaultValue(value || '');
  }, [value]);

  return (
    <div className="h-100 flex items-center justify-center">
      <div
        tabIndex={1}
        onBlur={resetSearchComplete}
        onKeyDown={handleKeyDown}
        className="position-relative"
        >

        <img
          src={toAbsoluteUrl(
            showsuggestions
              ? '/media/general/Search-Desktop-Icon.svg'
              : '/media/general/Search-Desktop-Icon-Faded.svg'
          )}
          alt="img"
          className={liveSearchStyles.searchIcon}
        />

        <input
          ref={searchInputRef}
          value={defaultValue}
          onChange={handleChange}
          onFocus={handleFocus}
          type="text"
          className={liveSearchStyles.searchInput}
          placeholder={placeholder}
        />

        {/* Search suggestions/products Container */}
          {showsuggestions && (products.length > 0 || suggestions.length > 0) && (
            <div className={`${liveSearchStyles.searchList} position-absolute w-100 bg-white`}>
              <div>
                {suggestions.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onMouseDown={() => handleSelection(index)}
                      onMouseEnter={() => setFocusedIndex(index)}
                      ref={index === focusedIndex ? resultContainer : null}
                      style={{
                        backgroundColor: index === focusedIndex ? 'rgba(0,0,0,0.1)' : '',
                      }}
                      className="cursor-pointer py-2 px-4 d-flex align-items-center font500">
                      <img src={toAbsoluteUrl('/media/general/Search-Desktop-Icon-Faded.svg')} alt="img" className={liveSearchStyles.smallSearch}/>
                      <span className={liveSearchStyles.sugestedName}>{renderSuggestion(item)}</span>
                    </div>
                  );
                })}
              </div>
              <div>
                {products.length > 0 && <div className={liveSearchStyles.suggestionsDivision}> Relacionados a sua pesquisa </div>}
                {products.map((item, index) => {
                  const aggregatedIndex = index + suggestions.length;
                  return (
                    <div
                      key={aggregatedIndex}
                      onMouseDown={() => handleSelection(aggregatedIndex)}
                      onMouseEnter={() => setFocusedIndex(aggregatedIndex)}
                      ref={aggregatedIndex === focusedIndex ? resultContainer : null}
                      style={{backgroundColor: aggregatedIndex === focusedIndex ? 'rgba(0,0,0,0.1)' : '',}}
                      className="cursor-pointer p-2 px-4">
                      <span>{renderProduct(item)}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

        {/* Search categories Container */}
          {showsuggestions && (products.length === 0 && suggestions.length === 0) && (categories && categories.length > 0) && (
            <div className={`${liveSearchStyles.searchList} position-absolute w-100 bg-white `}>
                <div>
                  {/* {categories.length > 0 && <div className={liveSearchStyles.suggestionsDivision}> Categorias mais buscadas </div>} */}
                  {categories.slice(0,MAX_CATEGORIES_VISIBLE).map((item, index) => {
                      return (
                        <div
                          key={index}
                          onMouseDown={() => handleSelection(index)}
                          onMouseEnter={() => setFocusedIndex(index)}
                          ref={index === focusedIndex ? resultContainer : null}
                          style={{ backgroundColor: index === focusedIndex ? 'rgba(0,0,0,0.1)' : ''}}
                          className="cursor-pointer p-2 px-4 d-flex align-items-center main-color font500">
                          <span className={liveSearchStyles.numberedSearch}>{index + 1}</span>
                          {renderCategory(item)}
                        </div>
                      );
                  })}
                </div>
            </div>
          )}

        {/* NO RESULT FOUND */}
          {showsuggestions && (defaultValue.length > 3 && products.length < 1 && categories.length < 1) &&
            <div className={`${liveSearchStyles.searchList} position-absolute w-100 bg-white`}>
              <div className='d-flex flex-column align-items-center pb-2'>
                <img src={toAbsoluteUrl('/media/Illustrations/Doubt-Illustration-Ballon.svg')} alt=''/>
                <span className='pt-2 font500 main-color'>Não há nenhum item no nosso servidor </span>
              </div>
            </div>
          }
      </div>
    </div>
  );
};

export default LiveSearch;
