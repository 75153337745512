import { StoreCart } from "./StoreCart";

export enum CheckoutFailureReason {
    MISSION_CHANGED = "MISSION_CHANGED",
    STORE_CART_CHANGED = "STORE_CART_CHANGED",
    FORMS_OF_PAYMENT_CHANGED = "FORMS_OF_PAYMENT_CHANGED",
}

export type CheckoutResult = {
    success: boolean,
    sales_order_id: string,
    failure_reason: CheckoutFailureReason,
    store_cart: StoreCart
}
