import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import PageTitle from 'components/common/PageTitle/PageTitle'
import StoreCartBar from 'components/mobile/StoreCartBar/StoreCartBar'
import { useStoreCart } from 'layouts/common/core/StoreCartProvider'
import ProductCardWishlist from 'layouts/mobile/ProductCard/ProductCardWishlist'
import { ProductWithQuantity } from 'models/StoreCart'
import { Wishlist, WishlistItem, WishlistItemGroup } from 'models/Wishlist'
import { ApiResponseError } from 'modules/api/core/_models'
import { useEffect, useMemo, useState } from 'react'
import { MdKeyboardArrowUp } from 'react-icons/md'
import Sheet from 'react-modal-sheet'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useIsFirstRender } from 'usehooks-ts'
import { getActualProductUnitPrice, getRootCategory, toAbsoluteUrl, toBRL } from 'utils/Functions'
import { ACCOUNT_WHISHLIST_PAGE, STORE_FRONT_PAGE, toUrl } from 'utils/Links'
import wishlistDetailStyles from './WishlistDetailPage.module.css'
import { detail, remove, update } from './core/_requests'

function WishlistDetailPage() {
    const { id } = useParams()
    const navigate = useNavigate()
    const { addItemCart, addItemsCart, cart: { items } } = useStoreCart()
    const queryClient = useQueryClient()
    const [wishlist, setWishlist] = useState<Wishlist>()
    const [isModalRemoveWishlistOpen, setIsModalRemoveWishlistOpen] = useState(false)
    const [isModalAddWishlistToCartOpen, setIsModalAddWishlistToCartOpen] = useState(false)
    const [itemsGrouped, setItemsGrouped] = useState<WishlistItemGroup[]>()
    const initialRender = useIsFirstRender()

    const { isLoading: isWishlistLoading, isFetching: isWishlistFetching, isError: isWishlistError } = useQuery({
        queryFn: async () => {
            const response = await detail(id!!)
            setWishlist(response.data)
            return response
        },
        queryKey: ['wishlist', id],
        enabled: !!id,
    })

    const { mutate: sendRemoveWishlistRequest } = useMutation({
        mutationFn: async () => {
            const response = remove(id!!)
            return response;
        },
        mutationKey: ['removeWishlist', id],
        onSuccess: () => {
            toast.success('Lista removida', {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            })
            navigate(toUrl(ACCOUNT_WHISHLIST_PAGE))
        },
        onError: (error) => {
            let errorMessage = "Erro ao excluir lista"
            if (error instanceof ApiResponseError) {
                errorMessage = error.getErrorMessage() || "Erro"
            }
            toast.error(errorMessage, {
                position: 'top-right',
                autoClose: 2000,
                theme: 'light',
            });
        }
    })

    const { mutate: sendUpdateWishlist } = useMutation({
        mutationFn: async (wishlist: Wishlist) => {
            const response = update(id!!, wishlist)
            return response;
        },
        mutationKey: ['updateWishlist', id],
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['wishlist', id] })
        },
        onError: (error) => {
            let errorMessage = "Erro ao atualizar lista de compras"
            if (error instanceof ApiResponseError) {
                errorMessage = error.getErrorMessage() || "Erro"
            }
            toast.error(errorMessage, {
                position: 'top-right',
                autoClose: 2000,
                theme: 'light',
            });
        }
    })

    const toggleItemsGroupVisibility = (position: number) => {
        if (!itemsGrouped) return
        const updatedItemsGrouped = itemsGrouped.map((itemsGroup: any, itemGroupIdx: number) => {
            if (itemGroupIdx === position) {
                return { ...itemsGroup, open: !itemsGroup.open }
            }
            return itemsGroup
        })
        setItemsGrouped(updatedItemsGrouped);
    }
    //mount items grouped
    useEffect(() => {

        if (!wishlist || !wishlist.items) return

        const result: WishlistItemGroup[] = []

        wishlist.items.forEach((item) => {
            const rootCategory = getRootCategory(item.product.category)
            const itemGroupIdx = result.findIndex((itemGroup) => {
                return itemGroup.category === rootCategory
            })
            const bestUnitPrice = getActualProductUnitPrice(item.product, item.quantity)

            if (itemGroupIdx === -1) {
                result.push({
                    category: rootCategory,
                    items: [item],
                    total: item.quantity * bestUnitPrice,
                    total_quantity: item.quantity,
                    open: true,
                })
            } else {
                result[itemGroupIdx].items.push(item)
                result[itemGroupIdx].total = result[itemGroupIdx].items.reduce((total, item) => {
                    const bestUnitPrice = getActualProductUnitPrice(item.product, item.quantity)
                    return total + (item.quantity * bestUnitPrice)
                }, 0)
                result[itemGroupIdx].total_quantity = result[itemGroupIdx].items.reduce((total, obj) => {
                    return total + obj.quantity
                }, 0)
            }
        })

        const updatedItemsGroup = result.sort((a, b) => {
            //ordena por ordem alfabética
            return a.category.localeCompare(b.category)
        })
        setItemsGrouped(updatedItemsGroup);
    }, [wishlist])

    const handleAddWishlistItemsToCartClick = () => {
        setIsModalAddWishlistToCartOpen(false)
        if (!wishlist || !wishlist.items) return

        const itemsToAdd : ProductWithQuantity[] = wishlist.items.map((item) => ({
            product: item.product,
            quantity: item.quantity,
        }))
        addItemsCart(itemsToAdd)
    }

    function showModalFilterExclude() {
        setIsModalRemoveWishlistOpen(true)
    }

    function onDismiss() {
        setIsModalRemoveWishlistOpen(false)
        setIsModalAddWishlistToCartOpen(false)
    }

    function showModalFilterAddToCart() {
        if (!wishlist?.items) return
        setIsModalAddWishlistToCartOpen(true)
    }

    const handleDeleteWishlistClick = () => {
        sendRemoveWishlistRequest()
    }

    const handleWishlistItemRemove = (item: WishlistItem) => {
        if (!wishlist || !wishlist.items) return
        const updatedWishlist = wishlist.items.filter((wishlistItem) => {
            return wishlistItem.id !== item.id
        })
        sendUpdateWishlist({
            ...wishlist,
            items: updatedWishlist
        })
    }

    const handleWishlistItemAddToCart = (item: WishlistItem) => {
        addItemCart(item.product, item.quantity)
        toast.success('Produto adicionado ao carrinho', {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        })
    }

    const handleWilistItemChange = (item: WishlistItem) => {
        if (!wishlist || !wishlist.items) return
        const updatedWishlistItems = wishlist.items.map((wishlistItem) => {
            if (wishlistItem.id === item.id) {
                return item
            }
            return wishlistItem
        })
        //TODO add timeout to avoid too many requests
        sendUpdateWishlist({ ...wishlist, items: updatedWishlistItems })
    }

    const totalItensQuantity = useMemo(() => {
        if (wishlist && wishlist.items && wishlist.items.length > 0) {
            return wishlist.items.reduce((accumulator, item) => {
                return accumulator + item.quantity;
            }, 0)
        }
        return 0;
    }, [wishlist])

    const totalListValue = useMemo(() => {
        if (wishlist && wishlist.items && wishlist.items.length > 0) {
            return wishlist.items.reduce((accumulator, item) => {
                const bestUnitPrice = getActualProductUnitPrice(item.product, item.quantity)
                return accumulator += item.quantity * bestUnitPrice
            }, 0)
        }
        return 0;
    }, [wishlist])

    const isEmptyPlaceholderVisible = useMemo(() => {
        return !isWishlistLoading && !isWishlistFetching && wishlist?.items.length === 0
    }, [isWishlistLoading, isWishlistFetching, wishlist])

    const isSearchingPlaceholderVisible = useMemo(() => {
        return  isWishlistFetching && initialRender
    }, [initialRender, isWishlistFetching])

    return (
        <>
            <StoreCartBar />
            <PageTitle title={`Lista "${wishlist?.name.toUpperCase() || ''}"`} />
            <div className={`${wishlistDetailStyles.pageContent} container`}>
                {isSearchingPlaceholderVisible && <div>Carregando...</div>}
                {isWishlistError &&
                    <div className={`${wishlistDetailStyles.listItems} d-flex flex-column align-items-center`}>
                        <img src={toAbsoluteUrl('/media/Illustrations/Clipboard-Illustration.svg')} alt='' />
                        <span className="mt-4">Não encontramos a lista</span>
                        <span>veja todas as suas listas clicando no botão abaixo:</span>
                        <div className={`${wishlistDetailStyles.backToListsButton} mt-4`} onClick={() => navigate(toUrl(ACCOUNT_WHISHLIST_PAGE))}>
                            <img src={'/media/ChevronsAndArrows/Arrow-Left.svg'} alt="" className='transform-1px'/>
                            Minhas Listas
                        </div>
                    </div>
                }
                {isEmptyPlaceholderVisible && (
                    <div className='d-flex flex-column align-items-center mt-4'>
                        <img src={toAbsoluteUrl('/media/general/X-Clipboard.svg')} alt='magnifying-glass' />
                        <div className='d-flex flex-column align-items-center main-color font500 font12rem my-4'>
                            <span>Sua lista está vazia por enquanto... </span>
                            <span>Adicione os primeiros itens na nossa loja!</span>
                        </div>
                        <button className={`${wishlistDetailStyles.backBtn} mb-3`} onClick={() => navigate(toUrl(STORE_FRONT_PAGE))}>
                            <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Arrow-Left.svg')} alt='' className='transform-1px' />
                            <span className='ps-2'>Voltar para a loja</span>
                        </button>
                    </div>
                )}

                {(itemsGrouped && itemsGrouped.length > 0) && (<>
                    <div className={wishlistDetailStyles.listSummary}>
                        <span className='font500 main-color'>QUANTIDADE</span>
                        <div className='d-flex justify-content-between font500'>
                            <span>ITENS</span>
                            <span>{totalItensQuantity}</span>
                        </div>
                        <div className='d-flex justify-content-between font500 mb-1'>
                            <span>PRODUTOS</span>
                            <span>{wishlist?.items.length || 0}</span>
                        </div>
                        <div className='d-flex justify-content-between font500 main-color'>
                            <span>SUBTOTAL</span>
                            <span>{toBRL(totalListValue)}</span>
                        </div>
                    </div>

                    <div className={`${wishlistDetailStyles.productCardContainer} padding-bottom-mobile`}>
                        {/* MAP FOR THE ITENS INSIDE THE LIST */}
                        {itemsGrouped.map((itemGroup: any, idx: number) => (
                            <div key={idx} className='mb-4'>
                                <div onClick={() => toggleItemsGroupVisibility(idx)}>
                                    <div className='d-flex justify-content-between w-100 mb-2 mt-3'>
                                        <span className={wishlistDetailStyles.itemGroupName}>{itemGroup.category}</span>
                                        <div>
                                            <button className={wishlistDetailStyles.cartItemClassButton}>
                                                {itemGroup.items.length} &nbsp;
                                                {itemGroup.items.length > 1 ? 'itens' : 'item'} - {toBRL(itemGroup.total)}
                                            </button>
                                            <MdKeyboardArrowUp className={itemGroup.open ? wishlistDetailStyles.chevronDown : wishlistDetailStyles.chevronUp} />
                                        </div>
                                    </div>
                                </div>
                                {itemGroup.open && itemGroup?.items.map((item: any) => (
                                    <ProductCardWishlist
                                        key={item.id}
                                        item={item}
                                        onAddToCart={handleWishlistItemAddToCart}
                                        onChange={handleWilistItemChange}
                                        onRemove={handleWishlistItemRemove}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </>)}

                <div className={items.length > 0 ? `${wishlistDetailStyles.listFooterBarWithCart}` : `${wishlistDetailStyles.listFooterBar}`}>
                    <div className='d-flex justify-content-between container'>
                        <div onClick={showModalFilterExclude} className={wishlistDetailStyles.excludeListButton}>
                            <span>Excluir lista</span>
                            <img src={toAbsoluteUrl('/media/general/Trash-Filled-Icon.svg')} alt='' className={wishlistDetailStyles.exitTrash} />
                        </div>
                        {itemsGrouped && itemsGrouped.length > 0 && <div onClick={showModalFilterAddToCart} className={wishlist?.items ? wishlistDetailStyles.addListButton : wishlistDetailStyles.addButtonDisabled}>
                            <span>Adicionar lista ao carrinho +</span>
                        </div>}
                    </div>
                </div>
            </div>

            <Sheet
                isOpen={isModalRemoveWishlistOpen}
                onClose={onDismiss}
                initialSnap={0}
                snapPoints={[-50, 100, 50]}
                    className={wishlistDetailStyles.bottomsheetTrashing}
                detent="content-height"
            >
                <Sheet.Container>
                    <Sheet.Content className={`${wishlistDetailStyles.bottomSheet} px-3 py-4`}>
                        <div className={wishlistDetailStyles.sheetContent}>
                            <img src={toAbsoluteUrl('/media/general/X-Sign.svg')} alt='' className='me-3' />
                            <span className='font600'>Deseja excliuir essa lista?</span>
                            <div className='d-flex justify-content-center ms-2'>
                                <div onClick={onDismiss} className={wishlistDetailStyles.buttonFaded}>
                                    Não
                                </div>
                                <div onClick={handleDeleteWishlistClick} className={wishlistDetailStyles.buttonDelete}>
                                    Sim
                                </div>
                            </div>
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={onDismiss} />
            </Sheet>

            <Sheet
                isOpen={isModalAddWishlistToCartOpen}
                onClose={onDismiss}
                initialSnap={0}
                snapPoints={[-50, 100, 50]}
                className={wishlistDetailStyles.bottomSheetAddCarrinho}
                detent="content-height"
            >
                <Sheet.Container>
                    <Sheet.Content className={`${wishlistDetailStyles.bottomSheet} px-3 py-4`}>
                        <div className={wishlistDetailStyles.sheetContent}>
                            <img src={toAbsoluteUrl('/media/general/Check-Sign.svg')} alt='' className='me-3' />
                            <div className='font600'>Mover lista para o carrinho?</div>
                            <div className='d-flex justify-content-center ms-2'>
                                <div onClick={onDismiss} className={wishlistDetailStyles.buttonFaded}>
                                    Não
                                </div>
                                <div onClick={handleAddWishlistItemsToCartClick} className={wishlistDetailStyles.buttonAddToCart}>
                                    Sim
                                </div>
                            </div>
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={onDismiss} />
            </Sheet>
        </>
    )
}

export default WishlistDetailPage
