import { setupAxios } from 'modules/api/axios'
import { setupAxiosCentral } from 'modules/api/centralAxios'
import { ApiResponse } from 'modules/api/core/_models'
import { setupAxiosRegional } from 'modules/api/regionalAxios'
import { createRoot } from 'react-dom/client'
import './index.css'
import AppRoutesWrapper from './routing/AppRoutesWrapper'

declare module 'axios' {
  export interface AxiosResponse<T = any> extends Promise<ApiResponse<T>> {}
}

export const centralClient = setupAxiosCentral();
export const regionalClient = setupAxiosRegional();

// CapacitorUpdater.notifyAppReady()
setupAxios()
const container = document.getElementById('root')

if (container) {
  createRoot(container).render(<AppRoutesWrapper />)
}
