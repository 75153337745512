import React from 'react'
import ContentLoader from 'react-content-loader'

function ProductModalSkeleton() {
  return (
    <ContentLoader 
    speed={2}
    width={600}
    height={730}
    viewBox="0 0 600 730"
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb">

    <rect x="26" y="76" rx="0" ry="0" width="240" height="295" /> 
    <rect x="303" y="79" rx="0" ry="0" width="390" height="52" /> 
    <rect x="342" y="206" rx="0" ry="0" width="320" height="60" /> 
    <rect x="342" y="292" rx="0" ry="0" width="320" height="60" /> 
    <rect x="388" y="379" rx="0" ry="0" width="250" height="32" /> 
    <rect x="27" y="495" rx="0" ry="0" width="640" height="230" />
    </ContentLoader>
  )
}

export default ProductModalSkeleton