import { useQuery } from '@tanstack/react-query';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { GroceryDetails } from 'modules/auth';
import { useAuth } from 'modules/auth/core/AuthProvider';
import { useMemo, useState } from 'react';
import { MdArrowForwardIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from 'utils/Functions';
import { ACCOUNT_PROFILE_REGISTRY, ACCOUNT_PROFILE_REGISTRY_OWNER, toUrl } from 'utils/Links';
import profileStyle from './ProfilePage.module.css';
import { getGroceryDetails } from './core/_requests';

function ProfilePage() {

    const navigate = useNavigate();
    const [groceryDetails, setGroceryDetails] = useState<GroceryDetails>();
    const { auth } = useAuth()

    const { isLoading: isGroceryDetailsLoading, isError : isGroceryDetailsError } = useQuery({
        queryFn: async () => {
            const response = await getGroceryDetails()
            setGroceryDetails(response.data)
            return response
        },
        queryKey: ['groceryDetails', auth],
        enabled: !!auth,
    })

    const fullAddress = useMemo(() => {
        if (!groceryDetails || !groceryDetails.address) return '---'
        return `${groceryDetails.address?.neighborhood}, ${groceryDetails?.address?.street}, ${groceryDetails?.address?.number}`
    }, [groceryDetails])

    // const mainContact = useMemo(() => {
    //     if (!groceryDetails || !groceryDetails.main_contact) return
    //     return groceryDetails.main_contact
    // }, [groceryDetails])

    return (
        <>
            <PageTitle title={`Atualizar cadastro`} />
            <div className='container padding-bottom-mobile'>
                {isGroceryDetailsError && (<> Erro ao carregar informações da loja </>)}
                {isGroceryDetailsLoading && (<> Carregando... </>)}
                {!isGroceryDetailsLoading && groceryDetails && (<>
                    <div className={profileStyle.mainData}>
                        <div>
                            <img src={toAbsoluteUrl('/media/menuPage/Square-List-Icon.svg')} alt="" className='me-1 transform-2px' />
                            <span className='font500 main-color'>DADOS DA EMPRESA</span>
                        </div>
                        <div className="d-flex flex-column mt-3">
                            <span className="font500">CNPJ/CPF</span>
                            <span>{groceryDetails.cnpj || groceryDetails.cpf}</span>
                        </div>
                        <div className="d-flex flex-column mt-3">
                            <span className="font500">Inscrição estadual</span>
                            <span>{groceryDetails.municipal_registration || '---'}</span>
                        </div>
                        <div className="d-flex flex-column mt-3">
                            <span className="font500">Nome Fantasia</span>
                            <span>{groceryDetails.company_name}</span>
                        </div>
                        <div className="d-flex flex-column mt-3">
                            <span className="font500">Razão Social</span>
                            <span>{groceryDetails.name}</span>
                        </div>
                        <div className="d-flex flex-column mt-3">
                            <span className="font500">Endereço</span>
                            <span>{fullAddress}</span>
                        </div>
                        <div className="d-flex flex-column mt-3">
                            <span className="font500">Telefone Comercial</span>
                            <span>{groceryDetails?.business_telephone_number || '----'}</span>
                        </div>
                        <div className="d-flex flex-column mt-3">
                            <span className="font500">E-mail</span>
                            <span>{groceryDetails.email || '----'}</span>
                        </div>
                    </div>

                    <div className={profileStyle.profileOptions}>
                        <div className="d-flex flex-column py-2">
                            <div className='d-flex'>
                                <img src={toAbsoluteUrl('/media/menuPage/Square-List-Icon-With-Avatar.svg')}
                                    alt=''
                                    className='me-2' />
                                <div className={profileStyle.profileCategory}>
                                    <span className='main-color font500'>CONTATO DO(A) PROPRIETÁRIO(A) OU RESPONSÁVEL</span>
                                </div>
                            </div>
                            <div>
                                {/* <div className='d-flex flex-column'>
                                    <span className='mt-2 font500'>Nome</span>
                                    <span>{mainContact?.name || '---'}</span>
                                </div>
                                <div className='d-flex flex-column'>
                                    <span className='mt-2 font500'>Telefone</span>
                                    <span>{mainContact?.telephone_number || '---'}</span>
                                </div>
                                <div className='d-flex flex-column'>
                                    <span className='mt-2 font500'>E-mail</span>
                                    <span>{mainContact?.email || '---'}</span>
                                </div> */}
                            </div>
                            <div className='mt-2'>
                                <span className='text-decoration-underline font500 main-color'
                                    onClick={() => navigate(toUrl(ACCOUNT_PROFILE_REGISTRY_OWNER))}>
                                    Atualizar informações
                                    <img src={toAbsoluteUrl('/media/general/Edit-Filled-Icon.svg')} alt='' className={profileStyle.editIcon} />
                                </span>
                            </div>
                        </div>

                    </div>

                    <div
                        className={`${profileStyle.profileOptions} mb-100px`}
                        onClick={() => {
                            navigate(toUrl(ACCOUNT_PROFILE_REGISTRY));
                        }}>
                        <div className="d-flex flex-row align-items-center py-2">
                            <img src={toAbsoluteUrl('/media/menuPage/Avatar-With-Stripes.svg')}
                                alt=''
                                className='me-2' />
                            <div className={profileStyle.profileCategory}>
                                <span className='main-color font500'>CONTATO ADICIONAL</span>
                            </div>
                        </div>
                        <div className='transform-2px'>
                            <MdArrowForwardIos />
                        </div>
                    </div>
                </>)}
            </div>
        </>
    );
}

export default ProfilePage;
