import { Product } from 'models/Product'
import { useMemo } from 'react'
import { toAbsoluteUrl, toBRL } from 'utils/Functions'
import btnProductMob from './BtnProductMob.module.css'

type Props = {
    product: Product,
    selected: boolean,
    enabled?: boolean,
}

function BtnBoxMob({ product, selected, enabled = false }: Props) {

    const packageUnitPrice = useMemo(() => {
      if (!product || !product.quantity_per_package || !product.package_sell_price) return 0
      return product.package_sell_price / product.quantity_per_package
    }, [product])

    return (
        <div
            className={
                selected
                    ? `${btnProductMob.btnBoxSelected} ${btnProductMob.btnBoxFaded} h-100 d-block`
                    : `${btnProductMob.btnBoxFaded} h-100 d-block`
            }>

            <div className={enabled ? 'main-filter' : 'gray-filter'}>
                <img
                    src={toAbsoluteUrl('/media/general/Box-Icon-2.svg')}
                    alt='Caixa'
                    className={btnProductMob.caixa}
                />
                CAIXA <span>C/{product.quantity_per_package}</span>
            </div>
            {enabled && (<>
                <div>
                    <span className={btnProductMob.priceMob}>{toBRL(product?.package_sell_price)}</span>
                </div>

                <div>
                    <span>{toBRL(packageUnitPrice)} /und</span>
                </div>
            </>)}
            {!enabled && (
                <div className='faded-color mt-3'>
                    <span>Indisponível</span>
                </div>
            )}
        </div>
    )
}

export default BtnBoxMob
