export type NavbarConfig = {
    backButtonEnabled: boolean
    title: string
    logoEnabled: boolean
    searchBarEnabled: boolean
}

export type ProductCardType = 'horizontal' | 'vertical'

export type StoreCartVisibility = boolean

export type ContentConfig = {
    productCardType: ProductCardType
}

export type FooterConfig = {
    title: string
}

export type TopbarConfig = {
    title: string
    visible: boolean
    backButtonEnabled?: boolean
    backButtonAction?: () => void
    forwardButtonEnabled?: boolean
    forwardButtonAction?: () => void
}

export type HeaderConfig = {
    topbarHeight: number
    navbarHeight: number
    searchbarHeight: number
    notificationbarHeight: number
}

export type LayoutConfig = {
    template: string
    scrollTop: boolean
    footer: FooterConfig
    header: HeaderConfig
    topbar: TopbarConfig
}

export type StoreConfig = {
    navbar: NavbarConfig
    content: ContentConfig
    layout: LayoutConfig
}

export const initialLayoutConfig : LayoutConfig= {
    template: 'default',
    scrollTop: true,
    footer: {
        title: '',
    },
    topbar: {
        title: '',
        visible: false,
        backButtonEnabled: true,
    },
    header: {
        topbarHeight: 0,
        navbarHeight: 0,
        searchbarHeight: 0,
        notificationbarHeight: 0,
    },
}

export const initialNavbarConfig = {
    backButtonEnabled: false,
    title: '',
    logoEnabled: true,
    searchBarEnabled: true,
}

export const initialContentConfig = {
    productCardType: 'horizontal' as ProductCardType,
}

export const initialStoreConfig = {
    navbar: initialNavbarConfig,
    content: initialContentConfig,
    layout: initialLayoutConfig,
}
