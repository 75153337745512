import AuthenticationSteps from 'components/desktop/AuthenticationSteps/AuthenticationSteps'
import React, {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {CgClose} from 'react-icons/cg'
import {IoAlertCircleOutline} from 'react-icons/io5'
import {useNavigate} from 'react-router-dom'
import finalizeStyle from './AOAuthentication.module.css'
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'
import { ACCOUNT_2_FACTOR, ACCOUNT_ORDERS_PAGE, toUrl } from 'utils/Links'

function AOFinalize() {
  const navigate = useNavigate()

  const breadcrumbs = useMemo(() => {
    return(
      [          
        {
          title : 'Perfil',
          path: toUrl(ACCOUNT_ORDERS_PAGE),
        },
        {    
          title : 'Autenticação',
          path : toUrl(ACCOUNT_2_FACTOR, 4),
        }
      ]
    )
  }, [])

  const [isModalOpen, setIsModalOpen] = useState(false)

  function showOpen() {
    setIsModalOpen(true)
  }

  function onDismiss() {
    setIsModalOpen(false)
  }

  return (
    <>
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className='font600 font15rem'>AUTENTICAÇÃO DE DOIS FATORES</span>
      </div>

      <AuthenticationSteps step={4} />

      <div className='py-3'>
        <h4 className='mb-4'>Finalizar a habilitação da autenticação de dois fatores</h4>
        <div className={`${finalizeStyle.inputBar} d-flex flex-column`}>
          <span>Digite aqui o código que enviamos para o seu e-mail cadastrado</span>
          <input
            type='text'
            placeholder='000 000'
            className={finalizeStyle.finalizeAuthenticationInput}
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = '000 000')}
            required
          ></input>
        </div>

        <div className={`${finalizeStyle.inputBar} d-flex flex-column`}>
          <span>
            Digite aqui o código de verificação disponível no aplicativo Google Authenticator
          </span>
          <input
            type='text'
            placeholder='000 000'
            className={finalizeStyle.finalizeAuthenticationInput}
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = '000 000')}
            required
          ></input>
        </div>

        <div className={`${finalizeStyle.authenticationAlert} my-4`}>
          <div className='d-flex flex-row align-items-center p-2'>
            <IoAlertCircleOutline style={{fontSize: '35px'}} />
            <span className='p-2'>
              Os códigos têm duração de 30 segundos cada. Você irá perceber que no canto direito da
              tela, um temporizador está sempre ativado para indicar se o código está próximo ou não
              de expirar.
            </span>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <button className={`${finalizeStyle.authenticationButton}`} onClick={showOpen}>
            {' '}
            Ativar autenticação{' '}
          </button>
        </div>
      </div>

      <Modal
        show={isModalOpen}
        className={finalizeStyle.authenticationConfirmationSheet}
        onHide={onDismiss}
        centered
      >
        <Modal.Body>
          <div
            className={`${finalizeStyle.exitButton} d-flex justify-content-end mb-4`}
            onClick={onDismiss}
          >
            <CgClose />
          </div>
          <div
            className={`${finalizeStyle.authenticationConfirmation} d-flex flex-column align-items-center`}
          >
            <img
              src={process.env.PUBLIC_URL + '/media/general/Ok-Icon.svg'}
              alt='ok'
              className={finalizeStyle.authenticationCheckIcon}
            />
            <span>Autenticação ativada!</span>
            <div>
              <button
                className={finalizeStyle.authenticationConfirmationButton}
                onClick={onDismiss}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AOFinalize
