import { FC, PropsWithChildren, createContext, useContext, useRef, useState } from "react";

const SPLASH_SCREEN_LABEL = "Carregando por favor aguarde...";
// const SPLASH_SCREEN_IMG = '%PUBLIC_URL%/media/logos/vupty-small.png'

type StoreScreenModel = {
  show: (label?: string, img?: string) => void;
  hide: () => void;
};

const initialStoreScreenModel: StoreScreenModel = {
  show: (label?: string, img?: string) => {},
  hide: () => {},
};

const SplashScreenContext = createContext(initialStoreScreenModel);

const SplashScreenProvider: FC<PropsWithChildren> = ({ children }) => {
  const [count, setCount] = useState(0);
  const [label, setLabel] = useState(SPLASH_SCREEN_LABEL);
  const [visible, setVisible] = useState(true);
  const timeoutRef = useRef<number>();

  const splashScreen = document.getElementById("splash-screen");
  const splashScreenLabel = document.getElementById("splash-screen-label");
  const splashScreenImg = document.getElementById("splash-screen-img");

  // useEffect(() => {
  //     const splashScreen = document.getElementById('splash-screen')
  //     const splashScreenLabel = document.getElementById('splash-screen-label')
  //     const splashScreenImg = document.getElementById('splash-screen-img')
  //     let timeout = -1

  //     if (!splashScreen) return

  //     if (!visible) {
  //         splashScreen.style.setProperty('display', 'none')
  //         clearTimeout(timeout)
  //         return
  //     }

  //     splashScreen.style.removeProperty('display')
  //     splashScreenLabel!!.innerHTML = label

  //     timeout = window.setTimeout(() => {
  //         setVisible(false)
  //     }, 3000)

  //     return () => {
  //         clearTimeout(timeout)
  //     }
  // }, [visible])

  const show = (label: string = SPLASH_SCREEN_LABEL, img?: string) => {
    // setLabel(label || SPLASH_SCREEN_LABEL);

    splashScreen!!.style.removeProperty("display");
    splashScreenLabel!!.innerHTML = label;

    clearTimeout(timeoutRef.current)

    timeoutRef.current = window.setTimeout(() => {
      splashScreen!!.style.setProperty("display", "none");
    }, 3000);

    // setImg(img || SPLASH_SCREEN_IMG)
    // setCount((prev) => {
    //     return prev + 1
    // })
    // setVisible(true)
  };

  const hide = () => {
    clearTimeout(timeoutRef.current)
    splashScreen!!.style.setProperty("display", "none");
    // setCount((prev) => {
    //     return prev - 1
    // })
    // setVisible(false)
  };

  return <SplashScreenContext.Provider value={{ show, hide }}>{children}</SplashScreenContext.Provider>;
};

const useSplashScreen = () => useContext(SplashScreenContext);

export { SplashScreenProvider, useSplashScreen };
