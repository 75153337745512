/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { useStoreConfig } from 'layouts/common/core/StoreConfigProvider'
import { forwardRef, useCallback, useMemo } from 'react'
import { MdArrowBackIosNew } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import topbarStyle from './Topbar.module.css'

function Topbar(props: any, ref: any) {
    const navigate = useNavigate()
    const { storeConfig } = useStoreConfig()
    const title = useMemo(() => storeConfig.layout.topbar.title, [storeConfig.layout.topbar])
    const isBackButtonVisible = useMemo(() => {
        return !!storeConfig.layout.topbar.backButtonEnabled
    }, [storeConfig])

    const handleBackClick = useCallback(() => {
        const backButtonAction = storeConfig.layout.topbar.backButtonAction
        if (!!backButtonAction) {
            backButtonAction()
            return
        }
        navigate(-1)
        return
    }, [storeConfig])

    return (<>
        <div className={clsx("row", topbarStyle.topBar)} ref={ref}>
            {isBackButtonVisible && (<span>
                <MdArrowBackIosNew onClick={handleBackClick} />
            </span>)}
            <p>{title}</p>
        </div>
    </>)
}

export default forwardRef(Topbar)
