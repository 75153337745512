import { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CgClose } from 'react-icons/cg'
import { DATE_USUAL } from 'utils/Consts'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { info } from 'pages/desktop/Account/AccountOrder/core/_requests'
import Loader from 'components/desktop/Loader/Loader'
import TrackingComponent from './TrackingComponent'
import { toAbsoluteUrl } from 'utils/Functions'

type Props = {
    show: boolean;
    onHide: () => void;
    id?: string;
}

function ModalTracking({show, onHide, id}: Props) {
  const [dayOfWeek, setDayOfWeek] = useState('');
  const params = useParams();
  const itemId = useMemo(() => id || params.id, [id, params.id]);

  const { data: order, error } = useQuery({
    queryFn: async () => {
      const { data } = await info(itemId!!);
      return data;
    },
    queryKey: ["order", id],
    enabled: itemId !== undefined,
  });

  useEffect(() => {
    if(show) {
      calculateDayOfWeek();
    }
  }, [order]);

  const calculateDayOfWeek = () => {
    const parts = moment(order.modification_date).format(DATE_USUAL).split('/');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = 2000 + parseInt(parts[2], 10);

      const date = new Date(year, month, day);
      const daysOfWeek = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
      const dayOfWeek = daysOfWeek[date.getDay()];

      setDayOfWeek(dayOfWeek);
    } else {
      setDayOfWeek('Invalid date format');
    }
  };

  return (
    <Modal size={`lg`} show={show} onHide={onHide} centered>
      <Modal.Body className='py-5 px-3'>
        {!order && <Loader />}
        {order &&
          <>
            <div className='d-flex justify-content-between align-items-center font14rem font600 mb-3'>
              <span>Rastreio {order.id}</span>
              <CgClose onClick={onHide} />
            </div>
            <div>
                <div className='d-flex justify-content-between font500 main-color mb-2 px-2 pt-1'>
                  <span>
                    {order.status.description === 'Entregue'
                      ? 'ENTREGUE NA DATA'
                      : 'PRAZO ESTIMADO DA ENTREGA'}
                  </span>
                  <span>
                    {/* {dayOfWeek} ({order.modification_date.format(DATE_USUAL)})  */}
                    {(order.status.description !== 'Entregue' && order.status.description !== 'Cancelado') && ' até às 16h'}
                  </span>
                  <span className={`badgeStatus${order.status.value} orderbadgeStatus transform-4px`}>
                    {order.status.description}
                  </span>
                </div>

                {order && 
                  <div className=''>
                    <div className='my-5 d-flex'>
                      <div className='col20 position-relative'>
                        <TrackingComponent 
                          currentStep={order.delivery_step} 
                          visualStep={0} 
                          deliveryStatus={order.status.value}
                          imagePath={toAbsoluteUrl('/media/tracking/R$.svg')} 
                          // date={order.modification_date} 
                          stepDescription={'Pagamento efetuado!'} 
                          stepDescriptionAlt={'Pagamento sob analise'}/>   
                      </div>

                      <div className='col20 position-relative'>
                        <TrackingComponent 
                            currentStep={order.delivery_step} 
                            visualStep={1}
                            deliveryStatus={order.status.value}
                            imagePath={toAbsoluteUrl('/media/tracking/Closed-Box-Big.svg')}
                            // date={order.modification_date} 
                            stepDescription={'Compras realizadas!'}/>
                      </div>

                      <div className='col20 position-relative'>
                        <TrackingComponent 
                            currentStep={order.delivery_step} 
                            visualStep={2} 
                            deliveryStatus={order.status.value}
                            imagePath={toAbsoluteUrl('/media/tracking/Wrapped-Box.svg')} 
                            // date={order.modification_date} 
                            stepDescription={'Compras embaladas'}/>
                      </div>

                      <div className='col20 position-relative'>
                        <TrackingComponent 
                            currentStep={order.delivery_step} 
                            visualStep={3}
                            deliveryStatus={order.status.value}
                            imagePath={toAbsoluteUrl('/media/tracking/Truck-Big.svg')} 
                            // date={order.modification_date} 
                            stepDescription={'Suas compras saíram para entrega'}/>
                      </div>

                      <div className='col20 position-relative'>
                        <TrackingComponent 
                            currentStep={order.delivery_step} 
                            visualStep={4}
                            deliveryStatus={order.status.value}
                            imagePath={toAbsoluteUrl('/media/tracking/Delivered-Box.svg')} 
                            // date={order.modification_date} 
                            stepDescription={'As compras foram entregues'}/>
                      </div>
                    </div>
                  </div>
                }
            </div>
          </>
        }    
      </Modal.Body>
    </Modal>
  )
}

export default ModalTracking