import React, { FC, useState } from 'react';
import faqGroupAccordionStyle from './FaqGroupAccordion.module.css';
import { toAbsoluteUrl } from 'utils/Functions';
import { FaqGroup } from 'models/FaqGroup';
import Collapsible from 'react-collapsible';
import { Faq } from 'models/Faq';

type Props = {
    faqGroup : FaqGroup
}

const FaqGroupAccordion: FC<Props> = ({faqGroup}) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const toggleVisibility = () => {
        setExpanded((prev) => !prev)
    }

    return <>
        <div className={`${faqGroupAccordionStyle.faqTitle} col-12 d-flex justify-content-between px-3 mt-3`} onClick={toggleVisibility}>
            <div className='d-flex'>
                <img src={toAbsoluteUrl('/media/general/Doubt-Icon.svg')} alt='' className='main-filter me-2'/>
                <span> {faqGroup.name} </span>
            </div>
            <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Down-Gray.svg')} alt=''
                className={expanded ? 'chevronDown filter' : 'chevronUp filter'} />
        </div>

        {expanded &&
            <div className={`container ${faqGroupAccordionStyle.faqContentTitle}`}>
                {faqGroup.faqs.map((faq: Faq, idx: number) => (
                    <div key={idx} className='py-2'>
                        <Collapsible
                            trigger={<div className='font500'><span className={faqGroupAccordionStyle.openSings}>+</span>{faq.question}</div>}
                            triggerWhenOpen={<div className='font500'><span className={faqGroupAccordionStyle.openSings}>-</span>{faq.question}</div>}
                            open={true}
                        >
                            <p className={faqGroupAccordionStyle.faqContentText}>
                                {faq.answer}
                            </p>
                        </Collapsible>
                    </div>
                ))}
            </div>
        }
    </>;
}

export default FaqGroupAccordion;
