import { Logout } from 'modules/auth'
import { Navigate, useRoutes } from 'react-router-dom'
import { STORE_FRONT_PAGE, toUrl } from 'utils/Links'
import CouponPage from './Coupon/CouponPage'
import AddCreditCardPage from './CreditCard/AddCreditCardPage/AddCreditCardPage'
import CreditCardPage from './CreditCard/CreditCardPage'
import DonationPage from './Donation/DonationPage'
import FaqPage from './Faq/FaqPage'
import MenuPage from './Menu/MenuPage'
import NotificationPage from './Notification/NotificationPage'
import OrderDetailPage from './Order/OrderDetailPage'
import OrdersPage from './Order/OrdersPage'

import WishlistDetailPage from 'pages/mobile/Account/Wishlist/WishlistDetailPage'
import WishlistPageMobile from 'pages/mobile/Account/Wishlist/WishlistPage'

import ChangePasswordPage from './ChangePassword/ChangePasswordPage'
import ContactPage from './Contact/ContactPage'
import InvoiceDetailPage from './InvoiceDetail/InvoiceDetailPage'
import PaymentDetailsPage from './Payment/PaymentDetails/PaymentDetailsPage'
import PaymentsPage from './Payment/PaymentsPage'
import Privacy from './PrivacyPage/Privacy'
import ProfileWrapper from './Profile/ProfileWrapper.'
import ReceiptDetailPage from './ReceiptDetail/ReceiptDetailPage'
import ReceiptDownload from './ReceiptDownload/ReceiptDownload'
import SuggestedListDetailPage from './SuggestedList/SuggestedListDetailPage'
import SuggestedListPage from './SuggestedList/SuggestedListPage'
import Terms from './Terms/Terms'
import TicketPage from './Ticket/TicketPage'
import TrackDetailPage from './TrackDetail/TrackDetailPage'
import TwoFactorAuthWrapper from './TwoFactorAuth/TwoFactorAuthWrapper'

function AccountWrapper() {

    const routes = useRoutes([
        {
            index: true,
            element: <MenuPage />,
        },
        {
            path: 'perfil/*',
            element: <ProfileWrapper />,
        },
        {
            path: 'faq',
            element: <FaqPage />,
        },
        {
            path: 'pedido/lista',
            element: <OrdersPage />,
        },
        {
            path: 'pedido/*',
            children: [{
                path: ':id',
                element: <OrderDetailPage />,
            }, {
                path: '*',
                element: <OrdersPage />,
            }]
        },
        {
            path: 'rastreio/:id',
            element: <TrackDetailPage />,
        },
        {
            path: 'nota/:id',
            element: <ReceiptDetailPage />,
        },
        {
            path: 'fatura/:id',
            element: <InvoiceDetailPage />,
        },
        {
            path: 'nota-fiscal/:id',
            element: <ReceiptDownload />,
        },
        {
            path: 'pagamento/lista',
            element: <PaymentsPage />,
        },
        {
            path: 'pagamento/:id',
            element: <PaymentDetailsPage />,
        },
        {
            path: 'doacao/lista',
            element: <DonationPage />,
        },
        {
            path: 'trocar-senha',
            element: <ChangePasswordPage />,
        },
        {
            path: 'cupons',
            element: <CouponPage />,
        },
        {
            path: 'tickets',
            element: <TicketPage />,
        },
        {
            path: 'cartoes',
            element: <CreditCardPage />,
        },
        {
            path: 'cartoes/novo',
            element: <AddCreditCardPage />,
        },
        {
            path: 'notificacoes',
            element: <NotificationPage />,
        },
        {
            path: 'autenticacao-2-fatores/*',
            element: <TwoFactorAuthWrapper />,
        },
        {
            path: 'lista-de-compras/lista',
            element: <WishlistPageMobile />,
        },
        {
            path: 'lista-de-compras/*',
            children: [{
                path: ':id',
                element: <WishlistDetailPage />,

            }, {
                path: '*',
                element: <WishlistPageMobile />
            }]
        },
        {
            path: 'lista-de-compras-sugerida/lista',
            element: <SuggestedListPage />,
        },
        {
            path: '/lista-de-compras-sugerida/:id',
            element: <SuggestedListDetailPage />,
        },
        {
            path: 'termos-de-uso',
            element: <Terms />,
        },
        {
            path: 'contato',
            element: <ContactPage />
        },
        {
            path: 'politica-de-privacidade',
            element: <Privacy />,
        },
        {
            path: 'sair',
            element: <Logout />,
        },
        {
            path: '*',
            element: <Navigate to={toUrl(STORE_FRONT_PAGE)} />,
        },
    ])


    return routes
}

export default AccountWrapper
