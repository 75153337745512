import { regionalClient } from "index"
import { Brand } from "models/Brand"
import { Category } from "models/Category"
import { FacetFilter } from "models/FacetFilter"
import { ProductSearch } from "models/ProductSearch"
import { SearchSuggestion } from "models/SearchSuggestion"
import { SortOption } from "models/SortOptionEnum"
import { Tag } from "models/Tag"
import { BrandEndpoints } from "modules/api/endpoints/BrandEndpoints"
import { CategoryEndpoints } from "modules/api/endpoints/CategoryEndpoints"
import { SearchEndpoints } from "modules/api/endpoints/SearchEndpoints"
import { TagEndpoints } from "modules/api/endpoints/TagEndpoints"
import { getEnumKeyByEnumValue } from "utils/Functions"

export const listTopCategories = () => {
    return regionalClient.get<Category[]>(CategoryEndpoints.listTop)
}
export const listProductSuggestion = (query: string) => {
    return regionalClient.get<SearchSuggestion>(SearchEndpoints.listProductSuggestions, {
        params: {
            search: query,
        }
    })
}
export const searchProductByTerm = (query: string, sort: SortOption, facets: FacetFilter[], page: number, perPage: number = 10) => {
    return regionalClient.post<ProductSearch>(SearchEndpoints.searchProductByTerm, {
        search: query,
        sort: getEnumKeyByEnumValue(SortOption, sort),
        facets: facets,
        page: page,
        per_page: perPage,
    })
}

export const searchProductByCategorySlug = (slug: string, sort: SortOption, facets: FacetFilter[], page: number, perPage: number = 10) => {
    return regionalClient.post<ProductSearch>(SearchEndpoints.searchProductByCategorySlug, {
        search: slug,
        sort: getEnumKeyByEnumValue(SortOption, sort),
        facets: facets,
        page: page,
        per_page: perPage,
    })
}

export const searchProductByTagSlug = (slug: string, sort: SortOption, facets: FacetFilter[], page: number, perPage: number = 10) => {
    return regionalClient.post<ProductSearch>(SearchEndpoints.searchProductByTagSlug, {
        search: slug,
        sort: getEnumKeyByEnumValue(SortOption, sort),
        facets: facets,
        page: page,
        per_page: perPage,
    })
}

export const dailySale = (slug: string) => {
    return regionalClient.post<ProductSearch>(SearchEndpoints.searchProductByTagSlug, {
        search: slug,
        sort: getEnumKeyByEnumValue(SortOption, 'A - Z'),
    })
}

export const searchProductByBrandSlug = (slug: string, sort: SortOption, facets: FacetFilter[], page: number, perPage: number = 10) => {
    return regionalClient.post<ProductSearch>(SearchEndpoints.searchProductByBrandSlug, {
        search: slug,
        sort: getEnumKeyByEnumValue(SortOption, sort),
        facets: facets,
        page: page,
        per_page: perPage,
    })
}

export const categoryDetailBySlug = (slug: string) => {
    return regionalClient.get<Category>(CategoryEndpoints.detailBySlug(slug))
}

export const tagDetailBySlug = (slug: string) => {
    return regionalClient.get<Tag>(TagEndpoints.detailBySlug(slug))
}

export const brandDetailBySlug = (slug: string) => {
    return regionalClient.get<Brand>(BrandEndpoints.detailBySlug(slug))
}
