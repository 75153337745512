import { useQuery } from "@tanstack/react-query";
import { GroceryDetails, GroceryPreference } from "modules/auth";
import { useAuth } from "modules/auth/core/AuthProvider";
import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getGroceryDetails, getGroceryPreferences } from "./_requests";

type GroceryContextModel = {
  groceryDetails: GroceryDetails | undefined;
  saveGroceryDetails: (details: GroceryDetails | undefined) => void;
  groceryPreferences: GroceryPreference | undefined;
  saveGroceryPreferences: (preferences: GroceryPreference | undefined) => void;
};

const initialGroceryContext: GroceryContextModel = {
  groceryDetails: undefined,
  saveGroceryDetails: () => {},
  groceryPreferences: undefined,
  saveGroceryPreferences: () => {},
};

const GroceryContext = createContext<GroceryContextModel>(initialGroceryContext);

const GroceryProvider: FC<PropsWithChildren> = ({ children }) => {
  const [groceryDetails, setGroceryDetails] = useState<GroceryDetails | undefined>();
  const [groceryPreferences, setGroceryPreferences] = useState<GroceryPreference | undefined>();

  const saveGroceryDetails = (details: GroceryDetails | undefined) => {
    setGroceryDetails(details);
  };

  const saveGroceryPreferences = (preferences: GroceryPreference | undefined) => {
    setGroceryPreferences(preferences);
  };

  return (
    <GroceryContext.Provider value={{ groceryDetails, groceryPreferences, saveGroceryDetails, saveGroceryPreferences }}>
      {children}
    </GroceryContext.Provider>
  );
};

const GroceryInfoInit: FC = () => {
  const { groceryDetails, groceryPreferences, saveGroceryDetails, saveGroceryPreferences } = useGrocery();
  const { auth, clearAuth } = useAuth();
  // const { show: showSplashScreen, hide: hideSplashScreen } = useSplashScreen();

  const { error : groceryDetailsError } = useQuery({
    queryFn: async () => {
      const { data: details } = await getGroceryDetails();
      saveGroceryDetails(details);
      return details;
    },
    queryKey: ["grocery-details", auth],
    enabled: !!auth && !groceryDetails,
  });

  const { error : groceryPreferencesError } = useQuery({
    queryFn: async () => {
      const { data: preferences } = await getGroceryPreferences();
      saveGroceryPreferences(preferences);
      return preferences;
    },
    queryKey: ["grocery-preferences", auth],
    enabled: !!auth && !groceryPreferences,
  });

  useEffect(() => {
    if (!groceryDetailsError && !groceryPreferencesError) return;
    toast.error("Informacoes da loja indisponiveis, por favor tente novamente mais tarde.");
    clearAuth();
  }, [groceryDetailsError, groceryPreferencesError, clearAuth]);

  return null;
};

const useGrocery = () => {
  return useContext(GroceryContext);
};

export { GroceryInfoInit, GroceryProvider, useGrocery };
