import React from 'react';
import { toAbsoluteUrl } from 'utils/Functions';
import deliveryNotificationLayoutStyle from './DeliveryNotificationLayout.module.css';

const DeliveryNotificationLayout: React.FC = () => {
  return (
    <div className={deliveryNotificationLayoutStyle.main} >
            <div className={`container d-flex justify-content-evenly py-1`}>
                <span>Realizando suas compras até as 11h00, Entregamos no mesmo dia!</span>
                <img src={toAbsoluteUrl('/media/general/Delivery-Truck.svg')} alt='Close'/>
            </div>
        </div>
  );
}

export default DeliveryNotificationLayout;
