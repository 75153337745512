import { Mission } from 'models/Mission'
import { toAbsoluteUrl, toBRL } from 'utils/Functions'
import barStyle from './MissionProgressBar.module.css'

type Props = {
    mission: Mission,
    className?: string,
}

function MissionProgressBar({ mission, className }: Props) {
    return (
        <div className={`${className}`}>
            <div className='progress'>
                <div className={`progress-bar ${barStyle.bar}`} role='progressbar' style={{ width: `${mission.progress}%` }}></div>
            </div>
            <div className='font600 main-color'>
                {mission.progress !== 100 &&
                    <div className='d-flex flex-row justify-content-between'>
                        <span>{toBRL(mission.purchase_value)}</span>
                        <span>{toBRL(mission.minimum_purchase_value)}</span>
                    </div>
                }
                {mission.progress === 100 &&
                    <div className='d-flex justify-content-center'>
                        <img src={toAbsoluteUrl('/media/general/Star-Icon.svg')} alt='' className='transform-1px me-1' />
                        <span style={{ fontSize: '0.95rem' }}>Oba! Você ganhou: {mission.description}</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default MissionProgressBar
