import { useMemo } from 'react';
import styled from 'styled-components';

type Props = {
    value: any
}

const Span = styled.div`
    font-size: 12px;
    margin-right: 4px;
    transform: translateY(7px);
    font-weight: 600;
`;

function CardBRLStyle({value}: Props) {

    const formatedValue = useMemo(() => {
        if(value === null){
            return
        }
        return new Intl.NumberFormat("pt-BR", {
                currency: "BRL",
                minimumFractionDigits: 2,
            }).format(value)
    }, [value])

    return (
        <>
        {value == null && <span>INDISPONÍVEL</span>}

        {value &&
        <div className='d-flex'>
            {/* <Span>R$</Span> */}
            R$
            <span className='ms-2'>{formatedValue}</span>
        </div>
        }
        </>
    )
}

export default CardBRLStyle
