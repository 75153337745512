import React from 'react';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import AccountBillPage from './AccountBillPage';
import AccountBillDetailModal from './modal/AccountBillDetailModal/AccountBillDetailModal';

const AccountBillWrapper: React.FC = () => {
  const navigate = useNavigate();
  return <Routes>
    <Route
      path='lista'
      element={<><AccountBillPage /><Outlet /></>}>
        <Route path=":id" element={<AccountBillDetailModal
          show={true}
          onHide={() => navigate('lista')}
        />} />
      </Route>
  </Routes>;
}

export default AccountBillWrapper;
