import { ApiResponseError } from "./_models"

export const apiErrorToString = (err: any): string => {
    if (err instanceof ApiResponseError) {
        if (err.message) {
            return err.message
        }
        if (err.error instanceof Array) {
            let message = ''
            for (let index = 0; index < err.error.length; index++) {
                const element = err.error[index]
                message = message.concat(element.field + ', ' + element.message)
            }
            return message
        }
        return 'Algo de errado não está certo, tente novamente mais tarde'
    }

    if (err.message)
        return err.message

    return 'Algo de errado não está certo, tente novamente mais tarde'
}
