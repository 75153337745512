import { useMemo } from 'react';
import { getActualProductPriceRange, getNextProductPriceRange, toAbsoluteUrl, toBRL } from 'utils/Functions';
import btnProductMob from './BtnProductMob.module.css';
import { useStoreCart } from 'layouts/common/core/StoreCartProvider';

type Props = {
    product: any,
    selected: boolean,
    enabled?: boolean,
}

function BtnUnitMob({ product, selected, enabled = false }: Props) {

    const { getItemCart } = useStoreCart();
    const itemCart = useMemo(() => getItemCart(product.id), [product, getItemCart]);

    const actualProductPriceRange = useMemo(() => {
        return getActualProductPriceRange(product, itemCart?.quantity);
    }, [itemCart?.quantity, product]);

    const nextProductPriceRange = useMemo(() => {
        return getNextProductPriceRange(product, itemCart?.quantity);
    }, [itemCart?.quantity, product]);

    const priceRangeLabel = useMemo(() => {
        if (nextProductPriceRange) {
            const price = toBRL(nextProductPriceRange.unit_price);
            return `A PARTIR DE ${nextProductPriceRange.min_quantity} ${product.measurement_unit} ${price}`;
        }
    }, [nextProductPriceRange]);

    const actualProductPrice = useMemo(() => {
        if (actualProductPriceRange && actualProductPriceRange.min_quantity <= (itemCart?.quantity || 0)) {
            return toBRL(actualProductPriceRange.unit_price);
        }
        return toBRL(product.sell_price);
    }, [actualProductPriceRange, product, itemCart])

    const actualProductOriginalPrice = useMemo(() => {
        if (actualProductPriceRange && !nextProductPriceRange) {
            // const discount = (actualProductPriceRange.unit_price / product.sell_price) * 100;
            return toBRL(product.sell_price);
        }
        if (!product.original_price) return
        return toBRL(product.original_price);
    }, [actualProductPriceRange, nextProductPriceRange, product]);

    return (
        <div
            className={
                selected
                    ? `${btnProductMob.btnItemSelected} ${btnProductMob.btnItemFaded} h-100 d-block`
                    : `${btnProductMob.btnItemFaded}  h-100 d-block`
            }>
            <div className={enabled ? 'main-filter' : 'gray-filter'}>
                <img
                    src={toAbsoluteUrl('/media/general/Basket-Icon-2.svg')}
                    alt='Cesta'
                    className={btnProductMob.cesta}
                />
                <span> UNIDADE</span>
            </div>
            {enabled && (<>
                <div className={btnProductMob.unitPricePosition}>
                    <span className={btnProductMob.priceMob}>{actualProductPrice}</span>
                </div>
                {priceRangeLabel &&
                    <div className='font08rem black-color'>
                        <span>{priceRangeLabel}</span>
                    </div>
                }
                {actualProductOriginalPrice && !priceRangeLabel &&
                    <div className='font08rem black-color'>
                        <span className={btnProductMob.originalPrice}>De {actualProductOriginalPrice}</span>
                    </div>
                }
            </>)}
            {!enabled && (
                <div className='faded-color mt-3'>
                    <span>Indisponível</span>
                </div>
            )}
        </div>
    )
}

export default BtnUnitMob
