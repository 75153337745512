import Image from "components/common/Image/Image";
import { StoreItem } from "models/StoreItem";
import { FC } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { toBRL, toBRLShort } from "utils/Functions";
import ProductCounter from "../ProductCounter/ProductCounter";
import styles from "./ModalCartItem.module.css";

type Props = {
  item: StoreItem;
  handleDeleteItem: (item: any) => void;
  handleProductAdd: (product: any) => void;
  handleProductRemove: (product: any) => void;
  handleProductChange: (product: any, quantity: number) => void;
};

const ModalCartItem: FC<Props> = ({
  item,
  handleProductRemove,
  handleProductChange,
  handleProductAdd,
  handleDeleteItem,
}) => {
  return (
    <div>
      {/* <button className={item.quantity > 99 ? styles.CartCounterLong : styles.CartCounter}>{item.quantity}</button> */}
      <div className={styles.product}>
        <div className="me-1">
          <Image
            src={item.product.thumbnail?.url}
            alt={item.product.thumbnail?.title}
            fallbackSrc="https://fakeimg.pl/80x100/cccccc/033858"
            className={styles.iconStyle}
          />
        </div>

        <div className={styles.productContent}>
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex flex-column mb-3">
              <div className={styles.productName}>{item.product.description}</div>
              <span className={styles.barcode}>{item.product.sku}</span>
            </div>
            <div className={styles.trashCan} onClick={() => handleDeleteItem(item.product)}>
              <IoTrashOutline style={{ fontSize: "1.5rem" }} />
            </div>
          </div>

          <div className="d-flex justify-content-between w-100">
            {item.unavailable && (
              <div>
                <span>Esse produto nao esta mais disponivel para compra</span>
              </div>
            )}
            {!item.unavailable && (
              <>
                <div className="d-flex">
                  <ProductCounter
                    loading={false}
                    product={item.product}
                    onAdd={handleProductAdd}
                    onRemove={handleProductRemove}
                    onChange={handleProductChange}
                    style={`styleCart`}
                  />
                  <div className="d-flex align-items-center ms-1"> x {toBRLShort(item.total / item.quantity)}/{item.product.measurement_unit}</div>
                </div>

                <div className="d-flex flex-column align-items-end justify-content-center">
                  <div className="font500">{toBRL(item.total)}</div>
                </div>
              </>
            )}
          </div>

          {item.product?.quantity_per_package && Math.floor(item.quantity / item.product.quantity_per_package) > 0 && (
            <div className={styles.boxBadge}>
              <span>
                {/* {`${Math.floor(item.quantity / item.product.quantity_per_package)} caixa${
item.quantity / item.product.quantity_per_package > 1 ? 's' : ''
}`} */}
                A caixa contém {item.product.quantity_per_package} {item.product.measurement_unit}s
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalCartItem;
