import { regionalClient } from "index"
import { Product } from "models/Product"
import { PageableResponse } from "modules/api/core/_models"
import { ProductEndpoints } from "modules/api/endpoints/ProductEndpoints"

export const detail = (productId : string) => {
    return regionalClient.get<Product>(ProductEndpoints.detail(productId))
}

export const relatedProducts = (productId : string, size: number = 10) => {
    return regionalClient.get<PageableResponse<Product>>(ProductEndpoints.listRelatedProducts(productId), {
            params: {
                per_page: size,
            }
    })
}