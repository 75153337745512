import SearchBar from 'components/mobile/SearchBar/SearchBar';
import { useStoreConfig } from 'layouts/common/core/StoreConfigProvider';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import Navbar from '../Navbar/Navbar';
import NotificationBar from '../NotificationBar/NotificationBar';
import Topbar from '../Topbar/Topbar';

const Container = styled.div`
    position: fixed;
    top: 0px;
    overflow: hidden;
    width: 100%;
    `;

const Header: React.FC = () => {
    const { storeConfig, updateHeaderConfig } = useStoreConfig()

    const mainRef = useRef<HTMLDivElement>(null)
    const notificationbarRef = useRef<HTMLDivElement>(null)
    const searchbarRef = useRef<HTMLDivElement>(null)
    const navbarRef = useRef<HTMLDivElement>(null)
    const topbarRef = useRef<HTMLDivElement>(null)

    const isTopbarVisible = useMemo(() => storeConfig.layout.topbar.visible, [storeConfig])

    useEffect(() => {
        if (!mainRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            updateHeaderConfig({
                topbarHeight: topbarRef.current?.clientHeight || 0,
                notificationbarHeight: notificationbarRef.current?.clientHeight || 0,
                searchbarHeight: searchbarRef.current?.clientHeight || 0,
                navbarHeight: (navbarRef.current?.clientHeight && navbarRef.current?.clientHeight - 1) || 0,
            })
        });
        resizeObserver.observe(mainRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, []);

    return (<div ref={mainRef}>
        <NotificationBar ref={notificationbarRef} />
        {isTopbarVisible ? <Topbar ref={topbarRef}/> : <Navbar ref={navbarRef} loading={true}/>}
        {!isTopbarVisible && <SearchBar placeholder='Do que você precisa?' ref={searchbarRef}/>}
    </div>);

}

export default Header;
