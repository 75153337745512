export type ChangePasswordRequest = {
    current_password: string
    new_password: string
    new_password_confirmation: string
}

export const initialChangePasswordRequest: ChangePasswordRequest = {
    current_password: '',
    new_password: '',
    new_password_confirmation: '',
}
