/* eslint-disable react-hooks/exhaustive-deps */
import BarcodeScannerModal from "layouts/mobile/BarcodeScanner/BarcodeScannerModal";
import { forwardRef, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "utils/Functions";
import { STORE_PRE_SEARCH, STORE_SEARCH_PAGE, toUrl } from "utils/Links";
import search from "./SearchBar.module.css";

type Props = {
  placeholder?: string;
};

function SearchBar({ placeholder }: Props, ref: any) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showBarcodeModal, setShowBarcodeModal] = useState<boolean>(false);

  const handleSearchbarFocus = useCallback(() => {
    const path = toUrl(STORE_PRE_SEARCH);
    if (pathname !== path) {
      navigate(path);
    }
  }, [pathname]);

  const openBarcodeScannerModal = () => {
    setShowBarcodeModal(true);
  };

  const handleBarcodeScannerModalClose = (code?: any) => {
    setShowBarcodeModal(false);
    if (!code) return;

    navigate(toUrl(STORE_SEARCH_PAGE, code));
  };

  return (
    <>
      <BarcodeScannerModal show={showBarcodeModal} handleClose={handleBarcodeScannerModalClose} />
      <div className={search.search} ref={ref}>
        <div className={`${search.formField} container d-flex`}>
          <div className={search.searchIconFaded}>
            {<img src={toAbsoluteUrl("/media/general/Magnifing-Glass-Icon.svg")} className="" alt="mg" />}
          </div>

          <input type="text" placeholder={placeholder} className={search.searchBar} onFocus={handleSearchbarFocus} />

          <div className={search.XIcon} onClick={openBarcodeScannerModal}>
            <img src={process.env.PUBLIC_URL + "/media/general/QR-Reader.svg"} alt="barcode" height={28} width={28} />
          </div>
        </div>
      </div>
    </>
  );
}
export default forwardRef(SearchBar);
