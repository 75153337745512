import { Logout } from "modules/auth";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { STORE_FRONT_PAGE, toUrl } from "utils/Links";

import AccountBillWrapper from "./AccountBill/AccountBillWrapper";
import AccountDonationWrapper from "./AccountDonation/AccountDonationWrapper";
import AccountOrderWrapper from "./AccountOrder/AccountOrderWrapper";
import TwoAuthWraper from "./AccountOutlets/AOAuthentication/TwoAuthWraper";
import AOCards from "./AccountOutlets/AOCards";
import AOContact from "./AccountOutlets/AOContact";
import AOCupons from "./AccountOutlets/AOCupons";
import AOData from "./AccountOutlets/AOData";
import AODiscounts from "./AccountOutlets/AODiscounts";
import AOEmail from "./AccountOutlets/AOEmail";
import AOFAQ from "./AccountOutlets/AOFAQ";
import AOInvoice from "./AccountOutlets/AOInvoice";
import AOPassword from "./AccountOutlets/AOPassword";
import AOPolicies from "./AccountOutlets/AOPolicies";
import AOReceiptDetails from "./AccountOutlets/AOReceiptDetails";
import AOReturns from "./AccountOutlets/AOReturns";
import AOSuggested from "./AccountOutlets/AOSuggested";
import AOTerms from "./AccountOutlets/AOTerms";
import AccountPage from "./AccountPage";
import AccountSuggestWishlistWrapper from "./AccountSuggestWishlist/AccountSuggestWishlistWrapper";
import AccountWishlistWrapper from "./AccountWishlist/AccountWishlistWrapper";

function AccountWrapper() {
  const navigate = useNavigate();
  const routes = useRoutes([
    {
      element: <AccountPage />,
      children: [
        {
          path: "pedido/*",
          element: <AccountOrderWrapper />,
        },
        {
          path: "doacao/*",
          element: <AccountDonationWrapper />,
        },
        {
          path: "pagamento/*",
          element: <AccountBillWrapper />,
        },
        {
          path: "lista-de-compras/*",
          element: <AccountWishlistWrapper />,
        },
        {
          path: "lista-de-compras-sugerida/*",
          element: <AccountSuggestWishlistWrapper />,
        },
        {
          path: "cupons",
          element: <AOCupons />,
        },
        {
          path: "perfil",
          element: <AOData />,
        },
        {
          path: "trocar-senha",
          element: <AOPassword />,
        },
        {
          path: "email",
          element: <AOEmail />,
        },
        // {
        //     path: 'endereco',
        //     element: <AOAddress />
        // },
        {
          path: "autenticacao-2-fatores/*",
          element: <TwoAuthWraper />,
        },

        {
          path: "nota/:id",
          element: <AOReceiptDetails />,
        },
        // {
        //   path: "nota-fiscal/:id",
        //   element: <AOInvoiceDetails />,
        // },
        {
          path: "fatura/:id",
          element: <AOInvoice />,
        },

        {
          path: "cartoes",
          element: <AOCards />,
        },


        {
          path: "lista-sugeridas/:slug",
          element: <AOSuggested />,
        },
        {
          path: "FAQ",
          element: <AOFAQ />,
        },
        {
          path: "termos-de-uso",
          element: <AOTerms />,
        },
        {
          path: "politica-de-privacidade",
          element: <AOPolicies />,
        },
        {
          path: "contato",
          element: <AOContact />,
        },

        {
          path: "sair",
          element: <Logout />,
        },
        {
          path: "trocas-e-devolucoes",
          element: <AOReturns />,
        },
        {
          path: "missoes",
          element: <AODiscounts />,
        },
      ],
    },

    {
      path: "*",
      element: <Navigate to={toUrl(STORE_FRONT_PAGE)} />,
    },
  ]);

  return routes;
}

export default AccountWrapper;
