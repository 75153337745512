import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'
import React, { useMemo } from 'react'
import {useNavigate} from 'react-router-dom'
import { ACCOUNT_ORDERS_PAGE, ACCOUNT_TERMS_PAGE, toUrl } from 'utils/Links'

function AOTerms() {
  const navigate = useNavigate()

  const breadcrumbs = useMemo(() => {
    return(
      [          
        {
          title : 'Perfil',
          path: toUrl(ACCOUNT_ORDERS_PAGE),
        },
        {    
          title : 'Termos de uso',
          path : toUrl(ACCOUNT_TERMS_PAGE),
        }
      ]
    )
  }, [])

  return (
    <div className='container'>
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs}/>
        <span className='font600 font15rem'>TERMOS DE USO</span>
      </div>
      <div>
        <p>Bem-vindo à Vupty!</p>
        <p>Estes termos de uso regem o acesso e uso dos serviços oferecidos por nossa loja virtual. Ao utilizar nossos serviços, você concorda com estes Termos e com nossa Política de Privacidade. Recomendamos a leitura cuidadosa destes documentos antes de utilizar nossos serviços.</p>
        <p className='d-flex flex-column'>
          <span className='font500 main-color'>1. Serviços Oferecidos:</span>
          A Vupty disponibiliza uma plataforma online para a realização de compras de produtos de supermercado, com entrega agendada no endereço fornecido pelo cliente.
        </p>
        <p className='d-flex flex-column'>
          <span className='font500 main-color'>2. Cadastro e Conta:</span>
          Para utilizar nossos serviços, é necessário criar uma conta. O usuário é responsável por manter suas informações de login seguras e atualizadas.
        </p>
        <p className='d-flex flex-column'>
          <span className='font500 main-color'>3. Prazos de Entrega:</span>
          Os prazos de entrega serão informados durante o processo de compra. Comprometemo-nos a envidar esforços para cumprir os prazos estabelecidos, mas eventuais atrasos podem ocorrer devido a circunstâncias fora do nosso controle.
        </p>
        <p className='d-flex flex-column'>
          <span className='font500 main-color'>4. Promoções e Descontos:</span>
          Promoções e descontos são oferecidos de acordo com critérios estabelecidos pela Vupty. As condições específicas de cada promoção serão informadas na plataforma. Reservamo-nos o direito de modificar ou encerrar promoções a qualquer momento, sem aviso prévio.
        </p>
        <p className='d-flex flex-column'>
          <span className='font500 main-color'>5. Validade dos Produtos:</span>
          Comprometemo-nos a fornecer produtos dentro de sua validade. Caso um produto entregue esteja próximo do vencimento, o cliente será informado previamente, e ofereceremos alternativas sempre que possível.
        </p>
        <p className='d-flex flex-column'>
          <span className='font500 main-color'>6. Cancelamento e Devoluções:</span>
          O cliente pode cancelar um pedido antes do processamento do mesmo. Em caso de insatisfação com um produto, entre em contato conosco para discutir opções de devolução ou reembolso.
        </p>
        <p className='d-flex flex-column'>
          <span className='font500 main-color'>7. Responsabilidades do Cliente:</span>
          O cliente é responsável por fornecer informações precisas e completas durante o processo de compra. Qualquer prejuízo decorrente de informações incorretas será de responsabilidade do cliente.
        </p>
        <p className='d-flex flex-column'>
          <span className='font500 main-color'>8. Direitos Autorais:</span>
          Todo o conteúdo presente na Vupty, incluindo textos, imagens e logotipos, é protegido por direitos autorais. O uso não autorizado do conteúdo é proibido.
        </p>
        <p className='d-flex flex-column'>
          <span className='font500 main-color'>9. Modificações nos Termos de Uso:</span>
          Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento. Alterações entrarão em vigor imediatamente após a publicação na plataforma.
        </p>
        <p className='d-flex flex-column'>
          <span className='font500 main-color'>10. Contato:</span>
          Para entrar em contato conosco ou relatar qualquer problema, utilize as informações disponíveis na seção de contato em nossa plataforma.
        </p>
        <p>Agradecemos por escolher a Vupty! Estamos comprometidos em oferecer a melhor experiência possível.</p>
      </div>
    </div>
  )
}

export default AOTerms
