import { EnumType } from "modules/api/core/_models";
import { Address } from "./Address";
import { Grocery } from "./Grocery";

export type PersonOfContact = {
    id?: string
    name: string
    sector?: string
    email?: string
    telephone_number?: string
    mobile_number?: string
    branch_line?: string
    active: boolean
    registration_date?: string
    modification_date?: string
    notes?: string
    address?: Address
    source_type?: EnumType
}

export type GroceryPersonOfContact = PersonOfContact & {
    grocery?: Grocery
    grocery_contact_type: EnumType
    is_main_contact? : boolean
}

export const initialGroceryMainContact : GroceryPersonOfContact = {
    name: '',
    telephone_number: '',
    email: '',
    active: true,
    grocery_contact_type: {
        value: '1',
        description: 'Dono'
    },
    is_main_contact: true,
}

export const initialGrocerySecundaryContact : GroceryPersonOfContact = {
    name: '',
    telephone_number: '',
    email: '',
    active: true,
    grocery_contact_type: {
        value: '4',
        description: 'Funcionário'
    },
    is_main_contact: false,
}
