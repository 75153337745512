import React, { useRef, useEffect } from 'react';
import JsBarcode from 'jsbarcode';

interface BarcodeGeneratorProps {
    value: string;
  }
  
  const BarcodeGenerator: React.FC<BarcodeGeneratorProps> = ({ value }) => {
    const barcodeRef = useRef<HTMLCanvasElement>(null);
  
    useEffect(() => {
      if (barcodeRef.current) {
        JsBarcode(barcodeRef.current, value, {
            height: 24,
            width: 1.5,
            displayValue: false,
        });
      }
    }, [value]);
  
    return <canvas ref={barcodeRef}></canvas>;
  };
  
  export default BarcodeGenerator;