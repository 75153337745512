import ErrorFooter from 'layouts/desktop/ErrorFooter/ErrorFooter'
import ErrorHeader from 'layouts/desktop/ErrorHeader/ErrorHeader'

function PrivacyPolicyPage() {
  return (
    <div>
      <ErrorHeader/>

      <div className='container mt-5'>
          <h6> Política de Privacidade da Vupty ltda.</h6>
          <span> Última atualização: <strong>22/01/2024</strong></span>
          <p style={{textIndent:'1rem'}}>Bem-vindo à Vupty! Agradecemos por escolher nossos serviços de delivery de produtos de mercado. Valorizamos a sua confiança e queremos garantir que você compreenda como tratamos suas informações pessoais. Esta Política de Privacidade descreve as práticas de privacidade da Vupty para o uso de nossos serviços, seja por meio de navegadores de desktop, smartphones ou aplicativos para Android e iOS.</p>

          <h4>Informação Coletadas</h4>
          <p style={{textIndent:'1rem'}}>Ao usar nossos serviços, podemos coletar as seguintes informações:</p>
          <ol>
              <li><strong>Informações de Conta: </strong>Ao se registrar em nossa plataforma, coletamos informações como nome, endereço de e-mail e informações de pagamento.</li>
              <li><strong>Informações de Entrega: </strong>Para fornecer nossos serviços de delivery, coletamos seu endereço de entrega.</li>
              <li><strong>Informações de Dispositivo: </strong>Podemos coletar informações sobre o dispositivo que você está usando, incluindo modelo, sistema operacional, identificadores exclusivos e informações de rede.</li>
              <li><strong>Localização: </strong>Se você permitir, podemos coletar informações de localização através do GPS do seu dispositivo para facilitar a entrega.</li>
              <li><strong>Wi-Fi: </strong>Podemos coletar informações sobre redes Wi-Fi disponíveis para melhorar a qualidade do serviço.</li>
              <li><strong>Lista de Contatos: </strong>Com sua permissão, podemos acessar a lista de contatos do seu dispositivo para facilitar o compartilhamento de informações com seus contatos.</li>
              <li><strong>Cookies e Tecnologias Semelhantes: </strong>Utilizamos cookies e tecnologias similares para melhorar a experiência do usuário, personalizar conteúdo e anúncios, bem como para análises.</li>    
          </ol>

          <h4>uso das informações</h4>
          <p style={{textIndent:'1rem'}}>As informações coletadas são utilizadas para os seguintes propósitos:</p>
          <ol>
              <li><strong>Fornecer e Melhorar Nossos Serviços: </strong>Utilizamos suas informações para processar pedidos, realizar entregas e melhorar nossos produtos e serviços.</li>
              <li><strong>Comunicação: </strong>Podemos enviar comunicações relacionadas ao serviço, como confirmações de pedidos, atualizações e informações importantes sobre sua conta.</li>
              <li><strong>Personalização: </strong>Utilizamos suas informações para personalizar sua experiência, fornecendo recomendações e ofertas relevantes.</li>
              <li><strong>Análises: </strong>Realizamos análises para entender melhor como nossos serviços são utilizados e como podemos aprimorá-los.</li>
          </ol>

          <h4>Compartilhamento de Informações</h4>
          <p style={{textIndent:'1rem'}}>Não vendemos suas informações pessoais. Podemos compartilhar suas informações com terceiros apenas nas seguintes circunstâncias:</p>
          <ol>
              <li><strong>Parceiros de Entrega: </strong>Compartilhamos informações relevantes com parceiros de entrega para garantir a entrega eficiente de seus pedidos.</li>
              <li><strong>Fornecedores de Serviços: </strong>Podemos compartilhar informações com provedores de serviços que nos auxiliam na prestação de nossos serviços.</li>
              <li><strong>Conformidade Legal: </strong>Reservamo-nos o direito de divulgar informações conforme exigido por lei ou quando acreditarmos que tal divulgação é necessária para proteger nossos direitos legais.</li>
          </ol>

          <h4>Segurança</h4>
          <p style={{textIndent:'1rem'}}>Implementamos medidas de segurança para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição não autorizada.</p>

          <h4>Seus Direitos</h4>
          <p style={{textIndent:'1rem'}}>Você tem o direito de acessar, corrigir ou excluir suas informações pessoais. Também pode optar por não receber comunicações promocionais.</p>

          <h4>Alterações nesta Política</h4>
          <p style={{textIndent:'1rem'}}>Esta Política de Privacidade pode ser atualizada ocasionalmente para refletir mudanças em nossas práticas. Recomendamos que reveja periodicamente esta política.</p>
      
          <h4>Contato</h4>
          <p style={{textIndent:'1rem'}}>Se tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco pelo e-mail <strong>contato@vupty.com.br</strong>.</p>
          <p>Obrigado por escolher a Vupty!</p>
      </div>

      <ErrorFooter/>
    </div>
  )
}

export default PrivacyPolicyPage