import React from 'react'
import AuthenticationSteps from 'components/mobile/AuthenticationSteps/AuthenticationSteps'
import {ACCOUNT_2_FACTOR, toUrl} from 'utils/Links'
import {useNavigate} from 'react-router-dom'
import PageTitle from 'components/common/PageTitle/PageTitle'
import codeStyle from './StepEnablePage.module.css'
import { toAbsoluteUrl } from 'utils/Functions'
import { toast } from 'react-toastify'

function StepCodePage() {
  const navigate = useNavigate()

  // NEEDS A VALUE AND THE IMPLEMENTATION TO ACTUALY COPY THE CODE
  const handleCopy = (stringToCopy : string) => {
    navigator.clipboard.writeText(stringToCopy)
      .then(() => {
        toast.success("Copiado", {
            position: 'top-right',
            autoClose: 2000,
            theme: 'light',
        });
      });
  }

  return (
    <>
      <PageTitle title={`AUTENTICAÇÃO DE DOIS FATORES`} />
      <AuthenticationSteps altStep={3} />
      <div className='container py-4 px-4 mb-4'>
        <h4>Passo a passo para finalizar a autenticação de dois fatores</h4>
        <ol type='1'>
          <li>
            Para completar a autenticação de dois fatores, você irá precisar primeiro fazer o
            download do aplicativo “Google Authenticator” no seu celular;
          </li>
          <li>
            Após realizar o download, abra o aplicativo e escaneie o QR Code a seguir (caso você
            esteja utilizando o seu próprio celular para realizar o acesso, insira manualmente o
            código a seguir no aplicativo Google Authenticator).
          </li>
        </ol>

        <div className='d-flex justify-content-center'>
          <div className='d-flex flex-column justify-content-center align-items-center my-3'>
            <img
              src='https://upload.wikimedia.org/wikipedia/commons/5/5e/QR_Code_example.png'
              alt='qr-code'
              style={{width: '96px'}}
            />
            <button className={`${codeStyle.qrCodeAuthentication} mt-3`} onClick={() => handleCopy('codyCode')}>
              Copiar código
              <img src={toAbsoluteUrl('/media/general/Copy-Icon.svg')} alt='' className='transform-1px main-filter ms-1'/>
            </button>
          </div>
        </div>

        <div className={`${codeStyle.authenticationAlert} my-4 px-3`}>
          <img
            src={process.env.PUBLIC_URL + '/media/general/Alert-Icon.svg'}
            alt='alert'
            className={codeStyle.stepAlertIcon}
          />
          <span className='p-2'>
            Nós recomendamos que, se possível e para maior segurança da sua conta, você anote o
            código disponibilizado para o seu acesso no Google Authenticator em algum lugar seguro e
            de fácil acesso, ou até mesmo que você imprima essa combinação; Outra ação recomendada é
            que junto ao código, você também preserve o QR Code (para fazer isso, basta tirar uma
            captura da tela).
          </span>
        </div>
        <button
          className={`${codeStyle.authenticationButton} col-12 mb-5`}
          onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 4))}
        >
          Próxima etapa{' '}
          <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Arrow-Right.svg')} alt='' className='transform-1px'/>
        </button>
      </div>
    </>
  )
}

export default StepCodePage
