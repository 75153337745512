import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from 'utils/Functions';
import { STORE_FRONT_PAGE } from 'utils/Links';
import styles from '../AccountBillPage.module.css';

const AccountBillTablePlaceholder: React.FC = () => {
  const navigate = useNavigate();

  return <div className="d-flex justify-content-center">
  <div className="d-flex flex-column align-items-center">
    <img
      src={toAbsoluteUrl("/media/Illustrations/Clipboard-Illustration.svg")}
      alt="rights reserved by Freepik Company"
    />

    <span className="pb-3 font500 font11rem mt-4">Nenhum pagamento por enquanto...</span>
    <span className="pb-4 font12rem">
      Quando existir um pagamento, ele será exibido aqui.
    </span>

    <div
      className={styles.startBuyingButton}
      onClick={() => navigate(STORE_FRONT_PAGE)}
    >
      Começar a comprar
    </div>
  </div>
</div>;
}

export default AccountBillTablePlaceholder;
