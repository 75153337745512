import { Capacitor } from "@capacitor/core";
import MenuIcon from "components/common/MenuIcon/MenuIcon";
import PageTitle from "components/common/PageTitle/PageTitle";
import { useMemo } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "utils/Functions";
import {
  ACCOUNT_CONTACT,
  ACCOUNT_DONATIONS_PAGE,
  ACCOUNT_FAQ_PAGE,
  ACCOUNT_NOTIFICATIONS_PAGE,
  ACCOUNT_ORDERS_PAGE,
  ACCOUNT_PAYMENTS_PAGE,
  ACCOUNT_PRIVACY_POLICY_PAGE,
  ACCOUNT_PROFILE_PAGE,
  ACCOUNT_PROFILE_PASSWORD_PAGE,
  ACCOUNT_SUGGESTED_WHISHLIST_PAGE,
  ACCOUNT_TERMS_PAGE,
  ACCOUNT_WHISHLIST_PAGE,
  FEEDBACK_PAGE,
  LOGOUT_PAGE,
  MISSIONS_PAGE,
  toUrl,
} from "utils/Links";
import menuPage from "./MenuPage.module.css";

function MenuPage() {
  const navigate = useNavigate();
  const isNative = useMemo(() => Capacitor.isNativePlatform(), []);
  return (
    <>
      <PageTitle title={`MINHA ÁREA`} />
      <div className="container">
        <div className={`mt-2 ${menuPage.profileClasses}`}>
          <h6>Perfil</h6>

          {/* DADOS */}
          <div
            className={`${menuPage.profileOptions}`}
            onClick={() => {
              navigate(toUrl(ACCOUNT_PROFILE_PAGE));
            }}
          >
            <div className={`d-flex flex-row align-items-center py-2`}>
              <MenuIcon hasNotification={true} path={toAbsoluteUrl("/media/menuPage/Menu-Badge.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Meus dados</p>
                <span>Mantenha suas informações atualizadas</span>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>

          {/* PEDIDOS   */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_ORDERS_PAGE));
            }}
          >
            <div className={`${menuPage.smallIcons} d-flex flex-row align-items-center py-2`}>
              <MenuIcon hasNotification={false} path={toAbsoluteUrl("/media/menuPage/Menu-Orders.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Pedidos</p>
                <span>Todos os seus pedidos estão aqui</span>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>

          {/* PAGAMENTOS  */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_PAYMENTS_PAGE));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <MenuIcon hasNotification={false} path={toAbsoluteUrl("/media/menuPage/Menu-Wallet.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Pagamentos</p>
                <span>Efetue e acompanhe os status dos seus Pagamentos</span>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>

          {/* CARTOES */}
          {/* <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_CARDS_PAGE))
            }}>
            <div className='d-flex flex-row align-items-center py-2'>
              <MenuIcon
                hasNotification={false}
                path={process.env.PUBLIC_URL + '/media/menuPage/Menu-Cards.svg'}
              />
              <div className={menuPage.profileCategory}>
                <p>Meus Cartões</p>
                <span>Edite os cartões registrados</span>
              </div>
            </div>
            <MdArrowForwardIos />


                    {/* LISTAS */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_WHISHLIST_PAGE));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <MenuIcon hasNotification={false} path={toAbsoluteUrl("/media/menuPage/Menu-Clipboard.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Minhas Listas</p>
                <span>Confira todas as listas que você criou</span>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>

          {/* LISTAS SUGERIDAS */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_SUGGESTED_WHISHLIST_PAGE));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <MenuIcon hasNotification={false} path={toAbsoluteUrl("/media/general/Bill-Icon.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Listas Sugeridas</p>
                <span>Listas pré-selecionadas para você</span>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>

          {/* MISSOES */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(MISSIONS_PAGE));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <MenuIcon hasNotification={false} path={toAbsoluteUrl("/media/menuPage/Prize-Icon.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Missões e descontos</p>
                <span>Ganhe descontos através de missões especiais</span>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>

          {/* NOTIFICAÇÕES */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_NOTIFICATIONS_PAGE));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <MenuIcon hasNotification={false} path={toAbsoluteUrl("/media/menuPage/Menu-Bell.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Notificações</p>
                <span>Acompanhe suas notificações aqui</span>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>

          {/* DOAÇÕES */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_DONATIONS_PAGE));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <MenuIcon hasNotification={true} path={toAbsoluteUrl("/media/menuPage/Menu-Heart.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Doações</p>
                <span>Faça uma doação</span>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>
        </div>

        <div className={`mt-4 ${menuPage.profileClasses}`}>
          <h6>Segurança</h6>

          {/* SENHAS   */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_PROFILE_PASSWORD_PAGE));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <MenuIcon hasNotification={true} path={toAbsoluteUrl("/media/menuPage/Menu-Padlock.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Senhas</p>
                <span>Gerenciar ou alterar senha</span>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>

          {/* ONLY ON MOBILE APP */}
          {/* BIOMETRIA   */}
          {/* <div className={menuPage.profileOptions}
          onClick={() => {}}>
            <div className='d-flex flex-row align-items-center py-2'>
              <MenuIcon
                hasNotification={false}
                path={process.env.PUBLIC_URL + '/media/menuPage/Menu-Fingerprint.svg'}/>
              <div className={menuPage.profileCategory}>
                <p>Autenticação com biometria</p>
                <span>Ative a proteção com biometria</span>
              </div>
            </div>
            <MdArrowForwardIos />
          </div> */}

          {/* TWOFACTOR   */}
          {/* <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_2_FACTOR, 1));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <MenuIcon hasNotification={true} path={toAbsoluteUrl("/media/menuPage/Menu-Shield.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Autenticação de dois fatores</p>
                <span>Habilitar ou desabilitar a autenticação de dois fatores</span>
              </div>
            </div>
            <MdArrowForwardIos />
          </div> */}
        </div>

        <div className={`mt-4 ${menuPage.profileClasses}`}>
          <h6>Atendimento</h6>

          {/* SUPORTE   */}
          {/* <div
                        className={menuPage.profileOptions}
                        onClick={() => {
                            navigate(toUrl(TICKET_PAGE));
                        }}>
                        <div className="d-flex flex-row align-items-center py-2">
                            <MenuIcon
                                hasNotification={true}
                                path={toAbsoluteUrl('/media/menuPage/Menu-Speach.svg')}
                            />
                            <div className={menuPage.profileCategory}>
                                <p>Suporte Vupty</p>
                            </div>
                        </div>
                        <MdArrowForwardIos />
                    </div> */}

          {/* FALE CONOSCO   */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_CONTACT));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <MenuIcon hasNotification={true} path={toAbsoluteUrl("/media/menuPage/Menu-Thumb.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Fale conosco!</p>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>

          {/* PERGUNTAS FREQUENTES   */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_FAQ_PAGE));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <MenuIcon hasNotification={false} path={toAbsoluteUrl("/media/menuPage/Menu-Talk.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Perguntas frequentes</p>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>

          {/* COMO FUNCIONA */}
          {!isNative && (
            <a
              href="https://vupty.com.br/como-funciona/"
              target="_blank"
              rel="noreferrer"
              className={menuPage.profileOptions}
            >
              <div className="d-flex flex-row align-items-center py-2">
                <MenuIcon hasNotification={false} path={toAbsoluteUrl("/media/menuPage/Menu-Doubt.svg")} />
                <div className={menuPage.profileCategory}>
                  <p>Como funciona a Vupty?</p>
                </div>
              </div>
              <MdArrowForwardIos />
            </a>
          )}

          {/* FEEDBACK   */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(FEEDBACK_PAGE));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <MenuIcon hasNotification={false} path={toAbsoluteUrl("/media/menuPage/Menu-Thumb.svg")} />
              <div className={menuPage.profileCategory}>
                <p>Envie-nos seu feedback</p>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>
        </div>

        {/* SOBRE */}
        <div className={`mt-4 mb-3`}>
          <h6 className="main-color">Sobre o aplicativo</h6>

          {/* TERMOS DE USO */}
          <div
            className={menuPage.profileOptions}
            onClick={() => {
              navigate(toUrl(ACCOUNT_TERMS_PAGE));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <div className={menuPage.profileCategory}>
                <p>Termos de uso</p>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>

          {/* PRIVACIDADE */}
          <div
            className={`${menuPage.profileOptions}`}
            onClick={() => {
              navigate(toUrl(ACCOUNT_PRIVACY_POLICY_PAGE));
            }}
          >
            <div className="d-flex flex-row align-items-center py-2">
              <div className={menuPage.profileCategory}>
                <p>Privacidade</p>
              </div>
            </div>
            <MdArrowForwardIos />
          </div>
        </div>

        {/* SAIR */}
        <div className={`mt-4 `}>
          <Link to={toUrl(LOGOUT_PAGE)} className="d-flex">
            <img src={toAbsoluteUrl("/media/general/Exit-Icon.svg")} alt="profile" className="me-4" />
            <span className={menuPage.exit}>Sair da conta</span>
          </Link>
        </div>

        {/* VERSÃO */}
        <div className="d-flex justify-content-center align-items-center w-100 mt-5 padding-bottom-mobile">
              <p className="fw-bolder">Versão {process.env.REACT_APP_VERSION}</p>
          </div>
      </div>
    </>
  );
}

export default MenuPage;
