import React from 'react'
import ContentLoader from 'react-content-loader'


function ThumbnailSkeleton() {
  return (
    <ContentLoader
    speed={2}
    width={96}
    height={96}
    viewBox="0 0 96 96"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb">

    <rect x="0" y="0" rx="8" ry="8" width="96" height="96" />

    </ContentLoader>
  )
}

export default ThumbnailSkeleton
