import React from 'react'
import ContentLoader from 'react-content-loader'

function ProductCounterSkeleton() {
  return (
    <ContentLoader 
        speed={2}
        width={152}
        height={31}
        viewBox="0 0 152 31"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb">

        <rect x="0" y="0" rx="8" ry="0" width="152" height="31" /> 
        <rect x="409" y="85" rx="0" ry="0" width="0" height="1" />
    </ContentLoader>
  )
}

export default ProductCounterSkeleton