export interface RatingButtons {
    rates: Rate[]
    satisfactions: Satisfaction[]
}

export interface Rate {
    value: number,
}

export interface Satisfaction {
    value: number,
    title: string,
    emoji: string,
}

const ratingButtons: RatingButtons = {
    rates: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
    ],

    satisfactions: [
        {
            value: 1,
            title: 'Ruim',
            emoji: '/media/emojis/Terrible.svg',
        },
        {
            value: 3,
            title: 'Razoável',
            emoji: '/media/emojis/Ok.svg',
        },
        {
            value: 4,
            title: 'Boa',
            emoji: '/media/emojis/Good.svg',
        },
    ],
} 

export default ratingButtons