/* eslint-disable react-hooks/exhaustive-deps */
import { StoreCart, initialStoreCart } from 'models/StoreCart'
import moment from 'moment'
import { useCallback } from 'react'
import { calculateCartTotals } from 'utils/Functions'
import { useStoreCart } from './StoreCartProvider'

const useRemoteCartHelper = () => {

    const {cart, saveCart} = useStoreCart()

    const isCartItemsEqual = useCallback((newer: StoreCart, older: StoreCart) => {

        if (newer.items.length !== older.items.length) return false

        //if (newer.total !== older.total) return false

        for (let i = 0; i < newer.items.length; i++) {

            const idxExists = older.items.findIndex((item) => item.product.id === newer.items[i].product.id)

            if (idxExists === -1) return false

            if (older.items[idxExists].quantity !== newer.items[i].quantity)
                return false
            if (older.items[idxExists].total !== newer.items[i].total) {
                return false
            }
        }
        return true
    }, [])

    const saveStoreCart = (oldCart: StoreCart, updatedCart: StoreCart) => {
        if (!oldCart) return

        if (!updatedCart) {
            saveCart(initialStoreCart)
            return
        }

        saveCart(updatedCart)
    }

    const checkOldItems = (oldCart: StoreCart, updatedCart: StoreCart) => {
        updatedCart.items.forEach((item) => {
            const itemModificationDate = moment(item.modification_date)
            const productModificationDate = moment(item.product.modification_date)

            if (productModificationDate.isAfter(itemModificationDate)) {
                const idx = oldCart.items.findIndex((oldItem) => oldItem.product.id === item.product.id)
                if (idx !== -1) {
                    console.log('product updated')
                }
            }
        })
    }

    const mergeCart = useCallback((remoteStoreCart: StoreCart) : StoreCart | null => {

        const modificationDate = moment(remoteStoreCart.modification_date)

        if (cart.items.length === 0
            || modificationDate.isAfter(cart.modification_date)
            || isCartItemsEqual(remoteStoreCart, cart)) {

            saveStoreCart(cart, {...remoteStoreCart, sync : true})
            return null
        }

        const updatedItems = mergeCartItems(remoteStoreCart, cart)
        const cartTotals = calculateCartTotals(updatedItems, cart.total_delivery_fee)

        const updatedCart : StoreCart = {
            ...cart,
            ...cartTotals,
            items: updatedItems,
            grocery_id: remoteStoreCart.grocery_id,
            form_of_payments: remoteStoreCart.form_of_payments,
            missions: remoteStoreCart.missions,
            estimated_delivery_date: remoteStoreCart.estimated_delivery_date,
            modification_date: remoteStoreCart.modification_date,
            sync: false
        }
        saveStoreCart(cart, updatedCart)

        return updatedCart
    }, [cart, saveCart])

    const mergeCartItems = useCallback((remoteCart: StoreCart, localCart: StoreCart) => {
        const localItems = [...localCart.items]
        const remoteItems = [...remoteCart.items]

        for (let i = 0; i < localItems.length; i++) {
            const idxExists = remoteItems.findIndex((item) => item.product.id === localItems[i].product.id)
            if (idxExists === -1) {
                remoteItems.push(localItems[i])
            } else if (remoteItems[idxExists].quantity !== localItems[i].quantity) {
                remoteItems[idxExists] = localItems[i]
            }
        }

        return remoteItems
    }, [])

    return { mergeCart, saveStoreCart }

}

export default useRemoteCartHelper
