import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// import {toBRL} from 'utils/Functions.ts'
import { ColumnDef } from "@tanstack/table-core";
import NewDatatable from "components/common/NewDatatable/NewDatatable";
import BasePagination from "components/common/NewDatatable/components/BasePagination";
import BaseTable from "components/common/NewDatatable/components/BaseTable";
import Breadcrumbs from "components/common/breadcrumbs/Breadcrumbs";
import { FilterOption } from "models/FilterOption";
import { SalesOrder } from "models/SalesOrder";
import { OrderEndpoints } from "modules/api/endpoints/OrderEndpoints";
import moment from "moment";
import { DATE_USUAL } from "utils/Consts";
import { ACCOUNT_ORDERS_PAGE, INVOICE_PAGE, toUrl } from "utils/Links";
// ACCOUNT_ORDER_DETAILS_PAGE
import { toBRL } from "utils/Functions";
import DateSelectionTableFilter from "../AccountBill/component/DateSelectionTableFilter";
import orderStyle from "./AccountOrderPage.module.css";
import AccountOrderTablePlaceholder from "./component/AccountOrderTablePlaceholder";
import AccountOrderTableSubRow from "./component/AccountOrderTableSubRow";

const breadcrumbs = [
  {
    title: "Perfil",
    path: toUrl(ACCOUNT_ORDERS_PAGE),
  },
  {
    title: "Pedidos",
    path: toUrl(ACCOUNT_ORDERS_PAGE),
  },
];

function AccountOrderPage() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [activeOrder, setActiveOrder] = useState<SalesOrder>({} as SalesOrder);
  const [isModalSummaryOpen, setIsModalSummaryOpen] = useState(false);
  const [isModalReceiptOpen, setIsModalReceiptOpen] = useState(false);
  const [isModalTrackOpen, setIsModalTrackOpen] = useState(false);
  const [isModalInvoiceOpen, setIsModalInvoiceOpen] = useState(false);
  const [isFilterContentVisible, setIsFilterContentVisible] = useState(false);
  const [filterOption, setFilterOption] = useState<FilterOption>();

  const columns = useMemo<ColumnDef<SalesOrder, any>[]>(
    () => [
      {
        header: "Data",
        accessorKey: "registration_date",
        id: "registrationDate",
        cell: (cell) => moment(cell.getValue()).format(DATE_USUAL),
      },
      {
        header: "Número do pedido",
        id: "number",
        accessorKey: "number",
        cell: (cell) => cell.getValue(),
      },
      {
        header: "Situação",
        id: "status",
        accessorKey: "status",
        cell: (cell) => (
          <span className={`badgeStatus${cell.getValue().value} orderbadgeStatus`}>{cell.getValue().description}</span>
        ),
      },
      {
        header: "Total",
        accessorKey: "total_price",
        id: "totalPrice",
        cell: (cell) => toBRL(cell.getValue()),
      },
      {
        header: "Detalhes do pedido",
        id: "id",
        accessorKey: "id",
        sortable: false,
        cell: ({ row }) => (
          <>
            {row.getCanExpand() ? (
              <div
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" },
                }}
                className="text-underline"
              >
                ver detalhes
              </div>
            ) : (
              <div className="faded-color text-underline">ver detalhes</div>
            )}{" "}
          </>
        ),
      },
    ],
    []
  );

  const toggleFilterContentVisibility = () => {
    setIsFilterContentVisible(!isFilterContentVisible);
  };

  function handleDateFilterChange(filterOption: FilterOption) {
    setFilterOption(filterOption);
    setCurrentPage(0);
    setIsFilterContentVisible(false);
  }
  function showSummaryModal(order: SalesOrder) {
    setIsModalSummaryOpen(true);
    setActiveOrder(order);
  }
  function showTrackModal(order: any) {
    setIsModalTrackOpen(true);
    setActiveOrder(order);
  }
  function navigateToInvoice(order: SalesOrder) {
    navigate(toUrl(INVOICE_PAGE, order.id));
  }
  function showReceiptModal(order: SalesOrder) {
    setIsModalReceiptOpen(true);
    setActiveOrder(order);
  }
  function onDismiss() {
    setIsModalSummaryOpen(false);
    setIsModalTrackOpen(false);
    setIsModalInvoiceOpen(false);
    setIsModalReceiptOpen(false);
  }
  // MODAL CONTROL
  function handleDateSelectorChange(value: string) {
    console.log(value);
  }

  return (
    <div className="container">
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className="font600 font15rem">PEDIDOS</span>
      </div>
      <div>
        <DateSelectionTableFilter />
      </div>
      <div className={orderStyle.order}>
        <NewDatatable
          columns={columns}
          endpoint={OrderEndpoints.list}
          renderSubComponent={AccountOrderTableSubRow}
          getRowCanExpand={() => true}
          placeHolder={<AccountOrderTablePlaceholder />}
        >
          <div>
            <BaseTable />
          </div>
          <div>
            <BasePagination />
          </div>
        </NewDatatable>
      </div>

      {/* {activeOrder && <PaymentSummaryModal show={isModalSummaryOpen} onHide={onDismiss} activeOrder={activeOrder} />} */}

      {/* {activeOrder && (
            <InvoiceModal
            show={isModalInvoiceOpen}
            onHide={onDismiss}
            activeOrder={activeOrder}/>
          )} */}

      {/* {activeOrder && <ModalTracking show={isModalTrackOpen} onHide={onDismiss} activeOrder={activeOrder} />} */}

      {/* {activeOrder && (
            <BillModal
            show={isModalReceiptOpen}
            onHide={onDismiss}
            activeOrder={activeOrder}/>
          )} */}
    </div>
  );
}

export default AccountOrderPage;
