import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  ExpandedState,
  FilterFn,
  PaginationState,
  Row,
  RowModel,
  SortingState,
  Table,
  Updater,
  functionalUpdate,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { centralClient, regionalClient } from "index";
import qs from "qs";
import { FC, PropsWithChildren, createContext, useContext, useMemo, useState } from "react";
import { DataTableFilter } from "../Datatable/core/_models";
import BasePagination from "./components/BasePagination";
import BaseSearchFilter from "./components/BaseSearchFilter";
import BaseTable from "./components/BaseTable";
import { fuzzyFilter } from "./core/_utils";

export type SubComponentProps<T> = {
  row: Row<T>;
};

type Props = {
  data?: any;
  endpoint?: string;
  scope?: "regional" | "central";
  columns: ColumnDef<any, any>[];
  perPage?: number;
  direction?: "asc" | "desc";
  sort?: string;
  filter?: DataTableFilter[];
  renderSubComponent?: FC<SubComponentProps<any>>;
  getRowCanExpand?: (row: Row<any>) => boolean;
  placeHolder? : React.ReactElement
};

type NewDataTableContextProps = {
  table?: Table<any>;
  data?: any;
  endpoint?: string;
  scope?: "regional" | "central";
  columns: ColumnDef<any, any>[];
  searchTerm?: string;
  filter: DataTableFilter[];
  updateFilter: (newFilter: DataTableFilter[]) => void;
  renderSubComponent?: FC<SubComponentProps<any>>;
  placeHolder? : React.ReactElement
  isLoading: boolean;
};

const initialNewDataTableContextProps: NewDataTableContextProps = {
  columns: [],
  filter: [],
  updateFilter: () => {},
  isLoading: false,
};

const DataTableContext = createContext<NewDataTableContextProps>(initialNewDataTableContextProps);

const NewDatatable: FC<PropsWithChildren<Props>> = ({
  data: localData,
  columns,
  scope = "regional",
  endpoint,
  children,
  renderSubComponent,
  getRowCanExpand,
  placeHolder,
  ...props
}) => {
  const [data, setData] = useState(localData || []);
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: props.sort || "",
      desc: props.direction === "desc",
    },
  ]);
  const [currentPaginationValue, setCurrentPaginationValue] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: props.perPage || 10,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<DataTableFilter[]>(props.filter || []);
  const [pageCount, setPageCount] = useState(1);

  const [expanded, setExpanded] = useState<ExpandedState>({})

  const handleSortChange = (updaterFunction: Updater<SortingState>) => {
    const newValue = functionalUpdate(updaterFunction, sorting);
    setSorting(newValue);
  };

  const handlePaginationChange = (updaterFunction: Updater<PaginationState>) => {
    const newValue = functionalUpdate(updaterFunction, currentPaginationValue);
    setCurrentPaginationValue(newValue);
  };

  const globalFilterFn: FilterFn<any> = (row, columnId, value, addMeta) => {
    if (endpoint) return true;
    return fuzzyFilter(row, columnId, value, addMeta);
  };

  const handleGlobalFilterChange = (value: string) => {
    setSearchTerm(value);
  };

  const updateFilter = (newFilter: DataTableFilter[]) => {
    if (!endpoint) return

    setFilter(newFilter);
  }

  const teste = (table: Table<any>) => {
    return () => ({
      rows: [],
      flatRows: [],
      rowsById: {}
    } as RowModel<any>)
  }

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter: searchTerm,
      pagination: currentPaginationValue,
    },
    pageCount: pageCount,
    globalFilterFn: globalFilterFn,
    onSortingChange: handleSortChange,
    onPaginationChange: handlePaginationChange,
    onGlobalFilterChange: handleGlobalFilterChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
    // onExpandedChange: setExpanded,
    manualPagination: true,
    debugTable: true,
  });

  const currentClient = useMemo(() => (scope === "regional" ? regionalClient : centralClient), [scope]);

  const {isLoading} = useQuery({
    queryFn: async () => {
      const response = await currentClient.get(endpoint!!, {
        params: {
          page: table.getState().pagination.pageIndex,
          per_page: table.getState().pagination.pageSize,
          sort: table.getState().sorting[0]?.id || "",
          direction: table.getState().sorting[0]?.desc ? "desc" : "asc",
          search: searchTerm,
          filter: filter,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { allowDots: true });
        },
      });

      setData(response.data.content);
      setPageCount(response.data.total_pages);

      return response.data;
    },
    queryKey: [
      endpoint,
      scope,
      searchTerm,
      table.getState().pagination,
      table.getState().sorting,
      filter
    ],
    enabled: !!endpoint,
    placeholderData: keepPreviousData,
  });

  return (
    <DataTableContext.Provider value={{ table, data, columns, scope, searchTerm, filter, updateFilter, renderSubComponent, placeHolder, isLoading }}>
      {!children ? (
        <>
          <div className="row">
            <BaseSearchFilter />
          </div>
          <div className="row" style={{border:'1ps solid red'}}>
            <BaseTable />
          </div>
          <div className="row">
            <BasePagination />
          </div>
        </>
      ) : (
        children
      )}
    </DataTableContext.Provider>
  );
};

export const useNewDataTable = () => {
  return useContext(DataTableContext);
};

export default NewDatatable;
