import Image from "components/common/Image/Image";
import ProductWishlistCounter from "components/desktop/ProductCounter/ProductWishlistCounter";
import { Product } from "models/Product";
import { WishlistItem } from "models/Wishlist";
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getActualProductUnitPrice, toAbsoluteUrl, toBRL } from 'utils/Functions';
import { STORE_FRONT_PRODUCT_DETAIL_PAGE, toUrl } from 'utils/Links';
import styles from './ProductCardStoreCart.module.css';


type Props = {
    item: WishlistItem;
    onAddToCart: (item: WishlistItem) => void;
    onChange?: (item: WishlistItem) => void;
    onRemove?: (item: WishlistItem) => void;
};

const ProductCardWishlist: FC<Props> = ({ item, onChange, onAddToCart, onRemove }) => {

    const navigate = useNavigate();
    const { product, quantity } = item

    const handleAddProductToCart = () => {
        onAddToCart(item)
    }

    const handleRemoveProductFromCart = () => {
        onRemove?.(item)
    }

    const handleDecreaseProductQuantity = (product: Product) => {
        if (quantity - 1 === 0) {
            onRemove?.(item)
            return
        }
        onChange?.({
            ...item,
            quantity: quantity - 1
        })
    }

    const handleIncreaseProductQuantity = (product: Product) => {
        onChange?.({
            ...item,
            quantity: quantity + 1
        })
    }

    const handleProductChange = (product: Product, quantity: number) => {
        onChange?.({...item, quantity})
    }

    const bestUnitPrice = useMemo(() => {
        return getActualProductUnitPrice(item.product, item.quantity)
    }, [item])

    const totalPrice = useMemo(() => {
        return bestUnitPrice * quantity
    }, [bestUnitPrice, quantity])

    //   const handleProductRemoveAll = (product: Product) => {
    //     deleteItemFromCart(product)
    //   }

    const hasOnePackageOrMore = useMemo(() => {
        if (!product.quantity_per_package) return false
        return Math.floor(quantity / product.quantity_per_package) > 0
    }, [quantity, product])

    return (
        <div className='py-2'>
            <div className={styles.productCard}>
                <div className='d-flex align-items-center mx-2'>

                    <div className='m-2 ms-0'>
                        <Image
                            src={product.thumbnail?.url}
                            alt={product.thumbnail?.title}
                            className={styles.imgStyle}
                            height={80}
                            width={80}
                            placeholderColor="#ffffff"
                            fallbackSrc="https://fakeimg.pl/80x80/cccccc/033858"
                            onClick={() => navigate(toUrl(STORE_FRONT_PRODUCT_DETAIL_PAGE, product.id))}
                        />
                    </div>

                    <div className='w-100'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex flex-column'>
                                <div className={styles.productDescription}>
                                    <span>{product.description}</span>
                                </div>
                                {hasOnePackageOrMore && (
                                    <div className={styles.boxBadgeList}>
                                        <img src={toAbsoluteUrl('/media/general/Box-Icon-2.svg')} alt='list' className={styles.alertIcon} />
                                        <span>
                                            {`A caixa contém ${product.quantity_per_package} unidades`}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div onClick={handleAddProductToCart}>
                                <img src={toAbsoluteUrl('/media/general/Cart-Addition.svg')} alt='' />
                            </div>
                        </div>
                    </div>

                </div>

                <div className={styles.counterBarList}>
                    <div className='d-flex'>
                        <ProductWishlistCounter
                            product={product}
                            quantity={quantity}
                            onAdd={handleIncreaseProductQuantity}
                            onRemove={handleDecreaseProductQuantity}
                            onChange={handleProductChange}
                        />
                        <div className='d-flex align-items-center'>
                            <span className='mx-2'>X</span>
                            {toBRL(bestUnitPrice)}/un
                        </div>
                    </div>

                    <div className='d-flex flex-column align-items-end'>
                        <div className='font500'>{toBRL(totalPrice)}</div>
                    </div>

                    {onRemove &&
                        <div className={styles.trashCan} onClick={handleRemoveProductFromCart}>
                            <img src={toAbsoluteUrl('/media/general/Trash-Outline-Icon.svg')} alt='Trash' />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductCardWishlist
