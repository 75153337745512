import ContentLoader from 'react-content-loader'

function ProductCounterSkeletonVertical() {
  return (
    <ContentLoader 
        speed={2}
        width={40}
        height={152}
        viewBox="0 0 35 152"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb">

        <rect x="0" y="0" rx="8" ry="0" width="38" height="152" />
        <rect x="409" y="85" rx="0" ry="0" width="0" height="1" />
    </ContentLoader>
  )
}

export default ProductCounterSkeletonVertical