import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import DropdownSelect from "components/common/DropdownSelect/DropdownSelect";
import PageTitle from "components/common/PageTitle/PageTitle";
import TimeDeliveryCalculator from "components/common/TimeDeliveryCalculator/TimeDeliveryCalculator";
import CheckoutSteps from "components/mobile/CheckoutSteps/CheckoutSteps";
import CheckoutException from "exceptions/CheckoutException";
import { useGrocery } from "layouts/common/core/GroceryProvider";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import { CheckoutRequest, initialCheckoutRequest } from "models/CheckoutRequest";
import { CheckoutFailureReason, CheckoutResult } from "models/CheckoutResult";
import { StoreCart, initialStoreCart } from "models/StoreCart";
import { apiErrorToString } from "modules/api/core/ApiUtils";
import { EnumType } from "modules/api/core/_models";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocalStorage } from "usehooks-ts";
import { LOCAL_STORAGE_CART_OLD_KEY } from "utils/Consts";
import { toAbsoluteUrl, toBRL } from "utils/Functions";
import { STORE_FRONT_CART_PAGE, STORE_FRONT_CHECKOUT_FINISH_PAGE, toUrl } from "utils/Links";
import * as Yup from "yup";
import checkoutStyles from "./CheckoutPage.module.css";
import { processCheckout } from "./core/_requests";

const donationOptions: number[] = [5, 10, 15, 20];
const invoiceTypeOptions: EnumType[] = [
  { description: "Não (Cupom Fiscal)", value: "2" },
  { description: "Sim (DANFE)", value: "1" },
];
const requestSchema = Yup.object().shape({
  address_id: Yup.string().required("O endereço de entrega é obrigatório"),
  form_of_payment_id: Yup.string().required("O método de pagamento é obrigatório"),
  order_invoice_type: Yup.object().required("O tipo de nota fiscal é obrigatório"),
});

function CheckoutPage() {
  const [oldCart, setOldCart] = useLocalStorage(LOCAL_STORAGE_CART_OLD_KEY, initialStoreCart);
  const {
    clearCart,
    saveCart,
    cart,
    cart: {
      total_delivery_fee,
      total,
      estimated_delivery_date,
      total_mission_discount,
      total_items_discount,
      items_total,
      form_of_payments,
      id,
      items,
    },
  } = useStoreCart();
  const { groceryDetails } = useGrocery();
  const navigate = useNavigate();
  const [checkoutRequest, setCheckoutRequest] = useState<CheckoutRequest>(initialCheckoutRequest);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorButtonAction, setErrorButtonAction] = useState<() => void>();
  const [isBypassDonationChecked, setBypassDonationChecked] = useState(true);
  const [isObservationActive, setIsObservationActive] = useState(false)

  useEffect(() => {
    if (form_of_payments && form_of_payments.length > 0) {
      setCheckoutRequest((prev) => {
        return {
          ...prev,
          payment_method: form_of_payments[0].payment_method!!,
          form_of_payment_id: form_of_payments[0].id,
        };
      });
    }
  }, [form_of_payments]);

  const paymentMethods = useMemo(() => {
    if (!form_of_payments || form_of_payments.length === 0) return [];
    return form_of_payments
      .filter(
        (formOfPayment, idx, self) =>
          idx === self.findIndex((f) => f.payment_method?.value === formOfPayment.payment_method?.value)
      )
      .map((formOfPayment) => formOfPayment.payment_method!!);
  }, [form_of_payments]);

  const addressFormatted = useMemo(() => {
    if (!groceryDetails || !groceryDetails.address) return "Nenhum endereço cadastrado";
    return `${groceryDetails.address.street}, ${groceryDetails.address.number} -
        ${groceryDetails.address.neighborhood}, ${groceryDetails.address.city}, ${groceryDetails.address.cep}`;
  }, [groceryDetails?.address]);

  const activePaymentMethodOptions = useMemo(() => {
    if (!form_of_payments) return [];
    return form_of_payments
      .filter(
        (formOfPayment) => formOfPayment.payment_method?.description === checkoutRequest.payment_method?.description
      )
      .map((formOfPayment) => ({
        label: formOfPayment.description!!,
        value: formOfPayment.id,
      }));
  }, [checkoutRequest.payment_method, form_of_payments]);

  const { mutate: sendCheckoutRequest, isPending: isCheckoutProcessPending } = useMutation({
    mutationFn: async (request: CheckoutRequest) => {
      const response = await processCheckout(request);
      return response;
    },
    mutationKey: ["checkout", checkoutRequest],
    onSuccess: ({ data: checkoutResult }: any) => {
      if (!checkoutResult) throw new CheckoutException("Erro ao finalizar compra");

      if (!checkoutResult.success) {
        handleCheckoutFailure(checkoutResult);
        return;
      }

      navigate(toUrl(STORE_FRONT_CHECKOUT_FINISH_PAGE, checkoutResult.sales_order_id), {
        replace: true,
        state: {
          doNotSync: true,
        },
      });

      clearCart();
    },
    onError: (err: any) => {
      setErrorMessage(apiErrorToString(err));
      setErrorButtonAction(() => () => {
        navigate(toUrl(STORE_FRONT_CART_PAGE), { replace: true });
      });
    },
  });

  const handleCheckoutFailure = useCallback(
    (checkoutResult: CheckoutResult) => {
      switch (checkoutResult.failure_reason) {
        case CheckoutFailureReason.MISSION_CHANGED:
        case CheckoutFailureReason.STORE_CART_CHANGED:
        case CheckoutFailureReason.FORMS_OF_PAYMENT_CHANGED:
          handleStoreCartChange(checkoutResult.store_cart, cart);
          break;
      }
    },
    [cart]
  );

  const handleStoreCartChange = (updatedCart: StoreCart, currentStoreCart: StoreCart) => {
    setErrorMessage("O carrinho foi alterado, por favor revise os produtos e tente novamente");
    setOldCart(currentStoreCart);
    saveCart(updatedCart);

    const action = () => {
      navigate(toUrl(STORE_FRONT_CART_PAGE), { replace: true });
    };
    setErrorButtonAction(() => action);
  };

  const handleFormOfPaymentChange = (paymentMethodId: string) => {
    setCheckoutRequest((prevState) => {
      return { ...prevState, form_of_payment_id: paymentMethodId };
    });
  };

  const handleActivePaymentMethodChange = (paymentMethod: EnumType) => {
    const formOfPayment = form_of_payments.find(
      (formOfPayment) => formOfPayment.payment_method?.description === paymentMethod.description
    );

    if (!formOfPayment) return;

    setCheckoutRequest((prevState) => {
      return { ...prevState, payment_method: paymentMethod, form_of_payment_id: formOfPayment.id };
    });
  };

  const handleDonationChange = (event: any) => {
    setCheckoutRequest((prevState) => {
      return { ...prevState, donation_value: Number(event.target.value) };
    });
  };

  const handleBypassDonationCheck = () => {

    if (isBypassDonationChecked) {
      setCheckoutRequest((prevState) => {
        return { ...prevState, donation_value: donationOptions[0] };
      });
    } else {
      setCheckoutRequest((prevState) => {
        return { ...prevState, donation_value: 0 };
      });
    }

    setBypassDonationChecked((prev) => !prev);
  };

  const handleInvoiceTypeChange = (event: any) => {
    const selectedInvoiceType = invoiceTypeOptions.find((option) => option.value === event.target.value);

    if (!selectedInvoiceType) return

    setCheckoutRequest((prevState) => {
      return {
        ...prevState,
        order_invoice_type: selectedInvoiceType,
      };
    });
  };

  const handleBackClickFromError = useCallback(() => {
    setErrorMessage(undefined);
    if (errorButtonAction) errorButtonAction();
  }, [errorButtonAction]);

  const numColumns = Math.min(paymentMethods.length, 4);

  const handleObservationChange = (e: any) => {
    setCheckoutRequest((prevState) => {
      return { ...prevState, observation: e.target.value };
    });
  };

  const handleSubmit = () => {
    const request: CheckoutRequest = {
      ...checkoutRequest,
      address_id: groceryDetails?.address?.id,
      store_cart: cart,
      observation: checkoutRequest.observation,
    };
    setCheckoutRequest(request);
    if (!requestSchema.isValidSync(request)) return;
    sendCheckoutRequest(request)
  };

  return (
    <div>
      <PageTitle title={`PAGAMENTO`} />
      <CheckoutSteps currentStep={2} />
      {loading && (
        <div className="container pb-2">
          {loadingMessage && (
            <div className="d-flex flex-column justify-content-center main-color font500 mb-2">
              <span>Finalizando sua compra...</span>
              <span>{loadingMessage}</span>
            </div>
          )}
          <ClipLoader color="#f0f0f0" size={18} />
        </div>
      )}

      {!loading && !errorMessage && (
        <div className="container pb-2">
          {/* SUMARY */}
          <div className={checkoutStyles.paymentFinalPrices}>
            <div className="d-flex flex-column p-2 py-3">
              <div className="d-flex justify-content-center main-color font500 mb-2">
                <span>RESUMO DO PEDIDO</span>
              </div>

              <div className={`${checkoutStyles.paymentFinalTotal} d-inline-flex justify-content-between px-2 mb-2`}>
                <div> PREÇO TOTAL </div>
                <div>{toBRL(total + checkoutRequest.donation_value)}</div>
              </div>

              <div className="d-inline-flex justify-content-between main-color font500 px-2 mb-2">
                <div> PRODUTOS </div>
                <div> {toBRL(items_total)} </div>
              </div>
              <div className="d-inline-flex justify-content-between px-2 mb-2">
                <div>
                  <div className={checkoutStyles.deliveryValues}> ENTREGA </div>
                  {estimated_delivery_date && <TimeDeliveryCalculator deliveryDate={estimated_delivery_date} />}
                </div>
                <div className="main-color font500">{toBRL(total_delivery_fee)}</div>
              </div>

              {(total_items_discount > 0 || total_mission_discount > 0) && (
                <div className="d-inline-flex justify-content-between px-2 mb-2">
                  <div>
                    <div className={checkoutStyles.deliveryValues}> DESCONTOS </div>
                  </div>
                  <div className="main-color font500"> -{toBRL(total_items_discount + total_mission_discount)}</div>
                </div>
              )}

              {checkoutRequest.donation_value > 0 && (
                <div className="d-inline-flex justify-content-between main-color font500 px-2">
                  <div> DOAÇÃO </div>
                  <div> {toBRL(checkoutRequest.donation_value)} </div>
                </div>
              )}
            </div>
          </div>

          {/* DELIVERY ADDRESS */}
          <div className={`${checkoutStyles.deliveryAddress} px-3 py-4`}>
            <div className="d-flex flex-column">
              <span className="mb-1 font600">ENDEREÇO DE ENTREGA</span>
              <span className="mb-2 font500">{addressFormatted}</span>
            </div>
            <span>
              <strong>Atenção:</strong> pedimos que no momento do recebimento das compras, uma pessoa responsável esteja
              disponível para efetuar o pagamento e receber o pedido.
            </span>
          </div>

          {/* OBSERVATIONS */}
          <div className="d-flex justify-content-center mb-4">
            {!isObservationActive && 
              <button className='button btn-main' onClick={() => setIsObservationActive(!isObservationActive)}>
                Adicionar observação
              </button>
            }
            {isObservationActive &&
              <textarea
                value={checkoutRequest.observation}
                onChange={handleObservationChange}
                className={checkoutStyles.observations}
              />
            }
          </div>      

          {/* DELIVERY DATE */}
          {estimated_delivery_date && (
            <div className={checkoutStyles.deliveryExtimation}>
              <div>
                <img src={toAbsoluteUrl("media/general/Closed-Box.svg")} alt="" className="pe-2" />
                <span>PRAZO ESTIMADO DA ENTREGA</span>
              </div>
              <span>{<TimeDeliveryCalculator deliveryDate={estimated_delivery_date} />}</span>
            </div>
          )}
          {/* PAYMENT */}
          <div className={`${checkoutStyles.paymentMethods}`}>
            <span className="font600 font">MÉTODO DE PAGAMENTO</span>
            {paymentMethods.length === 0 && (
              <span className="font09rem font500">
                Nenhum método de pagamento disponível, entre em contato com nosso suporte
              </span>
            )}
            {paymentMethods.length > 0 && (
              <>
                <span className="font09rem font500">Escolha um dos métodos de pagamento abaixo para continuar:</span>
                <div style={{ gridTemplateColumns: `repeat(auto-fill, minmax(auto, ${100 / numColumns}%))` , display: 'grid'}}>
                  {paymentMethods.map((paymentMethod, idx) => (
                    <div
                      key={idx}
                      onClick={() => handleActivePaymentMethodChange(paymentMethod)}
                      className={clsx(
                        checkoutStyles.tabs,
                        checkoutRequest.payment_method?.value === paymentMethod.value && checkoutStyles.activeTab
                      )}
                    >
                      <div>
                        <img src={toAbsoluteUrl(`/media/Payments/${paymentMethod.value}.svg`)} alt="" height={'26px'} className="my-1"/>
                      </div>
                      <div className={checkoutStyles.paymentDescription}>
                        <span className="px-2">{paymentMethod.description}</span>
                      </div>
                    </div>
                  ))}
                </div>
                {/* METHODS OF PAYMENT CONTENT */}
                {checkoutRequest.payment_method && (
                  <div className={checkoutStyles.contentTabs}>
                    {/* BOLETO */}
                    <div className="d-block">
                      <p className="font09rem">
                        Após realizar o pagamento dentro do prazo de vencimento, você poderá receber a sua encomenda em
                        até 2 dias úteis, após a confirmação do pagamento.
                      </p>
                      {/* PLACEHOLDER VALUES */}
                      {activePaymentMethodOptions.length > 0 && (
                        <DropdownSelect options={activePaymentMethodOptions} onChange={handleFormOfPaymentChange} />
                      )}
                    </div>
                  </div>
                )}

                {/* INVOICE OPTIONS   */}
                <div>
                  <div className="d-flex justify-content-left py-3">
                    <img
                      src={toAbsoluteUrl("media/general/Alert-Icon.svg")}
                      alt=""
                      className={checkoutStyles.warningIcon}
                    />
                    <span className="font500">Deseja incluir seu CNPJ na Nota Fiscal?</span>
                  </div>

                  <div className="font500">
                    <div className={checkoutStyles.invoiceButton}>
                      {invoiceTypeOptions.map((invoice, idx) => (
                        <div
                          key={idx}
                          className={`${checkoutStyles.donationBtn} d-flex align-items-center justify-content-center main-color w-100`}
                        >
                          <span className="d-flex">
                            <input
                              id={`invoice-${idx}`}
                              className={`${checkoutStyles.formCheckInput} transform-1px me-1`}
                              type="radio"
                              name="invoice"
                              value={invoice.value}
                              checked={invoice.value === checkoutRequest.order_invoice_type.value}
                              onChange={handleInvoiceTypeChange}
                            />
                          </span>
                          <label htmlFor={`invoice-${idx}`}>{invoice.description}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* DONATION */}
          <div className={`${checkoutStyles.addressContainer}`}>
            <div className={`${checkoutStyles.paymentDonation}`}>
              <div>
                <div className={`${checkoutStyles.paymentBottomLine} d-flex justify-content-between px-3`}>
                  <label>REALIZAR UMA DOAÇÃO</label>
                </div>

                <div className={checkoutStyles.paymentDonationRadios}>
                  <div className="d-flex">
                    {donationOptions.map((donation, idx) => (
                      <div
                        key={idx}
                        className={clsx(
                          "d-flex align-items-center justify-content-center col",
                          checkoutStyles.donationBtn,
                          isBypassDonationChecked && checkoutStyles.donationBtnDisabled
                        )}
                      >
                        <input
                          id={`donation-radio-${idx}`}
                          className={checkoutStyles.formCheckInput}
                          type="radio"
                          name="donation"
                          value={donation}
                          checked={donation === checkoutRequest.donation_value}
                          disabled={isBypassDonationChecked}
                          onChange={handleDonationChange}
                        />
                        <label htmlFor={`donation-radio-${idx}`}>{toBRL(donation)}</label>
                      </div>
                    ))}
                  </div>

                  <div>
                    <input
                      id="donation-bypass"
                      type="checkbox"
                      className={checkoutStyles.noDonation}
                      checked={isBypassDonationChecked}
                      onChange={handleBypassDonationCheck}
                    />
                    <label htmlFor="donation-bypass">Não desejo doar desta vez</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div onClick={handleSubmit} className={clsx(checkoutStyles.submitBtn)}>
            Finalizar compra
            <img src={toAbsoluteUrl("/media/ChevronsAndArrows/Arrow-Right.svg")} alt="chevron" className="ps-2" />
          </div>
        </div>
      )}

      {!loading && errorMessage && (
        <>
          <div className="container d-flex flex-column justify-content-center main-color pb-2">
            <div className="container d-flex flex-column align-items-center mb-2">
              <img src={toAbsoluteUrl("/media/icons/Red-Alert.svg")} alt="" />
              <p className="font500 font12rem mt-2">Não conseguimos finalizar sua compra</p>
              <p>
                Encontramos um erro e infelizmente não concluímos a compra, mas não se preocupe, seu carrinho está a
                salvo!
              </p>
              <div className="d-flex flex-column font12rem font500">
                Caso o erro persista, entre em contato com nosso suporte:
                <a
                  href={"https://wa.me/5583987458956?text=Hello+World"}
                  target="_blank"
                  rel="noreferrer"
                  className={checkoutStyles.supportButton}
                >
                  <div className="text-center">
                    <img
                      src={toAbsoluteUrl("/media/general/Whatsapp-Icon.svg")}
                      alt=""
                      className={checkoutStyles.whatsappIcon}
                    />
                    <span className="ms-1">(83) 999-999-999</span>
                  </div>
                </a>
              </div>
              <span>Clique no botão abaixo para voltar a loja e tentar novamente.</span>
            </div>
            <button type="button" 
            // onClick={handleBackClickFromError}
            // TESTE
            onClick={() => navigate(toUrl(STORE_FRONT_CHECKOUT_FINISH_PAGE, groceryDetails?.id))}
            className={checkoutStyles.backButton}>
              Voltar para loja
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default CheckoutPage;
