import { useQuery } from '@tanstack/react-query'
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'
import FAQConstructor from 'components/desktop/FAQConstructor/FAQConstructor'
import { FaqGroup } from 'models/FaqGroup'
import { useMemo, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { toAbsoluteUrl, toNormalizeString } from 'utils/Functions'
import { ACCOUNT_FAQ_PAGE, ACCOUNT_ORDERS_PAGE, toUrl } from 'utils/Links'
import FAQ from '../../../../resources/data/FAQ.json'
import { list } from './core/_requests'
import Image from 'components/common/Image/Image'
import Loader from 'components/desktop/Loader/Loader'

function AOFAQ() {
  const enumeratedArray = Array.from({ length: FAQ.length }, (_, index) => index);
  const [selected, setSelected] = useState<number[]>(enumeratedArray);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [faq, setFaq] = useState<any[]>([...FAQ]);
  const normalizedSearchTerm = toNormalizeString(searchTerm);
  const [faqGroups, setFaqGroups] = useState<FaqGroup[]>([]);
  
  const { isLoading: isFaqGroupsLoading } = useQuery({
    queryFn: async () => {
        const response = await list(debouncedSearchTerm)
        setFaqGroups(response.data.content)
        return response.data
    },
    queryKey: ['listFaqGroups', debouncedSearchTerm],
  })

  const breadcrumbs = useMemo(() => {
    return(
      [          
        {
          title : 'Perfil',
          path: toUrl(ACCOUNT_ORDERS_PAGE),
        },
        {    
          title : 'Perguntas Frequentes',
          path : toUrl(ACCOUNT_FAQ_PAGE),
        }
      ]
    )
  }, [])

  return (
    <div className='container'>
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs}/>
        <span className='font600 font15rem'>PERGUNTAS FREQUENTES - F.A.Q.</span>
      </div>

      <div className='container padding-bottom-cart-mobile'>
          {(!faqGroups || faqGroups.length === 0) &&
            <div className='d-flex justify-content-center flex-column align-items-center mt-4'>
              <Image src={toAbsoluteUrl('/media/Illustrations/Clipboard-Illustration.svg')} alt='not-found'/>
              <span className='font500 mt-3'>
                  Nenhum resultado encontrado
              </span>
            </div>
          }
          {faqGroups && faqGroups.map((faqGroup, idx) => (
              <FAQConstructor key={idx} faqGroup={faqGroup} />
          ))}
      </div>

      {isFaqGroupsLoading &&
        <div className='container'>
          <Loader />
        </div>
      }      
    </div>
  )
}

export default AOFAQ
