import { useMutation } from '@tanstack/react-query'
import PageTitle from 'components/common/PageTitle/PageTitle'
import { useFormik } from 'formik'
import { ChangePasswordRequest, initialChangePasswordRequest } from 'models/ChangePasswordRequest'
import { ApiResponseError } from 'modules/api/core/_models'
import { useState } from 'react'
import ReactPasswordChecklist from 'react-password-checklist'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toAbsoluteUrl } from 'utils/Functions'
import passStyle from './ChangePasswordPage.module.css'
import { changePassword } from './core/_requests'
import PasswordInput from 'components/common/PasswordInput/PasswordInput'

const messages = {
    minLength: 'A senha deve ter no mínimo 8 caracteres.',
    specialChar: 'A senha tem um caractere especial.',
    number: 'A senha tem um número.',
    capital: 'A senha tem uma letra maiúscula.',
    match: 'A senha deve ser identica a anterior.',
}

function ChangePasswordPage() {
    const navigate = useNavigate()
    const [validPassword, setValidPassword] = useState(false)

    const { mutate: sendChangePasswordRequest } = useMutation({
        mutationFn: async (values: ChangePasswordRequest) => {
            const response = await changePassword(values)
            return response.data
        },
        mutationKey: ['changePassword'],
        onSuccess: () => {
            toast.success('Senha alterada com sucesso')
            navigate(-1)
        },
        onError: (error: any) => {
            if (error instanceof ApiResponseError) {
                toast.error(error.getErrorMessage())
            } else {
                toast.error('Erro ao alterar senha')
            }
        },
        onSettled: () => {
            formik.setSubmitting(false)
        }
    })

    const formik = useFormik<ChangePasswordRequest>({
        initialValues: initialChangePasswordRequest,
        onSubmit: (values) => {
            sendChangePasswordRequest(values)
        },
    })

    return (
        <>
            <PageTitle title={`MUDAR SENHA DE ACESSO`} />
            <div className='container padding-bottom-mobile'>
                <div className='text-center'>
                    <span className='font11rem font500'>Deseja trocar sua senha de acesso?</span>
                    <div className='my-3'>
                        <img src={toAbsoluteUrl('/media/warnings/Warning-Main.svg')} className='black-filter transform-1px me-1' alt='' />
                        <span className='font09rem'>As informações com * são de preenchimento obrigatório</span>
                    </div>

                </div>
                <form onSubmit={formik.handleSubmit} noValidate id='login_form' className='d-flex flex-column align-items-center w-100'>
                    <div className='w-100 mb-1'>
                        <label htmlFor='newPass'>Senha atual*</label>
                        <PasswordInput
                            className='form-control'
                            customStyle='mobile'
                            {...formik.getFieldProps('current_password')} />
                        {formik.touched.current_password && formik.errors.current_password && (
                            <span className='text-danger'>{formik.errors.current_password}</span>
                        )}
                    </div>
                    <div className='w-100 mb-1'>
                        <label htmlFor='newPass'>Nova senha*</label>
                        <PasswordInput
                            className='form-control'
                            customStyle='mobile'
                            {...formik.getFieldProps('new_password')} />
                        {formik.touched.new_password && formik.errors.new_password && (
                            <span className='text-danger'>{formik.errors.new_password}</span>
                        )}
                    </div>

                    <div className='w-100'>
                        <label htmlFor='newPass2'>Repetir nova senha*</label>
                        <PasswordInput
                            className='form-control'
                            customStyle='mobile'
                            {...formik.getFieldProps('new_password_confirmation')} />
                        {formik.touched.new_password_confirmation && formik.errors.new_password_confirmation && (
                            <span className='text-danger'>{formik.errors.new_password_confirmation}</span>
                        )}
                    </div>
                    <ReactPasswordChecklist
                        rules={['minLength', 'number', 'match']}
                        minLength={8}
                        value={formik.values.new_password}
                        valueAgain={formik.values.new_password_confirmation}
                        onChange={setValidPassword}
                        messages={messages}
                        iconComponents={{
                            ValidIcon: <img src={toAbsoluteUrl('/media/general/Shield-Green.svg')} alt='' className='checklist-icons me-1' />,
                            InvalidIcon: <img src={toAbsoluteUrl('/media/general/Shield-Gray.svg')} alt='' className='checklist-icons me-1' />
                        }}
                    />
                </form>
                <button
                    type='submit'
                    className={passStyle.button}
                    onClick={() => formik.handleSubmit()}
                    disabled={formik.isSubmitting || !validPassword}>
                    <span>Atualizar senha</span>
                </button>
            </div>
        </>
    )
}

export default ChangePasswordPage
