// STORE FRONT LINKS
export const HOME_PAGE = 'HOME_PAGE'
export const STORE_FRONT_PAGE = 'STORE_FRONT_PAGE'
export const STORE_FRONT_PRODUCTS = 'STORE_FRONT_PRODUCTS'
export const STORE_FRONT_CONTACT_PAGE = 'STORE_FRONT_CONTACT_PAGE'
export const STORE_FRONT_CART_PAGE = 'STORE_FRONT_CART_PAGE'
export const STORE_FRONT_CART_PAGE_REVISION = 'STORE_FRONT_CART_PAGE_REVISION'
export const CITY_LOCALIZER_PAGE = 'CITY_LOCALIZER_PAGE'
export const STORE_FRONT_CHECKOUT_PAGE = 'STORE_FRONT_CHECKOUT_PAGE'
export const STORE_FRONT_CHECKOUT_FINISH_PAGE = 'STORE_FRONT_CHECKOUT_FINISH_PAGE'
export const STORE_FRONT_CART_CHANGE_CARD = 'STORE_FRONT_CART_CHANGE_CARD'
export const STORE_FRONT_PENDING_PAYMENT = 'STORE_FRONT_PENDING_PAYMENT'
export const STORE_FRONT_REQUEST_PAGE = 'STORE_FRONT_REQUEST_PAGE'
export const STORE_FRONT_SEARCH_CATEGORY_PAGE = 'STORE_FRONT_SEARCH_CATEGORY_PAGE'
export const STORE_FRONT_SEARCH_TAG_PAGE = 'STORE_FRONT_SEARCH_TAG_PAGE'
export const STORE_FRONT_SEARCH_PRICELIST_PAGE = 'STORE_FRONT_SEARCH_PRICELIST_PAGE'
export const STORE_FRONT_CATEGORY_MODAL = 'STORE_FRONT_CATEGORY_MODAL'
export const STORE_FRONT_PRODUCT_DETAIL_MODAL = 'STORE_FRONT_PRODUCT_DETAIL_MODAL'
export const STORE_FRONT_PRODUCT_DETAIL_PAGE = 'STORE_FRONT_PRODUCT_DETAIL_PAGE'
export const STORE_FRONT_CATEGORY_PAGE = 'STORE_FRONT_CATEGORY_PAGE'
export const STORE_FRONT_SEARCH_BRAND_PAGE = 'STORE_FRONT_SEARCH_BRAND_PAGE'
export const STORE_PRE_SEARCH = 'STORE_PRE_SEARCH'
export const STORE_SEARCH_PAGE = 'STORE_SEARCH_PAGE'
export const STORE_FRONT_STORE_SALES = 'STORE_FRONT_STORE_SALES'
export const STORE_SIGNUP = 'STORE_SIGNUP'

// ACCOUNT LINKS
export const ACCOUNT_MENU_PAGE = 'ACCOUNT_MENU_PAGE'
export const ACCOUNT_SUBMENU_PAGE = 'ACCOUNT_SUBMENU_PAGE'
export const ACCOUNT_PROFILE_PAGE = 'ACCOUNT_PROFILE_PAGE'
export const ACCOUNT_PROFILE_REGISTRY = 'ACCOUNT_PROFILE_REGISTRY'
export const ACCOUNT_PROFILE_REGISTRY_OWNER = 'ACCOUNT_PROFILE_REGISTRY_OWNER'
export const ACCOUNT_PROFILE_REGISTRY_NEW = 'ACCOUNT_PROFILE_REGISTRY_NEW'
export const ACCOUNT_PROFILE_REGISTRY_EDIT = 'ACCOUNT_PROFILE_REGISTRY_EDIT'
export const ACCOUNT_PROFILE_PASSWORD_PAGE = 'ACCOUNT_PROFILE_PASSWORD_PAGE'
export const ACCOUNT_PROFILE_ADDRESS_PAGE = 'ACCOUNT_PROFILE_ADDRESS_PAGE'
export const ACCOUNT_PROFILE_MAIL_PAGE = 'ACCOUNT_PROFILE_MAIL_PAGE'
export const ACCOUNT_ORDERS_PAGE = 'ACCOUNT_ORDERS_PAGE'
export const ACCOUNT_ORDER_DETAILS_PAGE = 'ACCOUNT_ORDER_DETAILS_PAGE'
export const ACCOUNT_ORDER_DETAILS_MODAL = 'ACCOUNT_ORDER_DETAILS_MODAL'
export const ACCOUNT_ORDERS_TRACKING_MODAL = 'ACCOUNT_ORDERS_TRACKING_MODAL'
export const ACCOUNT_PAYMENTS_PAGE = 'ACCOUNT_PAYMENTS_PAGE'
export const ACCOUNT_PAYMENT_DETAIL_PAGE = 'ACCOUNT_PAYMENT_DETAIL_PAGE'
export const ACCOUNT_PAYMENT_DETAIL_MODAL = 'ACCOUNT_PAYMENT_DETAIL_MODAL'
export const ACCOUNT_PAYMENT = 'ACCOUNT_PAYMENT'
export const ACCOUNT_MISSIONS_PAGE = 'ACCOUNT_MISSIONS_PAGE'
export const ACCOUNT_WHISHLIST_PAGE = 'ACCOUNT_WHISHLIST_PAGE'
export const ACCOUNT_WHISHLIST_DETAIL_PAGE = 'ACCOUNT_WHISHLIST_DETAIL_PAGE'
export const ACCOUNT_SUGGESTED_WHISHLIST_PAGE = 'ACCOUNT_SUGGESTED_WHISHLIST_PAGE'
export const ACCOUNT_SUGGESTED_WHISHLIST_DETAIL_PAGE = 'ACCOUNT_SUGGESTED_WHISHLIST_DETAIL_PAGE'
export const ACCOUNT_COUPONS_PAGE = 'ACCOUNT_COUPONS_PAGE'
export const MISSIONS_PAGE = 'MISSIONS_PAGE'
export const MISSION_DETAIL_PAGE = 'MISSION_DETAIL_PAGE'
export const ACCOUNT_NOTIFICATIONS_PAGE = 'ACCOUNT_NOTIFICATIONS_PAGE'
export const ACCOUNT_DONATIONS_PAGE = 'ACCOUNT_DONATIONS_PAGE'
export const ACCOUNT_PAYMENT_METHODS_PAGE = 'ACCOUNT_PAYMENT_METHODS_PAGE'
export const ACCOUNT_FAQ_PAGE = 'ACCOUNT_FAQ_PAGE'
export const ACCOUNT_ABOUT_PAGE = 'ACCOUNT_ABOUT_PAGE'
export const ACCOUNT_CARDS_PAGE = 'ACCOUNT_CARDS_PAGE'
export const ACCOUNT_CARDS_ADD_PAGE = 'ACCOUNT_CARDS_ADD_PAGE'
export const ACCOUNT_MOBILE_CARDS_PAGE = 'ACCOUNT_MOBILE_CARDS_PAGE'
export const ACCOUNT_REGISTRED_DATA_PAGE = 'ACCOUNT_REGISTRED_DATA_PAGE'
export const ACCOUNT_TERMS_PAGE = 'ACCOUNT_TERMS_PAGE'
export const ACCOUNT_PRIVACY_POLICY_PAGE = 'ACCOUNT_PRIVACY_POLICY_PAGE'
export const ACCOUNT_RETURNS_PAGE = 'ACCOUNT_RETURNS_PAGE'
export const STORE_FRONT_BRANDS_SEARCH = 'STORE_FRONT_BRANDS_SEARCH'

export const TERMS_PAGE = 'TERMS_PAGE'
export const PRIVACY_POLICY_PAGE = 'PRIVACY_POLICY_PAGE'
export const LOGOUT_PAGE = 'LOGOUT_PAGE'
export const LOGIN_PAGE = 'LOGIN_PAGE'
export const FEEDBACK_PAGE = 'FEEDBACK_PAGE'
export const FORGOT_PASSWORD_PAGE = 'FORGOT_PASSWORD_PAGE'
export const SUPPORT_PAGE = 'SUPPORT_PAGE'

// ORDER LINKS
export const ORDER_PAGE = 'ORDER_PAGE'
export const ORDER_SUMMARY_PAGE = 'ORDER_SUMMARY_PAGE'
export const RECEIPT_PAGE = 'RECEIPT_PAGE'
export const RECEIPT_DOWNLOAD = 'RECEIPT_DOWNLOAD'
export const TRACK_PAGE = 'TRACK_PAGE'
export const INVOICE_PAGE = 'INVOICE_PAGE'
export const ACCOUNT_CONTACT = 'ACCOUNT_CONTACT'
export const ACCOUNT_2_FACTOR = 'ACCOUNT_2_FACTOR'
export const TRACKING_PAGE = 'TRACKING_PAGE'
export const TICKET_PAGE = 'TICKET_PAGE'
export const ERROR_500_PAGE = 'ERROR_500_PAGE'
export const ERROR_404_PAGE = 'ERROR_404_PAGE'
export const GATEWAY_PAGE = 'GATEWAY_PAGE'
export const TEST_LINK = 'TEST_LINK'


// PUBLIC LINKS
export const RECEIPT_PRINT = 'RECEIPT_PRINT'
export const INVOICE_PRINT = 'INVOICE_PRINT'

const pathMap = {
    ERROR_500_PAGE: () => `/erro/500`,
    ERROR_404_PAGE: () => `/erro/404`,
    HOME_PAGE: () => `/home`,
    GATEWAY_PAGE: () => `/entrada`,
    STORE_FRONT_PAGE: () => `/`,
    STORE_FRONT_CONTACT_PAGE: () => `/contato`,
    STORE_FRONT_CART_PAGE: () => `/carrinho`,
    STORE_FRONT_CART_PAGE_REVISION: () => `/carrinho/revisao`,
    CITY_LOCALIZER_PAGE: () => `/cidade`,
    STORE_FRONT_CHECKOUT_PAGE: () => `/checkout`,
    STORE_FRONT_CHECKOUT_FINISH_PAGE: (orderId: number) => `/checkout/finalizado/${orderId}`,
    STORE_FRONT_CART_CHANGE_CARD: () => `/checkout/cartoes`,
    STORE_FRONT_PENDING_PAYMENT: (id: string) => `/checkout/status/${id}`,
    STORE_FRONT_PAYED: () => `/checkout/confirmado`, // APENAS PARA CHECAR A PAGINA, NAO USAR PARA LINKS
    STORE_FRONT_REQUEST_PAGE: (id: string) => `/pedido/${id}`,
    STORE_FRONT_SEARCH_CATEGORY_PAGE: (...slugs: []) => {
        const slug = slugs.join('/')
        return `/categoria/${slug}`
    },
    STORE_FRONT_SEARCH_TAG_PAGE: (...id: []) => {
        const slug = id.join('/')
        return `/tag/${slug}`
    },
    STORE_FRONT_SEARCH_PRICELIST_PAGE: (pricelistId: string) => `/lista/${pricelistId}`,
    STORE_FRONT_CATEGORY_PAGE: () => '/categorias',
    STORE_FRONT_CATEGORY_MODAL: () => '/categorias',
    STORE_FRONT_SEARCH_BRAND_PAGE: (...slugs: []) => {
        const slug = slugs.join('/')
        return `/marca/${slug}`
    },
    STORE_PRE_SEARCH: () => `/buscador`,
    STORE_SEARCH_PAGE: (term: string) => `/busca?query=${term}`,
    STORE_FRONT_PRODUCT_DETAIL_MODAL: (id: string) => `/produto/${id}`,
    STORE_FRONT_PRODUCT_DETAIL_PAGE: (id: string) => `/produto/${id}`,
    STORE_FRONT_BRANDS_SEARCH: () => `/marcas`,
    STORE_FRONT_STORE_SALES: () => `/ofertas`,
    FEEDBACK_PAGE: () => `/feedback`,

    ACCOUNT_MENU_PAGE: () => `/conta`,
    ACCOUNT_MOBILE_CARDS_PAGE: () => `/conta/cartoes`,
    ACCOUNT_PROFILE_PAGE: () => `/conta/perfil`,
    ACCOUNT_PROFILE_REGISTRY: () => `/conta/perfil/contato`,
    ACCOUNT_PROFILE_REGISTRY_OWNER: () => `/conta/perfil/contato/proprietario`,
    ACCOUNT_PROFILE_REGISTRY_NEW: () => `/conta/perfil/contato/adicionar`,
    ACCOUNT_PROFILE_REGISTRY_EDIT: (id: string) => `/conta/perfil/contato/${id}`,
    ACCOUNT_PROFILE_PASSWORD_PAGE: () => `/conta/trocar-senha`,
    ACCOUNT_PROFILE_MAIL_PAGE: () => `/conta/email`,
    // ACCOUNT_PROFILE_ADDRESS_PAGE: () => `/conta/endereco`,
    ACCOUNT_ORDERS_PAGE: () => `/conta/pedido/lista`,
    ACCOUNT_ORDER_DETAILS_PAGE: (id: string) => `/conta/pedido/${id}`,
    ACCOUNT_ORDER_DETAILS_MODAL: (id: string) => `/conta/pedido/lista/${id}`,
    ACCOUNT_ORDERS_TRACKING_MODAL: (id: string) => `/conta/pedido/lista/rastreio/${id}`,
    ACCOUNT_PAYMENTS_PAGE: () => `/conta/pagamento/lista`,
    ACCOUNT_PAYMENT_DETAIL_PAGE: (id: string) => `/conta/pagamento/${id}`,
    ACCOUNT_PAYMENT_DETAIL_MODAL: (id: string) => `/conta/pagamento/lista/${id}`,
    ACCOUNT_WHISHLIST_PAGE: () => `/conta/lista-de-compras/lista`,
    ACCOUNT_WHISHLIST_DETAIL_PAGE: (id: string) => `/conta/lista-de-compras/${id}`,
    ACCOUNT_SUGGESTED_WHISHLIST_PAGE: () => `/conta/lista-de-compras-sugerida/lista`,
    ACCOUNT_SUGGESTED_WHISHLIST_DETAIL_PAGE: (id: string) => `/conta/lista-de-compras-sugerida/${id}`,
    ACCOUNT_RETURNS_PAGE: () => `/conta/trocas-e-devolucoes`,
    ACCOUNT_CARDS_PAGE: () => `/conta/cartoes`,
    ACCOUNT_CARDS_ADD_PAGE: () => `/conta/cartoes/novo`,
    ACCOUNT_COUPONS_PAGE: () => `/conta/cupons`,
    MISSIONS_PAGE: () => `/missao`,
    MISSION_DETAIL_PAGE: (id: string) => `/missao/${id}`,
    ACCOUNT_NOTIFICATIONS_PAGE: () => `/conta/notificacoes`,
    ACCOUNT_DONATIONS_PAGE: () => `/conta/doacao/lista`,
    ACCOUNT_TERMS_PAGE: () => `/conta/termos-de-uso`,
    ACCOUNT_PRIVACY_POLICY_PAGE: () => `/conta/politica-de-privacidade`,
    ACCOUNT_PAYMENT_METHODS_PAGE: () => `/conta/metodos-de-pagamento`,
    ACCOUNT_MISSIONS_PAGE: () => `/conta/missoes`,
    ACCOUNT_FAQ_PAGE: () => `/conta/faq`,
    ACCOUNT_ABOUT_PAGE: () => `/conta/sobre`,
    ACCOUNT_CONTACT: () => `/conta/contato`,
    ACCOUNT_2_FACTOR: (n: string) => `/conta/autenticacao-2-fatores/${n}`,
    ORDER_PAGE: (id: string) => `/conta/pedido/${id}`,
    INVOICE_PAGE: (id: string) => `/conta/fatura/${id}`,
    INVOICE_PRINT: (id: string) => `/publico/fatura/${id}`,
    RECEIPT_DOWNLOAD: (id: string) => `/conta/nota-fiscal/${id}`,
    RECEIPT_PRINT: (id: string) => `/publico/nota-fiscal/${id}`,
    RECEIPT_PAGE: (id: string) => `/conta/nota/${id}`,
    TRACK_PAGE: (id: string) => `/conta/rastreio/${id}`,
    TRACKING_PAGE: (id: string) => `/conta/rastreio/${id}`,
    TICKET_PAGE: () => `/conta/tickets`,
    STORE_SIGNUP: () => `/cadastro`,
    LOGOUT_PAGE: () => `/conta/sair`,
    LOGIN_PAGE: () => `/entrar`,
    FORGOT_PASSWORD_PAGE: () => `/esqueci-minha-senha`,
    SUPPORT_PAGE: () => `/suporte`,
    TERMS_PAGE: () => `legal/termos-de-uso`,
    PRIVACY_POLICY_PAGE: () => `legal/politica-de-privacidade`,
} as any

export const toUrl = (key: string, ...params: any[]) => {
    return pathMap[key](...params)
}
