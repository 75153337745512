import { useQuery } from '@tanstack/react-query';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { SalesOrder } from 'models/SalesOrder';
import { useAuth } from 'modules/auth/core/AuthProvider';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toAbsoluteUrl } from 'utils/Functions';
import { orderDetails } from '../Order/core/_requests';
import billStyle from './InvoiceDetailPage.module.css';
import ComponentToPrint from './content/InvoiceContent';

function InvoiceDetailPage() {
  const { id : orderId } = useParams()
  const { accountDetails } = useAuth();
  const [order, setOrder] = useState<SalesOrder>()

  const { isLoading: isOrderDetailLoading } = useQuery({
    queryFn: async () => {
        const response = await orderDetails(orderId!!)
        setOrder(response.data)
        return response
    },
    queryKey: ['order', orderId],
    enabled: !!orderId,
  })

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <PageTitle title={`Fatura do pedido ${order && '#' + order?.number}`} />

      <ComponentToPrint forwardedRef={componentRef} />

      {/* THIS DOES NOTHING FOR NOW */}
      <div className={`${billStyle.faturaButton} d-flex justify-content-center`} onClick={handlePrint}>
        <button>
          Imprimir fatura
          <img src={toAbsoluteUrl('/media/general/Printer-Icon.svg')} alt='' className='transform-1px ms-2'/>
        </button>
      </div>

    </>
  )
}
export default InvoiceDetailPage
