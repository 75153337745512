import App from 'App'
import { SuspensedLayout } from 'layouts/common/SuspensedLayout/SuspensedLayout'
import LegalWrapper from 'pages/desktop/Legal/LegalWrapper'
import SupportPagePage from 'pages/desktop/Legal/SupportPage'
import ErrorWrapperMobile from 'pages/mobile/Error/ErrorWrapperMobile'
import GatewayPage from 'pages/mobile/Gateway/GatewayPage'
import React, { lazy, useMemo } from 'react'
import { useRoutes } from 'react-router-dom'
import { normalizeRoutes } from 'utils/Functions'
import StoreRoutes from './StoreRoutes'

const AppRoutes: React.FC = () => {

    const LoginPage = lazy(() => import('pages/desktop/Login/LoginPage'))
    const LoginPageMobile = lazy(() => import('pages/mobile/Login/LoginPage'))
    const PreLogin = lazy(() => import('pages/mobile/Login/PreLogin'))
    const SignupPageWrapper = lazy(() => import('pages/desktop/Signup/SignupPage'))
    const SignupPageWrapperMobile = lazy(() => import('pages/mobile/Signup/SingupPage'))
    // const ChangePasswordPage = lazy(() => import('pages/desktop/ChangePassword/ChangePasswordPage'))
    // const ChangePasswordPageMobile = lazy(() => import('pages/mobile/ChangePassword/ChangePasswordPage'))
    const ForgotPasswordPageWrapper = lazy(() => import('pages/desktop/ForgotPassword/ForgotPasswordPage'))
    const ForgotPasswordPageWrapperMobile = lazy(() => import('pages/mobile/ForgotPassword/ForgotPasswordPage'))
    const ReceiptPage = lazy(() => import('pages/public/ReceiptPage'))
    const InvoicePage = lazy(() => import('pages/public/InvoicePage'))
    const ErrorWrapper = lazy(() => import('pages/desktop/Error/ErrorWrapper'))

    const routes = useMemo(() => {
        return normalizeRoutes([
            {
                element: <App />,
                children: [
                    {
                        path: 'bem-vindo',
                        elementMobile: <SuspensedLayout><PreLogin /></SuspensedLayout>,
                    },
                    {
                        path: 'entrar',
                        element: <SuspensedLayout><LoginPage /></SuspensedLayout>,
                        elementMobile: <SuspensedLayout><LoginPageMobile /></SuspensedLayout>,
                    },
                    {
                        path: 'cadastro',
                        element: <SuspensedLayout><SignupPageWrapper /></SuspensedLayout>,
                        elementMobile: <SuspensedLayout><SignupPageWrapperMobile /></SuspensedLayout>,
                    },
                    {
                        path: 'esqueci-minha-senha',
                        element: <SuspensedLayout><ForgotPasswordPageWrapper /></SuspensedLayout>,
                        elementMobile: <SuspensedLayout><ForgotPasswordPageWrapperMobile /></SuspensedLayout>,
                    },
                    {
                        path: 'entrada',
                        element: '',
                        elementMobile:
                        <SuspensedLayout><GatewayPage /></SuspensedLayout>,
                    },
                    // {
                    //     path: 'alterar-senha/{token}',
                    //     element: <SuspensedLayout><ChangePasswordPage /></SuspensedLayout>,
                    // },
                    {
                        path: 'publico/nota-fiscal/:id',
                        element: <SuspensedLayout><ReceiptPage /></SuspensedLayout>
                    },
                    {
                        path: 'publico/fatura/:id',
                        element: <SuspensedLayout><InvoicePage /></SuspensedLayout>
                    },
                    {
                        path: 'erro/*',
                        element: <SuspensedLayout ><ErrorWrapper /></SuspensedLayout>,
                        elementMobile: <SuspensedLayout><ErrorWrapperMobile /></SuspensedLayout>,
                    },
                    {
                        path: 'legal/*',
                        element: <SuspensedLayout><LegalWrapper /></SuspensedLayout>
                    },
                    {
                        path: 'contato',
                        element: <SuspensedLayout><SupportPagePage/></SuspensedLayout>
                    },
                    {
                        path: '*',
                        element: <StoreRoutes />,
                    }
                ],
            },
        ])
    }, [])

    return useRoutes(routes)
}

export default AppRoutes
