import PageTitle from 'components/common/PageTitle/PageTitle';
import { IoAlertCircleOutline } from 'react-icons/io5';
import fakeData from 'resources/data/fake-data';
import couponPage from './CouponPage.module.css';

function CouponPage() {
  const { coupons } = fakeData;

  return (
    <>
      <PageTitle title={`CUPONS DE DESCONTO`} />
      <div className="container padding-bottom-mobile">
        {coupons.length > 0 ? (
          <>
            <div className="d-flex justify-content-center">
              <div className={`${couponPage.couponAlert}`}>
                <span>
                  {' '}
                  <IoAlertCircleOutline style={{ fontSize: '25px' }} /> Só é permitido utilizar um
                  cupom por vez{' '}
                </span>
              </div>
            </div>
            {coupons.map((coupon, index) => (
              <div
                key={index}
                className={`${couponPage.orderBubble} d-flex flex-column justify-content-between align-items-center my-3`}>
                <div className={`d-flex flex-column justify-content-between col-12 px-3 py-3`}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column col-3">
                      <span>{coupon.code}</span>
                      <span>Menos {coupon.discount}</span>
                    </div>
                    <div
                      className={`${couponPage.orderBubbleCupon} ${
                        couponPage['bubbleStatus' + coupon.status]
                      } col-3 d-flex justify-content-center`}>
                      {coupon.status}
                    </div>
                    <div className="d-flex flex-column col-5">
                      <span>Ativação: {coupon.start_date}</span>
                      <span>Válido até: {coupon.end_date}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="d-flex flex-column align-items-center">
            <img src={process.env.PUBLIC_URL + '/media/menuPage/Big-Cupon.svg'} className='my-4' alt="Vupty logo" />
            <p className='main-color text-center'>
              Nenhum cupom de desconto até o momento. Você tem acesso aos cupons de desconto
              disponíveis para aplicar na hora da compra na etapa “Detalhes do seu pedido”.
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default CouponPage;
