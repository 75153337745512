import { useQuery } from '@tanstack/react-query'
import PageTitle from 'components/common/PageTitle/PageTitle'
import OrderDateFilter from 'components/mobile/OrderDateFilter/OrderDateFilter'
import OrderItem from 'components/mobile/OrderItem/OrderItem'
import { FilterOption } from 'models/FilterOption'
import { SalesOrder } from 'models/SalesOrder'
import { useMemo, useRef, useState } from 'react'
import { IoIosClose } from 'react-icons/io'
import InfiniteScroll from 'react-infinite-scroller'
import { useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { useDebounce, useIsFirstRender } from 'usehooks-ts'
import { STORE_FRONT_CATEGORY_PAGE, toUrl } from 'utils/Links'
import orderPageStyle from './OrdersPage.module.css'
import { listOrders } from './core/_requests'

function OrdersPage() {
    const navigate = useNavigate()
    const [isFilterContentVisible, setIsFilterContentVisible] = useState(false)
    const [filterOption, setFilterOption] = useState<FilterOption>()
    const [currentPage, setCurrentPage] = useState(0)
    const [totalPages, setTotalPages] = useState(-1)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [orders, setOrders] = useState<SalesOrder[]>([])
    const [searchTerm, setSearchTerm] = useState('')
    const searchTermDebounced = useDebounce(searchTerm, 500)
    const initialRender = useIsFirstRender()
    const videoRef = useRef<HTMLVideoElement>(null);

    function handleDateFilterChange(filterOption: FilterOption) {
        setFilterOption(filterOption)
        setCurrentPage(0)
        setIsFilterContentVisible(false)
    }

    const hasMoreContent = useMemo(() => {
        return totalPages <= 1 ? false : totalPages > (currentPage + 1)
    }, [currentPage, totalPages])

    const { data: orderData, isLoading: isOrderLoading, isFetching: isOrderFetching } = useQuery({
        queryFn: async () => {
            const filters = filterOption?.value ? [filterOption.value] : []
            const response = await listOrders(searchTermDebounced, filters, currentPage)
            setOrders((prev) => {
                if (currentPage === 0) {
                    return response?.data.content
                }
                return [...prev, ...response?.data.content]
            })
            setTotalPages(response.data.total_pages)
            setIsLoadingMore(false)

            return response
        },
        queryKey: ['orders', filterOption, currentPage, searchTermDebounced],
    })

    const clearInput = () => {
        setSearchTerm('')
    }

    const toggleFilterContentVisibility = () => {
        setIsFilterContentVisible(!isFilterContentVisible)
    }

    const onLoadMoreContent = (page: number) => {
        if (isOrderLoading || currentPage >= totalPages - 1) return
        setCurrentPage((prev) => prev + 1)
        setIsLoadingMore(true)
    }

    const isEmptyPlaceholderVisible = useMemo(() => {
        return !isOrderLoading && !isOrderFetching && orders.length === 0
    }, [isOrderLoading, isOrderFetching, orders])

    const isSearchingPlaceholderVisible = useMemo(() => {
        return (!isLoadingMore && isOrderFetching) || (isOrderFetching && initialRender)
    }, [isLoadingMore, initialRender, isOrderFetching])

    return (<>
        <PageTitle title={`PEDIDOS`} />
        <div className='container padding-bottom-mobile'>
            {orders.length < 0 &&
                <div className="d-flex justify-content-between mb-3">
                    <div className={`${orderPageStyle.searchbar} col`}>
                        <input
                            type='text'
                            placeholder='Pesquisar número do pedido'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className={orderPageStyle.searchInput}
                        />
                        <div className={orderPageStyle.searchIcon}>
                            {searchTerm.length === 0 ? (
                            <img
                                src={process.env.PUBLIC_URL + '/media/general/Search-Desktop-Icon-Faded.svg'}
                                alt='img'
                                className={orderPageStyle.magnifyingGlass}
                            />
                            ) : (
                            <IoIosClose id='clearBtn' onClick={clearInput} />
                            )}
                        </div>
                    </div>
                    <div className={`${orderPageStyle.ordersFormSelector}`} onClick={toggleFilterContentVisibility}>
                        Filtrar por:
                        <img
                            src={process.env.PUBLIC_URL + '/media/general/Filter-Icon.svg'}
                            alt="arrows"
                            className="ms-2" />
                    </div>
                </div>
            }
            <OrderDateFilter open={isFilterContentVisible} onClose={handleDateFilterChange} />

            {isSearchingPlaceholderVisible &&
                <div className='d-flex justify-content-center align-items-center'>
                    <ClipLoader color="#033858" size={20} />
                <span className='font500 ms-2'>Carregando...</span>
                </div>
            }

            {isEmptyPlaceholderVisible && (<div className='d-flex flex-column align-items-center mb-4 mt-3'>
                <div className='d-flex flex-column align-items-center'>
                    <img src={process.env.PUBLIC_URL + '/media/menuPage/Big-Bill.svg'} alt="card" />
                    <p className='font500 my-4'>Nenhum pedido por enquanto...</p>
                    <p className='text-center font300 font09rem'>
                        Para acompanhar os seus pedidos, adicione os itens desejados ao seu carrinho e siga as etapas de pagamento e finalização da compra
                    </p>
                </div>
                <div className='button btn-main mt-4' onClick={() => navigate(toUrl(STORE_FRONT_CATEGORY_PAGE))}>
                    Começar a comprar
                </div>
            </div>)}

            {(orders && orders.length > 0) && (<>
                <InfiniteScroll
                    className='w-100'
                    pageStart={0}
                    initialLoad={false}
                    loadMore={onLoadMoreContent}
                    hasMore={hasMoreContent}
                    loader={<div className="loader d-flex justify-content-center" key={0}>
                        <ClipLoader
                            color="#033858"
                            size={18}
                        />
                    </div>}
                >
                    {orders.map((order, index) => (
                        <OrderItem order={order} key={index} />
                    ))}
                </InfiniteScroll>


            </>)}
        </div>
    </>)
}

export default OrdersPage
