import clsx from "clsx";
import AddToFavorites from "components/common/AddToFavorites/AddToFavorites";
import Image from "components/common/Image/Image";
import ProductBadge from "components/common/ProductBadge/ProductBadge";
import ProductCounterLong from "components/mobile/ProductCounter/Long/ProductCounterLong";
import HorizontalCardSkeleton from "components/mobile/Skeletons/HorizontalCardSkeleton";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import { Product } from "models/Product";
import { EnumType } from "modules/api/core/_models";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ITEM_TYPE_BOX, ITEM_TYPE_UNIT } from "utils/Consts";
import { getActualProductPriceRange, getNextProductPriceRange, toAbsoluteUrl, toBRL } from "utils/Functions";
import { STORE_FRONT_PRODUCT_DETAIL_PAGE, STORE_FRONT_SEARCH_TAG_PAGE, toUrl } from "utils/Links";
import styles from "./ProductCardHorizontal.module.css";

type Props = {
  product: Product;
  loading?: boolean;
};

const ProductCardHorizontal: FC<Props> = ({ product, loading = false }) => {
  const [itemType, setItemType] = useState<EnumType>();
  const navigate = useNavigate();
  const { addItemCart, removeItemCart, updateCartItem, getItemCart } = useStoreCart();
  const itemCart = useMemo(() => getItemCart(product.id), [product, getItemCart]);

  useEffect(() => {
    if (!product.supply_type) return;
    setItemType(
      product.supply_type.value === "3" || product.supply_type.value === "1" ? ITEM_TYPE_UNIT : ITEM_TYPE_BOX
    );
  }, [product]);

  const nextPriceRange = useMemo(() => {
    if (itemType === ITEM_TYPE_BOX) return;
    return getNextProductPriceRange(product, itemCart?.quantity);
  }, [itemCart?.quantity, product, itemType]);

  const displayRightPriceValue = useMemo(() => {
    if (itemType === ITEM_TYPE_BOX) {
      return `${toBRL(product.package_unit_price)}/${product.measurement_unit}`;
    }

    if (nextPriceRange && nextPriceRange.unit_price < product.sell_price) {
      return toBRL(nextPriceRange.unit_price);
    }
    if (product.original_price) {
      return toBRL(product.original_price);
    }
  }, [itemType, nextPriceRange, product]);

  const displayRightPriceLabel = useMemo(() => {
    if (itemType === ITEM_TYPE_BOX) return;

    if (nextPriceRange && nextPriceRange.unit_price < product.sell_price) {
      return `A PARTIR DE ${nextPriceRange.min_quantity} ${product.measurement_unit}`;
    }
  }, [itemType, nextPriceRange, product]);

  const btnUnitEnabled = useMemo(() => {
    if (!product.supply_type) return false;
    return product.supply_type.value === "3" || product.supply_type.value === "1" ? true : false;
  }, [product]);

  const btnBoxEnabled = useMemo(() => {
    if (!product.supply_type) return false;
    return product.supply_type.value === "3" || product.supply_type.value === "2" ? true : false;
  }, [product]);

  const displayLeftPriceValue = useMemo(() => {
    if (!btnUnitEnabled && !btnBoxEnabled) return;

    if (itemType === ITEM_TYPE_BOX) {
      return toBRL(product.package_sell_price);
    }
    const priceRange = getActualProductPriceRange(product, itemCart?.quantity);

    if (priceRange) return toBRL(priceRange.unit_price);

    return toBRL(product.sell_price);
  }, [itemType, product, itemCart, btnUnitEnabled, btnBoxEnabled]);

  const visibleTags = useMemo(() => {
    if (!product || !product.tags) return [];
    if (product.tags.length <= 2) return product.tags;
    return product.tags.slice(0, 2);
  }, [product]);

  const isAddButtonDisabled = useMemo(() => {
    return !product || !product.supply_type || product.unavailable;
  }, [product]);

  const handleTypeChange = (type: EnumType) => {
    setItemType(type);
  };

  const handleProductAdd = (product: Product) => {
    const quantity = itemType === ITEM_TYPE_UNIT ? 1 : product.quantity_per_package;
    addItemCart(product, quantity);
  };

  const handleProductRemove = (product: Product) => {
    removeItemCart(product);
  };

  const handleProductChange = (product: Product, quantity: number) => {
    updateCartItem(product, quantity);
  };

  return (
    <>
      {loading && <HorizontalCardSkeleton />}
      {!loading && (
          <div  className={`${styles.wholeMobileCard}`} key={product.id}>
            <div className={`g-0 ${styles.itemCardMobile}`}>
              <div className={`${styles.cardMobile} category border-0 col-12`}>
                <div className={`${styles.cardImageMobile} col-4`}>
                  <Image
                    src={product.thumbnail?.url}
                    alt={product.thumbnail?.title}
                    className={styles.productCardImage}
                    height={80}
                    width={80}
                    placeholderColor="#ffffff"
                    fallbackSrc="https://fakeimg.pl/80x80/cccccc/033858"
                    onClick={() => navigate(toUrl(STORE_FRONT_PRODUCT_DETAIL_PAGE, product.id))}
                  />
                  <div className={styles.clipboardIcon}>
                    <AddToFavorites product={product} quantity={itemCart?.quantity} />
                  </div>
                </div>

                <div className={`${styles.cardBodyMobile} col-8`}>
                  <div className={styles.badgeStyle}>
                    {visibleTags.map((tag, idx) => (
                      <ProductBadge
                        key={idx}
                        label={tag.name}
                        handleClick={() => navigate(toUrl(STORE_FRONT_SEARCH_TAG_PAGE, tag.slug))}
                      />
                    ))}
                  </div>

                  <span
                    className={styles.productName}
                    onClick={() => navigate(toUrl(STORE_FRONT_PRODUCT_DETAIL_PAGE, product.id))}
                  >
                    {product.description}
                  </span>
                  <div className={styles.prices}>
                    {displayLeftPriceValue && <span className={styles.mainPrice}> {displayLeftPriceValue}</span>}
                    {displayRightPriceLabel && displayRightPriceValue && (
                      <div className={styles.unitComparative}>
                        <span className="font500 font08rem black-color">{displayRightPriceLabel}</span>
                        <span className="font500 text-end">{displayRightPriceValue}</span>
                      </div>
                    )}
                    {displayRightPriceValue && !displayRightPriceLabel && (
                      <span className={styles.secundaryPrices}>{displayRightPriceValue}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className={`${styles.btns} d-flex flex-direction-row align-item-center col-11 g-0`}>
                <div
                  className={clsx(
                    styles.btnUnitM,
                    "d-flex justify-content-center col-6",
                    itemType === ITEM_TYPE_UNIT && styles.btnUnitMActive
                  )}
                >
                  <button
                    className={`btn btn-sm d-flex align-items-center justify-content-between border-0 main-color w-100 px-4`}
                    disabled={!btnUnitEnabled}
                    onClick={() => handleTypeChange(ITEM_TYPE_UNIT)}
                  >
                    <img src={toAbsoluteUrl("/media/general/Basket-Icon-2.svg")} alt="Cesta" className={styles.cesta} />
                    <span className={styles.unitType}>
                      {btnUnitEnabled ? product.measurement_unit : "INDISPONÍVEL"}
                    </span>
                  </button>
                </div>
                <div
                  className={clsx(
                    styles.btnBoxM,
                    "col-6 d-flex justify-content-center",
                    itemType === ITEM_TYPE_BOX && styles.btnBoxMActive
                  )}
                >
                  <button
                    className={`btn btn-sm ${styles.btnBoxContent}`}
                    disabled={!btnBoxEnabled}
                    onClick={() => handleTypeChange(ITEM_TYPE_BOX)}
                  >
                    <img src={toAbsoluteUrl("/media/general/Box-Icon-2.svg")} alt="" className={styles.caixa} />
                    <span className={styles.unitType}>
                      {btnBoxEnabled
                        ? `${product.package_measurement_unit} c/${product.quantity_per_package} ${product.measurement_unit}`
                        : "INDISPONÍVEL"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className={`g-0 ${styles.itemCardAddition}`}>
              <ProductCounterLong
                disabled={isAddButtonDisabled}
                loading={loading}
                product={product}
                onAdd={handleProductAdd}
                onRemove={handleProductRemove}
                onChange={handleProductChange}
              />
            </div>
          </div>
      )}
    </>
  );
};

export default ProductCardHorizontal;
