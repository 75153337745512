import AuthenticationSteps from 'components/desktop/AuthenticationSteps/AuthenticationSteps'
import React, { useMemo } from 'react'
import {useNavigate} from 'react-router-dom'
import {
  ACCOUNT_2_FACTOR,
  ACCOUNT_ORDERS_PAGE,
  toUrl,
} from 'utils/Links'
import codeStyle from './AOAuthentication.module.css'
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'

function AOCode() {
  const navigate = useNavigate()

  const breadcrumbs = useMemo(() => {
    return(
      [          
        {
          title : 'Perfil',
          path: toUrl(ACCOUNT_ORDERS_PAGE),
        },
        {    
          title : 'Autenticação',
          path : toUrl(ACCOUNT_2_FACTOR, 3),
        }
      ]
    )
  }, [])

  return (
    <>
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className='font600 font15rem'>AUTENTICAÇÃO DE DOIS FATORES</span>
      </div>

      <AuthenticationSteps step={3} />

      <div className='py-3'>
        <h4 className='mb-4'>Passo a passo para finalizar a autenticação de dois fatores</h4>
        <ol type='1'>
          <li>
            Para completar a autenticação de dois fatores, você irá precisar primeiro fazer o
            download do aplicativo <strong>“Google Authenticator”</strong> no seu celular;
          </li>
          <li>
            Após realizar o download, abra o aplicativo e escaneie o QR Code a seguir (caso você
            esteja utilizando o seu próprio celular para realizar o acesso, insira manualmente o
            código a seguir no aplicativo Google Authenticator).
          </li>
        </ol>

        <div className='d-flex justify-content-center'>
          <div className='d-flex flex-column justify-content-center align-content-center align-items-center my-3'>
            <img
              src='https://w7.pngwing.com/pngs/144/829/png-transparent-qr-code-information-qr-code-android-qrcode-text-rectangle-monochrome.png'
              alt='qr-code'
              style={{width: '240px'}}
            />
            <div className='input-group mt-4' style={{width: '160%'}}>
              <input
                type='text'
                className={`form-control`}
                placeholder='ABCDEFGHIJKLMNOPQRSZUVWXYZ'
                aria-describedby='basic-addon2'
              />
              <span
                className={`${codeStyle.qrCodeAuthentication} input-group-text`}
                id='basic-addon2'
              >
                Copiar código
              </span>
            </div>
          </div>
        </div>

        <div className={`${codeStyle.authenticationAlert} my-4 px-3`}>
          <img
            src={process.env.PUBLIC_URL + '/media/general/Alert-Icon.svg'}
            alt='alert'
            className={codeStyle.stepAlertIcon}
          />
          <span className='p-2'>
            Nós recomendamos que, se possível e para maior segurança da sua conta, você anote o
            código disponibilizado para o seu acesso no Google Authenticator em algum lugar seguro e
            de fácil acesso, ou até mesmo que você imprima essa combinação; Outra ação recomendada é
            que junto ao código, você também preserve o QR Code (para fazer isso, basta tirar uma
            captura da tela).
          </span>
        </div>
        <div className='d-flex justify-content-center'>
          <button
            className={`${codeStyle.authenticationButton} mb-5`}
            onClick={() => navigate(toUrl(ACCOUNT_2_FACTOR, 4))}
          >
            {' '}
            Finalizar autenticação{' '}
          </button>
        </div>
      </div>
    </>
  )
}

export default AOCode
