import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useGrocery } from "layouts/common/core/GroceryProvider";
import { useStoreLocation } from "layouts/common/core/StoreLocationProvider";
import { getGroceryDetails, getGroceryPreferences } from "layouts/common/core/_requests";
import { StoreLocation, StoreLocationStatus } from "models/StoreLocation";
import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useContext, useRef, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { LOCAL_STORAGE_AUTH_KEY } from "utils/Consts";
import { AccountDetails, AuthModel, LoginRequestModel } from "./_models";
import { doLogin, doLogout, getAccountDetails } from "./_requests_";

type Value<T> = T | null;

type AuthContextProps = {
  auth: Value<AuthModel | undefined>;
  saveAuth: (auth: AuthModel | undefined) => void;
  accountDetails: AccountDetails | undefined;
  setAccountDetails: Dispatch<SetStateAction<AccountDetails | undefined>>;
  logout: (callBack?: Function) => void;
  login: (payload: LoginRequestModel) => Promise<any>;
  clearAuth: () => void;
};

const initAuthContextPropsState: AuthContextProps = {
  auth: null,
  saveAuth: () => {},
  accountDetails: undefined,
  setAccountDetails: () => {},
  logout: () => {},
  login: () => new Promise(() => {}),
  clearAuth: () => {},
};

const authContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(authContext);
};

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient()
  const [auth, setAuth] = useLocalStorage<AuthModel | null>(LOCAL_STORAGE_AUTH_KEY, null);
  const [accountDetails, setAccountDetails] = useState<AccountDetails | undefined>();
  const { updateStoreLocation } = useStoreLocation();
  const {saveGroceryDetails, saveGroceryPreferences} = useGrocery();

  const saveAuth = (auth: AuthModel | undefined) => {
    //check if auth role is client
    if (auth?.roles.find((role) => role !== "Cliente")) {
      // authHelper.removeAuth()
      setAuth(null);
      throw new Error("Você não tem permissão para acessar essa área");
    }

    if (auth) {
      setAuth(auth);
      // authHelper.setAuth(auth)
    } else {
      setAuth(null);
      // authHelper.removeAuth()
    }
  };

  const logout = (callback?: Function) => {
    sendLogoutRequest();
    if (callback) {
      callback();
    }
  };

  const { mutate: sendLogoutRequest } = useMutation({
    mutationFn: async () => {
      const response = await doLogout();
      return response;
    },
    mutationKey: ["logout"],
    onSettled: () => {
      clearAuth();
      queryClient.invalidateQueries({ queryKey: ['pullStoreCart'] })
    },
  });

  const login = (payload: LoginRequestModel): Promise<any> => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response = await doLogin(payload);

          if (!response.data.regions || response.data.regions.length === 0) {
            reject("Usuário não possui região cadastrada");
            return;
          }

          saveAuth(response.data);

          const loggedUserLocation : StoreLocation = {
            region: response.data.regions[0],
            status: StoreLocationStatus.FROM_USER_LOCATION,
          }

          updateStoreLocation(loggedUserLocation, false);

          const { data: details } = await getGroceryDetails();
          saveGroceryDetails(details);
          const { data: preferences } = await getGroceryPreferences();
          saveGroceryPreferences(preferences);

          resolve("Login realizado com sucesso");
        } catch (err) {
          clearAuth();
          reject(err);
        }
      })();
    });
  };


  const clearAuth = () => {
    setAuth(null);
    // updateStoreLocation(null, false);
  };

  return (
    <authContext.Provider
      value={{
        auth,
        saveAuth,
        accountDetails,
        setAccountDetails,
        logout,
        login,
        clearAuth,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

const AuthInit: FC = () => {
  const { auth, logout, setAccountDetails } = useAuth();
  const didRequest = useRef(false);

  useQuery({
    queryFn: async () => {
      const response = await getAccountDetails();
      if (response.status !== 200) {
        if (!didRequest.current) {
          logout();
        }
        throw new Error("Erro ao tentar capturar informações do usuário");
      }
      setAccountDetails(response.data);
      return response;
    },
    queryKey: ["account-details", auth],
    enabled: !!auth && !didRequest.current,
  });

  // useEffect(() => {
  //     const requestUser = async () => {
  //         try {
  //             const { data: accountDetails } = await getAccountDetails()
  //             setAccountDetails(accountDetails)
  //         } catch (error) {
  //             console.error(error)
  //             if (!didRequest.current) {
  //                 logout()
  //             }
  //         } finally {
  //             hideSplashScreen()
  //         }
  //         return () => (didRequest.current = true)
  //     }

  //     if (auth && !didRequest.current) {
  //         showSplashScreen("Carregando informações do usuário...")
  //         requestUser()
  //     }
  // }, [])

  return null;
};

export { AuthInit, AuthProvider, useAuth };
