import { useQuery } from '@tanstack/react-query'
import PageTitle from 'components/common/PageTitle/PageTitle'
import CheckoutSteps from 'components/mobile/CheckoutSteps/CheckoutSteps'
import { useGrocery } from 'layouts/common/core/GroceryProvider'
import { CheckoutResult } from 'models/CheckoutResult'
import { useAuth } from 'modules/auth/core/AuthProvider'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { captilizeFirstLetterOfEachWord, toAbsoluteUrl } from 'utils/Functions'
import { ACCOUNT_ORDER_DETAILS_PAGE, STORE_FRONT_PAGE, toUrl } from 'utils/Links'
import statusStyle from './CheckoutFinishPage.module.css'
import { getCheckoutStatus } from './core/_requests'

function CheckoutFinishPage() {

  const navigate = useNavigate()
  const { orderId } = useParams()
  const {accountDetails} = useAuth()
  const {groceryDetails} = useGrocery()
  const [result, setResult] = useState<CheckoutResult>()

  const {isLoading, error} = useQuery({
      queryFn: async () => {
          const response = await getCheckoutStatus(orderId!!)
          setResult(response.data)
          return response
      },
      queryKey: ['result', orderId],
      enabled: !!orderId,
  })

  return (
      <>
        <PageTitle title={`FINALIZAR PEDIDO`} />
        <CheckoutSteps currentStep={3} />
        {(isLoading && !result && !error) && <> Carregando... </>}
        {(!isLoading && error) && <> Algo de errado não está certo </>}
        {(!isLoading && result) &&
          <div className='container padding-bottom-mobile'>
            <div className={`d-flex flex-column align-items-center mt-3`}>
              <img src={process.env.PUBLIC_URL + '/media/general/Approved-Icon.svg'} alt='' />
              <span className='mt-2 font500 font11rem'>Compra aprovada com sucesso!</span>
            </div>

            <div className={`${statusStyle.confirmationBody} container`}>
              <div>
              <p>{accountDetails?.name && `Olá, ${captilizeFirstLetterOfEachWord(accountDetails.name)}!`}</p>
                <p>
                  Nós acabamos de enviar todas as informações desta compra para o seu e-mail
                  cadastrado: <strong>{groceryDetails?.billing_email}</strong>
                </p>
                <p>Clique no botão abaixo para acompanhar o andamento do seu pedido!</p>
              </div>
              <div className='d-flex'>
                <button className={statusStyle.trackingBtn} onClick={() => navigate(toUrl(ACCOUNT_ORDER_DETAILS_PAGE, orderId))}>
                  Acompanhar meu pedido
                </button>
              </div>
              <div className={statusStyle.bottomText}>
                Se por algum motivo, você não recebeu o nosso e-mail, verifique a sua caixa de SPAM.
                Caso não esteja no SPAM, entre em contato com o nosso suporte através do endereço de
                e-mail: <strong>contato@vupty.com.br</strong>
              </div>

              <div className={statusStyle.buyAgainContainer}>
                <p className='text-center main-color font500 font12rem'>Entrega grátis mais de uma vez!</p>
                <div className='d-flex justify-content-center mb-3'>
                  <img src={toAbsoluteUrl('/media/general/Cart-On-Phone.svg')} alt='' />
                </div>
                <span className='mb-2'>Você sabia que pode fazer mais de uma compra no mesmo dia e nós só iremos cobrar uma única taxa de entrega?</span>
                <span className='mb-2'>Esqueceu de comprar algum item? Quer aproveitar a entrega grátis ainda no dia de hoje?</span>
                <span className='mb-2'>Volte para a loja e adicione os produtos ao seu carrinho.</span>
                <div className='d-flex justify-content-center mt-2'>
                  {/* <div className={statusStyle.buyAgainButton} onClick={() => navigate(toUrl(STORE_FRONT_STORE_SALES))}> */}
                  <div className={statusStyle.buyAgainButton} onClick={() => navigate(toUrl(STORE_FRONT_PAGE))}>
                    Realizar uma nova compra <img src={'/media/icons/Plus-Icon.svg'} alt='' className='transform-1px'/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

      </>
  )


}

export default CheckoutFinishPage
