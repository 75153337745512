/* eslint-disable react-hooks/exhaustive-deps */
import { Barcode, BarcodeScanner } from "@capacitor-mlkit/barcode-scanning";
import { Capacitor } from "@capacitor/core";
import clsx from "clsx";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";
import styles from "./BarcodeScannerModal.module.css";

type Props = {
  show: boolean;
  handleClose: (code?: any) => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const BarcodeScannerModal: React.FC<Props> = ({ show, handleClose }) => {
  useEffect(() => {
    if (!show) return;
    scan();
  }, [show]);

  const closeModal = async () => {
    document.getElementById("root")?.classList.remove("barcode-scanner-active");
    handleClose();

    if (!Capacitor.isNativePlatform()) return;
    await BarcodeScanner.stopScan();
  };

  const scanSingleBarcode = async () => {
    return new Promise<Barcode>(async (resolve) => {
      document.getElementById("root")?.classList.add("barcode-scanner-active");

      const listener = await BarcodeScanner.addListener("barcodeScanned", async (result) => {
        await listener.remove();
        document.getElementById("root")?.classList.remove("barcode-scanner-active");
        await BarcodeScanner.stopScan();
        resolve(result.barcode);
      });

      await BarcodeScanner.startScan();
    });
  };

  const scan = async () => {
    if (!Capacitor.isNativePlatform()) return;

    const granted = await requestPermissions();

    if (!granted) {
      toast.error("Permissão para acessar a câmera negada");
      closeModal();
      return;
    }

    const barcode: Barcode = await scanSingleBarcode();

    if (!barcode) return;

    handleClose(barcode.displayValue);
  };

  const requestPermissions = async (): Promise<boolean> => {
    const { camera } = await BarcodeScanner.requestPermissions();
    return camera === "granted" || camera === "limited";
  };

  if (!Capacitor.isNativePlatform()) return null;

  return createPortal(
    <Modal
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName={clsx("modal-dialog modal-dialog-centered modal-fullscreen", styles.modalDialog)}
      contentClassName={clsx("modal-content", styles.modalContent)}
      show={show}
      onHide={closeModal}
      backdrop={false}
    >
      <div className="modal-body ">
        <div className={styles.overlay}></div>
        <div className={styles.closeButton} onClick={closeModal}>
          X
        </div>
      </div>
    </Modal>,
    modalsRoot
  );
};

export default BarcodeScannerModal;
