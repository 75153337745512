import { useQuery } from '@tanstack/react-query'
import PageTitle from 'components/common/PageTitle/PageTitle'
import { Mission } from 'models/Mission'
import { useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { toAbsoluteUrl, toBRL } from 'utils/Functions'
import { MISSION_DETAIL_PAGE, toUrl } from 'utils/Links'
import missionStyle from './MissionPage.module.css'
import { list } from './core/_requests'

function MissionPage() {

    const navigate = useNavigate()
    const [missions, setMissions] = useState<Mission[]>([])
    const [totalElements, setTotalElements] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalPages, setTotalPages] = useState(-1)
    const [isLoadingMore, setIsLoadingMore] = useState(false)

    const { isLoading: isMissionLoading } = useQuery({
        queryFn: async () => {
            const response = await list(currentPage)
            setMissions((prev) => {
                if (currentPage === 0) {
                    return response?.data.content
                }
                return [...prev, ...response?.data.content]
            })
            setTotalElements(response.data.total_elements)
            setTotalPages(response.data.total_pages)
            setIsLoadingMore(false)
            return response
        },
        queryKey: ['listMissions', currentPage],
        refetchOnWindowFocus: false,
    })

    const hasMoreContent = useMemo(() => {
        return totalPages <= 1 ? false : totalPages >= (currentPage + 1)
    }, [currentPage, totalPages])

    const onLoadMoreContent = (page: number) => {
        if (isMissionLoading || currentPage >= totalPages - 1) return
        setCurrentPage((prev) => prev + 1)
        setIsLoadingMore(true)
    }


    return (
        <>
            <PageTitle title={`MISSÕES E DESCONTOS`} />
            <div className='container padding-bottom-mobile'>
                <div className='d-flex flex-column align-items-center'>
                    <img src={toAbsoluteUrl('/media/Illustrations/Doubt-Illustration-Ballon.svg')} alt='rights reserved by Freepik Company' />
                    <span className='main-color font500'>Como conseguir descontos?</span>
                    <span className='text-center main-color mt-2'>Para ganhar um desconto, você só vai precisar cumprir com a missão proposta. É simples assim!</span>
                </div>
                {missions && missions.length > 0 && (
                    <InfiniteScroll
                        className='w-100'
                        pageStart={0}
                        initialLoad={false}
                        loadMore={onLoadMoreContent}
                        hasMore={hasMoreContent}
                        loader={<div className="loader d-flex justify-content-center" key={0}>
                            <ClipLoader color="#033858" size={18} /></div>}>
                        {missions.map((mission, idx) => (
                            <div key={idx} className={missionStyle.missionBubble}>
                                <div className='d-flex flex-row justify-content-between align-items-center mb-2'>
                                    <div>
                                        <img src={toAbsoluteUrl('/media/menuPage/Prize-Icon.svg')} alt='' className={missionStyle.prizeIcon} />
                                        <span className='font500 main-color'>{mission.title}</span>
                                    </div>
                                    {mission.products && mission.products.length > 0 && (
                                        <div className={missionStyle.wantButton} onClick={() => navigate(toUrl(MISSION_DETAIL_PAGE, mission.id))}>
                                            Ver produtos
                                        </div>
                                    )}
                                </div>
                                <span>
                                    <strong>Recompensa: </strong>
                                    {(mission.discount_type?.value === '1') &&
                                        `${mission.discount}% de desconto`
                                    }
                                    {(mission.discount_type?.value === '2') &&
                                        `${toBRL(mission.discount)} de desconto`
                                    }
                                </span>
                                <span className='mb-2'>
                                    <strong>Missão: </strong>{mission.description}
                                </span>

                                {/* <ProgressRewardBar target={mission.target} type={mission.type} reward={mission.reward} className='mt-2'/> */}
                            </div>))}
                    </InfiniteScroll>
                )}
            </div>
        </>
    )
}

export default MissionPage
