import { Category } from 'models/Category'
import { useEffect, useRef, useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Link, useNavigate } from 'react-router-dom'
import { STORE_FRONT_SEARCH_CATEGORY_PAGE, toUrl } from 'utils/Links'
import MegaMenuSkeleton from '../Skeletons/MegaMenuSkeleton'
import megaStyle from './MegaMenu.module.css'
import { toAbsoluteUrl } from 'utils/Functions'
import clsx from 'clsx';
import Image from 'components/common/Image/Image'

type Props = {
  categories: Category[];
  title?: string;
  loading?: boolean;
};

function MegaMenu({categories, loading = true,}: Props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [activeCategory, setActiveCategory] = useState<Category>()
  const navigate = useNavigate()

  const useClickOutside = (handler : any) => {
    const domNode = useRef<HTMLDivElement>(null)

    useEffect(() => {
      let maybeHandler = (event : any) => {
        if (domNode.current && !domNode.current.contains(event.target)) {
          handler()
        }
      }

      document.addEventListener('mousedown', maybeHandler)

      return () => {
        document.removeEventListener('mousedown', maybeHandler)
      }
    })

    return domNode
  }
  const domNode = useClickOutside(() => {
    setMenuOpen(false)
  })

  const handleClose = () => {
    setMenuOpen(false)
  }

  const categoryLink = (category: Category) => {
    navigate(toUrl(STORE_FRONT_SEARCH_CATEGORY_PAGE, category.slug))
    setMenuOpen(false)
  }

  return (
      <div className='d-md-block d-none'>
        <div className={megaStyle.categoriesContainer}>
          <div className='container'>
          {loading && (
            <MegaMenuSkeleton/>
          )}

          {(!loading && categories.length > 1) && (
            <div ref={domNode} className={`d-flex`}>
              <div className='position-relative'>
                <div className={menuOpen ? megaStyle.mainMenuOpen : megaStyle.mainMenu} onClick={() => setMenuOpen(!menuOpen)}>
                  <GiHamburgerMenu className='transform-1px' />
                  <span className={`font600`}> NOSSAS CATEGORIAS </span>
                </div>

                <div className={clsx(megaStyle.menuContent, {[megaStyle.show]: menuOpen, [megaStyle.hide]: !menuOpen })}>
                  {menuOpen && (
                    <div className={megaStyle.categoriesMenuExpanded}>
                      <div className={megaStyle.listContent}>
                        {categories.map((category, index) => (
                          <div key={index}
                          className={megaStyle.listContentTopic}
                          onClick={() => categoryLink(category)}
                          onMouseEnter={() => setActiveCategory(category)}>
                            <div className='me-1' >
                              <Image
                                src={category.thumbnail?.url}
                                alt={category.thumbnail?.title}
                                className={megaStyle.classIcon}
                                fallbackSrc={toAbsoluteUrl('/media/logos/Vupty-Small-Logo.png')}
                              />
                              <span className={'font500'}>
                                {category.name.toUpperCase()}
                              </span>
                            </div>
                            <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Right.svg')} alt='' className='transform-1px'/>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {menuOpen && activeCategory?.children && activeCategory.children.length > 0 && (
                    <div className={megaStyle.subMenuContent}>
                      <div className={megaStyle.subMenuGrid}>
                        {activeCategory.children
                          .map((category, sIndex) => (
                            <div className='d-flex flex-column mx-3 mb-3' key={sIndex}>
                              <Link
                                to={toUrl(STORE_FRONT_SEARCH_CATEGORY_PAGE, category.slug)}
                                onClick={handleClose}
                                className={`${megaStyle.subCategory} font500 main-color`}
                              >
                                {category.name.toUpperCase()}
                              </Link>
                              <div>
                                {category.children != null &&
                                  category.children
                                    // .sort(sortCategoriesByName)
                                    .map((children, ssIndex) => (
                                      <div key={ssIndex}>
                                        <Link
                                          to={toUrl(STORE_FRONT_SEARCH_CATEGORY_PAGE, children.slug)}
                                          onClick={handleClose}
                                          className={`${megaStyle.subSubCategory} main-color`}
                                        >
                                          {children.name}
                                        </Link>
                                      </div>
                                    ))}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={megaStyle.quickLinks}>
                {categories.map((category, idx) => (
                  <div key={idx} className={megaStyle.quickLink}>
                    <Link to={toUrl(STORE_FRONT_SEARCH_CATEGORY_PAGE, category.slug)}>
                      <div className={megaStyle.categoryLink}>{category.name}</div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}

          {(!loading && categories.length < 1) && (
            <div ref={domNode} className={`d-flex`}>
              <div className={megaStyle.mainMenu} onClick={() => setMenuOpen(!menuOpen)}>
                <GiHamburgerMenu className='transform-1px' />
                <span className={`font600`}> NOSSAS CATEGORIAS </span>
              </div>
              <div className={megaStyle.menuContent}>
                <div className={`${megaStyle.emptyWrapper} ${menuOpen && megaStyle.emptyWrapperExpanded}`}>
                  {menuOpen &&
                      <div className={megaStyle.categoriesMenuEmpty}>
                        <img src={toAbsoluteUrl('/media/Illustrations/Categories-Illustration.svg')} alt='rights reserved by Freepik Company'/>
                        <span className='font500'>Que pena! Ainda não temos itens disponíveis nesta categoria...</span>
                        <span>A parte boa é que nós temos muitas outras categorias repletas de produtos para você realizar suas compras</span>
                      </div>
                  }
                </div>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
  )
}

export default MegaMenu
