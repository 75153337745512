import ContentLoader from 'react-content-loader'

function ProductCardShortSkeleton() {
  return (
    <ContentLoader
    speed={2}
    width={186}
    height={228}
    viewBox="0 0 186 228"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb">

    <rect x="0" y="0" rx="8" ry="8" width="182" height="96" />
    <rect x="39" y="109" rx="0" ry="0" width="96" height="24" />
    <rect x="0" y="149" rx="0" ry="0" width="180" height="28" />
    <rect x="1" y="180" rx="0" ry="0" width="180" height="28" />
  </ContentLoader>
  )
}

export default ProductCardShortSkeleton
