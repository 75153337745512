import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from 'utils/Functions';
import orderStyle from '../AccountOrderPage.module.css';

const AccountOrderTablePlaceholder: React.FC = () => {
  const navigate = useNavigate();

  return <div className="d-flex justify-content-center">
  <div className="d-flex flex-column align-items-center">
    <img
      src={toAbsoluteUrl("/media/Illustrations/Clipboard-Illustration.svg")}
      alt="rights reserved by Freepik Company"
    />

    <span className="pb-3 font500 font11rem mt-4">Nenhum pedido por enquanto...</span>
    <span className="pb-4 font12rem">
      Para acompanhar os seus pedidos, adicione os itens desejados ao seu carrinho e siga para as etapas de
      pagamento e finalização da compra.
    </span>

    <div
      className={orderStyle.startBuyingButton}
      onClick={() =>
        navigate("/loja/products", {
          state: { targetId: "products" },
        })
      }
    >
      Começar a comprar
    </div>
  </div>
</div>;
}

export default AccountOrderTablePlaceholder;
