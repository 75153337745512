class CheckoutException extends Error {

    constructor(message: string) {
        super(message)
        this.name = 'CheckoutException'
        this.message = message
    }
}

export default CheckoutException
