import { TableColumn } from 'react-data-table-component'

export type DataTableSelectedState = {
    allSelected: boolean
    selectedCount: number
    selectedRows: []
}

export type DataTableFilter = {
    key: string
    value: string
    operation: string
}

export type DataTableProviderState<T> = {
    endpoint: string
    columns: TableColumn<T>[]
    data: T[]
    filteredData: T[]
    searchTerm: string
    selectedState?: DataTableSelectedState
    isLoading: boolean
    refreshData: Function
    filter: Array<DataTableFilter>
    searchColumns: string[]
    links?: DataTableLink[]
    additional_content?: any
    refresh?: boolean
    scope: "regional" | "central"
}

export const initialDataTableProviderState: DataTableProviderState<any> = {
    endpoint: '',
    data: [],
    filteredData: [],
    columns: [],
    searchTerm: '',
    isLoading: false,
    refreshData: () => {
        console.log('refreshData not set')
    },
    filter: [],
    searchColumns: [],
    scope: "regional"
}

export type DataTableContextProps<T> = {
    datatableState: DataTableProviderState<T>
    updateDatatableState: (updates: Partial<DataTableProviderState<T>>) => void
}

export const initialDataTableContextProps: DataTableContextProps<any> = {
    datatableState: initialDataTableProviderState,
    updateDatatableState: (updates: Partial<DataTableProviderState<any>>) => { },
}

export type DataTableLink = {
    label: string
    to: string
    icon?: string
}
