import Breadcrumbs from "components/common/breadcrumbs/Breadcrumbs"
import CartItemCounterNew from "components/desktop/CartItemCounter/CartItemCounterNew"
import ThumbnailSkeleton from "components/desktop/Skeletons/ThumbnailSkeleton"
import { useStoreCart } from "layouts/common/core/StoreCartProvider"
import { Product } from "models/Product"
import { useMemo, useState } from "react"
import { IoTrashOutline } from "react-icons/io5"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from 'react-toastify'
import fakeData from "resources/data/fake-data"
import { toAbsoluteUrl } from "utils/Functions"
import { ACCOUNT_ORDERS_PAGE, ACCOUNT_SUGGESTED_WHISHLIST_DETAIL_PAGE, ACCOUNT_SUGGESTED_WHISHLIST_PAGE, toUrl } from "utils/Links"
// import AddToCartModal from "../AccountWishlist/modal/AccountWishlistAddAllModal/AddToCartModal"
import suggestedStyles from './AOStyle.module.css'

function AOSuggested() {
    const {suggestedLists} = fakeData
    const params = useParams()
    const list = suggestedLists.find((list) => list.slug === params.slug)
    const {deleteItemFromCart, addItemCart, removeItemCart, updateCartItem} = useStoreCart()
    const [isModalAddToCartOpen, setIsModalAddToCartOpen] = useState(false)
    const navigate = useNavigate()
    const loading = useState(false)

    const breadcrumbs = useMemo(() => {
        return(
          [
            {
              title : 'Perfil',
              path: toUrl(ACCOUNT_ORDERS_PAGE),
            },
            {
              title : 'Listas sugeridas',
              path : toUrl(ACCOUNT_SUGGESTED_WHISHLIST_PAGE),
            },
            {
              title : `${list?.name}`,
              path : toUrl(ACCOUNT_SUGGESTED_WHISHLIST_DETAIL_PAGE, list?.slug),
            }
          ]
        )
      }, [])

    const handleProductAdd = (product: Product) => {
      addItemCart(product, 1)
    }

    const handleProductRemove = (product: Product) => {
      removeItemCart(product, 1)
    }

    const handleProductChange = (product: Product, quantity: number) => {
      updateCartItem(product, quantity)
    }

    const handleDeleteItem = (item: any) => {
      deleteItemFromCart(item)
      toast.warn('Produto excluido', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }

    function onDismiss() {
      setIsModalAddToCartOpen(false)
    }

    const addItemsToCart = () => {
      list?.items.forEach((item) => {
        addItemCart(item.product, item.quantity)
      })
      // navigate(toUrl(STORE_FRONT_CART_PAGE))
    }

  return (
    <>
    <div className='container'>
      <div className='mb-4'>
        <Breadcrumbs breadcrumbs={breadcrumbs}/>
        <span className='font600 font15rem'>{list?.name.toUpperCase()}</span>
      </div>
      <div className={suggestedStyles.suggestionHeader}>
        <img src={toAbsoluteUrl('/media/Illustrations/Clipboard-Illustration.svg')} alt=''/>
        <span>Nossas listas sugeridas são criadas levando em consideração as compras realizadas em nosso site, os produtos mais vendidos de cada marca, assim como aqueles itens que são os mais comprados nos supermercados.</span>
      </div>
      <div className={suggestedStyles.suggestedItems}>
          <span onClick={() => setIsModalAddToCartOpen(true)}>Adicionar lista completa ao carrinho +</span>
          <div>
              {list?.items.map((item, idx) => (
                  <div key={idx} className="d-flex justify-content-between py-4">
                      <div className="col-2">
                        {!loading &&
                          <img src={item.product.thumbnail?.url} alt=""/>
                        }
                        {loading &&
                          <ThumbnailSkeleton />
                        }
                      </div>

                      <div className="col-4">
                          <span className={suggestedStyles.productTitle}>{item.product.description}</span>
                          <div>
                            <span className={suggestedStyles.barCode}>{item.product.sku}</span>
                            <span className={suggestedStyles.brandName}>{item.product.brand?.name}</span>
                          </div>
                      </div>

                      <div className="d-flex flex-column align-items-center col-1">
                        <span className="font11rem font500">
                          {parseFloat(`${item.product.sell_price}`).toFixed(2)}/un
                        </span>
                        <span className={suggestedStyles.fullPrice}>
                          {parseFloat(`${item.product.sell_price * 1.02}`).toFixed(2)}/un
                        </span>
                      </div>

                      <div className="col-2 d-flex align-items-center justify-content-center">
                        <CartItemCounterNew item={item}
                          product={item.product}
                          onAdd={handleProductAdd}
                          onRemove={handleProductRemove}
                          onChange={handleProductChange}
                        />
                      </div>

                      <div className={`${suggestedStyles.trashCan} col-1`} onClick={() => handleDeleteItem(item.product)}>
                        <IoTrashOutline style={{fontSize: '1.5rem'}}/>
                      </div>
                  </div>
              ))}
          </div>
      </div>
    </div>

      {/* <AddToCartModal
      show={isModalAddToCartOpen}
      onHide={onDismiss}
      onConfirm={addItemsToCart}
      /> */}
    </>
  )
}

export default AOSuggested
