import PageTitle from "components/common/PageTitle/PageTitle";
import { useStoreConfig } from "layouts/common/core/StoreConfigProvider";
import { useAuth } from "modules/auth/core/AuthProvider";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "utils/Functions";
import contactPage from "./ContactPage.module.css";

function ContactPage() {
  const { business } = useStoreConfig();
  const { auth } = useAuth();
  const isLogged = useMemo(() => auth !== null, [auth]);

  const copyString = (stringToCopy: string) => {
    navigator.clipboard.writeText(stringToCopy).then(() => {
      toast.success("Copiado", {
        position: "top-right",
        autoClose: 2000,
        theme: "light",
      });
    });
  };

  const whatsappLink = useMemo(() => {
    if (!business.business_telephone_number) return;
    const onlyNumbers = business.business_telephone_number.replace(/\D/g, '');
    return `https://wa.me/${onlyNumbers}?text=Ol%C3%A1%2C%20gostaria%20de%20pedir%20ajuda%20a%20central%20Vupty.`;
  }, [business.business_telephone_number]);

  const emailLink = useMemo(() => {
    return `mailto:${business.email}`;
  }, [business.email]);

  const telephoneLink = useMemo(() => {
    return `tel:${business.business_telephone_number}`;
  }, [business.business_telephone_number]);

  return (
    <>
      <PageTitle title={`CONTATO`} />
      <div className="container padding-bottom-mobile">
        {/* {isLogged && (
          <div className="d-flex justify-content-center my-3">
            <button className="button btn-main">Ver historico de solicitações</button>
          </div>
        )} */}

        <p>Escolha uma das opções abaixo para dar início ao seu atendimento através do nosso suporte.</p>
        <p>
          Em seguida, você irá se comunicar com um de nossos atendentes, que irá prosseguir com a solução do seu
          problema.
        </p>

        {/* TROCAR NUMERO DE TELEFONE DEPOIS */}
        <div className={`${contactPage.contactBigButton} d-flex flex-column my-3`}>
          <div
            className={`${contactPage.contactBigButtonTop} d-flex flex-column justify-content-center align-items-center py-2`}
          >
            <span
              className={`${contactPage.contactBigButtonTittle} d-flex flex-column align-items-center main-color my-2`}
            >
              <img src={toAbsoluteUrl("/media/icons/Whatsapp-Filled-Icon.svg")} alt="" />
              WhatsApp
            </span>
            <div className="font500 faded-color" onClick={() => copyString(business.whatsapp || "")}>
              {business.whatsapp}
              <img src={toAbsoluteUrl("/media/general/Copy-Icon.svg")} alt="" className={contactPage.copyIcon} />
            </div>
          </div>
          <div className={"d-flex justify-content-center mb-3"} onClick={() => (window.location.href = whatsappLink || '')}>
            <span className={`${contactPage.contactBigButtonBottom} py-1`}>Iniciar conversa no WhatsApp</span>
          </div>
        </div>

        <div className={`${contactPage.contactBigButton} d-flex flex-column my-3`}>
          <div
            className={`${contactPage.contactBigButtonTop} d-flex flex-column justify-content-center align-items-center py-2`}
          >
            <span
              className={`${contactPage.contactBigButtonTittle} d-flex flex-column align-items-center main-color my-2`}
            >
              <img src={toAbsoluteUrl("/media/icons/Mail-Filled-Icon.svg")} alt="" />
              E-mail
            </span>
            <div className="font500 faded-color" onClick={() => copyString(business.email || "")}>
              {business.email}
              <img src={toAbsoluteUrl("/media/general/Copy-Icon.svg")} alt="" className={contactPage.copyIcon} />
            </div>
          </div>
          <div className="d-flex justify-content-center mb-3" onClick={() => (window.location.href = emailLink)}>
            <span className={`${contactPage.contactBigButtonBottom} py-1`}>Escrever e-mail</span>
          </div>
        </div>

        <div className={`${contactPage.contactBigButton} d-flex flex-column my-3`}>
          <div
            className={`${contactPage.contactBigButtonTop} flex-column justify-content-center align-items-center py-2`}
          >
            <span
              className={`${contactPage.contactBigButtonTittle} d-flex flex-column align-items-center main-color my-2`}
            >
              <img src={toAbsoluteUrl("/media/icons/Phone-Filled-Icon.svg")} alt="" />
              Telefone para contato{" "}
            </span>
            <div className="d-flex flex-column align-items-center">
              <div className="font500 faded-color" onClick={() => copyString(business.business_telephone_number || "")}>
                {business.business_telephone_number}
                <img src={toAbsoluteUrl("/media/general/Copy-Icon.svg")} alt="" className={contactPage.copyIcon} />
              </div>
              <span>De Segunda à Sexta, das 8h00 às 18h00</span>
            </div>
          </div>
          <div className="d-flex justify-content-center mb-3" onClick={() => window.open(telephoneLink)}>
            <span className={`${contactPage.contactBigButtonBottom} py-1`}>Ligar</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
