// import PartialAlert from 'components/desktop/PartialAlert/PartialAlert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PartialAlert from 'components/desktop/PartialAlert/PartialAlert';
import { Notification } from 'models/Notification';
import { useAuth } from 'modules/auth';
import { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from 'utils/Functions';
import { useComponentVisible } from 'utils/Hooks';
import navbarNotificationSytle from './NavbarNotificationLayout.module.css';
import { list, markAllRead } from './core/_requests';

const NavbarNotificationLayout: FC = () => {
  const isDesktopSmall = useMediaQuery({query: `(max-width: 768px)`})
  const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [totalElements, setTotalElements] = useState(0)
  const {pathname} = useLocation()
  const queryClient = useQueryClient()
  const { auth } = useAuth()

  const handleOnClick = () => {
    if (isDesktopSmall) {
      return
    }
    setIsComponentVisible(!isComponentVisible)
  }

  const handlePartialClose = (clear:boolean = false) => {
    if(clear){
      sendMarkAllReadRequest()
    }
    setIsComponentVisible(false)
  }

  const { isLoading: isNotificationLoading, isFetching: isNotificationFetching } = useQuery({
    queryFn: async () => {
        const response = await list()
        setNotifications(response?.data.content)
        setTotalElements(response.data.total_elements)
        return response
    },
    queryKey: ['listNotification', pathname],
    enabled: auth !== undefined,
    refetchOnWindowFocus: false,
  })

  const { mutate: sendMarkAllReadRequest } = useMutation({
    mutationFn: async () => {
        const response = await markAllRead()
        return response
    },
    mutationKey: ['markAllRead'],
    onSuccess: () => {
        toast.success('Notificações lidas com sucesso!')
        queryClient.invalidateQueries({
            queryKey: ['listNotification', pathname],
        })
    },
    onError: (error) => {
        toast.error('Erro ao marcar notificações como lidas!')
    }
  })

  return (
    <div ref={ref} className='d-flex me-1' onClick={handleOnClick}>
      <div className={navbarNotificationSytle.navBarLinks}>
        <div className={navbarNotificationSytle.cartArea}>
          <img src={toAbsoluteUrl('/media/general/Bell-Icon.svg')} alt='notificações'/>
          {notifications.length > 0 &&
            <button className={navbarNotificationSytle.NotificationCounter}>
              <span>{notifications.length}</span>
            </button>
          }
        </div>
      </div>
      <PartialAlert open={isComponentVisible} onClose={handlePartialClose} notifications={notifications}/>
    </div>
  )
}

export default NavbarNotificationLayout;
