import { useStoreConfig } from 'layouts/common/core/StoreConfigProvider';
import { PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

export const TopContainer = styled.div`
  z-index: 3;
  position: sticky;
`;
export const MiddleContainer = styled.div`
  z-index: 1;
`;
export const FooterContainer = styled.div`
  z-index: 50;
`;

function StoreLayout({ children }: PropsWithChildren) {

    const { storeConfig } = useStoreConfig()
    const headerHeight = useMemo(() => {
        return -storeConfig.layout.header.navbarHeight - storeConfig.layout.header.notificationbarHeight;
    }, [storeConfig.layout.header.navbarHeight, storeConfig.layout.header.notificationbarHeight])

    return (<>
        <TopContainer style={{ top: headerHeight }}>
            <Header />
        </TopContainer>
        <MiddleContainer >
            {children}
        </MiddleContainer>
        <FooterContainer>
            <Footer />
        </FooterContainer>
    </>);
}

export default StoreLayout
