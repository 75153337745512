import { useStoreLocation } from "layouts/common/core/StoreLocationProvider";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "utils/Functions";
import { CITY_LOCALIZER_PAGE, LOGIN_PAGE, toUrl } from "utils/Links";
import noLocationStyle from "./SignInWithLocationNotificationLayout.module.css";

const SignInWithLocationNotificationLayout: React.FC = () => {
  const navigate = useNavigate();
  const { storeLocation } = useStoreLocation();

  return (
    <div className={noLocationStyle.container}>
      <span onClick={() => navigate(toUrl(CITY_LOCALIZER_PAGE))} className={noLocationStyle.city}>
        <img src={toAbsoluteUrl("/media/icons/Map-Pin-Filled.svg")} alt="" className="transform-1px" />
        <span className="font09rem">{storeLocation?.region.name}</span>
      </span>
      <span onClick={() => navigate(toUrl(LOGIN_PAGE))} className={noLocationStyle.message}>
        <img src={toAbsoluteUrl("/media/general/Another-Profile-Icon.svg")} alt="" />
        <span className="font09rem main-color font500">Faça login ou se cadastre</span>
      </span>
    </div>
  );
};

export default SignInWithLocationNotificationLayout;
