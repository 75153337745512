import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CgClose } from 'react-icons/cg'
import TrackingComponent from './TrackingComponent'
import { toAbsoluteUrl } from 'utils/Functions'
import { useParams } from 'react-router-dom'
import fakeData, { Order } from 'resources/data/fake-data'

type Props = {
    show: boolean
    onHide: () => void
}

function PostPurchaseTraking({show, onHide}: Props) {
  const [dayOfWeek, setDayOfWeek] = useState('');
  const {orders} = fakeData
  const orderId = useParams()
  const [order, setOrder] = useState({} as Order)

  useEffect(() => {
    let findOrder = orders.find((order : Order) => order.id === orderId.id)
    if(findOrder) {
        setOrder(findOrder)
    } 
  }, [orderId, orders])

//   useEffect(() => {
//     if(show) {
//       calculateDayOfWeek();
//     }
//   }, [order.date]);

//   const calculateDayOfWeek = () => {
//     const parts = order.date.split('/');
//     if (parts.length === 3) {
//     const day = parseInt(parts[0], 10);
//     const month = parseInt(parts[1], 10) - 1;
//     const year = 2000 + parseInt(parts[2], 10);

//     const date = new Date(year, month, day);
//     const daysOfWeek = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
//     const dayOfWeek = daysOfWeek[date.getDay()];

//     setDayOfWeek(dayOfWeek);
//     } else {
//     setDayOfWeek('Invalid date format');
//     }
//   };

  return (
    <Modal size={`lg`} show={show} onHide={onHide} centered>
      <Modal.Body className='py-5 px-3'>

        <div className='d-flex justify-content-between align-items-center font14rem font600 mb-3'>
          <span>Rastreio</span>
          <CgClose onClick={onHide} />
        </div>

        <div>
          <div className='d-flex justify-content-between font500 main-color mb-2 px-2 pt-1'>
            <span>
              {order.status_slug === 'entregue'
                ? 'ENTREGUE NA DATA'
                : 'PRAZO ESTIMADO DA ENTREGA'}
            </span>
            <span>
              {dayOfWeek} ({order.date}) 
              {(order.status_slug !== 'entregue' && order.status_slug !== 'cancelado') && ' até às 16h'}
            </span>
            <span className={`badgeStatus${order.status_slug} orderbadgeStatus transform-4px`}>
              {order.status}
            </span>
          </div>

          <div className=''>
            <div className='my-5 d-flex'>

            <div className='col20 position-relative'>
              <TrackingComponent 
                currentStep={order.delivery_step} 
                visualStep={0} 
                deliveryStatus={order.status_slug}
                imagePath={toAbsoluteUrl('/media/tracking/R$.svg')} 
                date={order.date} 
                stepDescription={'Pagamento efetuado!'} 
                stepDescriptionAlt={'Pagamento sob analise'}/>   
            </div>

            <div className='col20 position-relative'>
              <TrackingComponent 
                  currentStep={order.delivery_step} 
                  visualStep={1}
                  deliveryStatus={order.status_slug}
                  imagePath={toAbsoluteUrl('/media/tracking/Closed-Box-Big.svg')}
                  date={order.date} 
                  stepDescription={'Compras realizadas!'}/>
            </div>

            <div className='col20 position-relative'>
              <TrackingComponent 
                  currentStep={order.delivery_step} 
                  visualStep={2} 
                  deliveryStatus={order.status_slug}
                  imagePath={toAbsoluteUrl('/media/tracking/Wrapped-Box.svg')} 
                  date={order.date} 
                  stepDescription={'Compras embaladas'}/>
            </div>

            <div className='col20 position-relative'>
              <TrackingComponent 
                  currentStep={order.delivery_step} 
                  visualStep={3}
                  deliveryStatus={order.status_slug}
                  imagePath={toAbsoluteUrl('/media/tracking/Truck-Big.svg')} 
                  date={order.date} 
                  stepDescription={'Suas compras saíram para entrega'}/>
            </div>

            <div className='col20 position-relative'>
              <TrackingComponent 
                  currentStep={order.delivery_step} 
                  visualStep={4}
                  deliveryStatus={order.status_slug}
                  imagePath={toAbsoluteUrl('/media/tracking/Delivered-Box.svg')} 
                  date={order.date} 
                  stepDescription={'As compras foram entregues'}/>
            </div>

            </div>
          </div>
        </div>

      </Modal.Body>
    </Modal>
  )
}

export default PostPurchaseTraking