import { useMutation } from '@tanstack/react-query'
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'
import { useFormik } from 'formik'
import { ChangeEmailRequest, initialChangeEmailRequest } from 'models/ChangeEmailRequest'
import { ApiResponseError } from 'modules/api/core/_models'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ACCOUNT_ORDERS_PAGE, ACCOUNT_PROFILE_MAIL_PAGE, STORE_FRONT_STORE_SALES, toUrl } from 'utils/Links'
import mailStyle from './AOStyle.module.css'
import { changeEmail } from './core/_requestsCredential'

function AOEmail() {
    const [showModal, setShowModal] = useState(false)
    const [isSubmited, setIsSubmited] = useState(false)
    const navigate = useNavigate()

    const breadcrumbs = useMemo(() => {
      return(
        [          
          {
            title : 'Perfil',
            path: toUrl(ACCOUNT_ORDERS_PAGE),
          },
          {    
            title : 'E-mail',
            path : toUrl(ACCOUNT_PROFILE_MAIL_PAGE),
          }
        ]
      )
    }, [])

    const { mutate: sendChangeEmailRequest } = useMutation({
      mutationFn: async (values: ChangeEmailRequest) => {
          const response = await changeEmail(values)
          return response.data
      },
      mutationKey: ['changeEmail'],
      onSuccess: () => {
          toast.success('Email alterado com sucesso')
          setIsSubmited(!isSubmited)
      },
      onError: (error: any) => {
          if (error instanceof ApiResponseError) {
              toast.error(error.getErrorMessage())
          } else {
              toast.error('Erro ao alterar e-mail')
          }
      },
      onSettled: () => {
          formik.setSubmitting(false)
      }
    })

    const formik = useFormik<ChangeEmailRequest>({
        initialValues: initialChangeEmailRequest,
        onSubmit: (values) => {
            sendChangeEmailRequest(values)
        },
    })

    const handleConclude = () => {
      navigate(toUrl(STORE_FRONT_STORE_SALES))
    }

  return (
    <div className='container col-12'>
        <div>
            <Breadcrumbs breadcrumbs={breadcrumbs}/>
            <span className='font600 font15rem'>ALTERAR E-MAIL</span>
        </div>

        <h6 className='my-4'>Deseja trocar seu e-mail?</h6>

        <form onSubmit={formik.handleSubmit} noValidate>        
            <div className='d-flex flex-column flex-wrap'>
                <div className={`d-flex flex-column mb-4`}>
                  <label htmlFor='newMail'>Novo e-mail</label>
                  <input type='email' 
                    placeholder='Digite um novo endereço de e-mail' 
                    className={mailStyle.inputStyle}
                    {...formik.getFieldProps('new_email')}
                  />
                  {formik.touched.new_email && formik.errors.new_email && (
                    <span className='text-danger'>{formik.errors.new_email}</span>
                  )}
                </div>

                <div className={`d-flex flex-column mb-4`}>
                  <label>Confirmar novo e-mail</label>
                  <input type='email' 
                    placeholder='Repita o mesmo e-mail' 
                    className={mailStyle.inputStyle} 
                    {...formik.getFieldProps('new_email_confirmation')}
                  />
                  {formik.touched.new_email_confirmation && formik.errors.new_email_confirmation && (
                    <span className='text-danger'>{formik.errors.new_email_confirmation}</span>
                  )}
                </div>
            </div>
            {!isSubmited &&
              <div className='d-flex'>
                  <button className='button btn-second' type='submit' onClick={() => formik.handleSubmit()}>
                      Atualizar
                  </button>
              </div>
            }
            {isSubmited &&
              <div className='d-flex flex-column align-items-start'>
                <span>Um e-mail de confirmação foi enviado para o novo endereço cadastrado: <strong>{formik.values.new_email_confirmation}</strong> Verifique a caixa de entrada e clique no botão para confirmar. Após a confirmação, você já pode utilizar o novo e-mail cadastrado.</span>
                <button className='button btn-second mt-3' type='submit' onClick={handleConclude}>
                  Ok
                </button>
              </div>
            }
        </form>  

        {/* <Modal show={showModal} className='' onHide={handleModalClose} centered>
            <Modal.Body className={mailStyle.modalBodySuccess}>
                <div
                className={`${mailStyle.exitButton} d-flex justify-content-end`}
                onClick={handleModalClose}>
                <CgClose onClick={handleModalClose}/>
                </div>
                <div className={mailStyle.modalContentSuccess}>
                <img src={process.env.PUBLIC_URL + '/media/general/Ok-Icon.svg'} alt='ok' />
                <p className='font20rem my-4'>Um e-mail de confirmação foi enviado para o novo endereço cadastrado: clientetop@vupty.com.br. Verifique a caixa de entrada e clique no botão para confirmar. Após a confirmação, você já pode utilizar o novo e-mail cadastrado.</p>
                <button className='button btn-second' onClick={handleModalClose}>
                    {' '}
                    OK{' '}
                </button>
                </div>
            </Modal.Body>
        </Modal> */}
    </div>
  )
}

export default AOEmail