import { useFormik } from 'formik'
import * as Yup from 'yup';
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import cardsStyle from './AddNewCard.module.css';
import { CgClose } from 'react-icons/cg';

type Props = {
    show: boolean
    onHide: () => void
    onConfirm: () => void
}

function AddNewCardModal({show, onHide, onConfirm}:Props) {
    const [searchTerm, setSearchTerm] = useState('')
    
    const cardSchema = Yup.object().shape({
        cardNumber: Yup.string()
        .required('Digite o número do cartão')
        .test(
            'digite ',
            (value:any) => /(?<!\d)\d{16}(?!\d)|(?<!\d[ _-])(?<!\d)\d{4}(?:[_ -]\d{4}){3}(?![_ -]?\d)/gm.test(value)
        ),
        cardName: Yup.string()
        .required('Digite o nome do titular do cartão')
        .test(
            'Digite o nome completo como impresso no cartão',
            (value:any) => /^([a-zA-Z]{2,}\s{1}[a-zA-Z\s]+)/g.test(value)
        ),
        cardExpirationMonth: Yup.string()
        .required('Digite o mês de validade do cartão'),
        cardExpirationYear: Yup.string()
        .required('Digite o ano de validade do cartão'),
        cardCvc: Yup.string()
        .required('Digite o código de segurança do cartão'),
    }) 

    const formik = useFormik({
        initialValues: {
            cardNumber: '',
            cardName: '',
            cardExpirationMonth: '',
            cardExpirationYear: '',
            cardCvc: '',
            nickName: '',
        },
        validationSchema: cardSchema,
        onSubmit: async (values, formikHelpers) => {
        //   handleFormSubmit(values,formikHelpers)
        },
    })

  return (
    <Modal
    size='sm'
    show={show}
    className={cardsStyle.modalContainer}
    onHide={onHide}
    centered>
        <Modal.Body className={`${cardsStyle.modalBodyDisabling} p-4`}>
        <div className='d-flex justify-content-between align-items-center font12rem font600 main-color mb-4'>
            <span className='text-center font600 font15rem'>Adicionar cartão</span>
            <CgClose onClick={onHide} />
        </div>
        <div className='d-flex flex-column justify-content-center'>

            <div id="PaymentForm">
                <form className={cardsStyle.formStyle}>
                    <label className='d-flex flex-column'>
                        <span>Número do cartão</span>
                        <input
                        type="text"
                        name="name"
                        placeholder="**** **** **** ****"
                        maxLength={19}/>
                    </label>
                    {formik.touched.cardNumber && formik.errors.cardNumber && (
                        <span className='text-danger'>{formik.errors.cardNumber}</span>
                    )}

                    <label className='d-flex flex-column'>
                        <span>Nome no cartão</span>
                        <input
                        type="text"
                        name="number"/>
                    </label>
                        {formik.touched.cardName && formik.errors.cardName && (
                            <span className='text-danger'>{formik.errors.cardName}</span>
                        )}

                    <div className='d-flex flex-column'>
                        <span className='font500'>Data de expedição</span>
                        <div className={cardsStyle.dates}>
                            <label>
                                <input
                                type="text"
                                name="number"
                                placeholder="Mês"
                                maxLength={2}
                                className={cardsStyle.dateInput}
                                />
                            </label>
                                {formik.touched.cardNumber && formik.errors.cardNumber && (
                                    <span className='text-danger'>{formik.errors.nickName}</span>
                                )}
                            <label>
                                <input
                                type="text"
                                name="number"
                                placeholder="Ano"
                                maxLength={4}
                                className={cardsStyle.dateInput}
                                />
                            </label>
                                {formik.touched.cardNumber && formik.errors.cardNumber && (
                                    <span className='text-danger'>{formik.errors.nickName}</span>
                                )} 
                        </div>
                    </div>

                    <label className='d-flex flex-column'>
                        <span>Código de segurança (CVV)</span>
                        <input
                        type="text"
                        name="cvv"
                        placeholder="***"
                        maxLength={3}/>
                    </label>
                        {formik.touched.cardName && formik.errors.cardName && (
                            <span className='text-danger'>{formik.errors.cardName}</span>
                        )}

                    <label className='d-flex flex-column'>
                        <span>Apelido (opcional)</span>
                        <input
                        type="text"
                        name="nickname"
                        placeholder="Dê um nome ao seu cartão"/>
                    </label>
                        {formik.touched.cardName && formik.errors.cardName && (
                            <span className='text-danger'>{formik.errors.cardName}</span>
                        )}

                    <label>
                        <input
                            name="defaultCard"            
                            type="checkbox"
                            />
                        <span>Salvar cartão como padrão</span>
                    </label>

                    <div className='d-flex justify-content-center'>
                    <div className='button background-grey' onClick={onHide}> Cancelar </div>                
                    <button type='submit' className='button btn-main mx-2' onClick={onConfirm}>
                        Adicionar Cartão
                    </button>
                    </div>
                </form>
            </div>

        </div>
        </Modal.Body>
    </Modal>
  )
}

export default AddNewCardModal

{/* <Formik
initialValues={initialCardValues}
onSubmit={onSubmit}
validationSchema={validationSchema}
>
{() => (
    <Form>
    <div className='d-flex flex-column mb-3'>
        <label>Número do cartão</label>
        <Field
        name='card_number'
        className={`${cardsStyle.dataLabel} m-0`}
        placeholder='**** **** **** ****'
        onChange={(event) => handleSearch(event.target.value)}
        />
        <div className={cardsStyle.errorMessage}>
        <ErrorMessage name='company_name' component='span' />
        </div>
    </div>

    <div className='d-flex flex-column mb-3'>
        <label>Nome no cartão</label>
        <Field name='card_name' className={`${cardsStyle.dataLabel} m-0`} />
        <div className={cardsStyle.errorMessage}>
        <ErrorMessage name='company_name' component='span' />
        </div>
    </div>

    <div className='d-flex flex-column mb-3'>
        <label>Data de expiração</label>
        <div className='row'>
        <div className='col'>
            <Field
            as='select'
            name='card_month'
            className={`${cardsStyle.dataLabel} w-100 m-0 form-control`}
            placeholder='mês'
            >
            <option value='' disabled selected>
                Mês
            </option>
            <option value='01'>Janeiro</option>
            <option value='02'>Fevereiro</option>
            <option value='03'>Março</option>
            <option value='04'>Abril</option>
            <option value='05'>Maio</option>
            <option value='06'>Junho</option>
            <option value='07'>Julho</option>
            <option value='08'>Agosto</option>
            <option value='09'>Setembro</option>
            <option value='10'>Outubro</option>
            <option value='11'>Novembro</option>
            <option value='12'>Dezembro</option>
            </Field>
            <div className={cardsStyle.errorMessage}>
            <ErrorMessage name='company_name' component='span' />
            </div>
        </div>

        <div className='col'>
            <Field
            name='card_year'
            className={`${cardsStyle.dataLabel} m-0 form-control`}
            placeholder='ano'
            />
            <div className={cardsStyle.errorMessage}>
            <ErrorMessage name='company_name' component='span' />
            </div>
        </div>
        </div>
    </div>

    <div className='d-flex flex-column mb-3'>
        <label>Código de segurança (CVV)</label>
        <Field
        name='card_cvv'
        className={`${cardsStyle.dataLabel} m-0`}
        placeholder='***'
        />
        <div className={cardsStyle.errorMessage}>
        <ErrorMessage name='company_name' component='span' />
        </div>
    </div>
    </Form>
)}
</Formik> */}