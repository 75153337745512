import { centralClient } from "index"
import { Region } from "models/Region"
import { PageableResponse } from "modules/api/core/_models"
import { LocationEndpoints } from "modules/api/endpoints/LocationEndpoints"
import { RegionEndpoints } from "modules/api/endpoints/RegionEndpoints"

export const listRegions = (searchTerm : string, count : number) => {
    return centralClient.get<PageableResponse<Region>>(RegionEndpoints.list, {
            params: {
                search: searchTerm,
                per_page: count
            }
        })
}

export const getLocation = (lat: number, lng: number) => {
    return centralClient.get(LocationEndpoints.locationByCoordinates(lat, lng))
}
