import React, { useState } from 'react'
import { toAbsoluteUrl, toBRL } from 'utils/Functions'
import billStyle from '../InvoiceDetailPage.module.css'
import { useParams } from 'react-router-dom';
import { useGrocery } from 'layouts/common/core/GroceryProvider';
import { SalesOrder } from 'models/SalesOrder';
import { useQuery } from '@tanstack/react-query';
import { orderDetails } from '../../Order/core/_requests';

type Props = {
    forwardedRef: React.Ref<HTMLDivElement>;
}

function BillContent({forwardedRef}: Props) {
    const { groceryDetails } = useGrocery();
    const { id : orderId } = useParams()
    const [order, setOrder] = useState<SalesOrder>()

    const { isLoading: isOrderDetailLoading } = useQuery({
        queryFn: async () => {
            const response = await orderDetails(orderId!!)
            setOrder(response.data)
            return response
        },
        queryKey: ['order', orderId],
        enabled: !!orderId,
      })

  return (
    <div className={`${billStyle.container} container`} ref={forwardedRef}>
        <div className={`${billStyle.logoStyle} d-flex justify-content-center pb-2`}>
            <img src={toAbsoluteUrl('/media/logos/Vupty-Logo-160px.svg')} alt=''/>
        </div>

        <div className='d-flex flex-column mt-3'>
            <div className=' mt-1'>
                <span className='font500'>CNPJ: </span>
                {groceryDetails?.cnpj}
            </div>
            <div className=' mt-1'>
                <span className='font500'>Endereço: </span>
                {groceryDetails?.address?.street} - {groceryDetails?.address?.neighborhood}, {groceryDetails?.address?.number}
            </div>
            <div className=' mt-1'>
                <span className='font500'>Telefone comercial:</span>
                {groceryDetails?.cpf}
            </div>
            <div className=' mt-1'>
                <span className='font500'>E-mail:</span>
                {groceryDetails?.billing_email}
            </div>
        </div>

        {order &&
        <>
            <div className='d-flex flex-column mt-3'>
                <div className='font500 bottom-line d-flex justify-content-between font11rem'>
                    <span>Resumo da fatura atual</span>
                    <span>ID {order.id}</span>
                </div>
                <div className='d-flex justify-content-between font500 mt-1'>
                    <span>PRODUTOS - {toBRL(order.total_items_price)}</span>
                    <span>TOTAL - {toBRL(order.total_price)}</span>
                </div>
                <div>
                    <div className='mt-1'>
                    <span className='font500'>Data de emissão: </span>
                    {order.date}
                    </div>
                    <div className='mt-1'>
                    <span className='font500'>Fatura referente ao pedido de Nº: </span>
                    {order.id}
                    </div>
                    <div className='mt-1'>
                    <span className='font500'>Método de pagamento: </span>
                    {/* STILL NOT IN THE BD */}
                    {/* {order.payment_method} */}

                    </div>
                </div>
            </div>
            

            <div className='d-flex flex-column mt-3'>
                <div className='font500 bottom-line d-flex font11rem'>
                    <span>Sobre a cobrança</span>
                </div>
                <div>
                    <div className='mt-1'>
                        <span className='font500'>Empresa: </span>
                        {groceryDetails?.company_name}
                    </div>
                    <div className='mt-1'>
                        <span className='font500'>CNPJ: </span>
                        {groceryDetails?.cnpj}
                    </div>
                    <div className='mt-1'>
                        <span className='font500'>Endereço: </span>
                        {groceryDetails?.address?.street} - {groceryDetails?.address?.neighborhood}, {groceryDetails?.address?.number}
                    </div>
                </div>
            </div>

            <div className='d-flex flex-column mt-3'>
                <div className='font500 bottom-line d-flex justify-content-between font11rem'>
                    <span>Entrega</span>
                    <span>R$ 15,00</span>
                </div>
                <div>
                    <div className='mt-1'>
                        <span className='font500'>Responsável pelo recebimento: </span>
                        {order?.date}
                    </div>
                    <div className='mt-1'>
                        <span className='font500'>Endereço de entrega: </span>
                        {groceryDetails?.address?.street} - {groceryDetails?.address?.neighborhood}, {groceryDetails?.address?.number}
                        <div>
                            <div>
                                <span className='font500'>Atenção: </span>
                                <span>informamos que você só irá ser cobrado(a) pelos produtos que foram entregues no ato do recebimento das suas compras. Os preços dos produtos, por sua vez, correspondem aos do momento da compra em nossa loja online</span>
                            </div>
                            <span>Listamos os produtos que não conseguimos efetuar a compra por motivos maiores como ausência no estoque ou quantidade insuficiente.</span>
                        </div>
                    </div>

                </div>
            </div>

            {/* TABLE OF ITENS */}
            <div className={`${billStyle.billTables}`}>
                {/* DELIVERED ITEMS */}
                <div className='table-responsive'>
                    <span className='font500 font11rem d-flex justify-content-center my-2'>PRODUTOS</span>
                    <table className={`${billStyle.tableStyle} table mb-0`}>
                    <thead>
                        <tr className='text-center'>
                        <th scope='col'>DESCRIÇÃO</th>
                        <th scope='col'>UNIDADE</th>
                        <th scope='col'>QTD</th>
                        <th scope='col'>TOTAL</th>
                        </tr>
                    </thead>
                    <colgroup>
                        <col style={{width:'45%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'15%'}}/>
                        <col style={{width:'20%'}}/>
                    </colgroup>
                    <tbody>
                        {order?.invoice?.items?.map((item: any, idx: any) => (
                        <tr key={idx}>
                            <td className={billStyle.productDescription}>
                                <span className={billStyle.descriptionStyle}>{item.product.description}</span>
                                {/* CHANGE TO BARCODE LATER */}
                                <span className='font08rem font300'>{item.product.sku}</span>
                            </td>
                            <td className='text-center'>{toBRL(item.unit_price)}</td>
                            <td className='text-center'>{item.quantity}</td>
                            <td className='text-center'>{toBRL(item.total_price)}</td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                
                {/* UNDELIVERED ITEMS */}
                {order.invoice?.items_canceled && order.invoice.items_canceled.length > 0 &&
                <div className='table-responsive'>
                    <span className={billStyle.notDelivered}>PRODUTOS NÃO RECEBIDOS</span>
                    <table className={`${billStyle.tableStyle} table mb-0`}>
                    <colgroup>
                        <col style={{width:'45%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'15%'}}/>
                        <col style={{width:'20%'}}/>
                    </colgroup>
                    <tbody>
                        {order.invoice?.items_canceled?.map((item: any, idx: any) => (
                            <tr key={idx}>
                                <td className={billStyle.productDescription}>
                                    <span className={billStyle.descriptionStyle}>{item.product.description}</span>
                                    {/* CHANGE TO BARCODE LATER */}
                                    <span className='font08rem font300'>{item.product.sku}</span>
                                </td>
                                <td className='text-center'>{toBRL(item.unit_price)}</td>
                                <td className='text-center'>{item.quantity}</td>
                                <td className='text-center'>{toBRL(item.total_price)}</td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>       
                }
            </div>

            <div className='font600 font12rem mt-3'>
                <div className='d-flex justify-content-between'>
                    <span>SUBTOTAL</span>
                    <span>{toBRL(order.total_items_price)}</span>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                    <span>ENTREGA</span>
                    <span>{toBRL(order.total_delivery_price)}</span>
                </div>
                <div className={`d-flex justify-content-between mt-2 ${billStyle.billTotal}`}>
                    <span>TOTAL DA COMPRA</span>
                    <span>{toBRL(order.total_price)}</span>
                </div>
            </div>
        </>
        }

    </div>
  )
}

export default BillContent
