import { useMutation } from "@tanstack/react-query"
import PageTitle from "components/common/PageTitle/PageTitle"
import { useFormik } from "formik"
import { Feedback, initialFeedback } from "models/Feedback"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { toAbsoluteUrl } from "utils/Functions"
import { HOME_PAGE, toUrl } from "utils/Links"
import * as Yup from 'yup'
import feedbackStyle from "./FeedbackPage.module.css"
import { create } from "./core/_requests"
import ratingButtons from "./core/ratingButtons"

const validationSchema = Yup.object().shape({
  rate: Yup.number()
  .min(0, 'Selecione uma nota'),
  satisfaction: Yup.number()
  .min(0, 'Selecione seu nível de satisfação')
})

function FeedbackPage() {
  const {rates, satisfactions} = ratingButtons
  const navigate = useNavigate()
  const [isSuccessful, setIsSuccessful] = useState(false)

  const formik = useFormik({
    initialValues: initialFeedback,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      sendFeedbackRequest(values)
    },
  })

  const { mutate : sendFeedbackRequest, isPending } = useMutation({
    mutationFn: async (payload : Feedback) => {
      const response = await create(payload)
      return response
    },
    mutationKey: ['create-feedback'],
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: () => {
      setIsSuccessful(true)
    }
  })

  return (
    <div>
      <PageTitle title={`Feedback`} />
        <div className="container">
          {!isSuccessful &&
            <form onSubmit={formik.handleSubmit} >
              <div>
                <div className={feedbackStyle.textStyle}>
                  <span>Qual foi o seu nível de satisfação utilizando o nosso aplicativo?</span>
                </div>
                <div className="d-flex row my-4">
                  {rates.map((rate, idx) => (
                    <div key={idx} className="col px-2 font12rem">
                      <div
                        onClick={() => {formik.setFieldValue('rate',idx)}}
                        className={`col-12 text-center py-1 ${feedbackStyle.button} ${formik.values.rate === idx && feedbackStyle.buttonSelected}`}
                      >
                        {rate.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <div className={feedbackStyle.textStyle}>
                  <span>Nos conte como foi a sua experiência utilizando o nosso aplicativo em termos de usabilidade (facilidade de acesso)</span>
                </div>

                <div className="d-flex row my-4">
                  {satisfactions.map((satisfaction, idx) => (
                    <div key={idx} className="col px-1">
                      <div
                        onClick={() => {formik.setFieldValue('satisfaction',idx)}}
                        className={`col-12 d-flex flex-column align-items-center p-2 ${feedbackStyle.button} ${formik.values.satisfaction === idx && feedbackStyle.buttonSelected}`}
                      >
                        <img src={toAbsoluteUrl(satisfaction.emoji)} alt={satisfaction.emoji}/>
                        <span>{satisfaction.title}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <textarea
                  className={feedbackStyle.inputBox}
                  placeholder="Descreva sua experiência utilizando o nosso aplicativo..."
                  {...formik.getFieldProps('experience_feedback')}
                />
              </div>

              <div className="mt-3">
                <div className={feedbackStyle.textStyle}>
                  <span>Você enfrentou algum problema e/ou bug enquanto utilizava o nosso aplicativo?</span>
                </div>
                <div className="d-flex row text-center my-3">
                    <div className="col px-2">
                      <div
                      className={`col-12 py-2 ${feedbackStyle.button} ${formik.values.bugs === 1 && feedbackStyle.buttonSelected}`}
                      onClick={() => formik.setFieldValue('bugs', 1)}>
                        Sim. Tive alguns... <img src={toAbsoluteUrl('/media/emojis/Thumbs-Down.svg')} alt=""/>
                      </div>
                    </div>
                    <div className="col">
                      <div
                      className={`col-12 py-2 ${feedbackStyle.button} ${formik.values.bugs === 0 && feedbackStyle.buttonSelected}`}
                      onClick={() => formik.setFieldValue('bugs', 0)}>
                        Não. Tudo certo! <img src={toAbsoluteUrl('/media/emojis/Thumbs-Up.svg')} alt=""/>
                      </div>
                    </div>
                </div>

                {formik.values.bugs === 1 &&
                  <div>
                    <label className="d-flex flex-column">
                      <textarea
                        className={feedbackStyle.inputBox}
                        placeholder="Conte-nos mais sobre o problema encontrado..."
                        {...formik.getFieldProps('bug_description')}
                      />
                    </label>
                  </div>
                }
              </div>

              <button type='submit'
                disabled={isPending || !formik.isValid}
                className={feedbackStyle.sendButton}
              >
                Enviar meu feedback
              </button>
            </form>
          }
          {isSuccessful &&
            <div className="d-flex flex-column align-items-center">
              <img src={toAbsoluteUrl('/media/Illustrations/Star-Illustration.svg')} alt='star' className="py-3"/>
              <p className="main-color font12rem font500">Sua opinião é muito importante para nós!</p>
              <span className="text-center">Com o seu feedback, podemos tomar decisões mais assertivas, sempre levando em consideração as suas expectativas sobre o nosso serviço.</span>
              <div className={feedbackStyle.sendButton} onClick={() => navigate(toUrl(HOME_PAGE))}>
                <img src={toAbsoluteUrl('media/ChevronsAndArrows/Arrow-Left.svg')} alt='arrow' className="pe-2"/>
                Voltar para página principal
              </div>
            </div>
          }
        </div>
    </div>
  )
}

export default FeedbackPage
