import React from 'react';
import styled from 'styled-components';
import FooterMenu from '../FootMenu/FooterMenu';

const Container = styled.div`
    position: fixed;
    bottom: 0px;
    overflow: hidden;
    width: 100%;
    background-color: #e6e9ea;
    `;

const Footer: React.FC = () => {
    return (
        <Container>
            {/* TODO: TOTAL CART SHOULD BE HERE */}
            <FooterMenu />
        </Container>
    );
}

export default Footer;
