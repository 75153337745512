import { SuspensedLayout } from "layouts/common/SuspensedLayout/SuspensedLayout";
import { useAuth } from "modules/auth/core/AuthProvider";
import AccountWrapper from "pages/desktop/Account/AccountWrapper";
import AccountMobileWrapper from "pages/mobile/Account/AccountWrapper";
import CheckoutWrapperMobile from "pages/mobile/Checkout/CheckoutWrapper";
import MissionDetailPage from "pages/mobile/Mission/MissionDetailPage";
import MissionPage from "pages/mobile/Mission/MissionPage";
import { lazy } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { normalizeRoutes } from "utils/Functions";
import { LOGIN_PAGE, STORE_FRONT_PAGE, toUrl } from "utils/Links";

function PrivateRoutes() {
    const { auth } = useAuth();
    const location = useLocation();

    const CheckoutWrapper = lazy(() => import('pages/desktop/Checkout/CheckoutWrapper'))
    // const CheckoutWrapperMobile = lazy(() => import('pages/mobile/Checkout/CheckoutWrapper'))
    const RequestPage = lazy(() => import('pages/desktop/Request/RequestPage'))
    const RequestPageMobile = lazy(() => import('pages/mobile/Request/RequestPage'))
    // const AccountWrapper = lazy(() => import('pages/desktop/Account/AccountWrapper'))
    const AccountWrapperMobile = lazy(() => import('pages/mobile/Account/AccountWrapper'))

    const routes = useRoutes(normalizeRoutes([
        {
            path: "checkout/*",
            element: <SuspensedLayout><CheckoutWrapper /></SuspensedLayout>,
            elementMobile: <CheckoutWrapperMobile />,
        },
        {
            path: "pedido/:id",
            element: <SuspensedLayout><RequestPage /></SuspensedLayout>,
            elementMobile: <SuspensedLayout><RequestPageMobile /></SuspensedLayout>,
        },
        {
            path: 'missao',
            elementMobile: <MissionPage />,
        },
        {
            path: 'missao/:id',
            elementMobile: <MissionDetailPage />
        },
        {
            path: "conta/*",
            element: <AccountWrapper />,
            elementMobile: <AccountMobileWrapper />,
        },
        {
            path: "*",
            element: <Navigate to={toUrl(STORE_FRONT_PAGE)} />,
        },
    ]))

    //if logged in, return routes
    if (auth && auth.token) return routes;

    return <Navigate to={toUrl(LOGIN_PAGE)} replace state={{ from: location }} />;
}

export default PrivateRoutes;
