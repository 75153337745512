import { regionalClient } from "index";
import { StoreCart } from "models/StoreCart";
import { CartEndpoints } from "modules/api/endpoints/CartEndpoints";
import { GroceryEndpoints } from "modules/api/endpoints/GroceryEndpoints";
import { StoreConfigEndpoints } from "modules/api/endpoints/StoreConfigEndpoints";

export const pushStoreCart = (cart: StoreCart) => {
  return regionalClient.post<StoreCart>(CartEndpoints.pushStoreCart, cart);
};

export const pullStoreCart = () => {
  return regionalClient.get<StoreCart>(CartEndpoints.pullStoreCart);
};

export const getGroceryDetails = () => {
  return regionalClient.get(GroceryEndpoints.details);
};

export const getGroceryPreferences = () => {
  return regionalClient.get(GroceryEndpoints.preferences);
};

export const getBusinessDetails = () => {
  return regionalClient.get(StoreConfigEndpoints.getBusinessDetails);
};
