import { useQuery } from "@tanstack/react-query";
import SuggestedItem from "components/desktop/SuggestedItem/SuggestedItem";
import { useModalContext } from "layouts/common/core/StoreModalProvider";
import { Category, determineIfIsCategory } from "models/Category";
import { Product, determineIfIsProduct } from "models/Product";
import { listProductSuggestion } from "pages/desktop/Search/core/_requests";
import { ChangeEventHandler, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { STORE_FRONT_SEARCH_CATEGORY_PAGE, STORE_SEARCH_PAGE, toUrl } from "utils/Links";
import LiveSearch from "./LiveSearch";
import { listTopCategories } from "./core/_requests";

const MIN_SEARCH_TERM_LENGTH = 2;

function SearchBar() {
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get("query") || "";
    const [searchTerm, setSearchTerm] = useState(query);
    const [searchSuggestions, setSearchSuggestions] = useState<any>([]);
    const [searchProducts, setSearchProducts] = useState<Product[]>([]);
    const searchTermDebounce = useDebounce<string>(searchTerm, 500);
    const [products, setProducts] = useState([] as Product[]);
    const [categories, setCategories] = useState<Category[]>([])
    const [loading, setLoading] = useState(false);
    const { showProductModal } = useModalContext();
    const navigate = useNavigate();

    const { isLoading: isProductSuggestionLoading } = useQuery({
        queryFn: async () => {
            const response = await listProductSuggestion(searchTermDebounce)
            setSearchSuggestions(response.data.suggestions);
            setSearchProducts(response.data.products);
            return response
        },
        queryKey: ['productSuggestion', searchTermDebounce],
        enabled: searchTermDebounce.length >= MIN_SEARCH_TERM_LENGTH,
    })

    const handleSearchbarTermChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value.length < MIN_SEARCH_TERM_LENGTH) {
            setSearchSuggestions([]);
            setSearchProducts([]);
            return;
        }
    };

    const handleSearch = (item: any) => {
        clearInput();
        if (determineIfIsCategory(item)) {
            let fullPath = item.slug;
            while (item.parent) {
                item = item.parent;
                fullPath = `${item.slug}/${fullPath}`;
            }
            navigate(toUrl(STORE_FRONT_SEARCH_CATEGORY_PAGE, fullPath));
            return;
        }
        if (determineIfIsProduct(item)) {
            showProductModal(item.id);
            return;
        }
        navigate(toUrl(STORE_SEARCH_PAGE, item.toLocaleLowerCase()));
    };

    const clearInput = () => {
        setSearchTerm("");
        setSearchSuggestions([]);
        setSearchProducts([]);
    };

    useQuery({
        queryFn: async () => {
            const response = await listTopCategories()
            setCategories(response.data)
            return response.data
        },
        queryKey: ['topCategories']
    })

    return (

        <LiveSearch
            placeholder={'Do que você precisa?'}
            loading={loading}
            suggestions={searchSuggestions}
            products={searchProducts}
            categories={categories}
            value={searchTerm}
            renderCategory={(item) => <span>{item.name}</span>}
            renderProduct={(product: Product) => <SuggestedItem product={product} />}
            renderSuggestion={(item) => <span>{item}</span>}
            onChange={handleSearchbarTermChange}
            onSelect={handleSearch}
            onEnter={handleSearch}
        />

    );
}

export default SearchBar;
