import { useRoutes } from 'react-router-dom'
import ProfileContactPage from './ProfileContactPage'
import ProfileContactAddPage from './ProfileContactAddPage'
import ProfileContactEditPage from './ProfileContactEditPage'
import ProfilePage from './ProfilePage'
import ProfileOwnerInfo from './ProfileOwnerPage'



function ProfileWrapper() {
    const routes = useRoutes([
        {
            path: 'contato',
            element: <ProfileContactPage />,
        },
        {
            path: 'contato/proprietario',
            element: <ProfileOwnerInfo />,
        },
        {
            path: 'contato/adicionar',
            element: <ProfileContactAddPage />,
        },
        {
            path: 'contato/:id',
            element: <ProfileContactEditPage />,
        },
        {
          path: '*',
          element: <ProfilePage />,
        },
    ])
    return routes
}

export default ProfileWrapper
