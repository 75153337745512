import { useQuery } from '@tanstack/react-query'
import PageTitle from 'components/common/PageTitle/PageTitle'
import { Category } from 'models/Category'
import { useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { STORE_FRONT_SEARCH_CATEGORY_PAGE, toUrl } from 'utils/Links'
import { listCategories } from '../StoreFront/core/_requests'
import categoryStyle from './CategoriesPage.module.css'

function CategoriesPageMobile() {
  const [currentPage, setCurrentPage] = useState(0)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [totalPages, setTotalPages] = useState(-1)
  const [categories, setCategories] = useState<Category[]>([]);

  const { data: categoryData, isLoading: isCategoryLoading, isFetching: isCategoryFetching, isError : isCategoryError } = useQuery({
    queryFn: async () => {
      const response = await listCategories(currentPage)
      setCategories((prev) => {
        return [...prev, ...response.data.content]
      })
      setTotalPages(response.data.total_pages)
      setIsLoadingMore(false)
      return response
    },
    queryKey: ['listCategories', currentPage],
  })

  const hasMoreContent = useMemo(() => {
    return totalPages <= 1 ? false : totalPages >= (currentPage + 1)
  }, [currentPage, totalPages])

  const onLoadMoreContent = (page: number) => {
    if (isCategoryLoading || currentPage >= totalPages - 1) return
    setCurrentPage((prev) => prev + 1)
    setIsLoadingMore(true)
  }
 
  return (
    <>
      <PageTitle title={`CATEGORIAS`} />
  
      {/* {(!isCategoryFetching && isCategoryError) &&
          <div className='d-flex flex-column align-items-center'>
            <img src={toAbsoluteUrl('/media/Illustrations/Empty-Store.svg')} alt='' style={{maxWidth: '300px'}}/>
            <span className='main-color font500 mt-3'>Erro ao carregar categorias</span>
          </div>
      } */}

      <div className={categoryStyle.completeGrid}>
        <div className='container mt-3'>
          <InfiniteScroll
            className={categoryStyle.gridContainer}
            pageStart={0}
            initialLoad={false}
            loadMore={onLoadMoreContent}
            hasMore={hasMoreContent}
          >

            {categories?.map((category, idx) => (
              <div key={idx}  className={`${categoryStyle.completeCardCategory}`}>
                <Link to={toUrl(STORE_FRONT_SEARCH_CATEGORY_PAGE, category.slug)} className='category-modal-link'>
                  <div className={categoryStyle.completeCategoryImage}>
                    <img
                      src={`${category.thumbnail?.url || 'https://fakeimg.pl/42x42/cccccc/033858'}`}
                      alt='icon'
                      className={`${categoryStyle.categoryImage}`}
                    />
                    <div className='main-color px-2 font500 text-start'>
                      {category.name.length > 20
                        ? `${category.name.substring(0, 20)}...`
                        : category.name}
                    </div>
                  </div>
                  {/* SE O NOME DA CLASSE FOR MAIOR QUE 18, APOS 18 CARACTERES ENTRUNCAR A STRING */}
                </Link>
              </div>
            ))}

          </InfiniteScroll>
        </div>
      </div>
    </>
  )
}

export default CategoriesPageMobile
