import CardBRLStyle from "components/common/CardBRLStyle/CardBRLStyle";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import { Product } from "models/Product";
import { useCallback, useMemo } from "react";
import { getActualProductPriceRange, getNextProductPriceRange, toBRL } from "utils/Functions";
import ProductButtonSkeleton from "../Skeletons/ProductButtonSkeleton";
import btnUnit from "./BtnCard.module.css";

type Props = {
  product: Product;
  selected: boolean;
  loading?: boolean;
  onClick?: () => void;
};

function BtnUnit({ product, loading = true, selected, onClick }: Props) {
  const { getItemCart } = useStoreCart();
  const itemCart = useMemo(() => getItemCart(product.id), [product, getItemCart]);
  const isDisabled = useMemo(() => {
    if (!product || !product.supply_type) return true;
    return product.supply_type.value === "2";
  }, [product]);

  const getUnitPrice = useCallback(
    (product: Product) => {
      if (product.price_ranges) {
        const actualPriceRange = getActualProductPriceRange(product, itemCart?.quantity);
        if (actualPriceRange) {
          return actualPriceRange.unit_price;
        }
      }
      return product.sell_price;
    },
    [itemCart]
  );

  const leftValue = useMemo(() => {
    const unitPrice = getUnitPrice(product);
    const value = !isDisabled || !unitPrice ? unitPrice : null;

    return (
      <>
        <span className="text-start font09rem text-uppercase">{product.measurement_unit}</span>
        <span className={btnUnit.price}>
          <CardBRLStyle value={value} />
        </span>
      </>
    );
  }, [product, getUnitPrice, isDisabled]);

  const rightValue = useMemo(() => {
    if (!product.price_ranges || product.price_ranges.length === 0) {
      if (product.original_price) {
        return (
          <>
            <span></span>
            <span className={btnUnit.discount}>{toBRL(product.original_price)}</span>
          </>
        );
      }
      return "";
    }

    const nextPriceRange = getNextProductPriceRange(product, itemCart?.quantity);
    if (nextPriceRange) {
      return (
        <>
          <span className="text-start font600 font08rem">
            A PARTIR DE {nextPriceRange.min_quantity} {product.measurement_unit}.
          </span>
          <div className={btnUnit.values}>
            <span>{toBRL(nextPriceRange.unit_price)}</span>
          </div>
        </>
      );
    }

    return (
      <>
        <span></span>
        <span className={btnUnit.discount}>{toBRL(product.sell_price)}</span>
      </>
    );
  }, [product, itemCart]);

  const handleOnClick = () => {
    if (!isDisabled && onClick) {
      onClick();
    }
  };

  return (
    <>
      {loading && <ProductButtonSkeleton />}
      {!loading && (
        <div className={selected && !isDisabled ? btnUnit.btnSelected : btnUnit.btnItemFaded} onClick={handleOnClick}>
          <button className={btnUnit.btnPrimary} type="button" disabled={isDisabled}>
            <div className="d-flex flex-row px-2 py-1 justify-content-between">
              {leftValue && (
                <div className="d-flex flex-column align-items-start justify-content-between">{leftValue}</div>
              )}
              {!isDisabled && !!rightValue && (
                <div className="d-flex flex-column align-items-end justify-content-between">{rightValue}</div>
              )}
            </div>
          </button>
        </div>
      )}
    </>
  );
}

export default BtnUnit;
