import { useMutation, useQuery } from "@tanstack/react-query";
import Image from "components/common/Image/Image";
import Breadcrumbs from "components/common/breadcrumbs/Breadcrumbs";
import CartItemGroupWishlist from "components/desktop/CartItemGroup/CartItemGroupWishlist";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import { WishlistItemGroup } from "models/Wishlist";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getActualProductUnitPrice, getRootCategory, toAbsoluteUrl } from "utils/Functions";
import {
    ACCOUNT_ORDERS_PAGE,
    ACCOUNT_WHISHLIST_DETAIL_PAGE,
    ACCOUNT_WHISHLIST_PAGE,
    STORE_FRONT_PAGE,
    toUrl,
} from "utils/Links";
import suggestedStyles from "./AccountWishlistPage.module.css";
import { detail, remove } from "./core/_requests";
import AccountWishlistAddAllModal from "./modal/AccountWishlistAddAllModal/AccountWishlistAddAllModal";
import AccountWishlistDeleteModal from "./modal/AccountWishlistDeleteModal/AccountWishlistDeleteModal";

function AccountWishlistDetailPage() {

  const navigate = useNavigate();
  const { id } = useParams();
  const [isModalAddAllOpen, setIsModalAddAllOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const {addItemsCart} = useStoreCart();

  const { data: wishlist, isLoading: isWishlistLoading } = useQuery({
    queryFn: async () => {
      const response = await detail(id!!);
      return response.data;
    },
    queryKey: ["wishlist", id],
    enabled: id !== undefined,
  });

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: "Perfil",
        path: toUrl(ACCOUNT_ORDERS_PAGE),
      },
      {
        title: "Minhas listas",
        path: toUrl(ACCOUNT_WHISHLIST_PAGE),
      },
      {
        title: wishlist?.name || "Não encontrado",
        path: toUrl(ACCOUNT_WHISHLIST_DETAIL_PAGE, wishlist?.id),
      },
    ];
  }, [wishlist]);

  //mount items grouped
  const itemsGrouped = useMemo(() => {
    if (!wishlist || !wishlist.items) return;

    const result: WishlistItemGroup[] = [];

    wishlist.items.forEach((item) => {
      const rootCategory = getRootCategory(item.product.category);
      const itemGroupIdx = result.findIndex((itemGroup) => {
        return itemGroup.category === rootCategory;
      });
      const bestUnitPrice = getActualProductUnitPrice(item.product, item.quantity);

      if (itemGroupIdx === -1) {
        result.push({
          category: rootCategory,
          items: [item],
          total: item.quantity * bestUnitPrice,
          total_quantity: item.quantity,
          open: true,
        });
      } else {
        result[itemGroupIdx].items.push(item);
        result[itemGroupIdx].total = result[itemGroupIdx].items.reduce((total, item) => {
          const bestUnitPrice = getActualProductUnitPrice(item.product, item.quantity);
          return total + item.quantity * bestUnitPrice;
        }, 0);
        result[itemGroupIdx].total_quantity = result[itemGroupIdx].items.reduce((total, obj) => {
          return total + obj.quantity;
        }, 0);
      }
    });

    return result.sort((a, b) => {
      //ordena por ordem alfabética
      return a.category.localeCompare(b.category);
    });
  }, [wishlist]);

  const { mutate: sendRemoveWishlistRequest } = useMutation({
    mutationFn: async (id : string) => {
      const response = await remove(id);
      return response;
    },
    mutationKey: ["removeWishlist", id],
    onSuccess: () => {
      toast.error("Lista de compras removida");
      navigate(toUrl(ACCOUNT_WHISHLIST_PAGE));
    },
    onError: (error) => {
      toast.error("Erro ao tentar remover a lista de compras");
    },
  });

  const handleDeleteModalClose = (remove: boolean = false) => {
    if (remove && wishlist?.id) {
      sendRemoveWishlistRequest(wishlist.id);
    }
    setIsModalDeleteOpen(false);
  };

  const handleAddAllModalClose = (add : boolean = false) => {
    if (add && wishlist) {
      const itemsToAdd = wishlist.items.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
        };
      })
      addItemsCart(itemsToAdd);
    }
    setIsModalAddAllOpen(false);
  }

  return (
    <>
      <AccountWishlistAddAllModal show={isModalAddAllOpen} onHide={handleAddAllModalClose} />
      <AccountWishlistDeleteModal show={isModalDeleteOpen} wishlist={wishlist} onHide={handleDeleteModalClose} />

      <div className="container">
        <div className="mb-4">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <span className="font600 font15rem">{wishlist?.name}</span>
        </div>

        {itemsGrouped && itemsGrouped.length === 0 && (
          <div className={`${suggestedStyles.listItems} d-flex flex-column align-items-center`}>
            <img src={toAbsoluteUrl("/media/Illustrations/Clipboard-Illustration.svg")} alt="" />
            <span className="mt-4">Sua lista está vazia</span>
            <span>Adicione produtos clicando no botão abaixo:</span>
            <div
              className={`${suggestedStyles.backToListsButton} mt-4`}
              onClick={() => navigate(toUrl(STORE_FRONT_PAGE))}
            >
              Ir para loja
            </div>
          </div>
        )}

        {itemsGrouped && itemsGrouped.length > 0 && (
          <>
            <div className="d-flex justify-content-between px-3 pb-2">
              <div className="cursor-pointer" onClick={() => setIsModalDeleteOpen(true)}>
                <span className="text-decoration-underline pe-2">Remover lista</span>
                <Image
                  src={toAbsoluteUrl("/media/general/Trash-Outline-Icon.svg")}
                  alt=""
                  className={suggestedStyles.listDetailTrash}
                />
              </div>
              <div className="cursor-pointer" onClick={() => setIsModalAddAllOpen(true)}>
                <span className="text-decoration-underline pe-2">Adicionar lista completa ao carrinho</span>
                <Image src={toAbsoluteUrl("/media/icons/Plus-Icon.svg")} alt="" className="black-filter " />
              </div>
            </div>
            <div className={suggestedStyles.groupedList}>
              {itemsGrouped.map((itemGroup, idx) => (
                <CartItemGroupWishlist key={idx} itemGroup={itemGroup} wishlist={wishlist!!} editable={true}/>
              ))}
            </div>
          </>
        )}

        {!wishlist && (
          <div className={`${suggestedStyles.listItems} d-flex flex-column align-items-center`}>
            <img src={toAbsoluteUrl("/media/Illustrations/Clipboard-Illustration.svg")} alt="" />
            <span className="mt-4">Não encontramos a lista</span>
            <span>veja todas as suas listas clicando no botão abaixo:</span>
            <div
              className={`${suggestedStyles.backToListsButton} mt-4`}
              onClick={() => navigate(toUrl(ACCOUNT_WHISHLIST_PAGE))}
            >
              <img src={"/media/ChevronsAndArrows/Arrow-Left.svg"} alt="" />
              Minhas Listas
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AccountWishlistDetailPage;
