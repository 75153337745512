export type ChangeEmailRequest = {
    current_email: string
    new_email: string
    new_email_confirmation: string 
}

export const initialChangeEmailRequest: ChangeEmailRequest = {
    current_email: '',
    new_email: '',
    new_email_confirmation: '',
}