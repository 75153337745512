import { KTSVG } from 'components/common/KTSVG'
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'
import { AccountCoupon, Coupon } from 'models/Coupon'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import fakeData from 'resources/data/fake-data'
import { ACCOUNT_COUPONS_PAGE, ACCOUNT_ORDERS_PAGE, toUrl } from 'utils/Links'
import couponStyle from './AOStyle.module.css'

function AOCoupons() {
  const navigate = useNavigate()
  const {coupons, missions} = fakeData

  const breadcrumbs = useMemo(() => {
    return(
      [          
        {
          title : 'Perfil',
          path: toUrl(ACCOUNT_ORDERS_PAGE),
        },
        {    
          title : 'Cupons',
          path : toUrl(ACCOUNT_COUPONS_PAGE),
        }
      ]
    )
  }, [])

  function copiedCoupon(coupon: Coupon) {
    navigator.clipboard.writeText(coupon.code || '')
    toast.success('cupom copiado', {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  }

  const columns = [
    {
      name: 'Código de desconto',
      selector: (row: Coupon) => row.code,
      cell: (row: Coupon) => (
        <span className='cursor-pointer' onClick={() => copiedCoupon(row)}>
          {row.code}
        </span>
      ),
    },
    {
      name: 'Data de ativação',
      selector: (row: Coupon) => row.start_date,
      cell: (row: Coupon) => <span>{row.start_date}</span>,
    },
    {
      name: 'Data de validade',
      selector: (row: Coupon) => row.end_date,
      cell: (row: Coupon) => <span>{row.end_date}</span>,
    },
    {
      name: 'Desconto',
      selector: (row: Coupon) => row.discount,
      cell: (row: Coupon) => <span className='font600'>{row.discount}</span>,
    },
    {
      name: 'Situação',
      selector: (row: AccountCoupon) => row.status,
      cell: (row: AccountCoupon) => (
        <span className={`badgeStatus${row.status_slug} orderbadgeStatus`}>{row.status}</span>
      ),
    },
  ]

  // MODAL CONTROL 
  function handleDateSelectorChange(value: string) {
    console.log(value)
  }

  return (
    <div>
      <div className='mb-4'>
        <Breadcrumbs breadcrumbs={breadcrumbs}/>
        <span className='font600 font15rem'>CUPONS DE DESCONTO</span>
      </div>

      {coupons.length === 0 &&
        <div className='d-flex justify-content-center'>
          <div className={couponStyle.emptyPage}>
            <div className='d-flex flex-column align-items-center'>
              <KTSVG path='/media/general/Big-Ticket.svg' className='pb-5' />
              <span className='pb-3 main-color font500 font12rem'>
                Nenhum cupom de desconto até o momento. Você tem acesso aos coupons de desconto
                disponíveis para aplicar na hora da compra na etapa “Detalhes do seu pedido”.
              </span>
            </div>
          </div>
        </div>
      }
      {coupons.length > 0 &&
        <div className={couponStyle.missionCards}>
          {/* {missions.map((mission, idx) => (
            <MissionCard key={idx} mission={mission}/>
          ))} */}
        </div>
      }
    </div>
  )
}

export default AOCoupons
