import { SubComponentProps } from "components/common/NewDatatable/NewDatatable";
import { SalesOrder } from "models/SalesOrder";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "utils/Functions";
import { ACCOUNT_ORDERS_TRACKING_MODAL, ACCOUNT_ORDER_DETAILS_MODAL, INVOICE_PAGE, RECEIPT_PAGE, toUrl } from "utils/Links";
import orderStyle from "../AccountOrderPage.module.css";

const AccountOrderTableSubRow: FC<SubComponentProps<SalesOrder>> = ({ row }) => {
  const navigate = useNavigate();

  return (
    <div className={`${orderStyle.orderIcons} d-flex justify-content-center`}>
      <button
        className={`${orderStyle.orderIconsButtons} m-2`}
        onClick={() => navigate(toUrl(ACCOUNT_ORDER_DETAILS_MODAL, row.original.id))}
      >
        <img src={process.env.PUBLIC_URL + "/media/general/List-Icon.svg"} alt="list" className="mb-2" />
        Resumo
      </button>

      <button
        className={`${!row.original.active ? orderStyle.disabledButtons : orderStyle.orderIconsButtons} m-2`}
        onClick={() => navigate(toUrl(RECEIPT_PAGE, row.original.id))}
        disabled={!row.original.active}
      >
        <img src={toAbsoluteUrl("/media/general/Scroll-Icon.svg")} alt="scroll" className="mb-2" />
        Nota Fiscal
      </button>

      <button 
        className={`${orderStyle.orderIconsButtons} m-2`}
        onClick={() => navigate(toUrl(ACCOUNT_ORDERS_TRACKING_MODAL, row.original.id))}
      >
        <img src={toAbsoluteUrl("/media/general/Map-Icon.svg")} alt="map" className="mb-2" />
        Rastreio
      </button>

      <button
        className={`${!row.original.active ? orderStyle.disabledButtons : orderStyle.orderIconsButtons} m-2`}
        onClick={() => navigate(toUrl(INVOICE_PAGE, row.original.id))}
        disabled={!row.original.invoice}
      >
        <img src={toAbsoluteUrl("/media/general/Bill-Icon.svg")} alt="bill" className="mb-2" />
        Fatura
      </button>
    </div>
  );
};

export default AccountOrderTableSubRow;
