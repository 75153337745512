import { useStoreConfig } from 'layouts/common/core/StoreConfigProvider';
import { Link, useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from 'utils/Functions';
import { ACCOUNT_MENU_PAGE, HOME_PAGE, STORE_FRONT_PAGE, STORE_SIGNUP, toUrl } from 'utils/Links';
import errorStyle from './ErrorHeader.module.css';


function ErrorHeader() {
  const { business } = useStoreConfig();
  const navigate = useNavigate()
  return (
    <>
    <div className={errorStyle.topbar}>
        <span className='button-color px-4'>
            <img src={toAbsoluteUrl("/media/general/Mail-Fill.svg")} alt='logo'  className='transform-1px'/>
            {business.email}
        </span>
        <span className='button-color px-4'>
            <img src={toAbsoluteUrl("/media/general/Whatsapp-Icon-Fill.svg")} alt='logo' className='transform-1px'/>
            {business.business_telephone_number}
        </span>
    </div>

    <div className={errorStyle.header}>
      <div className={`container d-flex justify-content-between ${errorStyle.headerContent}`}>
        <Link to={toUrl(STORE_FRONT_PAGE)}>
            <img src={toAbsoluteUrl('/media/logos/Navbar-Logo.svg')} alt='logo' height={'32px'}/>
        </Link>
        <div onClick={() => navigate(toUrl(STORE_FRONT_PAGE))}>
          Loja
        </div>
        <div onClick={() => navigate(toUrl(HOME_PAGE))}>
          Sobre
        </div>
        <div onClick={() => navigate(toUrl(ACCOUNT_MENU_PAGE))}>
          Perfil
        </div>
        <div onClick={() => navigate(toUrl(STORE_SIGNUP))}>
          Cadastro
        </div>
      </div>
    </div>
    </>
  )
}

export default ErrorHeader
