import ProductLayoutSelector from 'components/mobile/ProductLayoutSelector/ProductLayoutSelector';
import VerticalCardSkeleton from 'components/mobile/Skeletons/VerticalCardSkeleton';
import ProductCardVertical from 'layouts/mobile/ProductCard/ProductCardVertical';
import { ProductLayoutProps } from 'models/Product';
import { FC } from 'react';
import productLayoutDefaultStyles from './ProductLayoutDefault.module.css';


const ProductLayoutVertical: FC<ProductLayoutProps> = ({ loading = true, products, title }) => {

    const VerticalLayoutSkeleton: FC = () => {
        return <>
            {[...Array(4)].map((_, idx) => (
                <VerticalCardSkeleton key={idx} />
            ))}
        </>
    }

    return <div className={`d-flex justify-content-center row ${productLayoutDefaultStyles.cardComponentMobile}`}>
        {!loading && (
            <div className={productLayoutDefaultStyles.cardText}>
                <h3 className='my-4'>{title}</h3>
                <ProductLayoutSelector />
            </div>
        )}
        <div className={productLayoutDefaultStyles.verticalGrid}>
            {(loading) && <VerticalLayoutSkeleton />}
            {!loading && products?.map((product, idx) => (
                <ProductCardVertical key={idx} product={product} loading={loading} />
            ))}
        </div>
    </div>;
}

export default ProductLayoutVertical;
