import clsx from 'clsx';
import { useStoreConfig } from 'layouts/common/core/StoreConfigProvider';
import React from 'react';
import { toAbsoluteUrl } from 'utils/Functions';

const ProductLayoutSelector: React.FC = () => {

    const {storeConfig, setProductCardType} = useStoreConfig()

    return (
        <div className='d-flex gap-2 align-items-center'>
            <div onClick={() => setProductCardType("horizontal")}>
                <img src={toAbsoluteUrl('/media/general/Long-Card-Icon.svg')}
                    className={clsx({ 'faded-filter': storeConfig.content.productCardType === "vertical" }, 'p-0')}
                    alt='Icon' />
            </div>
            <div onClick={() => setProductCardType("vertical")}>
                <img src={toAbsoluteUrl('/media/general/Short-Card-Icon.svg')}
                    className={clsx({ 'faded-filter': storeConfig.content.productCardType === "horizontal" }, 'p-0')}
                    alt='Icon' />
            </div>
        </div>
    )
}

export default ProductLayoutSelector;
