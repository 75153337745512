import { centralClient } from "index";
import { ChangeEmailRequest } from "models/ChangeEmailRequest";
import { ChangePasswordRequest } from "models/ChangePasswordRequest";
import { AccountEndpoints } from "modules/api/endpoints/AccountEndpoints";

export const changePassword = (changePasswordRequest : ChangePasswordRequest) => {
    return centralClient.post(AccountEndpoints.changePassword, changePasswordRequest)
}

export const changeEmail = (changeEmailRequest : ChangeEmailRequest) => {
    return centralClient.post(AccountEndpoints.changeEmail, changeEmailRequest)
}