import StoreApp from "StoreApp";
import CategoriesModal from "components/desktop/CategoriesModal/CategoriesModal";
import ProductDetailModal from "components/desktop/ProductDetailModal/ProductDetailModal";
import { SuspensedLayout } from "layouts/common/SuspensedLayout/SuspensedLayout";
import { useStoreLocation } from "layouts/common/core/StoreLocationProvider";
import AOContact from "pages/desktop/Account/AccountOutlets/AOContact";
import SalesPage from "pages/desktop/Sales/SalesPage";
import ContactPage from "pages/mobile/Account/Contact/ContactPage";
import BrandsPageMobile from "pages/mobile/Brands/BrandsPageMobile";
import CategoriesPageMobile from "pages/mobile/Categories/CategoriesPage";
import FeedbackPage from "pages/mobile/FeedbackPage/FeedbackPage";
import ProductPageMobile from "pages/mobile/Product/ProductPage";
import { FC, lazy } from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { normalizeRoutes } from "utils/Functions";
import { CITY_LOCALIZER_PAGE, STORE_FRONT_PAGE, toUrl } from "utils/Links";
import PrivateRoutes from "./PrivateRoutes";

const StoreRoutes: FC = () => {
  const StoreFrontPageMobile = lazy(() => import("pages/mobile/StoreFront/StoreFrontPage"));
  const StoreFrontPage = lazy(() => import("pages/desktop/StoreFront/StoreFrontPage"));
  const SearchPage = lazy(() => import("pages/mobile/Search/SearchPage"));
  const SearchTermPage = lazy(() => import("pages/desktop/Search/SearchTermPage"));
  const SearchTermPageMobile = lazy(() => import("pages/mobile/Search/SearchTermPage"));
  // const ShoppingCartPage = lazy(() => import('pages/desktop/ShoppingCart/ShoppingCartPage'))
  // const ShoppingCartPageMobile = lazy(() => import('pages/mobile/ShoppingCart/ShoppingCartPage'))
  const SearchBrandPage = lazy(() => import("pages/desktop/Search/SearchBrandPage"));
  const SearchTagPage = lazy(() => import("pages/desktop/Search/SearchTagPage"));
  const SearchTagPageMobile = lazy(() => import("pages/mobile/Search/SearchTagPage"));
  const BrandsPage = lazy(() => import("pages/desktop/BrandsPage/BrandsPage"));
  const CitySearchPageMobile = lazy(() => import("pages/mobile/CitySearch/CitySearchPage"));
  const CitySearchPage = lazy(() => import("pages/desktop/CitySearch/CitySearchPage"));
  const SearchCategoryPageMobile = lazy(() => import("pages/mobile/Search/SearchCategoryPage"));
  const SearchCategoryPage = lazy(() => import("pages/desktop/Search/SearchCategoryPage"));
  const SearchBrandPageMobile = lazy(() => import("pages/mobile/Search/SearchBrandPage"));
  const SearchCategoryWrapper = lazy(() => import("pages/desktop/Search/SearchCategoryWrapper"));
  const ShoppingCartWrapper = lazy(() => import("pages/desktop/ShoppingCart/ShoppingCartWrapper"));
  const ShoppingCartWrapperMobile = lazy(() => import("pages/mobile/ShoppingCart/ShoppingCartWrapper"));

  const navigate = useNavigate();
  const previousPath = document.referrer;
  const { storeLocation } = useStoreLocation();

  const handleClose = () => {
    if (previousPath && previousPath.startsWith(window.location.origin)) {
      navigate(-1);
    } else {
      navigate(toUrl(STORE_FRONT_PAGE));
    }
  };

  const routesWithoutLocation = [
    {
      path: "cidade",
      element: (
        <SuspensedLayout>
          <CitySearchPage />
        </SuspensedLayout>
      ),
      elementMobile: (
        <SuspensedLayout>
          <CitySearchPageMobile />
        </SuspensedLayout>
      ),
    },
    {
      path: "*",
      element: <Navigate to={toUrl(CITY_LOCALIZER_PAGE)} />,
    },
  ];

  const routesWithLocation = [
    {
      index: true,
      element: (
        <SuspensedLayout>
          <StoreFrontPage />
        </SuspensedLayout>
      ),
      elementMobile: (
        <SuspensedLayout>
          <StoreFrontPageMobile />
        </SuspensedLayout>
      ),
    },
    {
      path: "categorias",
      element: (
        <SuspensedLayout>
          <StoreFrontPage />
          <CategoriesModal show={true} handleClose={handleClose} />
        </SuspensedLayout>
      ),
      elementMobile: <CategoriesPageMobile />,
    },
    {
      path: "produto/:productId",
      element: (
        <SuspensedLayout>
          <StoreFrontPage />
          <ProductDetailModal show={true} handleClose={handleClose} />
        </SuspensedLayout>
      ),
      elementMobile: <ProductPageMobile />,
    },
    {
      path: "buscador",
      elementMobile: (
        <SuspensedLayout>
          <SearchPage />
        </SuspensedLayout>
      ),
    },
    {
      path: "busca",
      element: (
        <SuspensedLayout>
          <SearchTermPage />
        </SuspensedLayout>
      ),
      elementMobile: (
        <SuspensedLayout>
          <SearchTermPageMobile />
        </SuspensedLayout>
      ),
    },
    {
      path: "carrinho/*",
      element: (
        <SuspensedLayout>
          <ShoppingCartWrapper />
        </SuspensedLayout>
      ),
      elementMobile: (
        <SuspensedLayout>
          <ShoppingCartWrapperMobile />
        </SuspensedLayout>
      ),
    },
    {
      path: "categoria/:slug",
      element: (
        <SuspensedLayout>
          {/* <SearchCategoryWrapper /> */}
          <SearchCategoryPage />
        </SuspensedLayout>
      ),
      elementMobile: (
        <SuspensedLayout>
          <SearchCategoryPageMobile />
        </SuspensedLayout>
      ),
    },
    {
      path: "marca/:slug",
      element: (
        <SuspensedLayout>
          <SearchBrandPage />
        </SuspensedLayout>
      ),
      elementMobile: (
        <SuspensedLayout>
          <SearchBrandPageMobile />
        </SuspensedLayout>
      ),
    },
    {
      path: "tag/:slug",
      element: (
        <SuspensedLayout>
          <SearchTagPage />
        </SuspensedLayout>
      ),
      elementMobile: (
        <SuspensedLayout>
          <SearchTagPageMobile />
        </SuspensedLayout>
      ),
    },
    {
      path: "marcas",
      element: (
        <SuspensedLayout>
          <BrandsPage />
        </SuspensedLayout>
      ),
      elementMobile: (
        <SuspensedLayout>
          <BrandsPageMobile />
        </SuspensedLayout>
      ),
    },
    {
      path: "ofertas",
      element: (
        <SuspensedLayout>
          <SalesPage />
        </SuspensedLayout>
      ),
    },
    {
      path: "feedback",
      element: "",
      elementMobile: (
        <SuspensedLayout>
          <FeedbackPage />
        </SuspensedLayout>
      ),
    },
    {
      path: "contato",
      element: (
        <SuspensedLayout>
          <AOContact />
        </SuspensedLayout>
      ),
      elementMobile: (
        <SuspensedLayout>
          <ContactPage />
        </SuspensedLayout>
      ),
    },
    {
      path: "cidade",
      element: (
        <SuspensedLayout>
          <CitySearchPage />
        </SuspensedLayout>
      ),
      elementMobile: (
        <SuspensedLayout>
          <CitySearchPageMobile />
        </SuspensedLayout>
      ),
    },
    {
      path: "*",
      element: <PrivateRoutes />,
    },
  ];

  const routes = normalizeRoutes([
    {
      element: <StoreApp />,
      children: storeLocation ? routesWithLocation : routesWithoutLocation,
    },
  ]);

  return useRoutes(routes);
};

export default StoreRoutes;
