import MegaMenu from 'components/desktop/MegaMenu/MegaMenu';
import { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';
import Footer from '../Footer/Footer';
import InfoBar from '../InfoBar/InfoBar';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import { useQuery } from '@tanstack/react-query';
import { listPopularCategories } from 'pages/desktop/StoreFront/core/_requests';

const TopContainer = styled.div`
  z-index: 100;
`;
const MiddleContainer = styled.div`
  z-index: 50;
`;
const FooterContainer = styled.div`
  z-index: 10;
`;

const StoreLayout = ({ children }: PropsWithChildren) => {
    const [headerStyle, setHeaderStyle] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data: categoryData, isLoading: isCategoryLoading } = useQuery({
        queryFn: () => listPopularCategories(),
        queryKey: ['listCategories']
    })

    useEffect(() => {
        const handleScroll = () => {
            if (document.documentElement.scrollTop > 40) {
                setHeaderStyle(true);
            } else {
                setHeaderStyle(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <TopContainer>
                <InfoBar />
                <header className={`${headerStyle ? 'fixed' : 'sticky'}`}>
                    <NavbarLayout />
                    <MegaMenu categories={categoryData?.data || []} loading={loading} />
                </header>
            </TopContainer>

            <MiddleContainer style={{ marginTop: headerStyle ? "128px" : "0px" }}>
                {children}
            </MiddleContainer>

            <FooterContainer>
                <Footer />
            </FooterContainer>
        </>
    );
}

export default StoreLayout;
