import { useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import fakeData from 'resources/data/fake-data';
import receiptStyle from './AOReceiptDetails.module.css';
import { toAbsoluteUrl, toBRL } from 'utils/Functions';
import { useReactToPrint } from 'react-to-print';
import BarcodeGenerator from 'components/common/BarcodeGenerator/BarcodeGenerator';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ClipLoader from "react-spinners/ClipLoader";
import { ACCOUNT_ORDERS_PAGE, RECEIPT_PAGE, RECEIPT_PRINT, toUrl } from 'utils/Links';
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import { useQuery } from '@tanstack/react-query';
import { orderDetails } from './core/_requestsOrder';
import { SalesOrder } from 'models/SalesOrder';
import { DATE_USUAL } from 'utils/Consts';
import { Invoice } from 'models/Invoice';
import moment from 'moment';
import Loader from 'components/desktop/Loader/Loader';

function AOReceiptDetails() {
    const { users, vupty } = fakeData
    const frete = 15
    const { id : orderId } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [order, setOrder] = useState<SalesOrder>()
    const [receipt, setReceipt] = useState<Invoice>()

    const { isLoading: isOrderDetailLoading } = useQuery({
        queryFn: async () => {
            const response = await orderDetails(orderId!!)
            setOrder(response.data)
            // CHANGE data.invoice TO data.receipt ONCE ITS WORKING 
            if (response.data.invoice) return (
                setReceipt(response.data.invoice)
            )
            else return response
        },
        queryKey: ['order', orderId],
        enabled: !!orderId,
    })

    const scaledWrapper=useRef<HTMLDivElement | null>(null);
    const scaledContent=useRef<HTMLDivElement | null>(null);

    // DOWNLOAD LOGIC
    const handleDownloadClick = async (): Promise<void> => {
        setLoading(true)
        if (!scaledContent.current) return;

        const divElement = scaledContent.current;
    
        const contentHeight = divElement.scrollHeight;

        const scale = 1.5
    
        const canvas = await html2canvas(divElement, { height: contentHeight, scale: scale });
        const imgData = canvas.toDataURL('image/png');
    
        const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height, undefined, 'MEDIUM');

        pdf.save(`Nota-${receipt?.id}.pdf`);
        setLoading(false)
    };

    const handlePrint = useReactToPrint({
        content: () => scaledContent.current,
    });

    const breadcrumbs = useMemo(() => {
        return(
          [          
            {
              title : 'Perfil',
              path: toUrl(ACCOUNT_ORDERS_PAGE),
            },
            {    
              title : 'Pedidos',
              path : toUrl(ACCOUNT_ORDERS_PAGE),
            },
            {
                title : 'Notas Fiscais',
                path : toUrl(RECEIPT_PAGE),
            },
            {
                title : `Nota fiscal n° ${receipt?.number}`,
                path : ''
            }
          ]
        )
    }, [])

    const openInvoceNewTab = (id: any) => {
        window.open(toUrl(RECEIPT_PRINT, id), '_blank')
    }

  return (
    <>
        <div>
            <Breadcrumbs breadcrumbs={breadcrumbs}/>
            <span className='font600 font15rem'>NOTAS FISCAIS</span>
        </div>

        <div>
        {(receipt && order) && !isOrderDetailLoading && (
            <div ref={scaledWrapper} className={receiptStyle.completereceipt}>

                <div className={receiptStyle.totalButtons}>
                    <div onClick={handleDownloadClick} className={`${receiptStyle.downloadButton}`}>
                        {loading &&
                        <div className='transform2px'>
                            <ClipLoader
                                color="#f0f0f0"
                                size={18}
                            />
                        </div>
                        }
                        {!loading &&
                        <>
                            <div className={receiptStyle.downloadButtonSpan}>
                                {/* Baixar {receipt.type.toLowerCase()} */}
                                Baixar
                            </div>
                            <img src={toAbsoluteUrl('/media/general/Download-Icon.svg')} alt='' className='ms-2'/>
                        </>
                        }
                    </div>
                
                    <div onClick={() => openInvoceNewTab(order.id)} className={receiptStyle.downloadButton}>
                        {/* Imprimir {receipt.type.toLowerCase()} */}
                        Imprimir
                        <img src={toAbsoluteUrl('/media/general/Printer-Icon.svg')} alt='' className='ms-2'/>
                    </div>
                </div> 

                <div lang="pt-BR" ref={scaledContent}>
                    <div className={`${receiptStyle.page} ${receiptStyle.nfeArea}`}>
                        <img className={receiptStyle.imgCanceled} src="tarja_nf_cancelada.png" alt="" />
                        <img className={receiptStyle.imgNull} src="tarja_nf_semvalidade.png" alt="" />
                        <div className={receiptStyle.boxFields}>
                        <table cellSpacing={0} cellPadding={0} border={1}>
                            <tbody>
                            <tr>
                                <td colSpan={3} className={receiptStyle.txtUpper}>
                                RECEBEMOS DE VUPTY PRODUTOS/SERVIÇOS CONTANTES NA NOTA FISCAL INDICADA AO LADO
                                </td>
                                <td rowSpan={2} className={`${receiptStyle.tserie} ${receiptStyle.txtCenter}`}>
                                    <span className="font-12"> NF-e </span>
                                    <span>Nº {receipt.number}</span>
                                    <span>
                                        {/* NEEDS CHANGE */}
                                        Série: {receipt.number}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '32mm' }}>
                                    <span className={receiptStyle.nfLabel}>Data de recebimento</span>
                                    <span style={{marginLeft: '3px'}}>
                                        {moment(receipt.modification_date).format(DATE_USUAL)}
                                    </span>
                                </td>
                                <td style={{ width: '62.6mm' }}>
                                    <span className={receiptStyle.nfLabel}>Identificação do Recebedor</span>
                                    <span style={{marginLeft: '3px'}}>
                                        {/* ALTER LATER */}
                                        {/* {receipt.consumer_id} */}
                                        002158742
                                    </span>
                                </td>
                                <td style={{ width: '62mm' }}>
                                    <span className={receiptStyle.nfLabel}>Assinatura do Recebedor</span>
                                    <span style={{marginLeft: '3px'}}>RYAN GOSGLING</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <hr className={receiptStyle.hrDashed}/>
                        <table cellSpacing={0} cellPadding={0} border={1}>
                            <tbody>
                                <tr>
                                    <td rowSpan={3} style={{ width: '150px' }} className={receiptStyle.txtCenter}>
                                    <img className={receiptStyle.clientLogo}
                                        src={toAbsoluteUrl('/media/logos/Receipt-Logo.png')}
                                        alt=""/>
                                        <span className='d-block'>VUPTY</span>
                                        <span className='d-block'>EMPRESARIAL MUNDO PLAZA - SALA 1808</span>
                                        <span className='d-block'>RUA BENJAMIN CONSTANT, 180</span>
                                        <span className='d-block'>ESTAÇÃO VELHA, 58410-003</span>
                                        <span className='d-block'>CAMPINA GRANDE - PB</span>
                                        <span className='d-block'><strong>FONE/FAX:</strong> (83) 99999-9999</span>
                                        <span className='d-block'><strong>E-MAIL:</strong> vupty@gmail.com</span>
                                    </td>

                                    <td rowSpan={3}
                                        className={`${receiptStyle.txtc} ${receiptStyle.txtUpper}`}
                                        style={{ width: '120px', height: '29.5mm' }}>
                                        <h3 className={receiptStyle.title}>Danfe</h3>
                                        <p className={receiptStyle.mb2}>Documento auxiliar da Nota Fiscal Eletrônica </p>
                                        <p className={`${receiptStyle.entradaSaida} ${receiptStyle.mb2}`}>
                                            <span className={receiptStyle.identificacao}>
                                                {receipt.number}
                                            </span>
                                            <span className={receiptStyle.legenda}>
                                            <span>0 - Entrada</span>
                                            <span>1 - Saída</span>
                                            </span>
                                        </p>
                                        <span style={{fontSize:'7pt'}}>
                                            <span className={`d-block ${receiptStyle.bold}`}>
                                                <span>Nº</span>
                                                <span>{receipt.number}</span>
                                            </span>
                                            <span className={`d-block ${receiptStyle.bold}`}>
                                                <span>SÉRIE:</span>
                                                <span>{receipt.id}</span>
                                            </span>
                                            <span className="d-block font500">
                                                <span>Folha: </span>
                                                <span>1</span>
                                                <span> de </span>
                                                <span>1</span>
                                            </span>
                                        </span>
                                    </td>

                                    <td  className={`${receiptStyle.txtUpper} border-0`}  style={{ width: '250px' }}>                                
                                        <span className={receiptStyle.codigo}>
                                            {/* CHANGE VALUE LATER */}
                                            <BarcodeGenerator value={receipt.number} />
                                        </span>                     
                                        <span className={`${receiptStyle.nfLabel} text-center`}>CHAVE DE ACESSO</span>
                                        <span className={`d-block ${receiptStyle.txtCenter} ${receiptStyle.info}`}>
                                            {/* ALTER LATER */}
                                            {/* {receipt.consumer_id} */}
                                            12257006
                                        </span>
                                    </td>
                                </tr>

                                <tr className='text-center'>
                                    <td>
                                        <span className="d-block">
                                        Consulta de autenticidade no portal nacional da NF-e{' '}
                                        </span>{' '}
                                        www.nfe.fazenda.gov.br/portal ou no site da Sefaz Autorizada.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* Natureza da Operação */}
                        <table
                            className={`${receiptStyle.boxNaturezaOperacao} ${receiptStyle.noTop}`}
                            cellSpacing={0}
                            cellPadding={0}
                            border={1}>
                            <tbody>
                            <tr>
                                <td>
                                <span className={receiptStyle.nfLabel}>NATUREZA DA OPERAÇÃO</span>
                                <span className={receiptStyle.info}>Venda de mercadorias</span>
                                </td>
                                <td style={{ width: '84.7mm' }}>
                                <span className={receiptStyle.nfLabel}>Número de protocolo de autorização deuso da NF-e</span>
                                <span className={receiptStyle.info}>{receipt.id}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {/* Inscrição */}
                        <table className={`${receiptStyle.boxInscricao} ${receiptStyle.noTop}`} cellSpacing={0} cellPadding={0} border={1}>
                            <tbody>
                            <tr>
                                <td>
                                <span className={receiptStyle.nfLabel}>INSCRIÇÃO ESTADUAL</span>
                                <span className={receiptStyle.info}>{users[0].state_number}</span>
                                </td>
                                <td style={{ width: '67.5mm' }}>
                                <span className={receiptStyle.nfLabel}>INSCRIÇÃO ESTADUAL DO SUBST. TRIB.</span>
                                <span className={receiptStyle.info}>{users[0].state_tribute}</span>
                                </td>
                                <td style={{ width: '64.3mm' }}>
                                <span className={receiptStyle.nfLabel}>CNPJ</span>
                                <span className={receiptStyle.info}>{vupty[0].cnpj}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {/* Destinatário/Emitente */}
                        <p className={receiptStyle.areaName}>Destinatário/Emitente</p>
                        <table className={receiptStyle.boxDestinatario} cellSpacing={0} cellPadding={0} border={1}>
                            <tbody>
                            <tr>
                                <td className={receiptStyle.pd0}>
                                <table cellSpacing={0} cellPadding={0} border={1} style={{borderBottom: 'none'}}>
                                    <tbody>
                                    <tr>
                                        <td>
                                        <span className={receiptStyle.nfLabel}>NOME/RAZÃO SOCIAL</span>
                                        <span className={receiptStyle.info}>{users[0].company_name}</span>
                                        </td>
                                        <td style={{ width: '40mm' }}>
                                        <span className={receiptStyle.nfLabel}>CNPJ/CPF</span>
                                        <span className={receiptStyle.info}>{users[0].cnpj}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </td>
                                <td style={{ width: '26mm' }}>
                                <span className={receiptStyle.nfLabel}>DATA DE EMISSÃO</span>
                                <span className={receiptStyle.info}>
                                    {moment(receipt.registration_date).format(DATE_USUAL)}
                                </span>
                                </td>
                            </tr>
                            <tr>
                                <td className={receiptStyle.pd0}>
                                <table cellSpacing={0} cellPadding={0} border={1}>
                                    <tbody>
                                    <tr>
                                        <td>
                                        <span className={receiptStyle.nfLabel}>ENDEREÇO</span>
                                        <span className={receiptStyle.info}>
                                            {`${users[0].address.street.charAt(0).toUpperCase() + users[0].address.street.slice(1)}, ${users[0].address.number}`}
                                        </span>
                                        </td>
                                        <td style={{ width: '47mm' }}>
                                        <span className={receiptStyle.nfLabel}>BAIRRO/DISTRITO</span>
                                        <span className={receiptStyle.info}>
                                            {users[0].address.neighbourhood.charAt(0).toUpperCase() + users[0].address.neighbourhood.slice(1)}
                                        </span>
                                        </td>
                                        <td style={{ width: '37.2 mm' }}>
                                        <span className={receiptStyle.nfLabel}>CEP</span>
                                        <span className={receiptStyle.info}>{users[0].address.zip_code}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </td>
                                <td>
                                <span className={receiptStyle.nfLabel}>DATA DE ENTR./SAÍDA</span>
                                <span className={receiptStyle.info}>
                                    {moment(receipt.modification_date).format(DATE_USUAL)}
                                </span>
                                </td>
                            </tr>
                            <tr>
                                <td className={receiptStyle.pd0}>
                                <table
                                    style={{ marginBottom: '-1px' }}
                                    cellSpacing={0}
                                    cellPadding={0}
                                    border={1}>
                                    <tbody>
                                    <tr>
                                        <td>
                                        <span className={receiptStyle.nfLabel}>MUNICÍPIO</span>
                                        <span className={receiptStyle.info}>
                                            {users[0].address.city.charAt(0).toUpperCase() + users[0].address.city.slice(1)}
                                        </span>
                                        </td>
                                        <td style={{ width: '34mm' }}>
                                        <span className={receiptStyle.nfLabel}>FONE/FAX</span>
                                        <span className={receiptStyle.info}>{users[0].phone}</span>
                                        </td>
                                        <td style={{ width: '28mm' }}>
                                        <span className={receiptStyle.nfLabel}>UF</span>
                                        <span className={receiptStyle.info}>{users[0].address.state.toUpperCase()}</span>
                                        </td>
                                        <td style={{ width: '51mm' }}>
                                        <span className={receiptStyle.nfLabel}>INSCRIÇÃO ESTADUAL</span>
                                        <span className={receiptStyle.info}>{users[0].state_number}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </td>
                                <td>
                                <span className={receiptStyle.nfLabel}>HORA ENTR./SAÍDA</span>
                                <span className={receiptStyle.infoTime}>
                                    {/* NEEDS NEW VALUE */}
                                    {/* {receipt.exit_time} */}

                                </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {/* Fatura */}
                        <div className={receiptStyle.boxFatura}>
                            <p className={receiptStyle.areaName}>Fatura</p>
                            <table>
                                <td>
                                    <span className={receiptStyle.nfLabel}>FORMA DE PAGAMENTO</span>
                                    <span className={receiptStyle.info}>
                                        {/* NEEDS NEW VALUE */}
                                        {/* {receipt.payment_method} */}
                                        Cartão de Crédito
                                    </span>
                                </td>
                            </table>
                        </div>
                        {/* Calculo do Imposto */}
                        <p className={receiptStyle.areaName}>Calculo do imposto</p>
                        <div className={receiptStyle.wrapperTable}>
                            <table className={receiptStyle.boxImposto} cellSpacing={0} cellPadding={0} border={1}>
                            <tbody className={receiptStyle.taxesContent}>
                                <tr>
                                <td>
                                    <span className={`${receiptStyle.nfLabel} ${receiptStyle.labelSmall}`}>BASE DE CÁLC. DO ICMS</span>
                                    <span className={receiptStyle.info}>0.00</span>
                                </td>
                                <td>
                                    <span className={receiptStyle.nfLabel}>VALOR DO ICMS</span>
                                    <span className={receiptStyle.info}>0.00</span>
                                </td>
                                <td>
                                    <span className={`${receiptStyle.nfLabel} ${receiptStyle.labelSmall}`}>
                                    BASE CÁLC. DO ICMS ST
                                    </span>
                                    <span className={receiptStyle.info}>0.00</span>
                                </td>
                                <td>
                                    <span className={receiptStyle.nfLabel}>VALOR DO ICMS ST</span>
                                    <span className={receiptStyle.info}>0.00</span>
                                </td>
                                <td>
                                    <span className={`${receiptStyle.nfLabel} ${receiptStyle.labelSmall}`}>V. IMP. IMPORTAÇÃO</span>
                                    <span className={receiptStyle.info} />
                                </td>
                                <td>
                                    <span className={`${receiptStyle.nfLabel} ${receiptStyle.labelSmall}`}>V. ICMS UF REMET.</span>
                                    <span className={receiptStyle.info} />
                                </td>
                                <td>
                                    <span className={receiptStyle.nfLabel}>VALOR DO FCP</span>
                                    <span className={receiptStyle.info} />
                                </td>
                                <td>
                                    <span className={receiptStyle.nfLabel}>VALOR DO PIS</span>
                                    <span className={receiptStyle.info} />
                                </td>
                                <td>
                                    <span className={`${receiptStyle.nfLabel} ${receiptStyle.labelSmall}`}>V. TOTAL DE PRODUTOS</span>
                                    <span className={receiptStyle.info}>
                                        {/* VALUE NEEDS CHANGE */}
                                        {/* {toBRL(receipt.value)} */}
                                        {toBRL(order.total_price)}
                                    </span>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <span className={receiptStyle.nfLabel}>VALOR DO FRETE</span>
                                    <span className={receiptStyle.info}>{toBRL(frete)}</span>
                                </td>
                                <td>
                                    <span className={receiptStyle.nfLabel}>VALOR DO SEGURO</span>
                                    <span className={receiptStyle.info}></span>
                                </td>
                                <td>
                                    <span className={receiptStyle.nfLabel}>DESCONTO</span>
                                    <span className={receiptStyle.info}>
                                        {toBRL(order.total_mission_discount)}
                                    </span>
                                </td>
                                <td>
                                    <span className={receiptStyle.nfLabel}>OUTRAS DESP.</span>
                                    <span className={receiptStyle.info}></span>
                                </td>
                                <td>
                                    <span className={receiptStyle.nfLabel}>VALOR DO IPI</span>
                                    <span className={receiptStyle.info}>0.00</span>
                                </td>
                                <td>
                                    <span className={receiptStyle.nfLabel}>V. ICMS UF DEST.</span>
                                    <span className={receiptStyle.info} />
                                </td>
                                <td>
                                    <span className={`${receiptStyle.nfLabel} ${receiptStyle.labelSmall}`}>V. APROX. DO TRIBUTO</span>
                                    <span className={receiptStyle.info}>
                                    {}
                                    </span>
                                </td>
                                <td>
                                    <span className={`${receiptStyle.nfLabel} ${receiptStyle.labelSmall}`}>VALOR DA CONFINS</span>
                                    <span className={receiptStyle.info} />
                                </td>
                                <td>
                                    <span className={`${receiptStyle.nfLabel} ${receiptStyle.labelSmall}`}>V. TOTAL DA NOTA</span>
                                    <span className={receiptStyle.info}>{toBRL(order.total_price)}</span>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        {/* Transportador/Volumes transportados */}
                        <p className={receiptStyle.areaName}>Transportador/volumes transportados</p>
                        <table cellSpacing={0} cellPadding={0} border={1}>
                            <tbody>
                            <tr>
                                <td>
                                    <span className={receiptStyle.nfLabel}>RAZÃO SOCIAL</span>
                                    <span className={receiptStyle.info}>Vupty LTDA.</span>
                                </td>
                                <td style={{ width: '32mm' }}>
                                    <span className={receiptStyle.nfLabel}>FRETE POR CONTA</span>
                                    <span className={receiptStyle.info}>1 - Destinatário</span>
                                </td>
                                <td style={{ width: '18.3mm' }}>
                                    <span className={receiptStyle.nfLabel}>CÓDIGO ANTT</span>
                                    <span className={receiptStyle.info}/>
                                </td>
                                <td style={{ width: '24.5mm' }}>
                                <span className={receiptStyle.nfLabel}>PLACA</span>
                                <span className={receiptStyle.info}></span>
                                </td>
                                <td style={{ width: '11.3mm' }}>
                                <span className={receiptStyle.nfLabel}>UF</span>
                                <span className={receiptStyle.info}>{vupty[0].state}</span>
                                </td>
                                <td style={{ width: '35mm' }}>
                                <span className={receiptStyle.nfLabel}>CNPJ/CPF</span>
                                <span className={receiptStyle.info}>{vupty[0].cnpj}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table className={receiptStyle.noTop} cellSpacing={0} cellPadding={0} border={1}>
                            <tbody>
                            <tr>
                                <td className={`${receiptStyle.field} ${receiptStyle.endereco}`}>
                                <span className={receiptStyle.nfLabel}>ENDEREÇO</span>
                                <span className={`${receiptStyle.contentSpacer} ${receiptStyle.info}`}>
                                    {vupty[0].street.charAt(0).toUpperCase() + vupty[0].street.slice(1)}
                                </span>
                                </td>
                                <td style={{ width: '32mm' }}>
                                <span className={receiptStyle.nfLabel}>MUNICÍPIO</span>
                                <span className={receiptStyle.info}>
                                    {vupty[0].city.charAt(0).toUpperCase() + vupty[0].city.slice(1)}
                                </span>
                                </td>
                                <td style={{ width: '31mm' }}>
                                <span className={receiptStyle.nfLabel}>UF</span>
                                <span className={receiptStyle.info}>{vupty[0].state}</span>
                                </td>
                                <td style={{ width: '51.4mm' }}>
                                <span className={receiptStyle.nfLabel}>INSC. ESTADUAL</span>
                                <span className={receiptStyle.info}>{vupty[0].state_registration}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table className={receiptStyle.noTop} cellSpacing={0} cellPadding={0} border={1}>
                            <tbody>
                            <tr>
                                <td className={`${receiptStyle.field} ${receiptStyle.quantidade}`}>
                                <span className={receiptStyle.nfLabel}>QUANTIDADE</span>
                                <span className={`${receiptStyle.contentSpacer} ${receiptStyle.info}`}>
                                    {order.items_count}
                                </span>
                                </td>
                                <td style={{ width: '31.4mm' }}>
                                    <span className={receiptStyle.nfLabel}>ESPÉCIE</span>
                                    <span className={receiptStyle.info}>{}</span>
                                </td>
                                <td style={{ width: '31mm' }}>
                                    <span className={receiptStyle.nfLabel}>MARCA</span>
                                    <span className={receiptStyle.info}>{}</span>
                                </td>
                                <td style={{ width: '31.5mm' }}>
                                    <span className={receiptStyle.nfLabel}>NUMERAÇÃO</span>
                                    <span className={receiptStyle.info}>{}</span>
                                </td>
                                <td style={{ width: '31.5mm' }}>
                                    <span className={receiptStyle.nfLabel}>PESO BRUTO</span>
                                    <span className={receiptStyle.info}>{}</span>
                                </td>
                                <td style={{ width: '32.5mm' }}>
                                    <span className={receiptStyle.nfLabel}>PESO LÍQUIDO</span>
                                    <span className={receiptStyle.info}>{}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {/* Dados do produto/serviço */}
                        <p className={receiptStyle.areaName}>Dados do produto/serviço</p>
                        <div className={receiptStyle.wrapperBorder}>
                            <table className={receiptStyle.boxProdutoServico} cellSpacing={0} cellPadding={0} border={1}>
                                <thead className={receiptStyle.listProdutoServico} id="table">
                                    <tr className={receiptStyle.titles}>
                                    <th className={receiptStyle.cod} style={{ width: '14.5mm' }}>
                                        CÓDIGO
                                    </th>
                                    <th className={receiptStyle.descrit} style={{ width: '63.1mm' }}>
                                        DESCRIÇÃO DO PRODUTO/SERVIÇO
                                    </th>
                                    <th className="ncmsh">NCMSH</th>
                                    <th className="cst">CST</th>
                                    <th className="cfop">CFOP</th>
                                    <th className="un">UN</th>
                                    <th className="amount">QTD.</th>
                                    <th className="valUnit">VLR.UNIT</th>
                                    <th className="valTotal">VLR.TOTAL</th>
                                    <th className="bcIcms">BC ICMS</th>
                                    <th className="valIcms">VLR.ICMS</th>
                                    <th className="valIpi">VLR.IPI</th>
                                    <th className="aliqIcms">ALIQ.ICMS</th>
                                    <th className="aliqIpi">ALIQ.IPI</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {receipt?.items.map((item: any, idx: any) => (
                                        <tr key={idx} className={receiptStyle.productBox}>
                                            <td>{item.product?.id}</td>
                                            <td>{item.product?.description}</td>
                                            <td>{item.product?.ncm}</td>
                                            <td>{item.product?.cst}</td>
                                            <td>{item.product?.cfop}</td>
                                            <td>{item.product?.unid}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.unit_price}</td>
                                            <td>{item.total_price}</td>
                                            <td>{item.product?.bc_icms}</td>
                                            <td>{item.product?.v_icms}</td>
                                            <td>{item.product?.ipi}</td>
                                            <td>{item.product?.aliq_icms}</td>
                                            <td>{item.product?.aliq_ipi}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        </div>
                    </div>
                    {/* Dados adicionais */}
                    {/* Calculo de ISSQN */}
                    <div className={receiptStyle.footer}>
                        <div style={{marginInline: '6px'}}>
                            <p className={receiptStyle.areaName}>Calculo do ISSQN</p>
                            <table className="boxIssqn" cellSpacing={0} cellPadding={0} border={1}>
                                <tbody>
                                <tr>
                                    <td className="field inscrMunicipal">
                                        <span className={receiptStyle.nfLabel}>INSCRIÇÃO MUNICIPAL</span>
                                        <span className="info txt-center">45986325</span>
                                    </td>
                                    <td className="field valorTotal">
                                        <span className={receiptStyle.nfLabel}>VALOR TOTAL DOS SERVIÇOS</span>
                                        <span className={`${receiptStyle.info} ${receiptStyle.txtRight}`}> </span>
                                    </td>
                                    <td className="field baseCalculo">
                                        <span className={receiptStyle.nfLabel}>BASE DE CÁLCULO DO ISSQN</span>
                                        <span className={`${receiptStyle.info} ${receiptStyle.txtRight}`}> </span>
                                    </td>
                                    <td className="field valorIssqn">
                                        <span className={receiptStyle.nfLabel}>VALOR DO ISSQN</span>
                                        <span className={`${receiptStyle.info} ${receiptStyle.txtRight}`}> </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <p className={receiptStyle.areaName}>Dados adicionais</p>
                            <table className={receiptStyle.boxDadosAdicionais} cellSpacing={0} cellPadding={0} border={1}>
                                <tbody>
                                <tr>
                                    <td className="field infoComplementar">
                                    <span className={receiptStyle.nfLabel}>INFORMAÇÕES COMPLEMENTARES</span>
                                    <span>CAMPO PARA INFORMAÇÕES COMPLEMENTARES DA NOTA FISCAL.</span>
                                    </td>
                                    <td className="field reservaFisco" style={{ width: '85mm', height: '24mm' }}>
                                    <span className={receiptStyle.nfLabel}>RESERVA AO FISCO</span>
                                    <span />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>    
                    </div>
                </div>
                                     
            </div>
        )}

        {isOrderDetailLoading &&
            <Loader />
        }
        </div>
    </>
  )
}

export default AOReceiptDetails