import { toAbsoluteUrl } from "utils/Functions"
import successStyle from './StepEnablePage.module.css'
import { useState } from "react"
import * as Yup from 'yup'
import { useFormik } from "formik"

const loginSchema = Yup.object().shape({
  username: Yup.string().min(3, 'Minimo de 3 símbolos').max(50, 'Máximo de 50 símbolos').required('Digite um código válido'),
})

function StepSuccessConclusion() {
  const [disable, setDisable] = useState(false)

  const formik = useFormik({
    initialValues: {
        code: '000 000',
    },
    validationSchema: loginSchema,
    onSubmit: async () => {
        handleFormSubmit()
    },
  })

  const handleFormSubmit = () => {
    console.log('post')
  };

  const handleDisable = () => {
    setDisable(!disable)
  }

  return (
    <>
      <div className="container py-3">
          <div className="d-flex flex-column align-items-center">
              <img src={toAbsoluteUrl('/media/Illustrations/Two-Factor-Success.svg')} alt=""/>
              <span className="main-color font12rem font500 text-center my-3">A autenticação de dois fatores está ativada!</span>
              <span className="text-center">Para desabilitar a autenticação de dois fatores, clique no botão abaixo e digite o código disponívell no aplicativo Google Authenticator para confirmar.</span>
            {!disable &&
              <div className={successStyle.disableButton} onClick={() => handleDisable()}>
                Desabilitar 2FA
              </div>
            }
            {disable &&
              <>
                <span className="font09rem faded-color text-center mt-3">Para cancelar a autenticação de dois fatores, confirme o código de confirmação que enviamos para o seu dispositivo.</span>
                <form onSubmit={formik.handleSubmit}>
                  <div className='d-flex flex-column'>
                      <label>
                          Código de confirmação:
                          <input type='text' className='form-control' {...formik.getFieldProps('username')} />
                      </label>
                      {formik.touched.code && formik.errors.code && (
                          <span className='text-danger'>{formik.errors.code}</span>
                      )}
                  </div>
                </form>
                <button type='submit' className={successStyle.disableButton}>
                  Confirmar
                </button>
                <div className={successStyle.cancelStyle} onClick={() => handleDisable()}>
                  cancelar
                </div>        
              </>
            }

          </div>
      </div>
    </>
  )
}

export default StepSuccessConclusion