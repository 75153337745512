import CitySearchDesktop from 'components/common/CitySearchDesktop/CitySearchDesktop'
import { useStoreCart } from 'layouts/common/core/StoreCartProvider'
import { useStoreLocation } from 'layouts/common/core/StoreLocationProvider'
import { Region } from 'models/Region'
import { StoreLocationStatus } from 'models/StoreLocation'
import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { CgClose } from 'react-icons/cg'
import styles from './CitySearchModal.module.css'

type Props = {
    open: boolean
    onHide: () => void
}

const CitySearchModal: FC<Props> = ({ open, onHide }) => {

    const { storeLocation, updateStoreLocation } = useStoreLocation();
    const {clearCart} = useStoreCart()

    function handleDateSelectorChange(region: Region) {
        updateStoreLocation({
            region,
            status: StoreLocationStatus.FOUND
        })
        clearCart()
        onHide()
    }

    return (
        <Modal size={`lg`} show={open} onHide={onHide} className={styles.modal} centered>
            <Modal.Body className={styles.modalBody}>
                <div className='d-flex justify-content-between align-items-center pt-3'>
                    <div className='d-flex'>
                        {!storeLocation && (<span className='font12rem font600 main-color me-2'>Selecione sua cidade</span>)}
                        {storeLocation &&
                            <>
                                <span className='font12rem font600 main-color me-2'>Você está em </span>
                                <span className={styles.city}>{storeLocation.region.name}</span>
                            </>
                        }
                    </div>
                    <CgClose onClick={() => onHide()} className={styles.x}/>
                </div>
                <CitySearchDesktop onChange={handleDateSelectorChange} />
            </Modal.Body>
        </Modal>
    )
}

export default CitySearchModal
