import { Capacitor } from "@capacitor/core";
import { Category } from "models/Category";
import { FacetFilter, FacetItem } from "models/FacetFilter";
import { Mission } from "models/Mission";
import { Product, ProductPriceRange } from "models/Product";
import { SortOption } from "models/SortOptionEnum";
import { StoreCart } from "models/StoreCart";
import { StoreItem } from "models/StoreItem";
import { WishlistItem } from "models/Wishlist";

export const toBRL = (value: any) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(value);
};

export const toBRLShort = (value: any) => {
  return parseFloat(value).toFixed(2).toLocaleString();
};

export const normalizeRoutes = (routes: any) => {
  const isMobile = isMobileScreen();
  const isNative = Capacitor.isNativePlatform();
  return routes.map(({ element, elementMobile, children, ...rest }: any) => ({
    ...rest,
    element: (isMobile || isNative) && elementMobile ? elementMobile : element,
    children: children ? normalizeRoutes(children) : null,
  }));
};

export const toNormalizeString = (value: string) => {
  return value
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

export const isMobileScreen = () => {
  return window.matchMedia("(max-width: 768px)").matches;
};

export function toAbsoluteUrl(pathname: string) {
  return process.env.PUBLIC_URL + pathname;
}

export function getCategoryFullPath(category: any) {
  let fullPath = category.slug;
  while (category.parent) {
    category = category.parent;
    fullPath = `${category.slug}/${fullPath}`;
  }
  return fullPath;
}

export function getRootCategory(category?: Category) {
  if (!category) return "Sem categoria";
  let rootCategoryName = category.name;
  while (category.parent) {
    category = category.parent;
    rootCategoryName = category.name;
  }
  return rootCategoryName;
}

export const sortByProductDescription = (a: any, b: any) => {
  if (a.product.description > b.product.description) {
    return 1;
  }
  if (a.product.description < b.product.description) {
    return -1;
  }
  return 0;
};

export const sortByItemCreationDate = (a: StoreItem, b: StoreItem) => {
  return new Date(a.creation_date).getTime() - new Date(b.creation_date).getTime();
};
export const sortByWishlistItemCreationDate = (a: WishlistItem, b: WishlistItem) => {
  if (a.creation_date && b.creation_date) {
    return new Date(a.creation_date).getTime() - new Date(b.creation_date).getTime();
  }
};

// export const sortByNotificationCreationDate = (a: Notification, b: Notification) => {
//     return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
// }

export const deepEqual = (object1: any, object2: any) => {
  if (object1 === object2) {
    return true;
  } else if (typeof object1 == "object" && object1 != null && typeof object2 == "object" && object2 != null) {
    if (Object.keys(object1).length != Object.keys(object2).length) {
      return false;
    }

    for (let prop in object1) {
      if (object2.hasOwnProperty(prop)) {
        if (!deepEqual(object1[prop], object2[prop])) {
          return false;
        }
      } else {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
};

export const calculateCartTotals = (
  items: StoreItem[],
  deliveryFee: number,
  missions?: Mission[]
): Partial<StoreCart> => {
  const itemsCount = items.length;
  const itemsQuantity = items.reduce((total, item) => total + item.quantity, 0);
  const itemsTotal = items.reduce((total, item) => total + item.total, 0);
  const totalItemsDiscount = items.reduce((total, item) => total + item.total_discount, 0);
  const totalMissionDisount = calculateMissionDiscountTotalValue(missions);
  const total = itemsTotal + deliveryFee - totalMissionDisount;

  return {
    items_count: itemsCount,
    items_quantity: itemsQuantity,
    items_total: itemsTotal,
    total_items_discount: totalItemsDiscount,
    total_mission_discount: totalMissionDisount,
    total: total,
  };
};

export const getActualProductUnitPrice = (product: Product, quantity: number | undefined = 0): number => {
  if (product.price_ranges && product.price_ranges.length > 0) {
    const bestPriceRange = getBestPriceFromPriceRangeForQuantity(product.price_ranges, quantity);
    if (bestPriceRange) {
      return bestPriceRange.unit_price;
    }
  }
  //check if supply type is package or both and quantity is greater than package quantity
  if (product.supply_type && (product.supply_type.value === "3" || product.supply_type.value === "2")) {
    if (
      product.package_unit_price &&
      product.quantity_per_package &&
      product.quantity_per_package > 0 &&
      quantity >= product.quantity_per_package
    ) {
      return product.package_unit_price;
    }
  }

  return product.sell_price;
};

export const getActualProductProviderDiscountValue = (product: Product, quantity: number | undefined = 0): number => {
  let result = 0;
  if (product.original_price && product.original_price > 0) {
    result = product.original_price - product.sell_price;
  }

  const bestUnitPrice = getActualProductUnitPrice(product, quantity);

  if (bestUnitPrice < product.sell_price) {
    result = product.sell_price - bestUnitPrice;
  }

  return result;
};

export const getBestPriceFromPriceRangeForQuantity = (
  priceRanges: ProductPriceRange[],
  quantity: number
): ProductPriceRange | undefined => {
  if (!priceRanges || priceRanges.length === 0 || quantity === 0) return;

  return priceRanges.reduce((min: ProductPriceRange | undefined, priceRange: ProductPriceRange) => {
    const isWithinRange =
      (priceRange.min_quantity === null || priceRange.min_quantity === 0 || quantity >= priceRange.min_quantity) &&
      (priceRange.max_quantity === null || priceRange.max_quantity === 0 || quantity <= priceRange.max_quantity);
    const isMinPrice = isWithinRange && (!min || priceRange.unit_price < min.unit_price);
    return isMinPrice ? priceRange : min;
  }, undefined);
};

export const getBestProductProvider = (product: Product | null) => {
  return product && product.providers && product.providers.length > 0 ? product.providers[0] : null;
};

export const getProductTags = (product: Product | null) => {
  const tags: string[] = [];
  if (!product) return tags;
  const productProvider = getBestProductProvider(product);

  if (productProvider && productProvider.tag) {
    tags.push(productProvider.tag);
  } else if (product && product.tags) {
    tags.push(...product.tags.map((tag) => tag.name));
  }
  return tags;
};

export const getProductDiscount = (product: Product | null) => {
  if (!product) return 0;
  const productProvider = getBestProductProvider(product);
  return productProvider && productProvider.promo_price
    ? Math.round(((productProvider.sell_price - productProvider.promo_price) / productProvider.sell_price) * 100)
    : 0;
};

export const getActualProductPriceRange = (
  product: Product | undefined,
  quantity: number | undefined = 0
): ProductPriceRange | undefined => {
  if (!product || !product.price_ranges) return;
  return getBestPriceFromPriceRangeForQuantity(product.price_ranges, quantity);
};

export const getNextProductPriceRange = (product: Product | undefined, quantity: number | undefined = 0) => {
  if (!product || !product.price_ranges) return;
  let diff = 9999999;
  let result: ProductPriceRange | undefined = undefined;
  for (let i = 0; i < product.price_ranges.length; i++) {
    const priceRange = product.price_ranges[i];
    if (quantity < priceRange.min_quantity && priceRange.min_quantity - quantity <= diff) {
      diff = priceRange.min_quantity - quantity;
      result = priceRange;
    }
  }
  return result;
};

export const calculateItemCartTotalValue = (item: StoreItem): number => {
  const bestUnitPrice = getActualProductUnitPrice(item.product, item.quantity);
  return item.quantity * bestUnitPrice;
};
export const calculateItemWishlistTotalValue = (item: WishlistItem): number => {
  const bestUnitPrice = getActualProductUnitPrice(item.product, item.quantity);
  return item.quantity * bestUnitPrice;
};
export const calculateMissionDiscountTotalValue = (missions?: Mission[]): number => {
  if (!missions || missions.length === 0) return 0;
  const discountValues = missions
    .filter((mission) => mission.discount_value && mission.progress === 100)
    .map((mission) => mission.discount_value);

  return discountValues.length > 0 ? Math.max(...discountValues) : 0;
};

export const sortFacets = (facets: FacetFilter[], order: string[]): FacetFilter[] => {
  const indices: { [key: string]: number } = {};

  order.forEach((val: string, idx: number) => {
    indices[val] = idx;
  });

  return [...facets].sort((a, b) => {
    const idxA = indices[a.label] ?? Infinity;
    const idxB = indices[b.label] ?? Infinity;

    return idxA - idxB;
  });
};

export const filtersToString = (facetFilters: FacetFilter[]): string => {
  const filtersParsed = [];
  for (const filter of facetFilters) {
    let filterContent = filter.label + "---";
    if (filter.value) {
      const filterValues = [];
      for (let i = 0; i < filter.value.length; i++) {
        let filterValueContent = filter.value[i].name;
        const filterValueValue = filter.value[i].value;
        if (filterValueValue instanceof Array) {
          filterValueContent = filterValueValue.join(":");
        }
        filterValues.push(filterValueContent);
      }
      filterContent += filterValues.join("--");
    }
    filtersParsed.push(filterContent);
  }
  return filtersParsed.join("+");
};

export const stringToFilters = (payload: string | null): FacetFilter[] => {
  const result: FacetFilter[] = [];

  if (payload) {
    const filters = payload.split("+");

    for (const filter of filters) {
      const filterPayload = filter.split("---");
      const filterLabel = filterPayload[0];
      const filterValuePayload = filterPayload[1];
      const filterValue: FacetItem[] = [];

      if (filterValuePayload) {
        const filterValues = filterValuePayload.split("--");

        for (const value of filterValues) {
          if (value.indexOf(":") >= 0) {
            filterValue.push({
              selected: true,
              value: value.split(":"),
            });
          } else {
            filterValue.push({
              selected: true,
              name: value,
            });
          }
        }
      }

      result.push({
        label: filterLabel,
        value: filterValue,
        selected: true,
      });
    }
  }

  return result;
};

export function getEnumKeyByEnumValue(myEnum: any, enumValue: string): string | null {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}

export const stringToSortOption = (payload: string | null): SortOption => {
  if (payload) {
    const option = SortOption[payload as keyof typeof SortOption];
    return option ? option : SortOption.AZ;
  }
  return SortOption.AZ;
};

export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${day}/${month}/${year}`;
}

export function validarCNPJ(cnpj: string) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(0))) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(1))) return false;

  return true;
}

export function captilizeFirstLetterOfEachWord(string: string) {
  let words = string.split(" ");

  for (let i = 0; i < words.length; i++) {
    if (words[i].length > 0) {
      words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
  }
  return words.join(" ");
}

export const generateUniqueID = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};
