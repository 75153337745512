import { Mission } from "models/Mission";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl, toBRL } from "utils/Functions";
import { STORE_FRONT_STORE_SALES, toUrl } from "utils/Links";
import { useStoreCart } from "../core/StoreCartProvider";
import barStyle from "./ProgressRewardBarModal.module.css";

type Props = {
  missions?: Mission[];
  className?: string;
};

function ProgressRewardBarModal(props: Props) {
  const {cart: { missions }} = useStoreCart();
  
  const activeMissions = useMemo(() => {
    if (props.missions) return props.missions;
    return missions;
  }, [missions, props]);

  const navigate = useNavigate();

  const bestArchivedMission = useMemo<Mission | undefined>(() => {
    if (!activeMissions) return;
    return activeMissions
      .filter((mission) => mission.progress === 100)
      .reduce((prev: Mission | undefined, current: Mission) => {
        if (!prev) return current;
        if (prev.discount_value > current.discount_value) return prev;
        return current;
      }, undefined);
  }, [activeMissions]);

  const closestMission = useMemo<Mission | undefined>(() => {
    if (!missions) return;
    return missions
      .filter(
        (mission) =>
          mission.progress < 100 &&
          mission.discount_value > 0 &&
          (!bestArchivedMission || mission.discount_value > bestArchivedMission.discount_value)
      )
      .reduce((prev: Mission | undefined, current: Mission) => {
        if (!prev) return current;
        if (prev.progress > current.progress) return prev;
        return current;
      }, undefined);
  }, [missions, bestArchivedMission]);

  function handleClick() {
    navigate(toUrl(STORE_FRONT_STORE_SALES));
  }

  const emphasizeNumbersAndSymbols = (text: string) => {
    const styleLetterA = (char: string) => {
      const regex = /[0-9R$%]/;
      if (regex.test(char)) {
        return { fontWeight: "bold" };
      }
      return {};
    };
    return (
      <div>
        {text.split("").map((char, index) => (
          <span key={index} style={styleLetterA(char)}>
            {char}
          </span>
        ))}
      </div>
    );
  };

  if (!closestMission && !bestArchivedMission) return null;

  return (
    <div className={props.className}>
      <span className="font500 font11rem">MISSÃO ATIVA</span>
      <div>
        {closestMission && (
          <>
            <div className="mb-2 mt-1">
              {closestMission.description && emphasizeNumbersAndSymbols(closestMission.description)}
            </div>
            <div>
              <div className="progress">
                <div
                  className={`progress-bar ${barStyle.bar}`}
                  role="progressbar"
                  style={{ width: `${closestMission.progress}%` }}
                ></div>
              </div>
              <div className="main-color">
                {closestMission.progress !== 100 && (
                  <div className="d-flex flex-row justify-content-between align-items-center mt-1">
                    <div>
                      <span>
                        <strong>{toBRL(closestMission.purchase_value)}</strong> /{" "}
                        {toBRL(closestMission.minimum_purchase_value)}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <img
                        src={toAbsoluteUrl("/media/menuPage/Fancy-Prize-Icon.svg")}
                        alt=""
                        className={barStyle.prizeIcon}
                      />
                      <span className="font500 main-color">{closestMission?.title?.toLocaleUpperCase()}</span>
                    </div>
                  </div>
                )}
                {closestMission.progress >= 100 && (
                  <div className="d-flex justify-content-center">
                    <img src={toAbsoluteUrl("/media/general/Star-Icon.svg")} alt="" className="transform-1px me-1" />
                    <span style={{ fontSize: "0.95rem" }}>Oba! Você ganhou: {closestMission.description}</span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {bestArchivedMission && !closestMission && (
          <div className="d-flex flex-column">
            <div className="progress">
              <div className={`progress-bar ${barStyle.bar}`} role="progressbar" style={{ width: `100%` }}></div>
            </div>
            <div className="d-flex align-items-center font500 main-color">
              <span className="font09rem text-center mt-1">
                <img
                  src={toAbsoluteUrl("/media/menuPage/Fancy-Prize-Icon.svg")}
                  alt=""
                  className={barStyle.prizeIcon}
                />
                YAY! Você acaba de completar a missão e ganhou {bestArchivedMission.title}!
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProgressRewardBarModal;
