import clsx from "clsx";
import { Product } from "models/Product";
import { StoreItem } from "models/StoreItem";
import { ChangeEvent, FC } from "react";
import { toAbsoluteUrl } from "utils/Functions";
import style from "./CartItemCounter.module.css";

type Props = {
  item: StoreItem;
  product: Product;
  boxBadge?: boolean;
  layout?: "horizontal" | "vertical";
  onAdd: (product: Product) => void;
  onRemove: (product: Product) => void;
  onChange: (product: Product, quantity: number) => void;
};

const CartItemCounterNew: FC<Props> = ({
  item,
  product,
  boxBadge = true,
  layout = "vertical",
  onAdd,
  onRemove,
  onChange,
}) => {
  // const {getItemCart} = useStoreCart()
  // const cartItem = useMemo(() => getItemCart(product.id), [getItemCart, product])

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Check if the value is a valid number using a regular expression
    const isValidNumber = /^-?\d+$/.test(value);

    let newQty = 1;
    if (isValidNumber) {
      newQty = parseInt(value, 10);
    }

    if (newQty <= 0){
      onRemove(product);
      return;
    }

    if (newQty !== item?.quantity) {
      onChange(product, newQty);
    }
  };

  return (
    <div className={clsx("d-flex", { "flex-column": layout === "vertical" })}>
      {item.quantity === 0 && (
        <div onClick={() => onAdd(product)} className={`${style.container} ${style.addNew}`}>
          <img src={toAbsoluteUrl("/media/general/Add-Cart-Black.svg")} alt="" className="me-2" />
          <span>ADICIONAR</span>
        </div>
      )}
      {item.quantity > 0 && (
        <>
          <div className={style.container}>
            <button type="button" className={style.counterButton} onClick={() => onRemove(product)}>
              -
            </button>

            <input
              type="number"
              className={style.numberField}
              value={item.quantity}
              onChange={handleQuantityChange}
            />

            <button type="button" className={style.counterButton} onClick={() => onAdd(product)}>
              +
            </button>
          </div>

          {item.product?.quantity_per_package && Math.floor(item.quantity / item.product.quantity_per_package) > 0 && (
            <div className={style.boxBadge}>
              <span>A caixa contém {item.product.quantity_per_package} {item.product.measurement_unit}s</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CartItemCounterNew;
