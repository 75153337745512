import { regionalClient } from "index";
import { Category } from "models/Category";
import { FacetFilter } from "models/FacetFilter";
import { ProductSearch } from "models/ProductSearch";
import { SearchSuggestion } from "models/SearchSuggestion";
import { SortOption } from "models/SortOptionEnum";
import { PageableResponse } from "modules/api/core/_models";
import { CategoryEndpoints } from "modules/api/endpoints/CategoryEndpoints";
import { SearchEndpoints } from "modules/api/endpoints/SearchEndpoints";
import { getEnumKeyByEnumValue } from "utils/Functions";

const ENDPOINTS = {
  listSuggestions: "/store/product/suggestion",
  listTopCategories: "/store/category/list/top-categories",
};

export const listTopCategories = () => {
  return regionalClient.get<Category[]>(CategoryEndpoints.listTop);
};

export const listCategories = (search: string, page: number = 0, perPage: number = 15) => {
  return regionalClient.post<PageableResponse<Category>>(CategoryEndpoints.list, {
    search: search,
    per_page: perPage,
    page: page,
  });
};

export const listSuggestions = (query: string) => {
  return regionalClient.get<SearchSuggestion>(SearchEndpoints.listProductSuggestions, {
    params: {
      search: query,
    },
  });
};

export const searchProductByTerm = (
  query: string,
  sort: SortOption,
  facets: FacetFilter[],
  page: number = 0,
  perPage: number = 40
) => {
  return regionalClient.post<ProductSearch>(SearchEndpoints.searchProductByTerm, {
    search: query,
    sort: getEnumKeyByEnumValue(SortOption, sort),
    facets: facets,
    per_page: perPage,
    page: page,
  });
};
