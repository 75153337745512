export type Feedback = {
    rate: number,
    satisfaction: number,
    bugs: number,
    experience_feedback: string,
    bug_description: string,
}

export const initialFeedback: Feedback = {
    rate: -1,
    satisfaction: -1,
    bugs: -1,
    experience_feedback: '',
    bug_description: '',
}
