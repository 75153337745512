import { useStoreConfig } from "layouts/common/core/StoreConfigProvider";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "utils/Functions";
import { STORE_FRONT_PAGE, toUrl } from "utils/Links";
import footerErrorStyle from "./ErrorFooter.module.css";

function ErrorFooter() {
  const { business } = useStoreConfig();
  const currentYear = new Date().getFullYear();

  return (
    <div className={footerErrorStyle.footerContainer}>
      <footer className={`${footerErrorStyle.footerStorefront}`}>
        <div className="container">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <span className="font500 main-color">CONTATO</span>
              <div className="transform-2px py-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/Vupty-102571939219080/"
                  style={{ color: "#033858" }}
                >
                  <img src={"/media/icons/Facebook-Icon.svg"} alt="" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/vuptyy/"
                  style={{ color: "#033858" }}
                  className="ms-2"
                >
                  <img src={"/media/icons/Instagram-Icon.svg"} alt="" />
                </a>
              </div>

              <div className="d-flex flex-column main-color">
                <div className="pe-2">
                  <img
                    src={toAbsoluteUrl("/media/general/Mail-Fill.svg")}
                    alt=""
                    className="main-filter transform-1px pe-1"
                  />
                  <span className="font500">{business.email}</span>
                </div>
                <div>
                  <img
                    src={toAbsoluteUrl("/media/general/Whatsapp-Icon-Fill.svg")}
                    alt=""
                    className="main-filter transform-1px pe-1"
                  />
                  <span className="font500">{business.business_telephone_number}</span>
                </div>
              </div>
            </div>

            <div>
              <span className="font500 main-color">ENDEREÇO</span>
              <div className="d-flex flex-column pt-2">
                <span>{business.address?.complement},</span>
                <span>{business.address?.street}, {business.address?.number}, {business.address?.neighborhood},</span>
                <span>{business.address?.city} - {business.address?.state?.uf}, {business.address?.cep}</span>
              </div>
            </div>

            <div className="d-flex justify-content-center flex-column align-items-center">
              <Link to={toUrl(STORE_FRONT_PAGE)}>
                <img src={toAbsoluteUrl("/media/logos/Navbar-Logo.svg")} alt="logo" height={"48px"} />
              </Link>
            </div>
          </div>
        </div>
      </footer>
      <footer className={footerErrorStyle.footerEnd}>
        <span>{`© Copyright ${currentYear}. ${business.name}. Todos os direitos reservados. CNPJ: ${business.cnpj} |  ${business.address?.complement}, ${business.address?.street}, ${business.address?.number}, ${business.address?.neighborhood}, ${business.address?.city} - ${business.address?.state?.uf}, ${business.address?.cep}`}</span>
      </footer>
    </div>
  );
}

export default ErrorFooter;
