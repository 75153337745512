import clsx from "clsx";
import ProductCounterSkeletonVertical from "components/desktop/Skeletons/ProductCounterSkeletonVertical";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import { Product } from "models/Product";
import { ChangeEvent, useMemo } from "react";
// import { toAbsoluteUrl } from 'utils/Functions'
import { toAbsoluteUrl } from "utils/Functions";
import style from "./ProductCounterLong.module.css";

type Props = {
  product: Product;
  loading?: boolean;
  itemType?: string;
  title?: string;
  onAdd: (product: Product) => void;
  onRemove: (product: Product) => void;
  onChange: (product: Product, quantity: number) => void;
  disabled?: boolean;
};

function ProductCounterLong({ product, loading = true, onChange, onAdd, onRemove, disabled = true }: Props) {
  const { getItemCart } = useStoreCart();
  const cartItem = useMemo(() => getItemCart(product.id), [getItemCart, product]);

  // const enabled = useMemo(() => {
  //   if (!product.sell_price || (cartItem && cartItem.quantity <= 0)) return false;
  //   return true;
  // }, [product, cartItem]);

  const handleAddClick = () => {
    if (disabled) return;
    onAdd(product);
  };

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newQty = parseInt(event.target.value);

    if (newQty !== cartItem?.quantity) {
      onChange(product, newQty);
    }
  };

  return (
    <>
      {loading && <ProductCounterSkeletonVertical />}
      {!loading && (!cartItem || disabled) && (
        <div className={clsx(style.itemCardCounter, disabled && style.disabled)} onClick={handleAddClick}>
          <div className={clsx(style.addToCart, disabled && style.disabled)}>
            <img src={process.env.PUBLIC_URL + "/media/general/Mobile-Card-Addition.svg"} alt="img" />
          </div>
        </div>
      )}
      {!loading && cartItem && !disabled && (
        <div className={style.activeCounter}>
          <button onClick={() => onAdd(product)} className={style.add}>
            +
          </button>
          <input type="number" className={style.barCounter} value={cartItem.quantity} onChange={handleQuantityChange} />
          {cartItem.quantity > 1 && (
            <button onClick={() => onRemove(product)} className={style.remove}>
              -
            </button>
          )}
          {cartItem.quantity <= 1 && (
            <div className={style.removeTrash}>
              <img
                src={toAbsoluteUrl("/media/general/Trash-Outline-Icon.svg")}
                alt="Trash"
                onClick={() => onRemove(product)}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ProductCounterLong;
