import Image from 'components/common/Image/Image';
import { ItemImage } from 'models/ItemImage';
import { FC, useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import imageStyle from './ProductImageCarousel.module.css';

type Props = {
    height?: number;
    width?: number;
    className?: string;
    images: ItemImage[];
    thumbnail?: ItemImage;
}

const ProductImageCarousel: FC<Props> = ({ images, className, height, width, thumbnail }) => {

    const displayImages = useMemo<ItemImage[]>(() => {
        const result = thumbnail ? [thumbnail, ...images] : images;

        if (result.length === 0) return [{
            url: 'https://via.placeholder.com/150',
            title: 'Sem imagem'
        }]

        return result;
    }, [thumbnail, images])

    return <>
        <Carousel showStatus={false} showThumbs={false} autoPlay={true} interval={10000} >
            {displayImages.map((image, idx) => (
                <div key={idx}>
                    <Image
                        src={image.url}
                        alt={image.title}
                        height={height}
                        width={width}
                        fallbackSrc='https://via.placeholder.com/150'
                        className={imageStyle.productImage}
                    />
                    {/* <img src={image.url} alt={image.title} className={imageStyle.productImage} /> */}
                </div>
            ))}
        </Carousel>
    </>;
}

export default ProductImageCarousel;
