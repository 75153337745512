import { useStoreConfig } from "layouts/common/core/StoreConfigProvider";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "utils/Functions";
import footerStyle from "./Footer.module.css";

const Footer = () => {
  const { business } = useStoreConfig();
  const currentYear = new Date().getFullYear();

  const openGPlay = () => {
    window.open("https://play.google.com/store/apps", "_blank");
  };

  const openAppStore = () => {
    window.open("https://www.apple.com/app-store/", "_blank");
  };

  return (
    <div className={footerStyle.footerContainer}>
      <div className={`${footerStyle.contactBar}`}>
        <div className="container d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex">
            <span className="font500 main-color">Siga-nos!</span>
            <div className="transform-2px">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Vupty-102571939219080/"
                style={{ color: "#033858" }}
                className="ms-2"
              >
                <img src={"/media/icons/Facebook-Icon.svg"} alt="" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/vuptyy/"
                style={{ color: "#033858" }}
                className="ms-2"
              >
                <img src={"/media/icons/Instagram-Icon.svg"} alt="" />
              </a>
            </div>
          </div>

          <div></div>

          <div className="d-flex main-color">
            <div className="pe-2">
              <img
                src={toAbsoluteUrl("/media/general/Mail-Fill.svg")}
                alt=""
                className="main-filter transform-1px pe-1"
              />
              <span className="font500">{business.email}</span>
            </div>
            <div>
              <img
                src={toAbsoluteUrl("/media/general/Whatsapp-Icon-Fill.svg")}
                alt=""
                className="main-filter transform-1px pe-1"
              />
              <span className="font500">{business.business_telephone_number}</span>
            </div>
          </div>
        </div>
      </div>

      <footer className={`${footerStyle.footerStorefront}`}>
        <div className="container">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-none d-md-flex justify-content-center flex-column align-items-start ">
              <img
                src={toAbsoluteUrl("/media/logos/Vupty-Logo-Footer.svg")}
                alt="logo"
                className={footerStyle.footerLogo}
              />

              <img
                src={process.env.PUBLIC_URL + "/media/logos/google-play-badge.png"}
                alt="logo"
                className={footerStyle.appStore}
                onClick={openGPlay}
              />

              <img
                src={toAbsoluteUrl("/media/logos/App-Store-Badge.svg")}
                alt="Applelogo"
                className="cursor-pointer"
                onClick={openAppStore}
              />
            </div>

            <div className={footerStyle.linksList}>
              <div>
                <div className="d-flex flex-column mb-2">
                  <span className="font500 font11rem main-color mb-3">Como comprar?</span>
                  <Link to="/home/how" className={footerStyle.listedLinks}>
                    {" "}
                    Primeiro acesso{" "}
                  </Link>
                  <Link to="/home" className={footerStyle.listedLinks}>
                    {" "}
                    Primeiro pedido{" "}
                  </Link>
                  <Link to="/home" className={footerStyle.listedLinks}>
                    {" "}
                    Pagamento{" "}
                  </Link>
                </div>
              </div>
            </div>

            <div className={footerStyle.linksList}>
              <div>
                <div className="d-flex flex-column mb-2">
                  <span className="font500 font11rem main-color mb-3">Sobre a Vupty</span>
                  <Link to="/conta/faq" className={footerStyle.listedLinks}>
                    {" "}
                    Sobre nós{" "}
                  </Link>
                  <Link to="" className={footerStyle.listedLinks}>
                    {" "}
                    Nossos parceiros{" "}
                  </Link>
                  <Link to="/marcas" className={footerStyle.listedLinks}>
                    {" "}
                    Marcas{" "}
                  </Link>
                  <Link to="" className={footerStyle.listedLinks}>
                    {" "}
                    Listas sugeridas{" "}
                  </Link>
                </div>
              </div>
            </div>

            <div className={footerStyle.linksList}>
              <div>
                <div className="d-flex flex-column mb-2">
                  <span className="font500 font11rem main-color mb-3">Políticas</span>
                  <Link to="/legal/termos-de-uso" target="_blank" className={footerStyle.listedLinks}>
                    {" "}
                    Termos de uso{" "}
                  </Link>
                  <Link to="/legal/politica-de-privacidade" target="_blank" className={footerStyle.listedLinks}>
                    {" "}
                    Regras de uso e política de privacidade{" "}
                  </Link>
                  <Link to="" target="_blank" className={footerStyle.listedLinks}>
                    {" "}
                    Política de cookies{" "}
                  </Link>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-between mb-2">
              <div className="d-flex flex-column">
                <span className="font500 font11rem main-color mb-3">Suporte</span>
                <Link to="" className={footerStyle.listedLinks}>
                  {" "}
                  Garantia do produto{" "}
                </Link>
                <Link to="/suporte" className={footerStyle.listedLinks}>
                  {" "}
                  Trocas e devoluções{" "}
                </Link>
              </div>
            </div>
          </div>

          {/* MAKE THE CHATBOT WORK */}
          <div className="d-flex align-items-center justify-content-end mt-2">
            <span className="main-color font500 me-2">Precisando de ajuda?</span>
            <img src="/media/icons/Bot-Icon.svg" alt="" className="me-1" />
            <div className={footerStyle.botButton}>Tire suas dúvidas no nosso CHAT online!</div>
          </div>
        </div>
      </footer>

      <footer className={footerStyle.footerEnd}>
        <span>{`© Copyright ${currentYear}. ${business.name}. Todos os direitos reservados. CNPJ: ${business.cnpj} |  ${business.address?.complement}, ${business.address?.street}, ${business.address?.number}, ${business.address?.neighborhood}, ${business.address?.city} - ${business.address?.state?.uf}, ${business.address?.cep}`}</span>
      </footer>
    </div>
  );
};

export default Footer;
