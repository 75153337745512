import { useQuery } from '@tanstack/react-query';
import { City, Region } from 'models/Region';
import { useCallback, useMemo, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { useDebounce, useLocalStorage } from 'usehooks-ts';
import { toAbsoluteUrl } from 'utils/Functions';
import searchStyle from './CitySearchDesktop.module.css';
import { getLocation, listRegions } from './core/_requests';

type Props = {
    onChange: Function;
};

function CitySearchDesktop({ onChange }: Props) {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [geoPermissionStatus, setGeoPermissionStatus] = useLocalStorage('store-geo-permission', true);
    const [browserLocation, setBrowserLocation] = useState<{ latitude: number, longitude: number }>();

    const { data: regionsData, isLoading: regionIsLoading } = useQuery({
        queryFn: () => listRegions(debouncedSearchTerm, 6),
        queryKey: ['listRegions', debouncedSearchTerm],
    })

    const { data: geoData, isLoading : geoIsLoading} = useQuery({
        queryFn: async () => {
            const response = await getLocation(browserLocation!!.latitude, browserLocation!!.longitude)
            handleSelect( response.data.city, response.data.region)
            return response
        },
        queryKey: ['getLocation', browserLocation],
        enabled: !!browserLocation,
    })

    const cities = useMemo(() => {
        if (!regionsData) return []

        const normalizedSearchTerm = debouncedSearchTerm.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        return regionsData.data.content.flatMap((region) =>
            region.cities?.filter((city) =>
                city.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(normalizedSearchTerm)
            ) || []
        );
    }, [regionsData, debouncedSearchTerm]);

    const handleSelect = (city: City, region?: Region) => {
        if (!regionsData) return

        const selectedRegion = region ?? regionsData.data.content.find(reg => reg.cities?.some(c => c.id === city.id));
        onChange(selectedRegion);
    }

    const handleUseMyLocationClick = useCallback(() => {
        // Check if the browser supports geolocation
        if (!navigator.geolocation) {
            toast.error('Seu navegador nao suporta geo localizacao', {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            return;
        }
        navigator.geolocation.getCurrentPosition(
            (position) => {
                // Retrieve latitude and longitude coordinates
                setGeoPermissionStatus(true);
                setBrowserLocation(position.coords)
            },
            (error) => {
                setGeoPermissionStatus(false);
                toast.error('Acesso a geo localizacao esta bloqueado', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        );
    }, []);

    return (
        <div className={searchStyle.search}>
            {!geoPermissionStatus && (<>
                <span>O acesso a sua localizacao foi negado para permitir o acesso a localização novamente </span>
                <a href='https://support.google.com/chrome/answer/142065?hl=pt-br' target='_blank' rel="noreferrer">Clique aqui</a>
            </>)}
            <div className={searchStyle.locationButton} onClick={handleUseMyLocationClick}>
                {geoIsLoading && (<div className='d-flex justify-content-center align-items-center'>
                    <ClipLoader color="#033858" size={20} />
                    <span className='font500 ms-2'>Carregando...</span>
                </div>)}
                {!geoIsLoading && (<>
                    <span>Utilizar localização atual</span>
                    <img
                        src={toAbsoluteUrl('/media/general/GPS-Icon.svg')}
                        alt=""
                        className="ms-2 transform-1px"
                    />
                </>)}
            </div>

            <div className="position-sticky">
                <div className={`${searchStyle.formField} d-flex`}>
                    <div className={searchStyle.searchIconFaded}>
                        <img
                            src={toAbsoluteUrl('/media/general/Magnifing-Glass-Icon.svg')}
                            alt="mg"
                            className={`${searchTerm.length > 0 && 'main-filter'}`}
                        />
                    </div>
                    <input
                        type="text"
                        placeholder={'Digite seu CEP ou Endereço'}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={searchStyle.searchBar}
                    />

                    <div className={searchStyle.xIcon}>
                        {searchTerm.length > 0 && (
                            <img
                                src={toAbsoluteUrl('/media/general/X-icon.svg')}
                                alt=""
                                onClick={() => setSearchTerm('')}
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* {debouncedSearchTerm.length > 0 && debouncedSearchTerm.length < 3 && ( */}
                <div className='px-3'>
                    <div className="mt-2">
                        {cities.slice(0, 4).map((city, idx) => (
                            <div
                                key={idx}
                                className="d-flex justify-content-between cursor-pointer font500 py-3"
                                onClick={() => handleSelect(city)}>
                                <span>{city.name}</span>
                                <span>{city.uf}</span>
                            </div>
                        ))}
                    </div>
                    {/*
                        {cepAddressResult && (
                            <div className="mt-3">
                                <span>{cepAddressResult}</span>
                            </div>
                        )}
                    */}
                </div>
            {/* )} */}

            {debouncedSearchTerm.length >= 4 && regionsData?.data.size === 0 && (
                <div className='d-flex flex-column align-items-center mt-3'>
                    <img src={'/media/Illustrations/No-Location-Illustration.svg'} alt="rights reserved by Freepik Company" className='mb-3' />
                    <div>
                        <img
                            src={toAbsoluteUrl('/media/general/GPS-Icon-Red.svg')}
                            alt=""
                            className="me-2 transform-1px "
                        />
                        <span className='font500 danger-color'>Ops! Infelizmente ainda não conseguimos chegar até aí...</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CitySearchDesktop;
