import React from 'react';
import { normalizeRoutes } from 'utils/Functions';
import { useRoutes } from 'react-router-dom';
import UserTermsPage from './UserTermsPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';

const LegalWrapper: React.FC = () => {
    const routes = normalizeRoutes([
        {
            path: 'termos-de-uso',
            element: <UserTermsPage />,
        },{
            path: 'politica-de-privacidade',
            element: <PrivacyPolicyPage />,
        }
    ])

  return useRoutes(routes);
}

export default LegalWrapper;
