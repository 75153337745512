import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router';
import AccountDonationPage from './AccountDonationPage';

const AccountDonationWrapper: React.FC = () => {
  return <Routes>
    <Route path='lista' element={<><AccountDonationPage /><Outlet /></>} />
    <Route path='*' element={<Navigate to='lista' />} />
  </Routes>;
}

export default AccountDonationWrapper;
