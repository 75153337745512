import React from 'react'
import ContentLoader from 'react-content-loader'

function MegaMenuSkeleton() {
  return (
    <ContentLoader 
      speed={2}
      width={1900}
      height={23}
      viewBox="0 0 1900 23"
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb">
      <rect x="0" y="0" rx="16" ry="16" width="190" height="56" /> 
      <rect x="210" y="-2" rx="0" ry="0" width="100" height="26" /> 
      <rect x="330" y="-1" rx="0" ry="0" width="100" height="26" /> 
      <rect x="450" y="0" rx="0" ry="0" width="100" height="26" /> 
      <rect x="570" y="0" rx="0" ry="0" width="100" height="26" /> 
      <rect x="690" y="0" rx="0" ry="0" width="100" height="26" /> 
      <rect x="810" y="0" rx="0" ry="0" width="100" height="26" /> 
      <rect x="930" y="0" rx="0" ry="0" width="100" height="26" /> 
      <rect x="1050" y="0" rx="0" ry="0" width="100" height="26" />
    </ContentLoader>
  )
}

export default MegaMenuSkeleton