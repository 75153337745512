import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'
import { parse } from 'date-format-parse'
import { useEffect, useMemo } from 'react'
import fakeData, { Return } from 'resources/data/fake-data'
import { toAbsoluteUrl, toBRL } from 'utils/Functions'
import { ACCOUNT_ORDERS_PAGE, ACCOUNT_RETURNS_PAGE, toUrl } from 'utils/Links'
import DataTableBase from '../../../../components/common/Datatable/DataTableBase'
import DateSelectionTableFilter from '../AccountBill/component/DateSelectionTableFilter'
import returnStyle from './AOStyle.module.css'

function AOReturns() {
    const {returns} = fakeData

    useEffect(() => {
        returns.sort((a:Return, b:Return) => {
          const returnDateA = parse(a.updated_date, 'DD/MM/YYYY')
          const returnDateB = parse(b.updated_date, 'DD/MM/YYYY')
          return returnDateA === returnDateB ? 0 : returnDateA > returnDateB ? -1 : 1
        })
    }, [returns])

    const breadcrumbs = useMemo(() => {
      return(
        [
          {
            title : 'Perfil',
            path: toUrl(ACCOUNT_ORDERS_PAGE),
          },
          {
            title : 'Pagamentos',
            path : toUrl(ACCOUNT_RETURNS_PAGE),
          }
        ]
      )
    }, [])

    const columns = [
      {
        name: 'Número do pedido',
        selector: (row:Return) => row.id,
        sortable: true,
      },
      {
        name: 'Data de atualização',
        selector: (row:Return) => row.updated_date,
        sortable: true,
      },
      {
        name: 'Data de criação',
        selector: (row:Return) => row.creation_date,
        sortable: true,
      },
      {
        name: 'Situação',
        selector: (row:Return) => row.status,
        cell: (row:Return) => (
          <span className={`badgeStatus${row.status_slug} orderbadgeStatus`}>{row.status}</span>
        ),
      },
      {
        name: 'Total',
        selector: (row:Return) => row.payment_value,
        sortable: true,
        cell: (row:Return) => toBRL(row.payment_value),
      },
    ]

  // MODAL CONTROL
  function handleDateSelectorChange(value: string) {
    console.log(value)
  }

  return (
  <div className='container'>
    <div>
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <span className='font600 font15rem'>TROCAS E DEVOLUÇÕES</span>
    </div>

    <div>
      <DateSelectionTableFilter/>
    </div>

    {returns.length === 0 &&
      <div className='d-flex justify-content-center'>
        <div className={returnStyle.emptyPage}>
          <div className='d-flex flex-column align-items-center'>
            <img src={toAbsoluteUrl('/media/general/Return-Icon.svg')} alt='coockie' className='me-4'/>
            <span className='font500 font12rem mt-4 pb-3'>
                Você ainda não realizou nenhuma troca ou devolução de marcadoria
            </span>
          </div>
        </div>
      </div>
    }

    {returns.length > 0 &&
      <div className={returnStyle.order}>
        <DataTableBase
          columns={columns}
          data={returns}
          // expandableRowsComponent={ExpandedComponent}
        />
      </div>
    }

  </div>
  )
}

export default AOReturns
