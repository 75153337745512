import moment from 'moment'
import { DATE_TIME_FORMAT } from 'utils/Consts'
import { FormOfPayment } from './FormOfPayment'
import { Mission } from './Mission'
import { StoreItem } from './StoreItem'
import { Product } from './Product'

export type Payment = {
    method?: string
    payment_condition?: string
    card?: number
}

export type StoreCart = {
    id?: string
    items: StoreItem[]
    items_quantity: number
    items_count: number
    items_total: number
    total: number
    total_delivery_fee: number
    total_mission_discount: number
    total_donation: number
    total_items_discount: number
    sync: boolean
    modification_date?: string
    creation_date?: string
    grocery_id?: string
    fingerprint: string
    form_of_payments: FormOfPayment[]
    // form_of_payment?: FormOfPayment
    missions?: Mission[]
    estimated_delivery_date?: string
}

export type ProductWithQuantity = {
    product: Product
    quantity: number
}

export const initialStoreCart: StoreCart = {
    items: [],
    items_total: 0,
    total_delivery_fee: 15,
    total: 0,
    items_quantity: 0,
    items_count: 0,
    total_mission_discount: 0,
    total_items_discount: 0,
    total_donation: 0,
    sync: false,
    grocery_id: '',
    modification_date: moment().format(DATE_TIME_FORMAT),
    creation_date: moment().format(DATE_TIME_FORMAT),
    fingerprint: '',
    form_of_payments: [],
    missions: []
}
