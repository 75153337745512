import PageTitle from "components/common/PageTitle/PageTitle";

import SortFilter from 'components/mobile/SortFilter/SortFilter';
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { useSplashScreen } from "components/common/SplashScreen/SplashScreenProvider";
import StoreCartBar from "components/mobile/StoreCartBar/StoreCartBar";
import MissionProgressBar from "layouts/common/MissionProgressBar/MissionProgressBar";
import ProductLayoutFactory from "layouts/mobile/ProductLayout/ProductLayoutFactory";
import { Mission } from "models/Mission";
import { Product } from "models/Product";
import { SortOption } from 'models/SortOptionEnum';
import InfiniteScroll from "react-infinite-scroller";
import ClipLoader from "react-spinners/ClipLoader";
import { useDebounce, useIsFirstRender } from "usehooks-ts";
import { toAbsoluteUrl } from "utils/Functions";
import missionDetailStyle from './MissionDetailPage.module.css';
import { detail, searchMissionProductsByTerm } from "./core/_requests";
import { STORE_FRONT_CART_PAGE, toUrl } from "utils/Links";

function MissionDetailPage() {

    const { id } = useParams()
    const [products, setProducts] = useState([] as Product[])
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 500)
    const [sortFilter, setSortFilter] = useState<SortOption>(SortOption.AZ)
    const [totalElements, setTotalElements] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalPages, setTotalPages] = useState(-1)
    const { hide: hideSplashScreen, show: showSplashScreen } = useSplashScreen()
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [mission, setMission] = useState<Mission>()
    const initialRender = useIsFirstRender()
    const navigate = useNavigate()

    const hasMoreContent = useMemo(() => {
        if (totalPages === -1) return false
        return totalPages > (currentPage + 1)
    }, [currentPage, totalPages])

    const { isLoading: isMissionLoading, isFetching: isMissionFetching, isError: isMissionError } = useQuery({
        queryFn: async () => {
            const response = await detail(id!!)
            setMission(response.data)
            return response
        },
        queryKey: ['mission', id],
        enabled: !!id,
    })

    const { isLoading: isProductLoading, isFetching: isProductFetching } = useQuery({
        queryFn: async () => {
            const response = await searchMissionProductsByTerm(id!!, debouncedSearchTerm, sortFilter!!, currentPage)
            setProducts((prev) => {
                if (currentPage === 0) {
                    return response?.data.content
                }
                return [...prev, ...response?.data.content]
            })
            setTotalElements(response.data.total_elements)
            setTotalPages(response.data.total_pages)
            setIsLoadingMore(false)
            hideSplashScreen()
            return response
        },
        queryKey: ['searchMissionProductsByTerm', id, debouncedSearchTerm, sortFilter, currentPage],
        enabled: !!mission && !!sortFilter,
        refetchOnWindowFocus: false,
    })

    const handleSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm)
    }

    const handleSortFilterChange = (sortOption: SortOption) => {
        setCurrentPage(0)
        setSortFilter(sortOption)
    }

    const onLoadMoreContent = (page: number) => {
        if (isProductLoading || currentPage >= totalPages - 1) return
        setCurrentPage((prev) => prev + 1)
        setIsLoadingMore(true)
    }

    const isSearchingPlaceholderVisible = useMemo(() => {
        return isMissionFetching && initialRender
    }, [initialRender, isMissionFetching])

    return (
        <>
            <StoreCartBar />
            <PageTitle title={`MISSÃO ${mission?.title}`} />
            {isSearchingPlaceholderVisible && <div>Carregando...</div>}
            {isMissionError && <div>Erro ao carregar missão</div>}

            <div className='container padding-bottom-cart-mobile'>
                {/* <div className={missionDetailStyle.barContainer}>
                    <div className='font600 main-color d-flex align-items-center mb-2'>
                        <img src={'/media/menuPage/Prize-Icon.svg'} alt='' className={missionDetailStyle.prizeIcon} /> */}
                {/* {mission?.description} */}
                {/* </div> */}
                {/* <MobileProgressBar mission={mission} className='mt-2' /> */}
                {/* <ProgressRewardBar target={mission.target} type={mission.type} reward={mission.reward} className="mt-2"/> */}
                {/* </div> */}
                {mission &&
                    <div className={missionDetailStyle.missionBubble}>
                        <div>
                            <img src={'/media/menuPage/Prize-Icon.svg'} alt='' height={'22px'} className="transform-2px"/>
                            <span className="main-color font500 font12rem">
                                {mission.title}
                            </span>
                        </div>
                        <MissionProgressBar mission={mission}/>
                        {(mission.purchase_value !== undefined && mission.minimum_purchase_value !== undefined) && (mission.purchase_value >= mission.minimum_purchase_value) && 
                            <div className={missionDetailStyle.backToCart} onClick={() => navigate(toUrl(STORE_FRONT_CART_PAGE))}>
                                <img src={toAbsoluteUrl('/media/icons/Enter-Icon.svg')} alt="" className="me-1"/>
                                Conferir carrinho
                            </div>
                        }
                    </div>
                }       

                <div className={missionDetailStyle.search}>
                    <div className={`${missionDetailStyle.formField} d-flex`}>
                        <div className={missionDetailStyle.searchIconFaded}>
                            <img
                                src={toAbsoluteUrl('/media/general/Magnifing-Glass-Icon.svg')}
                                className=''
                                alt="mg"
                            />
                        </div>

                        <input
                            type="text"
                            placeholder={'Pesquise items da missão'}
                            className={missionDetailStyle.searchBar}
                            value={searchTerm}
                            onChange={(event) => handleSearch(event.target.value)}
                        />

                        <div className={missionDetailStyle.XIcon}>
                            <img
                                id="qrCode"
                                // LOGIC TO OPEN THE CAMERA
                                // onClick={'openCamera'}
                                src={process.env.PUBLIC_URL + '/media/general/QR-Reader.svg'}
                                alt="img"
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-end mb-1">
                    <SortFilter onChange={handleSortFilterChange} value={sortFilter} />
                </div>
                {!isProductLoading && products.length === 0 && (
                    <div className={`d-flex flex-column align-items-center row mt-5`}>
                        <span>O item que você pesquisou não está disponivel no momento</span>
                        <img
                            src={process.env.PUBLIC_URL + '/media/general/Empty-Box.svg'}
                            alt="Caixa"
                            className="mt-3"
                        />
                    </div>
                )}
                {products.length > 0 && (
                    <InfiniteScroll
                        className='w-100'
                        pageStart={0}
                        initialLoad={false}
                        loadMore={onLoadMoreContent}
                        hasMore={hasMoreContent}
                        loader={<div className="loader d-flex justify-content-center" key={0}>
                            <ClipLoader
                                color="#033858"
                                size={18}
                            />
                        </div>}
                    >
                        <ProductLayoutFactory products={products} loading={isProductFetching && !isLoadingMore} />
                    </InfiniteScroll>
                )}
            </div>
        </>
    )
}

export default MissionDetailPage
