import { toAbsoluteUrl } from 'utils/Functions'
import FAQstyle from './SearchFAQ.module.css'
import React, { ChangeEvent, useEffect, useState } from 'react';

interface SearchBarProps{
    onChange: (value: string) => void;
}

const SearchFAQ: React.FC<SearchBarProps> = ({ onChange }) => {
  const [searchWord, setSearchWord] = useState('')

  useEffect(()=> (
    onChange(searchWord)
  ), [searchWord])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const searchWord = e.target.value;
      setSearchWord(searchWord);
  };

  return (
    <div className={FAQstyle.search}>

    <div>
      <div className={`${FAQstyle.formField} container d-flex`}>
        <div className={`${FAQstyle.searchIconFaded} ${searchWord.length > 0 && 'main-filter'}`}>
          {
            <img
              src={toAbsoluteUrl('/media/general/Magnifing-Glass-Icon.svg')}
              className=''
              alt="mg"
            />
          }
        </div>

        <input
          type="text"
          placeholder={'Qual a sua dúvida?'}
          className={FAQstyle.searchBar}
          onChange={(e) => handleChange(e)}
          value={searchWord}
        />

        {searchWord.length > 1 &&
            <div className={FAQstyle.XIcon}>
                <img
                    src={process.env.PUBLIC_URL + '/media/general/X-icon.svg'}
                    alt="img"
                    className='main-filter'
                    onClick={() => setSearchWord('')}
                />
            </div>
        }  
      </div>
    </div>
    </div>
  )
}

export default SearchFAQ