import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
 
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--mainColor);
  margin-bottom: 1rem;
`
const Label = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 1.2rem;
  font-weight: 500;
  &:focus {
    font-weight: 600;
  }
`
const SubLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
`
const SubCategory = styled.span`
  margin-bottom: 0.1rem;
  cursor: pointer;
  &:hover {
    font-weight: 500;
  }
`

type MenuOptionProps = {
  link?: string
  label: string
  options?: {label: string; link?: string}[]
  open?: boolean
  callback?: () => void
}

function MenuOption({link = '', label, options, callback, ...props}: MenuOptionProps) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(props.open)
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('query') || ''
  const [selected, setSelected] = useState('')

  const toggle = (label: string) => {
    if (selected === label) {
      return setSelected(label)
    }
    setSelected(label)
  }

  const toggleOption = () => {
    setOpen((prevState) => !prevState)
  }

  const handleClick = (link?: string) => {
    if (callback) {
      callback()
    }
    if (link) {
      navigate(link)
    }
  }

  return (
    <Container onClick={() => handleClick(link)}>
      <div className='d-flex flex-column align-items-start py-1'>
        <Label onClick={toggleOption}>
          <span onClick={() => toggle(label)} className={selected === label ? 'font500' : ''}>
            {label}
          </span>
          &nbsp;
        </Label>
        {options && (
          <SubLabel>
            {options.map((option, idx) => (
              <SubCategory onClick={() => handleClick(option.link)} key={idx}>
                {option.label}
              </SubCategory>
            ))}
          </SubLabel>
        )}
      </div>
    </Container>
  )
}

export default MenuOption
