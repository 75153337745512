import ContentLoader from 'react-content-loader'

function MenuImageSkeleton() {
  return (
    <ContentLoader 
        speed={2}
        width={28}
        height={22}
        viewBox="0 0 28 22"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb"
        >
    <rect x="0" y="0" rx="4" ry="4" width="28" height="22" />
    </ContentLoader>
  )
}

export default MenuImageSkeleton