import { useStoreConfig } from "layouts/common/core/StoreConfigProvider";
import { useStoreLocation } from "layouts/common/core/StoreLocationProvider";
import { useAuth } from "modules/auth";
import { useEffect, useRef, useState } from "react";
import { toAbsoluteUrl } from "utils/Functions";
import CitySearchModal from "../CitySearchModal/CitySearchModal";
import infoStyle from "./InfoBar.module.css";

function InfoBar() {
  const { storeLocation } = useStoreLocation();
  const { business } = useStoreConfig();
  const [isCityModalOpen, setCityModalOpen] = useState(false);
  const [isLocationBubbleOpen, setIsLocationBubbleOpen] = useState(false);
  const closingRef = useRef<HTMLDivElement>(null);
  const {auth} = useAuth();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (closingRef.current && !closingRef.current.contains(event.target as Node)) {
        setIsLocationBubbleOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCityModalClose = () => {
    setCityModalOpen(false);
  };

  function handleChangeLocationClick() {
    setIsLocationBubbleOpen(false);
    if (!auth){
      setCityModalOpen(true);
    }
  }

  return (
    <div className={infoStyle.contactBar}>
      <CitySearchModal open={isCityModalOpen} onHide={handleCityModalClose} />

      {!storeLocation && (
        <div className="d-flex cursor-pointer" onClick={handleChangeLocationClick}>
          <img src={toAbsoluteUrl("/media/icons/Map-Pin-Filled.svg")} alt="" className="transform-1px white-filter" />
          <span className="font09rem">Selecione sua cidade</span>
        </div>
      )}

      {storeLocation && (
        <div className="d-flex cursor-pointer position-relative">
          <div className="d-flex" onClick={() => setIsLocationBubbleOpen(!isLocationBubbleOpen)}>
            <img src={toAbsoluteUrl("/media/icons/Map-Pin-Filled.svg")} alt="" className="transform-1px white-filter" />
            <span className="font09rem">
              {storeLocation.region.name}
            </span>
          </div>
          {isLocationBubbleOpen && (
            <div className={infoStyle.locationBubble} ref={closingRef}>
              <div className={infoStyle.triangle}></div>
              <div className="p-2 d-flex flex-column align-items-center">
                <span className="main-color font500">Confira o seu endereço!</span>
                <span className="font500 black-color text-underline">
                  {storeLocation.region.name}
                </span>
                <div className={infoStyle.locationBubbleButton} onClick={handleChangeLocationClick}>
                  Alterar localização
                  <img
                    src={toAbsoluteUrl("/media/icons/Pinned-Map.svg")}
                    alt=""
                    className="transform-1px main-filter ms-1"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <span className={`d-none d-lg-block`}>Realizando suas compras até as 11h00, Entregamos no mesmo dia!</span>

      <div className="d-flex justify-content-between">
        <span>
          <img src={toAbsoluteUrl("/media/general/Mail-Fill.svg")} alt="logo" className="me-2" />
          {business?.email}
        </span>
        <span>
          <img src={toAbsoluteUrl("/media/general/Whatsapp-Icon-Fill.svg")} alt="logo" className="me-2" />
          {business?.business_telephone_number}
        </span>
      </div>
    </div>
  );
}

export default InfoBar;
