import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'
import { useGrocery } from 'layouts/common/core/GroceryProvider'
import { useStoreConfig } from 'layouts/common/core/StoreConfigProvider'
import { useMemo } from 'react'
import { ACCOUNT_ORDERS_PAGE, STORE_FRONT_CONTACT_PAGE, toUrl } from 'utils/Links'
import contactStyle from './AOStyle.module.css'

function AOContact() {

  const { business } = useStoreConfig()
  const {groceryDetails} = useGrocery()

  const breadcrumbs = useMemo(() => {
    return(
      [
        {
          title : 'Perfil',
          path: toUrl(ACCOUNT_ORDERS_PAGE),
        },
        {
          title : 'Contatos',
          path : toUrl(STORE_FRONT_CONTACT_PAGE),
        }
      ]
    )
  }, [])

  const whatsappLink = useMemo(() => {
    if (!business.business_telephone_number) return;
    const onlyNumbers = business.business_telephone_number.replace(/\D/g, '');
    return `https://wa.me/${onlyNumbers}?text=Ol%C3%A1%2C%20gostaria%20de%20pedir%20ajuda%20a%20central%20Vupty.`;
  }, [business.business_telephone_number]);

  const emailLink = useMemo(() => {
    return `mailto:${business.email}`;
  }, [business.email]);

  const telephoneLink = useMemo(() => {
    return `tel:${business.business_telephone_number}`;
  }, [business.business_telephone_number]);

  return (
    <div className='container'>
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <div>
        <span className='font600 font15rem mt-5'>FALE CONOSCO</span>
      </div>

      <div className='mt-5'>
        <p>
          Escolha uma das opções abaixo para dar início ao seu atendimento através do nosso suporte.
          Em seguida, você irá se comunicar com um de nossos atendentes, que irá prosseguir com a
          solução do seu problema.
        </p>
      </div>

      <div className='row'>
        <div className={`col-md-4 col-12 py-3`}>
          <div className={`${contactStyle.bubbles} d-flex flex-column justify-content-center`}>
            <div className='d-flex flex-column align-items-center mb-3'>
              <img
                src={process.env.PUBLIC_URL + '/media/general/Whatsapp-Icon.svg'}
                alt='img'
                width='35px'
                className='py-1'
              />
              <span className='font500'>WhatsApp</span>
            </div>
            <span className='d-flex justify-content-center mb-3 font500'>{business.whatsapp}</span>
            {/* NEED TO CHANGE PHONE NUMBER EVENTUALY */}
            <a
              href={whatsappLink}
              target='_blank'
              rel='noreferrer'
              className={contactStyle.faqButton}
            >
              Iniciar conversa no WhatsApp
            </a>
          </div>
        </div>

        <div className={`col-md-4 col-12 py-3`}>
          <div className={`${contactStyle.bubbles} d-flex flex-column justify-content-center`}>
            <div className='d-flex flex-column align-items-center mb-3'>
              <img
                src={process.env.PUBLIC_URL + '/media/general/Mail-Icon.svg'}
                alt='img'
                width='35px'
                className='py-1'
              />
              <span className='font500'>E-mail</span>
            </div>
            <span className='d-flex justify-content-center mb-3 font500'>{business.email}</span>
            <a href={emailLink} className={contactStyle.faqButton}>
              Escrever e-mail
            </a>
          </div>
        </div>

        <div className={`col-md-4 col-12 py-3`}>
          <div className={`${contactStyle.bubbles} d-flex flex-column justify-content-center`}>
            <div className='d-flex flex-column align-items-center mb-3'>
              <img
                src={process.env.PUBLIC_URL + '/media/general/UserCall-Icon.svg'}
                alt='img'
                width='35px'
                className='py-1'
              />
              <span className='font500'>Telefone para contato</span>
            </div>
            <span className='d-flex justify-content-center mb-3 font500'>{business.business_telephone_number}</span>
            <div className='text-center px-3'>De Segunda a Sexta, das 8h00 às 18h00</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AOContact
