import { Product } from 'models/Product';
import { toBRL } from 'utils/Functions';
import suggestedStyle from './SuggestedItem.module.css';
import Image from 'components/common/Image/Image';

type Props = {
    product: Product
}

function SuggestedItem({product}:Props) {
    return (
        <div className='d-flex align-items-center flex-row justify-content-between col'>
            <div className='d-flex align-items-center col-9'>
                {/* TODO: trocar pelo componente Image */}
                <Image
                alt={product.thumbnail?.title}
                src={product.thumbnail?.url}
                fallbackSrc='https://fakeimg.pl/80x80/cccccc/033858'
                className={suggestedStyle.suggestedImage} />

                <span className={suggestedStyle.suggestedDescription}>{product.description}</span>
            </div>
            {/* THIS WILL NEED TO CHANGE SO /UN. CAN BE OTHER VOLUMES */}
            <span className='font12rem font500 text-end col-3'>{toBRL(product.sell_price)}/un</span>
        </div>
    );
}

export default SuggestedItem
