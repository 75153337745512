import { useQuery } from '@tanstack/react-query'
import PageTitle from 'components/common/PageTitle/PageTitle'
import { Bill } from 'models/Bill'
import moment from 'moment'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import checkPaymentMethods from 'utils/CheckPaymentMethod'
import { DATE_USUAL, DATE_USUAL_FULL } from 'utils/Consts'
import { toAbsoluteUrl, toBRL } from 'utils/Functions'
import { paymentFullDetails } from '../core/_request'
import paymentDetails from './PaymentDetails.module.css'
import ClipLoader from 'react-spinners/ClipLoader'

function PaymentDetailsPage() {
  const { id : paymentId } = useParams()
  const [payment, setPayment] = useState<Bill>()

  const { isLoading: isOrderDetailLoading, isFetching: isOrderDetailFetching } = useQuery({
    queryFn: async () => {
        const response = await paymentFullDetails(paymentId!!)
        setPayment(response.data)
        return response
    },
    queryKey: ['payment', paymentId],
    enabled: !!paymentId,
  })

  return (
    <>
      <PageTitle title={`DETALHES DO PAGAMENTO ${payment && '#' + payment.id}`} />
      {payment &&
        <>
          <div className='container my-2'>
            <div className='bottom-line d-flex flex-row justify-content-between my-2 px-2'>
              <span>Data de realização da compra</span>
              <span className='font500'>
              {moment(payment.creation_date).format(DATE_USUAL_FULL)}
              </span>
            </div>

            <div className='bottom-line d-flex flex-row justify-content-between my-2 px-2'>
              <span>Data de aprovação do pagamento</span>
              <span className='font500'>
                {moment(payment.modification_date).format(DATE_USUAL_FULL)}
              </span>
            </div>

            <div className='bottom-line d-flex flex-row justify-content-between my-2 px-2'>
              <span>Método de pagamento</span>
              <span className='font500'>{checkPaymentMethods(payment)}</span>
            </div>

            <div className='bottom-line d-flex flex-row justify-content-between my-2 px-2'>
              <span>Parcelamento</span>
              <span className='font500'>
                {(payment.installment_quantity && payment.installment_quantity > 1) &&
                  <>
                    {payment.installment_quantity}x{' '} 
                  </>
                }
                {(payment.amount && payment.installment_quantity) &&
                  toBRL(payment.amount/payment.installment_quantity)
                } 
              </span>
            </div>

            <div className={paymentDetails.installmentsTable}>
                <div className='font500 font11rem mb-2'>Parcelas</div>
                {payment.installments?.map((installment, idx) => (
                  <div key={idx} className='col d-flex justify-content-between py-2'>
                    <span className='col'>{toBRL(installment.total_net_value)}</span>
                    <span className='col'>{moment(installment.due_date).format(DATE_USUAL)}</span>
                    <span className={`${paymentDetails.bubbleStatus} ${'bubbleStatus' + installment.status?.value} col`}>
                      {installment.status?.description}
                    </span>
                  </div>
                ))}
            </div>

            <div
              className={paymentDetails.totalBackground}
            >
              <span>TOTAL</span>
              <span>
                {toBRL(payment.amount)}
              </span>
            </div>
          </div>
        </>
      }
      {isOrderDetailFetching &&
        <div className='container main-color d-flex flex-column align-items-center p-4 my-3'>
          <ClipLoader
              color="#033858"
              size={20}
          />
          <span className='font500 mt-3'>
              Carregando...
          </span>
        </div>
      }
      {!payment && !isOrderDetailFetching &&
        <div className='d-flex flex-column justify-content-center my-3'>
          <img src={toAbsoluteUrl('/media/Illustrations/Doubt-Illustration-Ballon.svg')} alt='' height={'140px'}/>
          <span className='text-center mt-4'>Nenhum pagamento de número: <strong>{paymentId}</strong> foi encontrado</span>
        </div>
      }
    </>
  )
}

export default PaymentDetailsPage
