import ProductLayoutSelector from "components/mobile/ProductLayoutSelector/ProductLayoutSelector";
import HorizontalCardSkeleton from "components/mobile/Skeletons/HorizontalCardSkeleton";
import ProductCardHorizontal from "layouts/mobile/ProductCard/ProductCardHorizontal";
import { ProductLayoutProps } from "models/Product";
import { FC } from "react";
import productLayoutDefaultStyles from "./ProductLayoutDefault.module.css";

const ProductLayoutHorizontal: FC<ProductLayoutProps> = ({ loading = true, products, title }) => {
  // const isReady = useMemo(() => products && products.length !== 0 && !loading, [products, loading])

  const HorizontalLayoutSkeleton: FC = () => {
    return (
      <>
        {[...Array(4)].map((_, idx) => (
          <HorizontalCardSkeleton key={idx} />
        ))}
      </>
    );
  };

  return (
    <div className={`d-flex justify-content-center row`}>
      <div className={productLayoutDefaultStyles.cardText}>
        <h3 className="my-4">{title}</h3>
        <ProductLayoutSelector />
      </div>
      <div className="d-flex flex-wrap">
        {loading && <HorizontalLayoutSkeleton />}
        {!loading && products?.map((product, idx) => <ProductCardHorizontal key={idx} product={product} />)}
      </div>
    </div>
  );
};

export default ProductLayoutHorizontal;
