import { EnumType } from "modules/api/core/_models"
import { StoreCart } from "./StoreCart"

export type CheckoutRequest = {
    order_invoice_type: EnumType
    form_of_payment_id?: string
    payment_method?: EnumType
    address_id?: string
    donation_value: number
    store_cart?: StoreCart
    observation?: string
}

export const initialCheckoutRequest: CheckoutRequest = {
    order_invoice_type: { description: 'Não (Cupom Fiscal)', value: '2' },
    donation_value: 0,
    observation: '',
}
