import { useNavigate } from 'react-router-dom'
import { LOGIN_PAGE, STORE_FRONT_CONTACT_PAGE, STORE_SIGNUP, toUrl } from 'utils/Links'
import gatewayStyle from './GatewayPage.module.css'

function GatewayPage() {
  const navigate = useNavigate()

  return (
    <>
      <div className={`${gatewayStyle.page} container`}>
        <div>

        </div>
        <div>
          <img src={'/media/logos/Vupty-Logo-160px.svg'} alt=''/>
          <p className='font600 font12rem main-color mt-3 text-center'>Seja bem-vindo(a) à Vupty!</p>
          <div>
            <div onClick={() => navigate(toUrl(LOGIN_PAGE))} className={gatewayStyle.button}>
              Acessar sua conta
            </div>
            <div onClick={() => navigate(toUrl(STORE_SIGNUP))} className={gatewayStyle.button}>
              Criar conta
            </div>
          </div>
        </div>
        <div className='d-flex flex-column text-center mb-3'>
          <span className='font500'>
            Não está conseguindo acessar ou cadastrar?
          </span>
          <span onClick={() => navigate(toUrl(STORE_FRONT_CONTACT_PAGE))} className={gatewayStyle.supportLink}>
            Entre em contato com o nosso Suporte
          </span>
        </div>
      </div>
    </>
  )
}

export default GatewayPage
