import { FilterOption } from 'models/FilterOption';
import { SearchOperationType } from 'models/SearchOperationType';
import moment from 'moment';
import { FC, useRef, useState } from 'react';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { toAbsoluteUrl } from 'utils/Functions';
import orderDateFilterStyle from './OrderDateFilter.module.css';
import { DATE_FORMAT } from 'utils/Consts';

type Props = {
    onClose: (filterOption : FilterOption) => void,
    open: boolean,
}

const filterOptions : FilterOption[] = [
    {
        label: 'Todas',
        value: null
    },
    {
        label: 'Hoje',
        value: {
            key: 'date',
            operation: SearchOperationType.EQUAL,
            value: moment().format(DATE_FORMAT)
        }
    },
    {
        label: 'Últimos 7 dias',
        value: {
            key: 'date',
            operation: SearchOperationType.GREATER_THAN_EQUAL,
            value: moment().subtract(7, 'days').format(DATE_FORMAT)
        }
    },
    {
        label: 'Esse mês',
        value: {
            key: 'date',
            operation: SearchOperationType.GREATER_THAN_EQUAL,
            value: moment().subtract(30, 'days').format(DATE_FORMAT)
        }
    },
    {
        label: 'Últimos 3 meses',
        value: {
            key: 'date',
            operation: SearchOperationType.GREATER_THAN_EQUAL,
            value: moment().subtract(90, 'days').format(DATE_FORMAT)
        }
    },
    {
        label: 'Últimos 6 meses',
        value: {
            key: 'date',
            operation: SearchOperationType.GREATER_THAN_EQUAL,
            value: moment().subtract(180, 'days').format(DATE_FORMAT)
        }
    },
    {
        label: 'Esse ano',
        value: {
            key: 'date',
            operation: SearchOperationType.GREATER_THAN_EQUAL,
            value: moment().subtract(365, 'days').format(DATE_FORMAT)
        }
    }
]

const OrderDateFilter: FC<Props> = ({open, onClose}) => {

    const ref = useRef<SheetRef>();
    const [option, setOption] = useState<FilterOption>(filterOptions[0])

    const handleClose = () => {
        onClose(option)
    }

    const handleOptionChange = (e: any) => {
        const value = e.target.value
        const selected = filterOptions.find(op => op.label === value)
        if (selected) {
            setOption(selected)
            onClose(selected)
        }
    }

    return (<Sheet
        ref={ref}
        isOpen={open}
        onClose={handleClose}
        initialSnap={0}
        snapPoints={[-50, 100, 50]}
        detent="content-height">
        <Sheet.Container>
            <Sheet.Scroller>
                <Sheet.Content className={orderDateFilterStyle.bottomSheet}>
                    <div className="px-5 pt-3">
                        <div className='d-flex justify-content-between'>
                            <span className="main-color font12rem font500">Filtrar por</span>
                            <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Down-Gray.svg')} alt='' className='main-filter' />
                        </div>
                        <form className={orderDateFilterStyle.bottomSheetContent}>
                            {filterOptions.map((op, idx) => (
                                <div key={idx} className={orderDateFilterStyle.dateOption}>
                                    <input
                                        type="radio"
                                        value={op.label}
                                        id={`date_filter_${idx}`}
                                        checked={option.label === op.label}
                                        onChange={handleOptionChange}
                                        className="me-1"
                                    />
                                    <label htmlFor={`date_filter_${idx}`} >{op.label}</label>
                                </div>
                            ))}
                        </form>
                    </div>
                </Sheet.Content>
            </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop onTap={handleClose} />
    </Sheet>);
}

export default OrderDateFilter;
