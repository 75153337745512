export interface ApiResponse<T> {
  success: boolean
  status: number
  message: string
  data: T
  length: number
  error?: T
  config?: any
}

export interface Sort {
  sorted: boolean
  unsorted: boolean
  empty: boolean
}

export interface Pageable {
  sort: Sort
  offset: number
  page_size: number 
  page_number: number
  paged: boolean
  unpaged: boolean
}

export interface PageableResponse<T> {
  content: T[]
  pageable: Pageable
  last: boolean
  total_pages: number
  total_elements: number
  size: number
  number: number
  sort: Sort
  first: boolean
  number_of_elements: number
  empty: boolean
}

export interface FieldError {
    field: string
    message: string
}

export class ApiResponseError {
  status: number
  message: string
  error?: Array<FieldError>

  constructor(response: any) {
    this.status = response.status
    this.message = response.message
    this.error = response.error
  }

  getErrorMessage() {
    if (this.message) {
        return this.message
    }

    if (this.error && this.error.length > 0) {
        let message = ''
        for (let index = 0; index < this.error.length; index++) {
            const element = this.error[index]
            message = message.concat(element.message + "["+element.field+"]")
        }
        return message
    }

    return
}
}
export type State = {
  id?: string
  name: string
  uf: string
  icon?: string
}


export type EnumType = {
  value: string
  description: string
}

export type Marker = {
  id?: string
  name: string
  color?: string
  type?: string
  active?: boolean
  registration_date?: string
  modification_date?: string
}

export const initialMarker: Marker = {
  name: '',
  color: '',
  type: '',
  active: true,
}
