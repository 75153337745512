import { useMemo, useState } from "react";
import { Link, Outlet } from 'react-router-dom';
import fakeData from 'resources/data/fake-data';
import { toAbsoluteUrl } from 'utils/Functions';
import {
  ACCOUNT_CONTACT,
  ACCOUNT_DONATIONS_PAGE,
  ACCOUNT_FAQ_PAGE,
  ACCOUNT_MISSIONS_PAGE,
  ACCOUNT_ORDERS_PAGE,
  ACCOUNT_PAYMENTS_PAGE,
  ACCOUNT_PRIVACY_POLICY_PAGE,
  ACCOUNT_PROFILE_MAIL_PAGE,
  ACCOUNT_PROFILE_PAGE,
  ACCOUNT_PROFILE_PASSWORD_PAGE,
  ACCOUNT_SUGGESTED_WHISHLIST_PAGE,
  ACCOUNT_TERMS_PAGE,
  ACCOUNT_WHISHLIST_PAGE,
  LOGOUT_PAGE,
  toUrl
} from 'utils/Links';
import MenuOption from './AccountOutlets/components/MenuOption';
import accountPageStyle from './AccountPage.module.css';
function AccountPageWrapper() {
  const [openMenuOption, setOpenMenuOption] = useState("");
  const { buyLists } = fakeData;

  // FOR MAPPING VARIABLE LIST
  const buyListMap = useMemo(
    () =>
      buyLists.map((list, idx) => ({
        label: list.name,
        link: toUrl(ACCOUNT_WHISHLIST_PAGE, idx),
      })),
    [buyLists]
  );
 
  return (
    <div className="container py-5">
      <div className="d-flex justify-content-start">
        <div className={`${accountPageStyle.accordionMenu} col-2`}>
          <div className="d-flex align-items-start">
            <img src={toAbsoluteUrl("/media/general/Profile-Shadow.svg")} alt="profile" className="me-1" />
            <h4>Minha área</h4>
          </div>

          <div>
            <MenuOption
              label="MEUS DADOS"
              options={[
                {
                  label: "Dados da empresa",
                  link: toUrl(ACCOUNT_PROFILE_PAGE),
                },
                {
                  label: "Alterar e-mail",
                  link: toUrl(ACCOUNT_PROFILE_MAIL_PAGE),
                },
                {
                  label: "Alterar senha",
                  link: toUrl(ACCOUNT_PROFILE_PASSWORD_PAGE),
                },
                // {
                //     label: 'Meus cartões',
                //     link: toUrl(ACCOUNT_CARDS_PAGE),
                // },
                // {
                //     label: 'Autenticação de dois fatores',
                //     link: toUrl(ACCOUNT_2_FACTOR, 1),
                // },
              ]}
              open={openMenuOption === "meus_dados"}
            />

            <MenuOption
              label="SERVIÇOS"
              options={[
                {
                  label: "Pedidos",
                  link: toUrl(ACCOUNT_ORDERS_PAGE),
                },
                {
                  label: "Minhas listas",
                  link: toUrl(ACCOUNT_WHISHLIST_PAGE),
                },
                {
                  label: "Listas Sugeridas",
                  link: toUrl(ACCOUNT_SUGGESTED_WHISHLIST_PAGE),
                },
                // {
                //     label: 'Trocas e devoluções',
                //     link: toUrl(ACCOUNT_RETURNS_PAGE),
                // },
                {
                  label: "Missões e descontos",
                  link: toUrl(ACCOUNT_MISSIONS_PAGE),
                },
              ]}
            />

            <MenuOption
              label="HISTÓRICO"
              options={[
                {
                  label: "Pagamentos",
                  link: toUrl(ACCOUNT_PAYMENTS_PAGE),
                },
                {
                  label: "Doações",
                  link: toUrl(ACCOUNT_DONATIONS_PAGE),
                },
              ]}
              open={openMenuOption === "pagamentos"}
            />

            <MenuOption
              label="ATENDIMENTO"
              options={[
                {
                  label: "Fale conosco",
                  link: toUrl(ACCOUNT_CONTACT),
                },
                {
                  label: "Perguntas frequentes",
                  link: toUrl(ACCOUNT_FAQ_PAGE),
                },
                {
                  label: "Política de privacidade",
                  link: toUrl(ACCOUNT_PRIVACY_POLICY_PAGE),
                },
                {
                  label: "Termos de uso",
                  link: toUrl(ACCOUNT_TERMS_PAGE),
                },
              ]}
              open={openMenuOption === "pagamentos"}
            />
          </div>

          <Link to={toUrl(LOGOUT_PAGE)} className="d-flex">
            <img
              src={toAbsoluteUrl("/media/general/Exit-Icon.svg")}
              alt="profile"
              className={`${accountPageStyle.exitImage} me-1`}
            />
            <span className={accountPageStyle.exit}>Sair da conta</span>
          </Link>
        </div>

        <div className="col-10 ps-5 flex-grow-1">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AccountPageWrapper;
