import ProductDetailModal from "components/desktop/ProductDetailModal/ProductDetailModal";
import WishlistModalFactory from "layouts/common/WishlistModalFactory/WishlistModalFactory";
import { Product } from "models/Product";
import PostPurchaseTraking from "pages/desktop/Account/AccountOutlets/modals/ModalTracking/PostPurchaseTracking";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ACCOUNT_ORDERS_TRACKING_MODAL, STORE_FRONT_PRODUCT_DETAIL_MODAL, toUrl } from "utils/Links";

type ModalContextModel = {
    showProductModal: (itemId: string) => void,
    hideProductModal: () => void,
    showWishlistModal: (product: Product, quantity: number, onChange: (isWishlisted: boolean) => void) => void,
    hideWishlistModal: () => void,
    showTrackingModal: (orderId: string) => void,
    hideTrackingModal: () => void,
}

const initialModalContext: ModalContextModel = {
    showProductModal: () => { },
    hideProductModal: () => { },
    showWishlistModal: () => { },
    hideWishlistModal: () => { },
    showTrackingModal: () => { },
    hideTrackingModal: () => { },
}

const ModalContext = createContext(initialModalContext);

function StoreModalProvider({ children }: PropsWithChildren) {
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);
    const [isListModalOpen, setIsListModalOpen] = useState(false);
    const [itemId, setItemId] = useState<string>('');
    const [product, setProduct] = useState<Product>({} as Product);
    const [quantity, setQuantity] = useState<number>(0);
    const [orderId, setOrderId] = useState<string>('');
    const [wishlistCallback, setWishlistCallback] = useState<(isWishlisted: boolean) => void>();
    const location = useLocation();
    const navigate = useNavigate();

    function showProductModal(itemId: string) {
        setIsProductModalOpen(true);
        setItemId(itemId);
        window.history.replaceState(
            null,
            "",
            toUrl(STORE_FRONT_PRODUCT_DETAIL_MODAL, itemId)
        );
    }

    function hideProductModal() {
        setIsProductModalOpen(false);
        window.history.replaceState(null, "", location.pathname + location.search);
    }

    function showWishlistModal(product: Product, quantity: number, callback: (isWishlisted: boolean) => void) {
        setIsListModalOpen(true);
        setProduct(product);
        setQuantity(quantity);
        setWishlistCallback(() => (isWishlisted : boolean) => callback(isWishlisted)); // Store the callback
    }

    // You can use the stored callback function like this
    const handleWishlistAction = (isWishlisted: boolean) => {
        if (wishlistCallback) {
            wishlistCallback(isWishlisted);
        }
    }

    function hideWishlistModal() {
        setIsListModalOpen(false);
    }

    function showTrackingModal(orderId: string) {
        setIsTrackingModalOpen(true)
        setOrderId(orderId)
        window.history.replaceState(
            null,
            "",
            toUrl(ACCOUNT_ORDERS_TRACKING_MODAL, orderId)
        );
    }

    function hideTrackingModal() {
        setIsTrackingModalOpen(false)
        window.history.replaceState(null, "", location.pathname + location.search);
    }

    return (
        <ModalContext.Provider value={{ showProductModal, hideProductModal, showWishlistModal, hideWishlistModal, showTrackingModal, hideTrackingModal }}>
            {children}
            {isProductModalOpen && (
                <ProductDetailModal
                    show={true}
                    handleClose={hideProductModal}
                    id={itemId}
                />
            )}
            {isListModalOpen && (
                <WishlistModalFactory product={product} quantity={quantity} handleWishlistAction={handleWishlistAction} onDismiss={hideWishlistModal}/>
            )}

            {isTrackingModalOpen && (
                <PostPurchaseTraking
                    show={true}
                    onHide={hideTrackingModal}
                />
            )}
        </ModalContext.Provider>
    );
}

function useModalContext() {
    return useContext(ModalContext);
}

export { StoreModalProvider, useModalContext };
