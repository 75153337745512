import { useQuery } from "@tanstack/react-query";
import { Installment } from "models/Installment";
import moment from "moment";
import { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { CgClose } from "react-icons/cg";
import { useParams } from "react-router-dom";
import { toBRL } from "utils/Functions";
import { info } from "../../core/_requests";
import paymentStyle from "./AccountBillDetailModal.module.css";

type Props = {
  show: boolean;
  onHide: () => void;
};

function AccountBillDetailModal({ show, onHide }: Props) {
  const { id } = useParams();

  const { data: bill, isLoading } = useQuery({
    queryFn: async () => {
      const response = await info(id!!);
      return response.data;
    },
    queryKey: ["bill-detail", id],
    enabled: id !== undefined,
  });

  const status = useMemo(() => {
      //TODO MELHORAR LOGICA
      const amountPaid = bill?.installments?.reduce((acc: number, installment: Installment) => {
        return acc + (installment.amount_paid || 0);
      }, 0)
      const amountTotal = bill?.amount

      if (amountPaid === amountTotal) {
        return "Pago"
      } else if (amountPaid === 0) {
        return "Pendente"
      } else {
        return "Parcial"
      }
  }, [bill])

  return (
    <Modal
      size={`lg`}
      show={show}
      className={`${paymentStyle.authenticationConfirmationSheet}`}
      onHide={onHide}
      centered
    >
      <Modal.Body className={`${paymentStyle.modalBodyDisabling}  px-5 py-4`}>
        {!bill && isLoading && <div>Carregando...</div>}
        {bill && (
          <>
            <div className="col-12 d-flex justify-content-between font15rem font600 main-color mb-3">
              <span>Detalhes do pagamento do pedido #{bill.document_number}</span>
              <div className={`${paymentStyle.exitButton}`} onClick={onHide}>
                <CgClose />
              </div>
            </div>

            <div className={`${paymentStyle.modalContentDisabling} row`}>
              <div className="col-12 d-flex justify-content-between my-2">
                <span className="main-color font11rem font500">SITUAÇÃO</span>
                <span className={`badgeStatus orderbadgeStatus`}>{status}</span>
              </div>
              <div className="col-12 d-flex justify-content-between my-2">
                <span>Competência</span>
                <span className="font600">{moment(bill.competence_date).format("DD/MM/YYYY")}</span>
              </div>
              <div className="col-12 d-flex justify-content-between font500 font11rem main-color background-color-emphasis my-2 py-1">
                <span>TOTAL</span>
                <span>{toBRL(bill.amount)}</span>
              </div>
              {bill.installments && (
                <div className="col-12 d-flex justify-content-between my-2">
                  <span>Parcelas</span>
                  {bill.installments.map((installment: Installment, idx: number) => (
                    <div key={idx} className="d-flex flex-column">
                      <span>{moment(installment.due_date).format("DD/MM/YYYY")}</span>
                      <span>{toBRL(installment.net_value)}</span>
                      <span>{installment.status?.description || ""}</span>
                    </div>
                  ))}
                </div>
              )}

              {/* <div className="d-flex justify-content-center">
                <button className="button btn-main my-4" onClick={onHide}>
                  Lista de pedidos
                </button>
              </div> */}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default AccountBillDetailModal;
