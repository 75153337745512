import PageTitle from "components/common/PageTitle/PageTitle"
import { toAbsoluteUrl } from "utils/Functions"
import successStyle from './StepEnablePage.module.css'
import { useNavigate } from "react-router-dom"


function StepErrorConclusion() {
    const navigate = useNavigate()

  return (
    <>
        <PageTitle title={`AUTENTICAÇÃO DE DOIS FATORES`} />
        <div className="container py-3">
            <div className="d-flex flex-column align-items-center">
                <img src={toAbsoluteUrl('/media/Illustrations/Two-Factor-Error.svg')} alt=""/>
                <span className="danger-color font12rem text-center my-3">Ops! Algo deu errado...</span>
                <span className="text-center">
                    Para desabilitar a autenticação de dois fatores, clique no botão abaixo e digite o código disponívell no aplicativo Google Authenticator para confirmar:
                </span>
                <ul>
                    <span className="font500">Verifique o dispositivo:</span> 
                    Certifique-se de que está usando o dispositivo correto que está registrado para receber códigos de autenticação;
                </ul>
                <ul>
                    <span className="font500">Horário do código:</span> 
                    Verifique se o código gerado não expirou. Os códigos de autenticação costumam ter um tempo limitado de validade;
                </ul>
                <ul>
                    <span className="font500">Conexão à Internet:</span> 
                    Certifique-se de que seu dispositivo está conectado à internet de forma estável;
                </ul>
                <ul>
                    <span className="font500">Códigos múltiplos:</span> 
                    Se você solicitou vários códigos, use apenas o código mais recente gerado.
                </ul>
            </div>
            <div className={successStyle.disableButton} onClick={() => navigate(-1)}>
                Tentar novamente <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Return-Arrow.svg')} alt=''/>
            </div>
        </div>
    </>
  )
}

export default StepErrorConclusion