import { LOCAL_STORAGE_AUTH_KEY } from 'utils/Consts'
import { AuthModel } from '../modules/auth/core/_models'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }
  try {
    const lsValue: string | null = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)
    if (!lsValue) {
      localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(auth))
    } else {
      let oldAuth: AuthModel = JSON.parse(lsValue) as AuthModel
      const updatedLsValue = JSON.stringify({...oldAuth, ...auth})
      localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, updatedLsValue)
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, "null")
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export { getAuth, removeAuth, setAuth }
