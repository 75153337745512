import WishlistModalDesktop from 'components/desktop/WishlistModalDesktop/WishlistModalDesktop';
import WishlistModal from 'components/mobile/WishlistModal/WishlistModal';
import { Product } from 'models/Product';
import { isMobileScreen } from 'utils/Functions';

export type WishlistModalProps = {
    product: Product
    quantity: number
    handleWishlistAction: any
    onDismiss: () => void
}

function WishlistModalFactory ({product, quantity, handleWishlistAction, onDismiss}: WishlistModalProps) {

    const Modal = isMobileScreen() ? WishlistModal : WishlistModalDesktop

    return (
        <Modal
            onDismiss={onDismiss}
            open={true}
            product={product}
            quantity={quantity}
            onChange={handleWishlistAction}
        /> 
    )
}

export default WishlistModalFactory