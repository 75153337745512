import { ColumnDef } from "@tanstack/table-core";
import NewDatatable from "components/common/NewDatatable/NewDatatable";
import BasePagination from "components/common/NewDatatable/components/BasePagination";
import BaseTable from "components/common/NewDatatable/components/BaseTable";
import Breadcrumbs from "components/common/breadcrumbs/Breadcrumbs";
import { Bill } from "models/Bill";
import { BillEndpoints } from "modules/api/endpoints/BillEndpoints";
import moment from "moment";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toBRL } from "utils/Functions";
import { ACCOUNT_ORDERS_PAGE, ACCOUNT_PAYMENTS_PAGE, ACCOUNT_PAYMENT_DETAIL_MODAL, toUrl } from "utils/Links";
import paymentStyle from "./AccountBillPage.module.css";
import AccountBillTablePlaceholder from "./component/AccountBillTablePlaceholder";
import DateSelectionTableFilter from "./component/DateSelectionTableFilter";

const breadcrumbs = [
  {
    title: "Perfil",
    path: toUrl(ACCOUNT_ORDERS_PAGE),
  },
  {
    title: "Pagamentos",
    path: toUrl(ACCOUNT_PAYMENTS_PAGE),
  },
];

function AccountBillPage() {
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<Bill, any>[]>(
    () => [
      {
        header: "Numero",
        accessorKey: "document_number",
        id: "documentNumber",
        cell: (cell) => {
          if (cell.getValue()) return cell.getValue();
          return "Avulso";
        },
      },
      {
        header: "Pedido",
        accessorKey: "document_number",
        id: "documentNumber",
        cell: (cell) => "Avulso",
      },
      {
        header: "Data",
        accessorKey: "competence_date",
        id: "competenceDate",
        cell: (cell) => moment(cell.getValue()).format("DD/MM/YYYY"),
      },
      {
        header: "Total",
        accessorKey: "amount",
        id: "amount",
        cell: (cell) => toBRL(cell.getValue()),
      },
      {
        header: "Situação",
        accessorKey: "amount",
        id: "amount",
        cell: (cell) => <span className={`badgeStatus orderbadgeStatus`}>Em andamento</span>,
      },
      {
        header: "Detalhes",
        accessorKey: "id",
        id: "id",
        cell: (cell) => (
          <span
            style={{ cursor: "pointer" }}
            className="text-underline"
            onClick={() => navigate(toUrl(ACCOUNT_PAYMENT_DETAIL_MODAL, cell.getValue()))}
          >
            ver detalhes
          </span>
        ),
      },
    ],
    []
  );

  const initialFilter = [
    {
      key: "competence_date",
      value: moment().subtract(3, "days").format("YYYY-MM-DD"),
      operation: "GREATER_THAN_EQUAL",
    },
  ];
  
  function handleDateSelectorChange(value: string) {
    console.log(value);
  }

  return (
    <div className="container">
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className="font600 font15rem">HISTÓRICO DE PAGAMENTOS</span>
      </div>

      <div>
        <DateSelectionTableFilter />
      </div>

      <div className={paymentStyle.order}>
        <NewDatatable
          columns={columns}
          endpoint={BillEndpoints.list}
          filter={initialFilter}
          placeHolder={<AccountBillTablePlaceholder />}
        >
          <div className="row">
            <BaseTable />
          </div>
          <div className="row">
            <BasePagination />
          </div>
        </NewDatatable>
      </div>
    </div>
  );
}

export default AccountBillPage;
