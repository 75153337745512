import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { BsPrinter } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { toBRL } from "utils/Functions";
import { RECEIPT_PRINT, toUrl } from "utils/Links";
import { info } from "../../core/_requests";
import Loader from "components/desktop/Loader/Loader";

type Props = {
  show: boolean;
  onHide: () => void;
  id?: string;
};

function OrderSummaryModal({ show, onHide, id }: Props) {
  const navigate = useNavigate();
  const params = useParams();
  const itemId = useMemo(() => id || params.id, [id, params.id]);

  const { data: order, error } = useQuery({
    queryFn: async () => {
      const { data } = await info(itemId!!);
      return data;
    },
    queryKey: ["order", id],
    enabled: itemId !== undefined,
  });

  // useEffect(() => {
  //   if (error) {
  //     onHide();
  //   }
  // }, [error, onHide]);

  return (
    <Modal size={`lg`} show={show} onHide={onHide} centered>
      <Modal.Body className="p-5">
        {!order && <Loader />}
        {order && (
          <>
            <div className="d-flex justify-content-between align-items-center font15rem font500 main-color mb-3">
              <span>Resumo do pedido #{order.number}</span>
              <CgClose onClick={onHide} />
            </div>
            <div>
              <div className="d-flex justify-content-between bottom-line mb-4">
                <span className="font12rem font500">Situação</span>
                {order.status && (
                  <span className={`badgeStatus${order.status.value} orderbadgeStatus`}>
                    {order.status.description}
                  </span>
                )}
              </div>

              <div className="mb-4">
                <div className="bottom-line">
                  <span className="font12rem font500">Quantidade</span>
                </div>
                <div>
                  <div className="d-flex justify-content-between">
                    <span>Items</span>
                    <span className="font12rem font500">{order.items_quantity}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span>Produtos</span>
                    <span className="font12rem font500">{order.items_count}</span>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between bottom-line font12rem font500 mb-2 mb-4">
                <span>Subtotal</span>
                <span>{toBRL(order.total_price)}</span>
              </div>

              {order.total_discount > 0 && (
                <div className="mb-4">
                  <div className="d-flex justify-content-between bottom-line font12rem font500 mb-2">
                    <span>Desconto</span>
                    <span>{toBRL(order.total_discount)}</span>
                  </div>
                  <span>Código de desconto {order.total_discount}</span>
                </div>
              )}

              <div className="mb-4">
                <div className="d-flex justify-content-between bottom-line font12rem font500 mb-2">
                  <span>Entrega</span>
                  <span>{toBRL(order.total_delivery_price ? order.total_delivery_price : 0)}</span>
                </div>

                <span>Realizando suas compras até às 11h00, você as recebe no mesmo dia.</span>
              </div>

              <div className="d-flex justify-content-between bottom-line font12rem font500 mb-4">
                <span>Pagamento</span>
                {/* <span>{order.form_of_payment.description}</span> */}
              </div>

              <div className="d-flex justify-content-between bottom-line font12rem font500 main-color btn-light mb-2 px-2 pt-1">
                <span>TOTAL DA COMPRA</span>
                <span>{toBRL(order.total_price)}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div className="btn-main button px-3 py-2" onClick={() => navigate(toUrl(RECEIPT_PRINT, order.id))}>
                Imprimir nota fiscal completa <BsPrinter />
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default OrderSummaryModal;
