import { useStoreCart } from 'layouts/common/core/StoreCartProvider'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { toBRL } from 'utils/Functions'
import { STORE_FRONT_CART_PAGE, toUrl } from 'utils/Links'
import cartBar from './CartBar.module.css'

function CartBar() {
    const { cart : {items, items_total, total_mission_discount, sync} } = useStoreCart()

    const cartTotalWithDiscounts = useMemo(() => {
        return items_total - total_mission_discount
    }, [items_total, total_mission_discount])

    if (!items || items.length <= 0) return null

    return (
        <div className={`${cartBar.footbar} col-12`}>
            {/* SHOPPING BAR */}
                <Link to={toUrl(STORE_FRONT_CART_PAGE)}>
                    <div className={`${cartBar.footbarBack} d-flex justify-content-center`}>
                        <div className={`${cartBar.footbarInfo} col-10 d-flex justify-content-between align-items-center my-1`}>
                            <div className={`d-flex justify-content-between col`}>
                                <p className='transform2px'> Seu Carrinho </p>
                            </div>

                            <img src={process.env.PUBLIC_URL + '/media/general/Icon-white.svg'} alt='carrinho' className='col' />

                            <div className='d-flex justify-content-end transform2px col'>
                                <span className={cartBar.priceBubble}>
                                    {!sync &&
                                        <div className='transform2px'>
                                            <span className='mt-2 me-3'>
                                                <ClipLoader color="#ffffff" size={14}/>
                                            </span>
                                        </div>
                                    }
                                        <span>
                                            {toBRL(cartTotalWithDiscounts)}
                                        </span>
                                </span>
                            </div>

                        </div>
                    </div>
                </Link>
        </div>
    )
}

export default CartBar
