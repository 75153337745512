import { useQuery } from "@tanstack/react-query";
import Image from "components/common/Image/Image";
import { Paginator } from "components/common/Paginator/Paginator";
import { Category } from "models/Category";
import { CSSProperties, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { CgClose } from "react-icons/cg";
import { IoIosClose } from "react-icons/io";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useDebounce } from "usehooks-ts";
import { toAbsoluteUrl } from "utils/Functions";
import { STORE_FRONT_SEARCH_CATEGORY_PAGE, toUrl } from "utils/Links";
import { listCategories } from "../SearchBar/core/_requests";
import CategoriesModalStyle from "./CategoriesModal.module.css";

type Props = {
  show: boolean;
  handleClose: () => void;
};

const override: CSSProperties = {
  borderWidth: "4px",
};

const modalsRoot = document.getElementById("root-modals") || document.body;

function CategoriesModal({ show, handleClose }: Props) {
  const [actualPage, setActualPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [categories, setCategories] = useState<Category[]>([]);
  const [totalPages, setTotalPages] = useState(-1);

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const {
    data: categoryData,
    isFetching: isCategoryFetching,
    isError: isCategoryError,
  } = useQuery({
    queryFn: async () => {
      const response = await listCategories(debouncedSearchTerm, actualPage);
      setCategories(response.data.content);
      setTotalPages(response.data.total_pages);
      return response;
    },
    queryKey: ["listCategories", debouncedSearchTerm, actualPage],
  });

  const clearInput = () => {
    setSearchTerm("");
  };

  return createPortal(
    <Modal
      size={`lg`}
      show={show}
      className={CategoriesModalStyle.authenticationConfirmationSheet}
      onHide={handleClose}
      centered
    >
      <Modal.Body className={`${CategoriesModalStyle.modalSize} p-5`}>
        <div>
          <div className="d-flex justify-content-between main-color font12rem font500 mb-4">
            <span>CATEGORIAS</span>
            <CgClose
              onClick={() => {
                handleClose();
              }}
            />
          </div>

          <div className={CategoriesModalStyle.searchbar}>
            <input
              type="text"
              placeholder="Pesquise aqui a categoria que deseja encontrar"
              value={searchTerm}
              onChange={(event) => handleSearch(event.target.value)}
              className={`${CategoriesModalStyle.searchInput}`}
            />

            <div className={CategoriesModalStyle.searchIcon}>
              {searchTerm.length === 0 ? (
                <img
                  src={process.env.PUBLIC_URL + "/media/general/Search-Desktop-Icon-Faded.svg"}
                  alt="img"
                  className={CategoriesModalStyle.magnifyingGlass}
                />
              ) : (
                <IoIosClose id="clearBtn" onClick={clearInput} />
              )}
            </div>
          </div>
        </div>

        {isCategoryFetching && (
          <div className={CategoriesModalStyle.loaderContainer}>
            <ClipLoader color="#033858" size={42} cssOverride={override} />
          </div>
        )}

        {!isCategoryFetching && categories.length === 0 && (
          <div className={CategoriesModalStyle.loaderContainer}>
            <img src={toAbsoluteUrl("/media/Illustrations/Doubt-Illustration.svg")} alt="" />
            {searchTerm.length > 3 && (
              <span className="mt-3 font500">
                Nenhum resultado para: <strong>{searchTerm}</strong>
              </span>
            )}
            <span>analise sua busca ou tente usar termos mais genéricos</span>
          </div>
        )}

        {categories.length > 0 && (
          <>
            <div className={`${CategoriesModalStyle.categoryGrid} row`}>
              {categories.map((category, idx) => (
                <div key={idx} className={`${CategoriesModalStyle.completeCardCategory}`}>
                  <Link
                    to={toUrl(STORE_FRONT_SEARCH_CATEGORY_PAGE, category.slug)}
                    className={CategoriesModalStyle.categoryCard}
                  >
                    <div className={`${CategoriesModalStyle.completeCategoryImage} border-0`}>
                      <Image
                        src={category.thumbnail?.url || "https://fakeimg.pl/56x56/cccccc/033858"}
                        alt={category.thumbnail?.title}
                        height={56}
                        width={56}
                        className={CategoriesModalStyle.categoryImage}
                        placeholderColor="#ffffff"
                        fallbackSrc="https://fakeimg.pl/56x56/cccccc/033858"
                      />
                    </div>
                    <div className="main-filter">{category.name}</div>
                  </Link>
                </div>
              ))}
            </div>
            <div className={CategoriesModalStyle.pageCounterContainer}>
              <Paginator totalPages={totalPages} onChange={setActualPage} page={actualPage} />
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>,
    modalsRoot
  );
}

export default CategoriesModal;
