
export const ProductEndpoints = {
    // listBestSellers: (page: number = 1) => get<PageableResponse<Product>>('/store/product/best-sellers', {
    //     params: {
    //         per_page: 15,
    //         page: page,
    //     }
    // }),
    // detail: (id : string) => get<Product>(`/store/product/${id}`),
    // listRelatedProducts: (id : string, size : number = 10) => get<PageableResponse<Product>>(`/store/product/related/${id}`, {
    //     params: {
    //         per_page: size,
    //     }
    // })
    listBestSellers: '/store/product/best-sellers',
    detail: (id : string) => `/store/product/${id}`,
    listRelatedProducts: (id : string, size : number = 10) => `/store/product/related/${id}`
}
