import React, {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {CgClose} from 'react-icons/cg'
import {useNavigate} from 'react-router-dom'
import disableStyle from './AOAuthentication.module.css'
import {Formik, Form, Field} from 'formik'
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'
import { ACCOUNT_2_FACTOR, ACCOUNT_ORDERS_PAGE, toUrl } from 'utils/Links'

function AODisable() {
  const navigate = useNavigate()
  const [showModal1, setShowModal1] = useState(false)
  const [showModal2, setShowModal2] = useState(false)

  const breadcrumbs = useMemo(() => {
    return(
      [          
        {
          title : 'Perfil',
          path: toUrl(ACCOUNT_ORDERS_PAGE),
        },
        {    
          title : 'Autenticação',
          path : toUrl(ACCOUNT_2_FACTOR, 5),
        }
      ]
    )
  }, [])

  const openDisablingModal1 = () => {
    setShowModal1(true)
  }

  const openDisablingModal2 = () => {
    setShowModal2(true)
  }

  const handleModalClose = () => {
    setShowModal1(false)
    setShowModal2(false)
  }

  const initialValues = {
    confirmationCode: '',
  }

  const onSubmit = (values:any) => {
    console.log('onSubmit', values)
  }

  return (
    <>
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className='font600 font15rem'>AUTENTICAÇÃO DE DOIS FATORES</span>
      </div>

      <div className='py-3'>
        <h4 className='mb-4'>Passo a passo para finalizar a autenticação de dois fatores</h4>
        <p>
          Para desabilitar a autenticação de dois fatores, clique no botão abaixo e digite o código
          disponível no aplicativo <strong>Google Authenticator</strong>.
        </p>
      </div>
      <button className='button btn-danger' onClick={openDisablingModal1}>
        {' '}
        Desabilitar{' '}
      </button>

      <Modal
        show={showModal1}
        className={disableStyle.authenticationConfirmationSheet}
        onHide={handleModalClose}
        centered
      >
        <Modal.Body className={disableStyle.modalBodyDisabling}>
          <div
            className={`${disableStyle.exitButton} d-flex justify-content-end`}
            onClick={handleModalClose}
          >
            <CgClose />
          </div>
          <div className={disableStyle.modalContentDisabling}>
            <p className='font20rem my-4'>Quase lá!</p>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {() => (
                <Form className='d-flex flex-column'>
                  <label className='font500'>Código de confirmação</label>
                  <Field
                    name='company_name'
                    placeholder='000 000'
                    className={disableStyle.dataLabel}
                  />
                </Form>
              )}
            </Formik>
            <button className='button btn-danger py-3 px-4 my-4' onClick={openDisablingModal2}>
              {' '}
              Desabilitar 2FA{' '}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModal2}
        className={disableStyle.authenticationConfirmationSheet}
        onHide={handleModalClose}
        centered
      >
        <Modal.Body className={disableStyle.modalBodyDisabling}>
          <div
            className={`${disableStyle.exitButton} d-flex justify-content-end`}
            onClick={handleModalClose}
          >
            <CgClose />
          </div>
          <div className={disableStyle.modalContentDisabling}>
            <img
              src={process.env.PUBLIC_URL + '/media/general/Ok-Icon.svg'}
              alt='ok'
              className={disableStyle.disableIcon}
            />
            <p className='font20rem my-4'>A autenticação de dois fatores foi desabilitada!</p>
            <button className='button btn-second btn-danger' onClick={handleModalClose}>
              {' '}
              OK{' '}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AODisable
