import PageTitle from "components/common/PageTitle/PageTitle";
import ProductSugestion from "layouts/mobile/ProductSugestion/ProductSugestion";
import { Product } from "models/Product";
import { EnumType } from "modules/api/core/_models";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { ITEM_TYPE_BOX, ITEM_TYPE_UNIT } from "utils/Consts";
import { toAbsoluteUrl, toBRL } from "utils/Functions";
import productStyle from "./ProductPage.module.css";

import clsx from "clsx";
import AddToFavorites from "components/common/AddToFavorites/AddToFavorites";
import ProductBadge from "components/common/ProductBadge/ProductBadge";
import BtnBoxMob from "components/mobile/Btn/BtnProduct/BtnBoxMob";
import BtnUnitMob from "components/mobile/Btn/BtnProduct/BtnUnitMob";
import ProductPageCounter from "components/mobile/ProductCounter/ProductPage/ProductPageCounter";
import ProductImageCarousel from "components/mobile/ProductImageCarousel/ProductImageCarousel";
import SearchBar from "components/mobile/SearchBar/SearchBar";
import StoreCartBar from "components/mobile/StoreCartBar/StoreCartBar";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import ClipLoader from "react-spinners/ClipLoader";
import { HOME_PAGE, STORE_FRONT_SEARCH_TAG_PAGE, toUrl } from "utils/Links";
import { detail, getRelatedProducts } from "./core/_requests";

function ProductPage() {
  const { productId } = useParams();
  const { deleteItemFromCart, addItemCart, removeItemCart, updateCartItem } = useStoreCart();
  const [itemType, setItemType] = useState<EnumType>();
  const [product, setProduct] = useState<Product>();
  const [relatedProducts, setRelatedProducts] = useState<Product[]>([]);
  const navigate = useNavigate();

  const { isLoading: isProductLoading, isFetching: isProductFetching } = useQuery({
    queryFn: async () => {
      const response = await detail(productId!!);
      setProduct(response.data);
      return response;
    },
    queryKey: ["productDetail", productId],
    enabled: !!productId,
  });

  const { isLoading: isRelatedProductsLoading } = useQuery({
    queryFn: async () => {
      const response = await getRelatedProducts(productId!!, 4);
      setRelatedProducts(response.data.content);
      return response;
    },
    queryKey: ["relatedProducts", productId],
    enabled: productId !== undefined,
  });

  const btnUnitEnabled = useMemo(() => {
    if (!product?.supply_type || !product.sell_price) return false;
    return product.supply_type.value === "3" || product.supply_type.value === "1" ? true : false;
  }, [product]);

  const btnBoxEnabled = useMemo(() => {
    if (!product?.supply_type || !product.package_sell_price) return false;
    return product.supply_type.value === "3" || product.supply_type.value === "2" ? true : false;
  }, [product]);

  const discountPercentage = useMemo(() => {
    if (!product || !product.original_price) return 0;
    return (product.sell_price * 100) / product.original_price;
  }, [product]);

  const activeProductProviders = useMemo(() => {
    if (!product) return [];
    return product.providers.filter((provider) => product.provision !== provider.id);
  }, [product]);

  const handleProductRemoveAll = (product: Product) => {
    deleteItemFromCart(product);
  };

  const handleProductAdd = (product: Product) => {
    if (itemType === ITEM_TYPE_UNIT) {
      addItemCart(product);
    } else {
      addItemCart(product, product.quantity_per_package);
    }
  };

  const handleProductRemove = (product: Product) => {
    removeItemCart(product);
  };

  const handleProductChange = (product: Product, quantity: number) => {
    updateCartItem(product, quantity);
  };

  const handleProductTypeChange = useCallback(
    (type: EnumType) => {
      if (type === ITEM_TYPE_UNIT && btnUnitEnabled) {
        setItemType(ITEM_TYPE_UNIT);
      }
      if (type === ITEM_TYPE_BOX && btnBoxEnabled) {
        setItemType(ITEM_TYPE_BOX);
      }
    },
    [btnUnitEnabled, btnBoxEnabled]
  );

  useEffect(() => {
    if (!product?.supply_type || (!product.sell_price && !product.package_sell_price)) return;
    setItemType(
      product.supply_type.value === "3" || product.supply_type.value === "1" ? ITEM_TYPE_UNIT : ITEM_TYPE_BOX
    );
  }, [product]);

  const isAddButtonDisabled = useMemo(() => {
    return !product || !product.supply_type || product.unavailable;
  }, [product]);

  return (
    <>
      <PageTitle title={`DETALHES DO PRODUTO`} />
      <div className="container">
        {isProductLoading && product && <div>Carregando...</div>}
        {/* {isError && <div className='text-center font500 font11rem danger-color my-3'>Erro ao carregar o produto</div>} */}
        {product && (
          <>
            <div className="d-flex justify-content-center">
              <div className="w-100 position-relative">
                <div
                  className={`${productStyle.topTags} ${
                    product.tags ? "justify-content-between" : "justify-content-end"
                  }`}
                >
                  <div className="d-flex flex-column">
                    {product.tags &&
                      product.tags
                        ?.slice(0, 2)
                        .map((tag, idx) => (
                          <ProductBadge
                            key={idx}
                            label={tag.name}
                            handleClick={() => console.log(toUrl(STORE_FRONT_SEARCH_TAG_PAGE, tag.slug))}
                          />
                        ))}
                  </div>
                  <div className={productStyle.favoriteIcon}>
                    <AddToFavorites product={product} />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column align-items-center">
              <ProductImageCarousel images={product.images} thumbnail={product.thumbnail} height={350} />
              <div className={productStyle.productTitle}>{product.description}</div>
              <div className={`${productStyle.selection}`}>Selecione a opção desejada:</div>
            </div>

            <div className="d-flex flex-column align-items-center my-3">
              <div className="d-flex flex-row mb-3 col-12">
                <div className="col-6" onClick={() => handleProductTypeChange(ITEM_TYPE_UNIT)}>
                  <BtnUnitMob product={product} selected={itemType === ITEM_TYPE_UNIT} enabled={btnUnitEnabled} />
                </div>
                <div className="col-6" onClick={() => handleProductTypeChange(ITEM_TYPE_BOX)}>
                  <BtnBoxMob product={product} selected={itemType === ITEM_TYPE_BOX} enabled={btnBoxEnabled} />
                </div>
              </div>
              <div className={`g-0 ${productStyle.itemCardAddition}`}>
                <ProductPageCounter
                  disabled={isAddButtonDisabled}
                  product={product}
                  onAdd={handleProductAdd}
                  onRemove={handleProductRemove}
                  onChange={handleProductChange}
                  onRemoveAll={handleProductRemoveAll}
                />
              </div>
            </div>

            {activeProductProviders.length > 0 && (
              <div className={`${productStyle.comparisonTable} p-2`}>
                <span className={productStyle.comparisonTableTitle}>Mais preços desse mesmo produto</span>
                <div className={productStyle.comparisonTableContent}>
                  {activeProductProviders.map((productProvider, idx) => (
                    <div
                      key={idx}
                      className={clsx("d-flex justify-content-between align-items-center ", idx !== activeProductProviders.length-1 && "bottom-line")}
                    >
                      <div className="d-flex justify-content-start align-items-center">
                        {/* <img
                          src={toAbsoluteUrl("/media/ChevronsAndArrows/Best-Price-Chevron.svg")}
                          alt=""
                          className="me-1"
                        /> */}
                        <span className={clsx("text-start", idx === 0 && "font600")}>{idx === 0 ? 'Vupty' : (productProvider.provider?.name || '--')}</span>
                      </div>
                      <span className={clsx(idx === 0 && "font600")}>{toBRL(productProvider.promo_price || productProvider.sell_price)}/{product.measurement_unit}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {relatedProducts.length > 0 && (
              <div className={productStyle.sugestedProducts}>
                <span className="mb-3 font600 font11rem main-color">PRODUTOS SUGERIDOS</span>
                <ProductSugestion
                  products={relatedProducts}
                  loading={isRelatedProductsLoading}
                  title="Melhores Ofertas"
                />
              </div>
            )}
          </>
        )}
        {isProductFetching && (
          <div className="d-flex justify-content-center align-items-center">
            <ClipLoader color="#033858" size={20} />
            <span className="font500 ms-2">Carregando...</span>
          </div>
        )}
        {!product && !isProductFetching && (
          <>
            <SearchBar placeholder="Do que você precisa?" />
            <div className="container">
              <div className="d-flex flex-column align-items-center">
                <img
                  src={toAbsoluteUrl("/media/Illustrations/Doubt-Illustration.svg")}
                  alt=""
                  className={productStyle.errorImage}
                />
                <div className="font12rem text-center">
                  <p>Não conseguimos encontrar o produto com código:</p>
                  <p className="font600 main-color"># {productId}</p>
                  <span>
                    {" "}
                    Pesquise novamente ou volte para
                    <p className="link" onClick={() => navigate(toUrl(HOME_PAGE))}>
                      a página inicial da Vupty
                    </p>
                  </span>
                </div>
              </div>
              <div>
                {relatedProducts.length > 0 && (
                  <div className={productStyle.sugestedProducts}>
                    <span className="mb-3 font600 font11rem main-color">PRODUTOS SUGERIDOS</span>
                    <ProductSugestion
                      products={relatedProducts}
                      loading={isRelatedProductsLoading}
                      title="Melhores Ofertas"
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <StoreCartBar />
    </>
  );
}

export default ProductPage;
