import React from 'react';
import { normalizeRoutes } from 'utils/Functions';
import { Navigate, useRoutes } from 'react-router-dom';
import NotFoundPageMobile from './NotFoundPageMobile';
import ServerErrorPageMobile from './ServerErrorPageMobile';

const ErrorWrapperMobile: React.FC = () => {
    const routes = normalizeRoutes([
        {
            path: '404',
            element: <NotFoundPageMobile />,
        },{
            path: '500',
            element: <ServerErrorPageMobile />,
        },{
            path: '*',
            element: <Navigate to='404' />,
        }
    ])

  return useRoutes(routes);
}

export default ErrorWrapperMobile;
