import { FC, useCallback, useMemo } from 'react'
import { toAbsoluteUrl } from 'utils/Functions'
import { STORE_FRONT_CART_PAGE, STORE_FRONT_CHECKOUT_PAGE, toUrl } from 'utils/Links'
import CheckoutStep from '../CheckoutStep/CheckoutStep'
import styles from './CheckoutSteps.module.css'
import { useStoreCart } from 'layouts/common/core/StoreCartProvider'

type Props = {
    currentStep: number
}

type Step = {
    label: string
    link?: string
    icon: string
    beforeFunction?: () => boolean
    afterFunction?: () => void
    replace?: boolean
}

const CheckoutSteps: FC<Props> = ({ currentStep }) => {

    const { cart : {items_total} } = useStoreCart()

    const checkCartItems = useCallback(() => {
        if (items_total === 0) {
            return false
        }
        return true
    }, [items_total])

    const steps: Step[] = useMemo(() => [
        {
            label: 'Detalhes do pedido',
            link: toUrl(STORE_FRONT_CART_PAGE),
            icon: toAbsoluteUrl('/media/CheckoutSteps/Checkout-Details.svg'),
            replace: true
        },
        {
            label: 'Pagamento e Entrega',
            link: toUrl(STORE_FRONT_CHECKOUT_PAGE),
            icon: toAbsoluteUrl('/media/CheckoutSteps/Checkout-Payment.svg'),
            beforeFunction: checkCartItems,
            replace: true
        },
        {
            label: 'Confirmação',
            icon: toAbsoluteUrl('/media/CheckoutSteps/Checkout-Finish.svg'),
            replace: true
        }
    ], [checkCartItems])



    return (
        <div className={`${styles.checkoutProgress} ${styles.sticky}`}>
            {steps.map((step, index) => (
                <CheckoutStep
                    key={index}
                    label={step.label}
                    active={currentStep === index + 1}
                    checked={currentStep > index}
                    link={step.link}
                    icon={step.icon}
                    beforeFunction={step.beforeFunction}
                    afterFunction={step.afterFunction}
                    replace={step.replace}
                />
            ))}
        </div>
    )
}

export default CheckoutSteps
