import clsx from "clsx";
import ImageSkeleton from "components/desktop/Skeletons/ImageSkeleton";
import * as React from "react";

type Props = {
  src?: string;
  fallbackSrc?: string;
  placeholderColor?: string;
  className?: string;
  alt?: string;
  onClick?: () => void;
  lazy?: any;
  height?: number | string;
  width?: number | string;
};

type State = {
  src?: string;
  hasError: boolean;
  loading: boolean;
  style: any;
};

export default class Image extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      src: props.src || props.fallbackSrc,
      hasError: false,
      loading: true,
      style: {
        backgroundColor: props.placeholderColor || "transparent",
      },
    };
  }

  componentDidMount() {
    // this.onLoaded();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.src === prevProps.src) return;
    this.setState((prev) => ({
      ...prev,
      loading: true,
      src: this.props.src || this.props.fallbackSrc,
      hasError: false,
      style: {
        backgroundColor: this.props.placeholderColor || "transparent",
      },
    }));
  }

  onLoaded = () => {
    this.setState((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  onError = () => {
    if (!this.state.hasError) {
      this.setState({
        src: this.props.fallbackSrc || "",
        hasError: true,
        loading: false,
      });
    }
  };

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <>
        {(this.state.loading || !this.state.src) && (
          <ImageSkeleton height={this.props.height} width={this.props.width} onClick={this.handleClick} />
        )}
        {this.state.src && (<img
            style={this.state.style}
            className={clsx(this.props.className, { "d-none": this.state.loading })}
            onLoad={this.onLoaded}
            onError={this.onError}
            src={this.state.src}
            alt={this.props.alt || ""}
            onClick={this.props.onClick}
            height={this.props.height}
            width={this.props.width}
          />
        )}
      </>
    );
  }
}
