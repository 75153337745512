import { Address } from "./Address"

export type Business = {
  id?: string
  name?: string
  fantasy_name?: string
  cnpj?: string
  address?: Address
  website?: string
  telephone_number?: string
  cellphone_number?: string
  business_telephone_number?: string
  billing_email?: string
  email?: string
  state_registration?: string
  municipal_registration?: string
  registration_date?: string
  modification_date?: string
  facebook?: string
  instagram?: string
  linkedin?: string
  twitter?: string
  youtube?: string
  pinterest?: string
  whatsapp?: string
  telegram?: string
  skype?: string
  discord?: string
  slack?: string
}

export const initialBusiness: Business = {
  name: 'Vupty Plataforma Digital Ltda',
  fantasy_name: 'Vupty',
  cnpj: '23.141.273/0001-51',
  address: {
    street: 'Rua Benjamin Constant',
    number: 170,
    complement: 'Empresarial Mundo Plaza, Sala 1908',
    neighborhood: 'Estação Velha',
    city: 'Campina Grande',
    state: {
      name: 'Paraíba',
      uf: 'PB'
    },
    cep: '58410-003',
  },
  website: 'vupty.com.br',
  telephone_number: '(83) 9-9999-9999',
  cellphone_number: '(83) 9-9999-9999',
  business_telephone_number: '(83) 9-9999-9999',
  billing_email: 'financeiro@vupty.com.br',
  email: 'contato@vupty.com.br',
  state_registration: '000000000',
  municipal_registration: '000000000000',
  facebook: 'https://www.facebook.com/Vupty-102571939219080/',
  instagram: 'https://www.instagram.com/vuptyy/',
  linkedin: 'https://www.linkedin.com/company/vupty/',
  twitter: 'https://twitter.com/vupty',
  youtube: 'https://www.youtube.com/channel/UCR2G9wLJmE2Jt3l7tN9j9Rw',
  pinterest: 'https://br.pinterest.com/vupty/',
  whatsapp: '+55 (83) 9-9999-9999',
  telegram: 'https://t.me/vupty',
  skype: 'live:vupty',
  discord: 'https://discord.gg/vupty',
  slack: 'https://vupty.slack.com',
}
