import { regionalClient } from "index"
import { NotificationEndpoints } from "modules/api/endpoints/NotificationEndpoints"

export const list = (page : number = 0) => {
    return regionalClient.get(NotificationEndpoints.list, {
        params : {
            page,
            per_page : 20
        }
    })
}
export const markAllRead = () => {
    return regionalClient.post(NotificationEndpoints.markAllRead)
}
