import { Bill } from "models/Bill";

const checkPaymentMethods = (bill: Bill) => {
    const paymentMethods = new Set();
    bill.installments?.forEach(installment => {
        const paymentMethodDescription = installment.payment_method?.description;
        paymentMethods.add(paymentMethodDescription);
    });
    if (paymentMethods.size > 1) {
        return 'Multiplos';
    } else {
        return paymentMethods.values().next().value;
    }
  }

export default checkPaymentMethods  