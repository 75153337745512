import { regionalClient } from "index"
import { Wishlist } from "models/Wishlist"
import { WishlistSuggestEndpoints } from "modules/api/endpoints/WishlistSuggestEndpoints"

export const list = () => {
    return regionalClient.get<Wishlist[]>(WishlistSuggestEndpoints.list)
}
export const detail = (id: string) => {
    return regionalClient.get<Wishlist>(WishlistSuggestEndpoints.info(id))
}
