import { useEffect, useRef, useState } from 'react';
import { toAbsoluteUrl } from 'utils/Functions';
import dropdownStyle from './DropdownSelectStyle.module.css';

type Option = { label: string, value: any }

type Props = {
    onChange?: (id: string) => void
    placeholder?: string;
    options: Option[];
}

function DropdownSelect({ options, placeholder = 'Selecione', onChange }: Props) {
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState<Option>()

    useEffect(() => {
        if (options.length > 0) {
            setSelected(options[0])
        }else{
            setSelected(undefined)
        }
    }, [options])

    function handleClick(option: Option) {
        setSelected(option);
        setOpen(!selected)
        if (onChange)
            onChange(option.value)
    }

    const useClickOutside = (handler: any) => {
        const domNode = useRef<HTMLDivElement>(null)

        useEffect(() => {
            let maybeHandler = (event: any) => {
                if (domNode.current && !domNode.current.contains(event.target)) {
                    handler()
                }
            }

            document.addEventListener('mousedown', maybeHandler)

            return () => {
                document.removeEventListener('mousedown', maybeHandler)
            }
        })

        return domNode
    }
    const domNode = useClickOutside(() => {
        setOpen(false)
    })

    return (
        <div ref={domNode} className={dropdownStyle.dropdown}>
            <div onClick={() => setOpen(!open)} className={`${dropdownStyle.button} d-flex justify-content-between`}>
                <span>
                    {selected?.label || placeholder}
                </span>
                <span>
                    <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Down-Gray.svg')} alt=''
                        className={open ? 'chevronDown' : 'chevronUp'} />
                </span>
            </div>

            <div className={`${dropdownStyle.dropdownContent} ${open ? dropdownStyle.visible : ''}`}>
                {open &&
                    <div className={dropdownStyle.content}>
                        {options.map((option: Option, idx: any) =>
                            <div key={idx} className={option === selected ? dropdownStyle.selectedOptionContent : dropdownStyle.optionContent}
                                onClick={() => handleClick(option)}>
                                {option.label}
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}

export default DropdownSelect
