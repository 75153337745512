import clsx from 'clsx'
import CartBar from 'components/mobile/CartBar/CartBar'
import { useStoreConfig } from 'layouts/common/core/StoreConfigProvider'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from 'utils/Functions'
import {
  ACCOUNT_MENU_PAGE,
  ACCOUNT_ORDERS_PAGE,
  ACCOUNT_WHISHLIST_PAGE,
  STORE_FRONT_PAGE,
  toUrl,
} from 'utils/Links'
import footmenu from './FooterMenu.module.css'

function FooterMenu() {
    const navigate = useNavigate()
    const location = useLocation();
    const {storeCartVisibility} = useStoreConfig();

    const isAreaActive = useCallback((path: string) => {
        if ( /^\/conta\/pedido\//.test(location.pathname) || /^\/conta\/lista-de-compras\//.test(location.pathname)) {
            return false
        }
        if (location.pathname && location.pathname.indexOf(path) > -1) {
            return true
        }
        return false
    }, [location])

    return (
        <>
            {storeCartVisibility && <CartBar/>}
            <div className={`${footmenu.footmenuOptions} d-flex align-items-center text-aling-center align-self-center`}>
                <div
                    className={`${footmenu.footmenuIcon} col-3 d-flex flex-column align-items-center`}
                    onClick={() => navigate(toUrl(STORE_FRONT_PAGE))}>
                    <img src={toAbsoluteUrl('/media/footmenu/Home-Faded.svg')} alt='img' className={clsx({ 'main-filter': window.location.pathname === '/' })} />
                    <span className={
                        clsx({
                            'main-filter font500': window.location.pathname === '/',
                            'gray-filter font500': window.location.pathname !== '/'
                        })}>
                        Ofertas
                    </span>
                </div>
                <div
                    className={`${footmenu.footmenuIcon} col-3 d-flex flex-column align-items-center`}
                    onClick={() => navigate(toUrl(ACCOUNT_ORDERS_PAGE))}>
                    <img src={toAbsoluteUrl('/media/footmenu/Orders-Faded.svg')}
                        alt='img'
                        className={clsx({ 'main-filter': /^\/conta\/pedido\//.test(window.location.pathname) })} />
                    <span className={
                        clsx({
                            'main-filter font500': /^\/conta\/pedido\//.test(window.location.pathname),
                            'gray-filter font500': window.location.pathname !== '/conta/pedido'
                        })}>
                        Pedidos
                    </span>
                </div>
                <div
                    className={`${footmenu.footmenuIcon} col-3 d-flex flex-column align-items-center`}
                    onClick={() => navigate(toUrl(ACCOUNT_WHISHLIST_PAGE))}>
                    <img src={toAbsoluteUrl('/media/footmenu/Lists-Faded.svg')} alt='img' className={clsx({ 'main-filter': /^\/conta\/lista-de-compras\//.test(window.location.pathname) })} />
                    <span className={
                        clsx({
                            'main-filter font500': /^\/conta\/lista-de-compras\//.test(window.location.pathname),
                            'gray-filter font500': window.location.pathname !== '/conta/lista-de-compras/lista'
                        })}>
                        Listas
                    </span>
                </div>
                <div
                    className={`${footmenu.footmenuIcon} col-3 d-flex flex-column align-items-center`}
                    onClick={() => navigate(toUrl(ACCOUNT_MENU_PAGE))}>
                    <img src={toAbsoluteUrl('/media/footmenu/Profile-Faded.svg')}
                        alt='img'
                        className={clsx({ 'main-filter': isAreaActive('/conta') })} />
                    <span className={
                        clsx({
                            'main-filter font500': isAreaActive('/conta'),
                            'gray-filter font500': !isAreaActive('/conta')
                        })}>
                        Perfil
                    </span>
                </div>
            </div>
        </>
    )
}

export default FooterMenu
