import { useMutation } from '@tanstack/react-query'
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs'
import { useFormik } from 'formik'
import { ChangePasswordRequest, initialChangePasswordRequest } from 'models/ChangePasswordRequest'
import { ApiResponseError } from 'modules/api/core/_models'
import { useMemo, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { CgClose } from 'react-icons/cg'
import ReactPasswordChecklist from 'react-password-checklist'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toAbsoluteUrl } from 'utils/Functions'
import { ACCOUNT_ORDERS_PAGE, ACCOUNT_PROFILE_PASSWORD_PAGE, toUrl } from 'utils/Links'
import passStyle from './AOStyle.module.css'
import { changePassword } from './core/_requestsCredential'
import PasswordInput from 'components/common/PasswordInput/PasswordInput'

const messages = {
  minLength: 'A senha deve ter no mínimo 8 caracteres.',
  specialChar: 'A senha tem um caractere especial.',
  number: 'A senha tem um número.',
  capital: 'A senha tem uma letra maiúscula.',
  match: 'A senha deve ser identica a anterior.',
}

function AOPassword() {
  const navigate = useNavigate()
  const [validPassword, setValidPassword] = useState(false)

  const { mutate: sendChangePasswordRequest } = useMutation({
      mutationFn: async (values: ChangePasswordRequest) => {
          const response = await changePassword(values)
          return response.data
      },
      mutationKey: ['changePassword'],
      onSuccess: () => {
          toast.success('Senha alterada com sucesso')
          navigate(-1)
      },
      onError: (error: any) => {
          if (error instanceof ApiResponseError) {
              toast.error(error.getErrorMessage())
          } else {
              toast.error('Erro ao alterar senha')
          }
      },
      onSettled: () => {
          formik.setSubmitting(false)
      }
  })

  const formik = useFormik<ChangePasswordRequest>({
      initialValues: initialChangePasswordRequest,
      onSubmit: (values) => {
          sendChangePasswordRequest(values)
      },
  })

  const breadcrumbs = useMemo(() => {
    return(
      [          
        {
          title : 'Perfil',
          path: toUrl(ACCOUNT_ORDERS_PAGE),
        },
        {    
          title : 'Redefinição de senha',
          path : toUrl(ACCOUNT_PROFILE_PASSWORD_PAGE),
        }
      ]
    )
  }, [])

  const [showModal, setShowModal] = useState(false)

  const handleModalClose = () => {
    setShowModal(false)
  }

  return (
    <div className='container col-12'>
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs}/>
        <span className='font600 font15rem'>MEUS DADOS</span>
      </div>

      <h6 className='my-4'>Deseja trocar sua senha de acesso?</h6>

      <form onSubmit={formik.handleSubmit} noValidate id='login_form' className='d-flex flex-column w-100'>
        <div className='mb-2'>
            <label htmlFor='newPass'>Senha atual*</label>
            <PasswordInput
                className={passStyle.inputStyle}
                customStyle='desktop'
                {...formik.getFieldProps('current_password')} />
            {formik.touched.current_password && formik.errors.current_password && (
                <span className='text-danger'>{formik.errors.current_password}</span>
            )}
        </div>
        <div className='mb-2'>
            <label htmlFor='newPass'>Nova senha*</label>
            <PasswordInput
                className={passStyle.inputStyle}
                customStyle='desktop'
                {...formik.getFieldProps('new_password')} />
            {formik.touched.new_password && formik.errors.new_password && (
                <span className='text-danger'>{formik.errors.new_password}</span>
            )}
        </div>
        <div className='mb-2'>
            <label htmlFor='newPass2'>Repetir nova senha*</label>
            <PasswordInput
                className={passStyle.inputStyle}
                customStyle='desktop'
                {...formik.getFieldProps('new_password_confirmation')} />
            {formik.touched.new_password_confirmation && formik.errors.new_password_confirmation && (
                <span className='text-danger'>{formik.errors.new_password_confirmation}</span>
            )}
        </div>

        <ReactPasswordChecklist
            rules={['minLength', 'number', 'match']}
            minLength={8}
            value={formik.values.new_password}
            valueAgain={formik.values.new_password_confirmation}
            onChange={setValidPassword}
            messages={messages}
            iconComponents={{
                ValidIcon: <img src={toAbsoluteUrl('/media/general/Shield-Green.svg')} alt='' className='checklist-icons me-1' />,
                InvalidIcon: <img src={toAbsoluteUrl('/media/general/Shield-Gray.svg')} alt='' className='checklist-icons me-1' />
            }}
        />
      </form>
      <button
          type='submit'
          className='button btn-second mt-3'
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting || !validPassword}>
          <span>Atualizar</span>
      </button>

      <Modal show={showModal} className='' onHide={handleModalClose} centered>
        <Modal.Body className={passStyle.modalBodySuccess}>
          <div
            className={`${passStyle.exitButton} d-flex justify-content-end`}
            onClick={handleModalClose}
          >
            <CgClose />
          </div>
          <div className={passStyle.modalContentSuccess}>
            <img src={process.env.PUBLIC_URL + '/media/general/Ok-Icon.svg'} alt='ok' />
            <p className='font20rem my-4'>Senha atualizada!</p>
            <button className='button btn-second' onClick={handleModalClose}>
              {' '}
              OK{' '}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AOPassword
