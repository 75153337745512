import Image from 'components/common/Image/Image';
import { useStoreConfig } from 'layouts/common/core/StoreConfigProvider';
import { useCallback } from 'react';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from 'utils/Functions';

function ServerErrorPageMobile() {
  const navigate = useNavigate();
  const { storeConfig } = useStoreConfig()
  const handleBackClick = useCallback(() => {
    const backButtonAction = storeConfig.layout.topbar.backButtonAction
    if (!!backButtonAction) {
        backButtonAction()
        return
    }
    navigate(-1)
    return
}, [storeConfig])

  return (
    <>
      <div className='d-flex justify-content-between main-color font600 mt-3 px-3'>
        <MdArrowBackIosNew onClick={handleBackClick} />
        <span>ERROR</span>
        <div></div>
      </div>
      <div className='container py-5 d-flex flex-column align-items-center'>
        <Image
          src={'/media/illustrations/No-Connection.svg'}
          alt='No-Connection'
          fallbackSrc='https://fakeimg.pl/250x250/cccccc/033858'
        />
        <img src={toAbsoluteUrl('/media/illustrations/No-Connection.svg')} alt=''/>
        <div className='d-flex flex-column align-items-center mt-2'>
          <span className='font500 font14rem main-color'>ERROR 500</span>
          <span className='main-color text-center'>Tentamos estabelecer conexão com nossos servidores, mas não obtivemos sucesso.</span>
          <span className='main-color'>Por favor, verifique sua conexão com a internet.</span>
        </div>
      </div>
    </>
  )
}

export default ServerErrorPageMobile
