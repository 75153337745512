import { useState } from 'react'
import styled from 'styled-components';
import { toAbsoluteUrl } from 'utils/Functions';
import cookieStyles from './CookiesModal.module.css';
import clsx from 'clsx';
import { getCookieItem, setCookieItem } from 'layouts/common/core/CookieHelper';
import { LOCAL_STORAGE_COOKIE_CONSET_KEY } from 'utils/Consts';

const Modal = styled.div`
  box-shadow: 0px 3px 20px #00000040;
  position: fixed;
  display: block;
  bottom: 0.5rem;
  border-radius: 8px;
  z-index: 100;
  background-color: #ffff;
  width: 80%;
  padding: 1rem;

  @media (max-width: 900px) {
    width: 85%;
  }
`;

function CookiesModalMobile() {
    const [showPopup, setShowPopup] = useState(!getCookieItem(LOCAL_STORAGE_COOKIE_CONSET_KEY));

    const acceptCookies = () => {
      setCookieItem(LOCAL_STORAGE_COOKIE_CONSET_KEY, true);
      setShowPopup(false);
    }
  
    const rejectCookies = () => {
      setCookieItem(LOCAL_STORAGE_COOKIE_CONSET_KEY, false);
      setShowPopup(false);
    }
  return (
    <div className='container d-flex justify-content-center'>
        <Modal className={clsx({'d-none' : !showPopup}, 'p-0')}>
            <div className='d-flex flex-column justify-content-center p-4'>
        
                <div className='d-flex justify-content-between'>
                <div>
                    <img src={toAbsoluteUrl('/media/general/Cookie.svg')} alt='coockie' className={cookieStyles.coockieIcon}/>
                    <span className='pb-1 font600'>Política de privacidade - Cookies</span>
                </div>
                <img src={toAbsoluteUrl('/media/general/X-icon.svg')} alt='' onClick={rejectCookies} className={cookieStyles.XIcon}/>
                </div>

                <div className='my-3'>
                <span>Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento.</span>
                </div>

                <div className={`d-flex justify-content-center`}>
                <button className={cookieStyles.buttonRefuseMobile} onClick={rejectCookies}>Recusar</button>
                <button className={cookieStyles.buttonAccept} onClick={acceptCookies}>Aceitar</button>
                </div>
            </div>
        </Modal>
    </div>
  )
}

export default CookiesModalMobile