import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import Image from "components/common/Image/Image";
import { Notification } from 'models/Notification';
import moment from "moment";
import { FC, useMemo, useState } from "react";
import { BsCheckAll } from "react-icons/bs";
import { toAbsoluteUrl } from "utils/Functions";
import partialAlert from "./PartialAlert.module.css";

type Props = {
  open: boolean;
  onClose: (clear?:boolean) => void;
  notifications: Notification[]
};

const PartialAlert: FC<Props> = ({ open, onClose, notifications }) => {
  const currentTime = new Date().getTime() as number
  const [isOld, setIsOld] = useState<number[]>([])
  const queryClient = useQueryClient()

  const handleNotificationClick = (idx: number) => {
    setIsOld([...isOld, idx]);
  };

  const handleClearAllClick = () => {
    onClose(true)
  };

  const getTimePassed = (timestamp: any) => {
    let timePassed = currentTime - (timestamp * 1000)
    if (timePassed < 60000) {
      return `${Math.floor(timePassed / 1000)} segundos`
    } else if (timePassed < 3600000) {
      return `${Math.floor(timePassed / 60000)} minutos`
    } else if (timePassed < 86400000) {
      return `${Math.floor(timePassed / 3600000)} horas`
    } else if (timePassed < 31536000000) {
      if (Math.floor(timePassed / 86400000) === 1) {
        return `${Math.floor(timePassed / 86400000)} dia`
      }
      return `${Math.floor(timePassed / 86400000)} dias`
    } else {
      if (Math.floor(timePassed / 31536000000) === 1) {
        return `${Math.floor(timePassed / 31536000000)} ano`
      }
      return `${Math.floor(timePassed / 31536000000)} anos`
    }
  }

  const visibleNotifications = useMemo(() => {
    return notifications.reverse().slice(0, 7);
  }, [notifications]);

  const allIndices = visibleNotifications.map((_, idx) => idx);

  return (
    <div className={clsx(partialAlert.dropdownAlert, { "d-none": !open })}>
      <div className={partialAlert.triangle}></div>
      <span className={partialAlert.notificationStyle}>Notificações</span>
      <div>
        {notifications.length === 0 && 
          <div className='d-flex flex-column align-items-center'>
            <Image
              src={toAbsoluteUrl('/media/Illustrations/Notification-Illustration.svg')}
              alt=''
              fallbackSrc='https://fakeimg.pl/140x140/cccccc/033858'
              className={partialAlert.image}
            />
            <span className='main-color font500 font12rem text-center'>
              Você não possui nenhuma notificação no momento.
            </span>
          </div>
        }
        {visibleNotifications.map((notification, idx) => (
          <div key={idx} className="row" onClick={() => handleNotificationClick(idx)}>
            <div className='col-9'>
              {!isOld.includes(idx) && <span className={partialAlert.newCircle}></span>}
              <span className={`${!isOld.includes(idx) && `font500`}`}>{notification.description}</span>
            </div>
            <div className={`${partialAlert.alertTime} col-3`}>{moment(notification.registration_date).toNow()}</div>
            <hr />
          </div>
        ))}
        {(notifications.length !== 0) && isOld.length !== visibleNotifications.length && (
          <div className="d-flex justify-content-end align-items-center">
            <span className={partialAlert.clearButton} onClick={() => handleClearAllClick()}> Marcar todas como Lidas </span> 
            <div className={partialAlert.checkIcon}>
              <BsCheckAll />
            </div>
          </div>     
        )}
      </div>
    </div>
  );
};

export default PartialAlert;