import { useMemo } from 'react';
import badgeStyle from './ProductBadge.module.css';

type Props = {
    label: string;
    color?: "purple" | "blue" | "green";
    handleClick?: () => void;
}

const BACKGROUND_COLORS = new Map([
    ["blue", "#2f38a0"],
    ["purple", "#862FA0"],
    ["green", "#2fa05b"]
])

function ProductBadge({ label, color, handleClick }: Props) {

    const hexColor = useMemo(() => {
        if (!label) return BACKGROUND_COLORS.get("blue")

        const colorIdx = label.length % BACKGROUND_COLORS.size
        return BACKGROUND_COLORS.get(Array.from(BACKGROUND_COLORS.keys())[colorIdx])
    }, [label])

    return (
        <div className={badgeStyle.productBadge} style={{ backgroundColor: hexColor }} onClick={handleClick}>
            <span>{label}</span>
        </div>
    )
}

export default ProductBadge
