import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { TableColumn } from 'react-data-table-component'
import { DataTableContextProps, DataTableFilter, DataTableLink, DataTableProviderState, initialDataTableContextProps } from './_models'

const DataTableContext = createContext<DataTableContextProps<any>>(initialDataTableContextProps)

type DataTableProviderProps<T> = {
    endpoint?: string
    columns: TableColumn<T>[]
    data?: T[]
    children?: ReactNode
    searchTerm?: string
    searchColumns?: string[]
    links?: DataTableLink[]
    onDataChanged?: (data: T[]) => void
    scope?: "regional" | "central"
    filter?: Array<DataTableFilter>
}

function DataTableProvider<T>(props: DataTableProviderProps<T>): JSX.Element {
    const { endpoint, columns, data, searchTerm, children, searchColumns, links, scope = "regional", filter } = props

    const [datatableState, setDatatableState] = useState<DataTableProviderState<T>>({
        endpoint: endpoint || '',
        columns: columns,
        data: data || [],
        filteredData: [],
        searchTerm: searchTerm || '',
        isLoading: false,
        filter: filter || [],
        refreshData: () => { },
        searchColumns: searchColumns || [],
        links: links || [],
        scope: scope
    })

    const updateDatatableState = (updates: Partial<DataTableProviderState<T>>, callback?: Function) => {
        setDatatableState((prevState) => ({ ...prevState, ...updates }))
    }

    useEffect(() => {
        if (data) {
            updateDatatableState({ data: data, columns: columns, endpoint: '' })
            if (props.onDataChanged) {
                props.onDataChanged(data)
            }
            return
        }
        updateDatatableState({ columns: columns, endpoint: endpoint })
    }, [data, columns, endpoint])

    return <DataTableContext.Provider value={{ datatableState, updateDatatableState }}>{children}</DataTableContext.Provider>
}
const useDataTable = () => useContext(DataTableContext)

export { DataTableProvider, useDataTable }
