import clsx from 'clsx'
import Searchbar from 'components/desktop/SearchBar/SearchBar'
import { useGrocery } from 'layouts/common/core/GroceryProvider'
import { useAuth } from 'modules/auth'
import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from 'utils/Functions'
import { ACCOUNT_ORDERS_PAGE, ACCOUNT_WHISHLIST_PAGE, LOGIN_PAGE, STORE_FRONT_PAGE, STORE_SIGNUP, toUrl } from 'utils/Links'
import NavbarCartLayout from '../NavbarCartLayout/NavbarCartLayout'
import NavbarNotificationLayout from '../NavbarNotificationLayout/NavbarNotificationLayout'
import navbarLayoutStyle from './NavbarLayout.module.css'

export default function NavbarLayout() {
    const { auth } = useAuth()
    const {groceryDetails} = useGrocery()
    const isDesktopSmall = useMediaQuery({ query: `(max-width: 768px)` })
    const isLogged = useMemo(() => {
      return auth && groceryDetails
    }, [auth, groceryDetails])

    return (
        <div
            className={clsx(`${navbarLayoutStyle.functionBar}`, {
                'justify-content-center': isDesktopSmall,
            })}
        >
            <div className='container d-flex justify-content-between align-items-center py-2'>
                <Link to={toUrl(STORE_FRONT_PAGE)}>
                    <img src={toAbsoluteUrl('/media/logos/Navbar-Logo.svg')} className={navbarLayoutStyle.AppLogo} alt='logo' />
                </Link>
                <Searchbar />
                <nav className={navbarLayoutStyle.navBar}>
                    <Link
                        to={toUrl(LOGIN_PAGE)}
                         className={clsx(navbarLayoutStyle.navBarLinks, {'d-none': isLogged,})}
                    >
                        <img src={toAbsoluteUrl('/media/general/Desktop-User-Icon.svg')} alt='user' className={navbarLayoutStyle.navBarIcons}/>
                        <div className='d-flex flex-column'>
                            <span className='d-lg-block d-none'>&nbsp;Entrar</span>
                        </div>
                    </Link>
                    <Link
                        to={toUrl(STORE_SIGNUP)}
                        className={clsx(navbarLayoutStyle.navBarLinks, {'d-none': isLogged,})}
                    >
                        <img src={toAbsoluteUrl('/media/general/Desktop-List-Icon.svg')} alt='list' className={navbarLayoutStyle.navBarIcons}/>
                        <span className='d-lg-block d-none'>&nbsp;Registrar</span>
                    </Link>
                    <Link
                        to={toUrl(ACCOUNT_ORDERS_PAGE)}
                        className={clsx(navbarLayoutStyle.navBarLinks, {'d-none': !isLogged,})}
                    >
                        <img src={toAbsoluteUrl('/media/general/Desktop-User-Icon.svg')} alt='user' className={navbarLayoutStyle.navBarIcons}/>
                        <div className='d-flex flex-column'>
                            <span className='d-lg-block d-none text-center lh-1'>&nbsp;{groceryDetails?.company_name}</span>
                        </div>
                    </Link>
                    <Link
                        to={toUrl(ACCOUNT_WHISHLIST_PAGE)}
                        className={clsx(navbarLayoutStyle.navBarLinks, {'d-none': !isLogged,})}
                    >
                        <img src={toAbsoluteUrl('/media/general/Desktop-List-Icon.svg')} alt='list' className={navbarLayoutStyle.navBarIcons}/>
                        <span className='d-lg-block d-none'>&nbsp;Listas</span>
                    </Link>
                    {isLogged &&
                        <div className={navbarLayoutStyle.navBarLinks}>
                            {groceryDetails && <NavbarNotificationLayout />}
                        </div>
                    }

                    <NavbarCartLayout />
                </nav>
            </div>
        </div>
    )
}
