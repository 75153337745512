import { SortOption } from 'models/SortOptionEnum';
import { useRef, useState } from 'react';
import Sheet, { SheetRef } from 'react-modal-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { toAbsoluteUrl } from 'utils/Functions';
import sortFilter from './SortFilter.module.css';

const sortOptions: SortOption[] = Object.values(SortOption) as SortOption[]

type Props = {
    value?: SortOption
    onChange: (option: SortOption) => void
}

function SortFilter({ value : activeOption = SortOption['AZ'], onChange }: Props) {

    const [isModalFilterOpen, setIsModalFilterOpen] = useState(false)
    // const [activeOption, setActiveOption] = useState(value)
    const ref = useRef<SheetRef>();

    // useEffect(() => {
    //     setActiveOption(value)
    // }, [value])

    function showModalFilter() {
        setIsModalFilterOpen(true)
    }

    function onDismiss() {
        setIsModalFilterOpen(false)
    }

    function handleClick(option: SortOption) {
        setIsModalFilterOpen(false)
        // setActiveOption(option)
        onChange(option)
    }

    return (
        <>
            <div className='me-1'>
                <div className={sortFilter.filterButton} onClick={showModalFilter}>
                    <div className={`${sortFilter.filterTitle} me-2`}>
                        {activeOption}
                    </div>
                    <img src={process.env.PUBLIC_URL + '/media/ChevronsAndArrows/Arrows-Icon.svg'} alt='arrows' />
                </div>
            </div>

            <Sheet
                ref={ref}
                isOpen={isModalFilterOpen}
                onClose={onDismiss}
                detent="content-height"
            >
                <Sheet.Container>
                    <Sheet.Header>
                        <div className='d-flex justify-content-between px-3 pt-3'>
                            <span className="flex items-center justify-center main-color font600 font11rem">
                                Ordenar por
                            </span>
                            <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Down-Gray.svg')} alt='' className='main-filter' />
                        </div>
                    </Sheet.Header>    
                        <Sheet.Content>
                            <div className='container'>
                                <div className={sortFilter.radioBottomContent}>
                                    <div className={`d-flex flex-column my-3 ${sortFilter.radioOptions}`}>
                                        {sortOptions.map((option, idx) => (
                                            <label className={sortFilter.singleOption} key={idx}>
                                                <input type='radio' name='filter'
                                                    onChange={() => handleClick(option)}
                                                    checked={activeOption === option} />
                                                <span>&nbsp; {option} </span>
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Sheet.Content>
                    
                </Sheet.Container>
                <Sheet.Backdrop onTap={onDismiss} />
            </Sheet>
        </>
    )
}

export default SortFilter
