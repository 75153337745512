import { useMutation, useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useStoreCart } from 'layouts/common/core/StoreCartProvider'
import { Wishlist, WishlistItem, WishlistItemGroup } from 'models/Wishlist'
import { ApiResponseError } from 'modules/api/core/_models'
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toAbsoluteUrl, toBRL } from 'utils/Functions'
import { update } from '../WishlistModalDesktop/core/_requests'
import WishlistProductComponent from '../WishlistProductComponent/WishlistProductComponent'
import styles from './ItemGroup.module.css'

type Props = {
  open?: boolean
  itemGroup: WishlistItemGroup
  wishlist: Wishlist;
  editable?: boolean;
}

const CartItemGroupWishlist: FC<Props> = ({itemGroup, wishlist, editable, ...props}) => {
  const { id } = useParams()
  const [open, setOpen] = useState(props.open || (true as boolean))
  const { addItemCart, addItemsCart, cart: { items } } = useStoreCart()
  const queryClient = useQueryClient()

  const toggleVisibility = () => {
    setOpen(!open)
  }

  const { mutate: sendUpdateWishlist } = useMutation({
    mutationFn: async (wishlist: Wishlist) => {
        const response = update(id!!, wishlist)
        return response;
    },
    mutationKey: ['updateWishlist', id],
    onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['wishlist', id] })
    },
    onError: (error) => {
        let errorMessage = "Erro ao atualizar lista de compras"
        if (error instanceof ApiResponseError) {
            errorMessage = error.getErrorMessage() || "Erro"
        }
        toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
            theme: 'light',
        });
    }
  })

  const handleWishlistItemAddToCart = (item: WishlistItem) => {
    addItemCart(item.product, item.quantity)
    toast.success('Produto adicionado ao carrinho', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
    })
  }

  const handleWilistItemChange = (item: WishlistItem) => {
    if (!wishlist || !wishlist.items) return
    const updatedWishlistItems = wishlist.items.map((wishlistItem) => {
        if (wishlistItem.id === item.id) {
            return item
        }
        return wishlistItem
    })
    //TODO add timeout to avoid too many requests
    sendUpdateWishlist({ ...wishlist, items: updatedWishlistItems })
  }

  const handleWishlistItemRemove = (item: WishlistItem) => {
    if (!wishlist || !wishlist.items) return
    const updatedWishlist = wishlist.items.filter((wishlistItem) => {
        return wishlistItem.id !== item.id
    })
    sendUpdateWishlist({
        ...wishlist,
        items: updatedWishlist
    })
  }

  return (
    <div>
      <div className={`${styles.cartItemClassDesktop}`} onClick={toggleVisibility}>
        <div className='d-flex justify-content-between bottom-line w-100 mb-2 p-3'>
          <span className={clsx({[styles.itemGroupName]: open})}>{itemGroup.category}</span>
          <div>
            <button className={styles.cartItemClassButton}>
              {itemGroup.items.length} &nbsp;
              {itemGroup.items.length > 1 ? 'itens' : 'item'} &nbsp;
              <span className={styles.cartItemPriceButton}>{toBRL(itemGroup.total)}</span>
            </button>
            <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Down-Gray.svg')} alt=''
            className={open ? 'chevronDown' : 'chevronUp'}/>
          </div>
        </div>
      </div>

      {open &&
        itemGroup.items.map((item, idx) => (
        <WishlistProductComponent
          key={idx}
          item={item}
          onAddToCart={handleWishlistItemAddToCart}
          onChange={handleWilistItemChange}
          onRemove={handleWishlistItemRemove}
          onDismiss={() => toggleVisibility}
          editable={editable}
        />
      ))}

    </div>
  )
}

export default CartItemGroupWishlist
