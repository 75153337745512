import { FC } from 'react';
import styled from "styled-components";
import { toAbsoluteUrl } from 'utils/Functions';

const LoadingContainer = styled.div`
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Helvetica, 'sans-serif';
  background-color: #f2f3f8;
  color: #5e6278;
  line-height: 1;
  font-size: 14px;
  font-weight: 400;

  span {
    color: #5e6278;
    transition: none !important;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
  }

  img {
    margin-left: calc(100vw - 100%);
    margin-bottom: 30px;
    height: 30px !important;
  }
`

type Props = {
    label?: string
    img?: string
}

const LoadingLayout: FC<Props> = ({ label = 'Carregando por favor aguarde...', img = toAbsoluteUrl('media/logos/vupty-small.png') }) => {
    return <LoadingContainer>
        <img src={img} alt='' />
        <span>{label}</span>
    </LoadingContainer>;
}

export default LoadingLayout;
