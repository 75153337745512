import { useQuery } from "@tanstack/react-query";
import PageTitle from "components/common/PageTitle/PageTitle";
import { Donation } from "models/Donation";
import { FilterOption } from "models/FilterOption";
import moment from "moment";
import { useMemo, useState } from "react";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import "react-spring-bottom-sheet/dist/style.css";
import { toBRL } from "utils/Functions";
import { STORE_FRONT_PAGE, toUrl } from "utils/Links";
import donationPage from "./DonationPage.module.css";
import { list } from "./core/_requestsDonation";

function DonationPage() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(null);
  const [sortedDonations, setSortedDonations] = useState<any>([]);
  const [isFilterContentVisible, setIsFilterContentVisible] = useState(false);
  const [filterOption, setFilterOption] = useState<FilterOption>();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(-1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [donations, setDonations] = useState([] as Donation[]);

  // const { isLoading: isDonationLoading, isFetching : isDonationFetching } = useQuery({
  //   queryFn: async () => {
  //       const response = await list()
  //       setDonations(response.data.content)
  //       console.log(response.data.content)
  //       return response
  //   },
  //   queryKey: ['list'],
  // })

  const { isLoading: isDonationLoading, isFetching: isDonationFetching } = useQuery({
    queryFn: async () => {
      const filters = filterOption?.value ? [filterOption.value] : [];
      const response = await list(filters, currentPage);
      setDonations((prev) => {
        if (currentPage === 0) {
          return response?.data.content;
        }
        return [...prev, ...response?.data.content];
      });
      setTotalPages(response.data.total_pages);
      setIsLoadingMore(false);

      return response;
    },
    queryKey: ["donations", filterOption, currentPage],
  });

  const toggle = (index: any) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  const isEmptyPlaceholderVisible = useMemo(() => {
    return !isDonationLoading && !isDonationFetching && donations.length === 0;
  }, [isDonationLoading, isDonationFetching, donations]);

  const isSearchingPlaceholderVisible = useMemo(() => {
    return !isLoadingMore && isDonationFetching;
  }, [isLoadingMore, isDonationFetching]);

  const onLoadMoreContent = (page: number) => {
    if (isDonationLoading || currentPage >= totalPages - 1) return;
    setCurrentPage((prev) => prev + 1);
    setIsLoadingMore(true);
  };

  const hasMoreContent = useMemo(() => {
    return totalPages <= 1 ? false : totalPages > currentPage + 1;
  }, [currentPage, totalPages]);

  return (
    <>
      <PageTitle title={`DOAÇÕES`} />
      <div className="container padding-bottom-mobile">
        {isSearchingPlaceholderVisible && (
          <div className="d-flex justify-content-center align-items-center">
            <ClipLoader color="#033858" size={20} />
            <span className="font500 ms-2">Carregando...</span>
          </div>
        )}

        {isEmptyPlaceholderVisible && (
          <div className="d-flex flex-column align-items-center mb-4 mt-3">
            <div className="d-flex flex-column align-items-center">
              <img src={process.env.PUBLIC_URL + "/media/menuPage/Big-Bill.svg"} alt="card" />
              <p className="font500 my-4">Nenhum doação por enquanto...</p>
              <p className="text-center font300 font09rem">
                Para acompanhar as suas doações, acesse a loja e faça uma compra.
              </p>
            </div>
            <div className="button btn-main mt-4" onClick={() => navigate(toUrl(STORE_FRONT_PAGE))}>
              Começar a comprar
            </div>
          </div>
        )}

        {donations && donations.length > 0 && (
          <>
            <InfiniteScroll
              className="w-100"
              pageStart={0}
              initialLoad={false}
              loadMore={onLoadMoreContent}
              hasMore={hasMoreContent}
              loader={
                <div className="loader d-flex justify-content-center" key={0}>
                  <ClipLoader color="#033858" size={18} />
                </div>
              }
            >
              {donations.map((donation, index) => (
                <div key={index}>
                  <div>
                    {/* BEGIN OPEN CARD */}
                    {selected === index ? (
                      <div
                        className={`${donationPage.orderBubble} d-flex flex-column justify-content-between align-items-center my-3`}
                      >
                        <div className="d-flex flex-column justify-content-between col-12 px-3 py-3">
                          <div className="d-flex justify-content-end">
                            <MdOutlineKeyboardArrowUp
                              onClick={() => toggle(index)}
                              className={selected === index ? "chevronUp me-2" : "chevronDown me-2"}
                            />{" "}
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>{moment(donation.date).format("DD/MM/YYYY")}</span>
                            <span
                              className={`${donationPage.orderBubbleDonation} ${
                                donationPage["bubbleStatus" + donation.status.value]
                              } col-4 d-flex justify-content-center`}
                            >
                              {donation.status.description}
                            </span>
                            <span>{toBRL(donation.value)}</span>
                          </div>
                          <div className="d-flex flex-column justify-content-between align-items-start">
                            <span>Instituição</span>
                            <span style={{ fontWeight: "400" }}>{donation.institution}</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`${donationPage.orderBubble} ${donationPage.bubbleClosed} d-flex flex-row justify-content-between align-items-center p-3 my-3`}
                        onClick={() => toggle(index)}
                      >
                        <span>{moment(donation.date).format("DD/MM/YYYY")}</span>
                        <span
                          className={`${donationPage.orderBubble} ${
                            donationPage["bubbleStatus" + donation.status.value]
                          } col-4 d-flex justify-content-center`}
                        >
                          {donation.status.description}
                        </span>
                        <span>{toBRL(donation.value)}</span>
                        <MdOutlineKeyboardArrowDown className="col-1" />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </>
        )}
      </div>
    </>
  );
}

export default DonationPage;
