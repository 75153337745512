import { useMutation, useQuery } from "@tanstack/react-query";
import Breadcrumbs from "components/common/breadcrumbs/Breadcrumbs";
import { getIn, useFormik } from "formik";
import { initialGroceryMainContact, initialGrocerySecundaryContact } from "models/PersonOfContact";
import { ApiResponseError } from "modules/api/core/_models";
import { GroceryDetails, initialGroceryDetailsValues } from "modules/auth";
import { useMemo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { PatternFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "utils/Functions";
import { ACCOUNT_ORDERS_PAGE, ACCOUNT_PROFILE_PAGE, toUrl } from "utils/Links";
import * as Yup from "yup";
import dataStyle from "./AOStyle.module.css";
import { getGroceryDetails, updateGroceryDetails } from "./core/_requestsData";

const validationSchema = Yup.object().shape({
  address: Yup.object({
    cep: Yup.string().required(),
    street: Yup.string().required(),
    number: Yup.string().required("aaaaa"),
    neighborhood: Yup.string().required(),
  }).required(),
  business_telephone_number: Yup.string()
    .required("Digite um telefone valido")
    .test("Digite um telefone valido", (value: any) =>
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/g.test(
        value
      )
    ),
  email: Yup.string()
    .email("Digite um e-mail válido")
    .min(3, "Minimo de 3 símbolos")
    .max(50, "Máximo de 50 símbolos")
    .required("Digite um e-mail válido"),
});

const breadcrumbs = [
  {
    title: "Perfil",
    path: toUrl(ACCOUNT_ORDERS_PAGE),
  },
  {
    title: "Minha Área",
    path: toUrl(ACCOUNT_PROFILE_PAGE),
  },
];

function AOData() {
  const navigate = useNavigate();
  const [openTabs, setOpenTabs] = useState<string[]>([]);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [groceryDetails, setGroceryDetails] = useState<GroceryDetails>(
    initialGroceryDetailsValues
  );

  const partialUpdate = (fieldsToUpdate: Partial<GroceryDetails>) => {
    const updatedData = { ...groceryDetails, ...fieldsToUpdate };
    setGroceryDetails(updatedData);
  };

  useQuery({
    queryFn: async () => {
      const response = await getGroceryDetails();
      partialUpdate({
        ...response.data,
        address: { ...groceryDetails.address, ...response.data.address },
      });
      return response;
    },
    queryKey: ["getGroceryDetails"],
  });

  const mainContact = useMemo(() => {
    if (!groceryDetails || !groceryDetails.contact_peoples) return initialGroceryMainContact;
    return groceryDetails.contact_peoples.find(
      (contact) => contact.is_main_contact
    ) || initialGroceryMainContact;
  }, [groceryDetails]);

  const secundaryContact = useMemo(() => {
    if (!groceryDetails || !groceryDetails.contact_peoples) return initialGrocerySecundaryContact;
    return groceryDetails.contact_peoples.find(
      (contact) => !contact.is_main_contact
    ) || initialGrocerySecundaryContact;
  }, [groceryDetails]);

  const { mutate: sendUpdateRequest } = useMutation({
    mutationFn: async (groceryDetails: GroceryDetails) => {
      const response = await updateGroceryDetails(groceryDetails);
      return response;
    },
    mutationKey: ["mainContactDetails"],
    onSuccess: () => {
      toast.success("Dados atualizados com sucesso");
    },
    onError: (error) => {
      if (error instanceof ApiResponseError) {
        toast.error(error.getErrorMessage());
      } else {
        toast.error("Erro ao atualizar dados");
      }
    },
  });

  const toggleTabVisibility = (tab: string) => {
    if (openTabs.includes(tab)) {
      const updated = openTabs.filter((t) => t !== tab);
      setOpenTabs(updated);
      return;
    }
    return setOpenTabs([...openTabs, tab]);
  };

  const formik = useFormik({
    initialValues: groceryDetails,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      sendUpdateRequest(values);
    },
  });

  const handleMainContactChange = (e: any) => {
    if (!groceryDetails || !groceryDetails.contact_peoples) return;

    const { name, value } = e.target;

    const hasMainContact = groceryDetails.contact_peoples.some(
      (contact) => contact.is_main_contact
    );

    if (!hasMainContact) {
      const mainContact = { ...initialGroceryMainContact, [name]: value };
      partialUpdate({
        contact_peoples: [...groceryDetails.contact_peoples, mainContact],
      });
      return;
    }

    const updatedContacts = groceryDetails.contact_peoples.map((contact) => {
      if (!contact.is_main_contact) return contact;

      return {
        ...contact,
        [name]: value,
      };
    });
    partialUpdate({ contact_peoples: updatedContacts });
  };

  const handleSecundaryContactChange = (e: any) => {
    if (!groceryDetails || !groceryDetails.contact_peoples) return;

    const { name, value } = e.target;

    const updatedContacts = groceryDetails.contact_peoples.map((contact) => {
      if (contact.is_main_contact) return contact;

      return {
        ...contact,
        [name]: value,
      };
    });
    partialUpdate({ contact_peoples: updatedContacts });
  };

  const handleMainContactTypeChange = (e: any) => {
    if (!groceryDetails || !groceryDetails.contact_peoples) return;

    const value = e.target.value;
    const label = e.target.options[e.target.selectedIndex].text;

    const updatedContacts = groceryDetails.contact_peoples.map((contact) => {
      if (contact.is_main_contact) {
        return {
          ...contact,
          grocery_contact_type: {
            value,
            description: label,
          },
        };
      }
      return contact;
    });
    partialUpdate({ contact_peoples: updatedContacts });
  };

  const handleSecundaryContactTypeChange = (e: any) => {
    if (!groceryDetails || !groceryDetails.contact_peoples || !secundaryContact) return;
  
    const value = e.target.value;
    const label = e.target.options[e.target.selectedIndex].text;
  
    const updatedContacts = groceryDetails.contact_peoples.map((contact) => {
      if (!contact.is_main_contact && contact === secundaryContact) {
        return {
          ...contact,
          grocery_contact_type: {
            value,
            description: label,
          },
        };
      }
      return contact;
    });
    partialUpdate({ contact_peoples: updatedContacts });
  }

  return (
    <div className="container col-12">
      <div className="mb-4">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className="font600 font15rem">MEUS DADOS</span>
      </div>

      <form onSubmit={formik.handleSubmit} noValidate className="container">
        <div className="row mb-3">
          <div
            className={dataStyle.dataBar}
            onClick={() => toggleTabVisibility("dados")}
          >
            <div className="d-flex align-items-center">
              <img
                src={toAbsoluteUrl("/media/icons/Company-Data.svg")}
                alt="profile"
                className="me-2"
              />
              <span className={dataStyle.formTitle}>DADOS DA EMPRESA</span>
            </div>
            <img
              src={toAbsoluteUrl(
                "/media/ChevronsAndArrows/Chevron-Down-Gray.svg"
              )}
              alt=""
              className={
                openTabs.includes("dados")
                  ? "chevronDown main-filter"
                  : "chevronUp main-filter"
              }
            />
          </div>
          {openTabs.includes("dados") && (
            <div className="d-flex flex-row flex-wrap mt-4 ps-4">
              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>Nome Fantasia</label>
                <span className="font11rem faded-color">
                  {groceryDetails.company_name}
                </span>
              </div>

              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>CNPJ</label>
                <span className="font11rem faded-color">
                  {groceryDetails.cnpj || "Não cadastrado"}
                </span>
              </div>

              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>Inscrição Estadual</label>
                <span className="font11rem faded-color">
                  {groceryDetails.state_registration || "Não cadastrado"}
                </span>
              </div>

              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>Inscrição Municipal</label>
                <span className="font11rem faded-color">
                  {groceryDetails.municipal_registration || "Não cadastrado"}
                </span>
              </div>

              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>Telefone Comercial</label>
                <PatternFormat
                  className={dataStyle.dataLabel}
                  format="(##) #####-####"
                  allowEmptyFormatting
                  mask="*"
                  {...formik.getFieldProps("business_telephone_number")}
                />
                {formik.touched.business_telephone_number &&
                  formik.errors.business_telephone_number && (
                    <span className="text-danger">
                      {formik.errors.business_telephone_number}
                    </span>
                  )}
              </div>

              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>E-mail</label>
                <input
                  placeholder="email"
                  className={dataStyle.dataLabel}
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="text-danger">{formik.errors.email}</span>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="row mb-3">
          <div
            className={dataStyle.dataBar}
            onClick={() => toggleTabVisibility("endereco")}
          >
            <div className="d-flex align-items-center">
              <img
                src={toAbsoluteUrl("/media/general/House.svg")}
                alt="profile"
                className={`${dataStyle.iconSize} me-2`}
              />
              <span className={dataStyle.formTitle}>ENDEREÇO</span>
            </div>
            <img
              src={toAbsoluteUrl(
                "/media/ChevronsAndArrows/Chevron-Down-Gray.svg"
              )}
              alt=""
              className={
                openTabs.includes("endereco")
                  ? "chevronDown main-filter"
                  : "chevronUp main-filter"
              }
            />
          </div>
          {openTabs.includes("endereco") && (
            <div className="mt-4 ps-4">
              <div className="d-flex flex-row flex-wrap ">
                <div className={`col-6 d-flex flex-column mb-4`}>
                  <label>CEP</label>
                  <PatternFormat
                  className={dataStyle.dataLabel}
                  placeholder="Insira seu CEP"
                  format="#####-###"
                  allowEmptyFormatting
                  mask="*"
                  {...formik.getFieldProps("address.cep")}
                />
                  {getIn(formik.touched.address, "cep") &&
                    getIn(formik.errors.address, "cep") && (
                      <span className="text-danger">
                        {getIn(formik.errors.address, "cep")}
                      </span>
                    )}

                </div>
              </div>
              <div className="d-flex flex-row flex-wrap">
                <div className={`col-10 d-flex flex-column mb-4`}>
                  <label>Rua</label>
                  <input
                    placeholder="Rua"
                    className={dataStyle.dataLabel}
                    {...formik.getFieldProps("address.street")}
                  />
                  {getIn(formik.touched.address, "street") &&
                    getIn(formik.errors.address, "street") && (
                      <span className="text-danger">
                        {getIn(formik.errors.address, "street")}
                      </span>
                    )}
                </div>
                <div className={`col-2 d-flex flex-column mb-4`}>
                  <label>Número</label>
                  <input
                    placeholder="Numero"
                    className={dataStyle.dataLabel}
                    {...formik.getFieldProps("address.number")}
                  />
                  {getIn(formik.touched.address, "number") &&
                    getIn(formik.errors.address, "number") && (
                      <span className="text-danger">
                        {getIn(formik.errors.address, "number")}
                      </span>
                    )}
                </div>

                <div className={`col-6 d-flex flex-column mb-4`}>
                  <label>Bairro</label>
                  <input
                    placeholder="Bairro"
                    className={dataStyle.dataLabel}
                    {...formik.getFieldProps("address.neighborhood")}
                  />
                  {getIn(formik.touched.address, "neighborhood") &&
                    getIn(formik.errors.address, "neighborhood") && (
                      <span className="text-danger">
                        {getIn(formik.errors.address, "neighborhood")}
                      </span>
                    )}
                </div>
                <div className={`col-6 d-flex flex-column mb-4`}>
                  <label>Complemento</label>
                  <input
                    placeholder="Ex:. Casa, Apartamento, Bloco, etc."
                    className={dataStyle.dataLabel}
                    {...formik.getFieldProps("address.complement")}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row mb-3">
          <div
            className={dataStyle.dataBar}
            onClick={() => toggleTabVisibility("contato_principal")}
          >
            <div className="d-flex align-items-center">
              <img
                src={toAbsoluteUrl("/media/icons/Contact-Icon.svg")}
                alt="profile"
                className="me-2"
              />
              <span className={dataStyle.formTitle}>CONTATO PRINCIPAL</span>
            </div>
            <img
              src={toAbsoluteUrl(
                "/media/ChevronsAndArrows/Chevron-Down-Gray.svg"
              )}
              alt=""
              className={
                openTabs.includes("contato_principal")
                  ? "chevronDown main-filter"
                  : "chevronUp main-filter"
              }
            />
          </div>
          {openTabs.includes("contato_principal") && (
            <div className="d-flex flex-row flex-wrap mt-4 ps-4">
              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>Nome</label>
                <input
                  type="text"
                  placeholder="Nome"
                  name="name"
                  className={dataStyle.dataLabel}
                  value={mainContact.name}
                  onChange={handleMainContactChange}
                />
              </div>
              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>Telefone</label>
                <PatternFormat
                  className={dataStyle.dataLabel}
                  placeholder="(**) ***** - ****"
                  format="(##) #####-####"
                  allowEmptyFormatting
                  mask="*"
                  value={mainContact.telephone_number}
                  onChange={handleMainContactChange}
                />
              </div>
              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>E-mail</label>
                <input
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  className={dataStyle.dataLabel}
                  value={mainContact.email}
                  onChange={handleMainContactChange}
                />
              </div>
              <div className="col-6 d-flex flex-column mb-4 pe-3">
                <label>Tipo</label>
                <select
                  className="form-control"
                  value={mainContact.grocery_contact_type?.value || "1"}
                  onChange={handleMainContactTypeChange}
                  style={{padding:'0.33rem 0rem 0.28rem 1rem'}}
                >
                  <option value="1">Dono</option>
                  <option value="2">Diretor</option>
                  <option value="3">Gerente</option>
                  <option value="4">Funcionário</option>
                  <option value="5">Outro</option>
                </select>
              </div>
            </div>
          )}
        </div>

        <div className="row mb-3">
          <div
            className={dataStyle.dataBar}
            onClick={() => toggleTabVisibility("contato_secundario")}
          >
            <div className="d-flex align-items-center">
              <img
                src={toAbsoluteUrl("/media/icons/Additional-Contact.svg")}
                alt="profile"
                className={`${dataStyle.iconSize} me-2`}
              />
              <span className={dataStyle.formTitle}>CONTATO ADICIONAL</span>
            </div>
            <img
              src={toAbsoluteUrl("/media/ChevronsAndArrows/Chevron-Down-Gray.svg")}
              alt=""
              className={
                openTabs.includes("contato_secundario")
                  ? "chevronDown main-filter"
                  : "chevronUp main-filter"
              }
            />
          </div>
          {openTabs.includes("contato_secundario") && (
            <div className="d-flex flex-row flex-wrap mt-4 ps-4">
              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>Nome</label>
                <input
                  type="text"
                  placeholder="Nome"
                  name="name"
                  className={dataStyle.dataLabel}
                  value={secundaryContact.name}
                  onChange={handleSecundaryContactChange}
                />
              </div>
              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>Telefone</label>
                <PatternFormat
                  className={dataStyle.dataLabel}
                  format="(##) #####-####"
                  allowEmptyFormatting
                  mask="*"
                  value={secundaryContact.telephone_number}
                  onChange={handleSecundaryContactChange}
                />
              </div>
              <div className={`col-6 d-flex flex-column mb-4`}>
                <label>E-mail</label>
                <input
                  type="email"
                  placeholder="nome@exemplo.com"
                  name="email"
                  className={dataStyle.dataLabel}
                  value={secundaryContact.email}
                  onChange={handleSecundaryContactChange}
                />
              </div>
              <div className="col-6 d-flex flex-column mb-4 pe-3">
                <label>Tipo</label>
                <select
                  className="form-control"
                  value={secundaryContact.grocery_contact_type?.value || "4"}
                  onChange={handleSecundaryContactTypeChange}
                >
                  <option value="1">Dono</option>
                  <option value="2">Diretor</option>
                  <option value="3">Gerente</option>
                  <option value="4">Funcionário</option>
                  <option value="5">Outro</option>
                </select>
              </div>
            </div>
          )}
        </div>
          
      </form>

      {/* {groceryDetails.contact_peoples?.[1].active && (
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          id="contact_form"
          className="container"
        >
          <div className={dataStyle.dataForm}>

          </div>
        </form>
      )} */}

      <div className="d-flex justify-content-center">
        <span className="button btn-third my-4" onClick={() => formik.handleSubmit()}>Salvar Informações</span>
      </div>
    </div>
  );
}

export default AOData;
