import { Product } from 'models/Product';
import { useState } from 'react';
import {Carousel} from 'react-bootstrap'
import wishSuggestion from './WishlistModalSuggestions.module.css'
import { toAbsoluteUrl } from 'utils/Functions';
import ProductCardVertical from 'layouts/mobile/ProductCard/ProductCardVertical';
import VerticalCardSkeleton from 'components/mobile/Skeletons/VerticalCardSkeleton';
import { useStoreCart } from 'layouts/common/core/StoreCartProvider';

type Props = {
    sale: string
    products: Product[]
}

const SaleLayoutRowSkeleton = (): JSX.Element => {
    return (
      <>
        {[...Array(4)].map((_, idx) => (
          <VerticalCardSkeleton key={idx} />
        ))}
      </>
    );
  };

function WishSuggestionLayout({sale, products}: Props) {
    const { addItemCart, updateCartItem, removeItemCart } = useStoreCart()
    const loading = false;
    const [index, setIndex] = useState(0);


    const handleSelect = (selectedIndex : any, e : any) => {
        setIndex(selectedIndex)
    }

    // NEEDS A LOGIC COMPRISING FILTERED ITEM
    const chunkArray = (array: any, chunkSize: number) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const productChunks = chunkArray(products, 4);

  return (
    <>
    {productChunks.length > 0 &&
      <div className={`${wishSuggestion.saleContainer} d-flex flex-column align-items-center`}>
          <span className='font500 font12rem py-4'>{sale}</span>
          {loading &&
              <div>
                  {SaleLayoutRowSkeleton()}
              </div>
          }
          {!loading &&
              <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              interval={90000} className='w-100 px-3'
              indicators={false} variant={'dark'}
              prevIcon={<img src={toAbsoluteUrl('/media/ChevronsAndArrows/Carousel-Prev.svg')} alt="Previous" />}
              nextIcon={<img src={toAbsoluteUrl('/media/ChevronsAndArrows/Carousel-Next.svg')} alt="Next" />}
              >

                  {productChunks.map((chunk, idx) =>
                      <Carousel.Item key={idx}>
                          <div className={wishSuggestion.productContainer}>
                          {chunk.map((product: any, idx: number) => (
                              <div key={idx}>
                                  <ProductCardVertical product={product}  />
                              </div>
                          ))}
                          </div>
                      </Carousel.Item>
                  )}

              </Carousel>
          }
      </div>
    }
    </>
  )
}

export default WishSuggestionLayout
