import {FC} from 'react'
import {myHistory} from './core/history'
import {HistoryRouter} from './HistoryRouter'
import AppRoutes from './AppRoutes'
const {PUBLIC_URL} = process.env

const AppRoutesWrapper: FC = () => {
  return (
    <HistoryRouter basename={PUBLIC_URL} history={myHistory}>
      <AppRoutes />
    </HistoryRouter>
  )
}

export default AppRoutesWrapper
