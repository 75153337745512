import clsx from "clsx";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import { Product } from "models/Product";
import { ChangeEvent, useMemo } from "react";
import { toAbsoluteUrl } from "utils/Functions";
import ProductCounterSkeleton from "../../../desktop/Skeletons/ProductCounterSkeleton";
import style from "./ProductPageCounter.module.css";

type Props = {
  product: Product;
  loading?: boolean;
  itemType?: string;
  title?: string;
  onAdd: (product: Product) => void;
  onRemove: (product: Product) => void;
  onChange: (product: Product, quantity: number) => void;
  onRemoveAll: (product: Product) => void;
  disabled?: boolean;
};

function ProductPageCounter({
  product,
  loading = false,
  title = "ADICIONAR AO CARRINHO",
  onChange,
  onAdd,
  onRemove,
  onRemoveAll,
  disabled = true
}: Props) {

  const { getItemCart } = useStoreCart();
  const cartItem = useMemo(() => getItemCart(product.id), [getItemCart, product]);

  const isMaxQuantity = useMemo(() => {
    if (!product.quantity_available) return false;
    if (cartItem?.quantity === product.quantity_available) return true;
    return false;
  }, [cartItem, product]);

  const boxQuantity = useMemo(() => {
    if (!cartItem || !product.quantity_per_package) return 0;
    return Math.floor(cartItem.quantity / product.quantity_per_package);
  }, [cartItem, product]);

  const handleAddClick = () => {
    if (disabled) return;
    onAdd(product);
  };

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Check if the value is a valid number using a regular expression
    const isValidNumber = /^-?\d+$/.test(value);

    let newQty = 1;
    if (isValidNumber) {
      newQty = parseInt(value, 10);
    }

    if (newQty <= 0){
      onRemove(product);
      return;
    }

    if (newQty !== cartItem?.quantity) {
      onChange(product, newQty);
    }
  };

  return (
    <>
      {loading && <ProductCounterSkeleton />}
      {!loading && !cartItem && (
        <div className={style.itemCardCounter}>
          <button onClick={handleAddClick} className={clsx(style.addNew, disabled && style.disabled)}>
            <div className={clsx(style.addToCart, disabled && style.labelDisabled)}>
              <img src={process.env.PUBLIC_URL + "/media/general/Cart-Addition.svg"} alt="img" />
              <span>{title}</span>
            </div>
          </button>
        </div>
      )}
      {!loading && cartItem && (
        <div className={style.itemCardCounter}>
          <div className="d-flex">
            {cartItem?.quantity > 1 && (
              <div className={style.removeTrashOutsideButton}>
                <img
                  src={toAbsoluteUrl("/media/general/Trash-Outline-Icon.svg")}
                  alt="Trash"
                  onClick={() => onRemoveAll(product)}
                />
              </div>
            )}

            <div className="d-flex">
              {cartItem?.quantity !== 1 && (
                <button onClick={() => onRemove(product)} className={style.remove}>
                  -
                </button>
              )}
              {cartItem?.quantity === 1 && (
                <div className={style.removeTrash}>
                  <img
                    src={toAbsoluteUrl("/media/general/Trash-Outline-Icon.svg")}
                    alt="Trash"
                    onClick={() => onRemove(product)}
                  />
                </div>
              )}
              <input
                type="number"
                min={1}
                className={style.barCounter}
                value={cartItem?.quantity}
                onChange={handleQuantityChange}
              />
              <button onClick={() => onAdd(product)} className={style.add}>
                +
              </button>
            </div>

            {boxQuantity > 0 && (
              <div style={{ width: "42px" }}>
                <div className="position-realtive">
                  <div
                    className={`${boxQuantity > 9 ? style.boxCounterLong : style.boxCounterShort} ${style.boxCounter}`}
                  >
                    <span className="transform-1px">{boxQuantity}</span>
                  </div>
                  <div className={style.boxIcon}>
                    <img src={toAbsoluteUrl("/media/general/Box-Icon-2.svg")} alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
          {isMaxQuantity && <div className="d-flex justify-content-center">Quantidade máxima atingida</div>}
        </div>
      )}
    </>
  );
}

export default ProductPageCounter;
