/* eslint-disable react-hooks/exhaustive-deps */
import qs from 'qs'
import { FC, useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useLocation } from 'react-router-dom'
import { centralClient, regionalClient } from '../../..'
import './DataTableBase.css'
import NoDataComponent from './components/NoDataComponent'
import { useDataTable } from './core/DataTableProvider'
import { DataTableSelectedState } from './core/_models'

declare type DataTableBaseProps = {
    defaultSortAsc?: string;
    defaultSortFieldId?: string;
}

const DataTableBase: FC<DataTableBaseProps & any> = ({defaultSortAsc, defaultSortFieldId, ...props}) => {
    const { datatableState, updateDatatableState } = useDataTable()
    const [totalRows, setTotalRows] = useState(0)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [direction, setDirection] = useState(defaultSortAsc ? 'asc' : 'desc')
    const [sort, setSort] = useState(defaultSortFieldId || '')
    const isServerSide = datatableState.endpoint?.length > 0
    const currentData = isServerSide ? datatableState.data : datatableState.filteredData
    // const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const state = useLocation()?.state as any

    useEffect(() => {
        if (state && state.refreshDatatable) {
            fetchData()
        }
    }, [state])

    useEffect(() => {
        // do first fetch here
        fetchData()
        // set refresh data function
        updateDatatableState({
            refreshData: () => {
                fetchData()
            },
        })
    }, [datatableState.filter, datatableState.searchTerm, direction, page, perPage])

    useEffect(() => {
        const resolvePath = (object: any, path: any, defaultValue: any) => path.split('.').reduce((o: any, p: any) => (o ? o[p] : defaultValue), object)

        //local data operations
        if (datatableState.endpoint.length === 0) {
            if (!datatableState.data) return

            //search by searchTerm in searchcoluns in data
            if (datatableState.searchColumns.length > 0) {
                const filteredData = datatableState.data.filter((item) => {
                    let found = false
                    datatableState.searchColumns.forEach((column) => {
                        let itemColumn = item[column]
                        if (column.indexOf('.') > -1) {
                            itemColumn = resolvePath(item, column, '')
                        }
                        if (itemColumn?.toString().toLowerCase().includes(datatableState.searchTerm.toLowerCase())) {
                            found = true
                        }
                    })
                    return found
                })
                if (filteredData) {
                    updateDatatableState({ filteredData: filteredData })
                    setTotalRows(filteredData.length)
                }
            } else {
                updateDatatableState({ filteredData: datatableState.data })
                setTotalRows(datatableState.data.length)
            }
        }
    }, [datatableState.data, datatableState.filter, datatableState.searchTerm])

    const fetchData = async () => {

        if (datatableState.endpoint.length === 0) return

        updateDatatableState({ isLoading: true })

        let client;
        switch (datatableState.scope) {
            case "central":
                client = centralClient;
                break;
            case "regional":
                client = regionalClient;
                break;
            default:
                client = regionalClient;
                break;
        }

        const response = await client.get(datatableState.endpoint, {
            params: {
                page: page - 1,
                per_page: perPage,
                sort: sort,
                direction: direction,
                search: datatableState.searchTerm,
                filter: datatableState.filter,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { allowDots: true })
            },
        })

        if (!response.data) {
            updateDatatableState({
                data: [],
                isLoading: false,
            })
            return
        }
        updateDatatableState({
            data: response.data.content,
            isLoading: false,
            additional_content: response.data.additional_content
        })
        setTotalRows(response.data.total_elements ? response.data.total_elements : 0)
    }

    const handleSort = (column: any, sortDirection: any) => {
        setSort(column.id)
        setDirection(sortDirection)
    }

    const handlePageChange = (page: number) => {
        setPage(page)
    }

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    }

    const handleRowSelected = useCallback((selectedState: DataTableSelectedState) => {
        console.log(selectedState)
        updateDatatableState({ selectedState })
        console.log(datatableState)
    }, [])

    return (
        <DataTable
            data={currentData}
            columns={datatableState.columns}
            progressPending={datatableState.isLoading}
            pagination
            paginationServer={isServerSide}
            sortServer={isServerSide}
            paginationTotalRows={totalRows}
            persistTableHead={true}
            onSort={handleSort}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            selectableRows
            selectableRowsComponentProps={{ className: 'form-check-input' }}
            paginationRowsPerPageOptions={[1, 2, 5, 10, 20, 30, 40, 50]}
            onSelectedRowsChange={handleRowSelected}
            noDataComponent={<NoDataComponent links={datatableState.links} />}
            {...props}
        />
    )
}

export default DataTableBase
