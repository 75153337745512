import { FC, useState } from 'react'
import {ACCOUNT_2_FACTOR, toUrl} from 'utils/Links'
import PageTitle from 'components/common/PageTitle/PageTitle'
import authStyle from './AuthenticationSteps.module.css'
import AuthenticationStep from '../AuthenticationStep/AuthenticationStep'

type Props = {
  altStep: number
}

type Step = {
  label: string
  link?: string
}

const AuthenticationSteps: FC<Props> = ({altStep}) => {

  const [authState, setAuthState] = useState(false)

  const changeClass = () => {
    const scrollvalue = document.documentElement.scrollTop
    if (scrollvalue > 200) {
      setAuthState(true)
    } else {
      setAuthState(false)
    }
  }

  window.addEventListener('scroll', changeClass)

  const steps: Step[] = [
    {label: 'ETAPA 1', link: toUrl(ACCOUNT_2_FACTOR, 1)},
    {label: 'ETAPA 2', link: toUrl(ACCOUNT_2_FACTOR, 2)},
    {label: 'ETAPA 3', link: toUrl(ACCOUNT_2_FACTOR, 3)},
    {label: 'ETAPA 4', link: ''},
  ]

  return (
    <>
      <PageTitle title={`AUTENTICAÇÃO DE DOIS FATORES`} />
      <div className={`${authState ? authStyle.fixed : authStyle.sticky} d-flex justify-content-center mt-2 col-12`}>
        {steps.map((step, index) => (
          <AuthenticationStep
            key={index}
            label={step.label}
            active={altStep === index + 1}
            checked={altStep > index}
            link={step.link}
          />
        ))}
      </div>
    </>
  )
}

export default AuthenticationSteps
