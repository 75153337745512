import { SalesOrder } from 'models/SalesOrder';
import { FC, useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { formatDate, toBRL } from 'utils/Functions';
import { INVOICE_PAGE, ORDER_PAGE, RECEIPT_PAGE, TRACKING_PAGE, toUrl } from 'utils/Links';
import orderItemStyle from './OrderItem.module.css';

type Props = {
    order: SalesOrder,
    open?: boolean,
}

const OrderItem: FC<Props> = ({ order, open = false }) => {

    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(open)

    const toggleVisibility = () => {
        setIsOpen(!isOpen)
    }

    const handleResumeBtnClick = () => {
        navigate(toUrl(ORDER_PAGE, order.id))
    }

    const handleReceiptClick = () => {
        if (!order.invoice) return
        navigate(toUrl(RECEIPT_PAGE, order.id))
    }

    const handleTrackingClick = () => {
        navigate(toUrl(TRACKING_PAGE, order.id))
    }

    const handleBillClick = () => {
        if (!order.invoice) return
        navigate(toUrl(INVOICE_PAGE, order.id))
    }

    return <>
        {isOpen && (<div
            className={`${orderItemStyle.orderBubble} d-flex flex-column justify-content-between align-items-center p-3 my-3`}>
            <div
                className={`${orderItemStyle.orderBubbleSpans} d-flex flex-column justify-content-between col-12`}
                onClick={toggleVisibility}>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex justify-content-center mb-3'>
                        Detalhe do pedido
                    </div>
                    <div className='d-flex justify-content-end'>
                        <MdOutlineKeyboardArrowUp style={{ marginTop: '6px' }} />
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <span>#{order.number}</span>
                    <span>{order.registration_date && formatDate(order.registration_date)}</span>
                    <span className={`${orderItemStyle.orderBubbleStatus} ${orderItemStyle['bubbleStatus' + order.status.value]}`}>
                        {order.status.description}
                    </span>
                    <span>{toBRL(order.total_price)}</span>
                </div>
            </div>
            <div className={`${orderItemStyle.orderIcons} d-flex justify-content-around col-12`}>
                <div
                    className={orderItemStyle.orderIconsButtons}
                    onClick={handleResumeBtnClick}>
                    <img
                        src={process.env.PUBLIC_URL + '/media/general/List-Icon.svg'}
                        alt='list'
                        className='mb-1' />
                    Resumo
                </div>

                <div
                    className={`${orderItemStyle.orderIconsButtons} ${order.invoice ? 'main-color' : 'faded-color'}`}
                    onClick={handleReceiptClick}>
                    <img
                        src={process.env.PUBLIC_URL + '/media/general/Scroll-Icon.svg'}
                        alt='scroll'
                        className={order.invoice ? `main-filter mb-1` : `faded-filter mb-1`} />
                    Nota Fiscal
                </div>
                <div
                    className={orderItemStyle.orderIconsButtons}
                    onClick={handleTrackingClick}>
                    <img
                        src={process.env.PUBLIC_URL + '/media/general/Map-Icon.svg'}
                        alt='map'
                        className='mb-1' />
                    Rastreio
                </div>
                <div
                    className={`${orderItemStyle.orderIconsButtons} ${order.invoice ? 'main-color' : 'faded-color'}`}
                    onClick={handleBillClick}>
                    <img
                        src={process.env.PUBLIC_URL + '/media/general/Bill-Icon.svg'}
                        alt='bill'
                        className={order.invoice ? `main-filter mb-1` : `faded-filter mb-1`} />
                    Fatura
                </div>
            </div>
        </div>)}

        {!isOpen && (<div
            className={`${orderItemStyle.orderBubble} d-flex flex-row justify-content-between align-items-center p-3 my-3`}
            onClick={toggleVisibility}>
            <span>#{order.number}</span>
            <span>
                {order.registration_date && formatDate(order.registration_date)}
            </span>
            <span className={`${orderItemStyle.orderBubbleStatus} ${orderItemStyle['bubbleStatus' + order.status.value]}`}>
                {order.status.description}
            </span>
            <span>{toBRL(order.total_price)}</span>
            <MdOutlineKeyboardArrowDown />
        </div>)}
    </>;
}

export default OrderItem;
