/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import { useSplashScreen } from "components/common/SplashScreen/SplashScreenProvider";
import { Business, initialBusiness } from "models/Business";
import {
  FooterConfig,
  HeaderConfig,
  ProductCardType,
  StoreConfig,
  TopbarConfig,
  initialStoreConfig,
} from "models/StoreConfig";
import { FC, PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { LOCAL_STORAGE_STORE_CONFIG_KEY } from "utils/Consts";
import { LayoutSetup } from "./LayoutSetup";
import { useStoreLocation } from "./StoreLocationProvider";
import { getBusinessDetails } from "./_requests";

type StoreConfigContextModel = {
  storeConfig: StoreConfig;
  updateStoreConfig: (storeConfig: Partial<StoreConfig>) => void;
  updateHeaderConfig: (headerConfig: Partial<HeaderConfig>) => void;
  updateTopbarConfig: (topbarConfig: Partial<TopbarConfig>) => void;
  updateFooterConfig: (footerConfig: Partial<FooterConfig>) => void;
  setProductCardType: (productCardType: ProductCardType) => void;
  storeCartVisibility: boolean;
  setStoreCartVisibility: (storeCartVisibility: boolean) => void;
  isLayoutReady: boolean;
  business: Business;
  updateBusiness: (business: Business) => void;
};

const initialStoreConfigContext: StoreConfigContextModel = {
  storeConfig: initialStoreConfig,
  updateStoreConfig: () => {},
  updateHeaderConfig: () => {},
  updateTopbarConfig: () => {},
  updateFooterConfig: () => {},
  setProductCardType: () => {},
  storeCartVisibility: false,
  setStoreCartVisibility: () => {},
  isLayoutReady: false,
  business: initialBusiness,
  updateBusiness: () => {}
};

const StoreConfigContext = createContext(initialStoreConfigContext);

const StoreConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const [storeConfigLS, setStoreConfigLS] = useLocalStorage(LOCAL_STORAGE_STORE_CONFIG_KEY, initialStoreConfig);
  const [storeConfig, setStoreConfig] = useState(storeConfigLS);
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const storeConfigTimerRef = useRef<NodeJS.Timeout>();
  const [storeCartVisibility, setStoreCartVisibility] = useState<boolean>(false);
  const { hide } = useSplashScreen();
  const [business, setBusiness] = useState<Business>(initialBusiness);

  useEffect(() => {
    if (!storeConfig) return;
    LayoutSetup.initLayout(storeConfig.layout);
    setStoreConfigLS(storeConfig);
    setIsLayoutReady(true);
    hide();
  }, [storeConfig]);

  // useEffect(() => {
  //   storeConfigTimerRef.current = setTimeout(() => {
  //     setStoreConfigLS(storeConfig);
  //   }, 5000);

  //   return () => {
  //     clearTimeout(storeConfigTimerRef.current);
  //   };
  // }, [storeConfig, setStoreConfigLS]);

  const updateBusiness = (business: Business) => {
    setBusiness(business);
  }

  const updateStoreConfig = (partialConfig: Partial<StoreConfig>) => {
    setStoreConfig((prev) => {
      return {
        ...prev,
        ...partialConfig,
      };
    });
  };

  const updateHeaderConfig = (partialConfig: Partial<HeaderConfig>) => {
    setStoreConfig((prev) => {
      return {
        ...prev,
        layout: {
          ...prev.layout,
          header: {
            ...prev.layout.header,
            ...partialConfig,
          },
        },
      };
    });
  };

  const updateTopbarConfig = (partialConfig: Partial<TopbarConfig>) => {
    setStoreConfig((prev) => {
      return {
        ...prev,
        layout: {
          ...prev.layout,
          topbar: {
            ...prev.layout.topbar,
            ...partialConfig,
          },
        },
      };
    });
  };

  const updateFooterConfig = (partialConfig: Partial<FooterConfig>) => {
    setStoreConfig((prev) => {
      return {
        ...prev,
        layout: {
          ...prev.layout,
          footer: {
            ...prev.layout.footer,
            ...partialConfig,
          },
        },
      };
    });
  };

  const setProductCardType = (productCardType: ProductCardType) => {
    updateStoreConfig({
      content: {
        ...storeConfig.content,
        productCardType: productCardType,
      },
    });
  };

  const value = {
    storeConfig,
    updateStoreConfig,
    updateHeaderConfig,
    updateTopbarConfig,
    updateFooterConfig,
    setProductCardType,
    storeCartVisibility,
    setStoreCartVisibility,
    isLayoutReady,
    business,
    updateBusiness
  };

  return <StoreConfigContext.Provider value={value}>{children}</StoreConfigContext.Provider>;
};

export function useStoreConfig() {
  return useContext(StoreConfigContext);
}

export const StoreConfigInit : FC = () => {

  const {storeLocation} = useStoreLocation();
  const {updateBusiness} = useStoreConfig();

  useQuery({
    queryFn: async () => {
      const response = await getBusinessDetails();
      updateBusiness(response.data);
      return response.data;
    },
    queryKey: ['business-details', storeLocation?.region.id],
    enabled: !!storeLocation?.region.id,
  })

  return null
}

export { StoreConfigContext, StoreConfigProvider };
