import { Address } from "models/Address";
import { Attachment } from "models/Attachment";
import { GroceryPersonOfContact } from "models/PersonOfContact";
import { Region } from "models/Region";
import { EnumType } from "modules/api/core/_models";

export type AuthModel = {
  token: string;
  refresh_token: string;
  type: string;
  roles: string[];
  regions: Region[];
};

export const initialAuthModel: AuthModel = {
  token: "",
  refresh_token: "",
  type: "",
  roles: [],
  regions: [],
};

export type LoginRequestModel = {
  username: string;
  password: string;
  token?: string;
  email?: string;
  cnpj?: string;
  remember?: boolean;
};

export type passwordRequestModel = {
  password: string;
};

export const initialLoginRequestModel: LoginRequestModel = {
  username: "",
  password: "",
  token: "",
  email: "",
  cnpj: "",
  remember: false,
};

export type SignUpModel = {
  cnpj: string;
  cel: string;
  acceptCel?: boolean;
  email: string;
  acceptEmail?: boolean;
};

export const initialSignUpModel: SignUpModel = {
  cnpj: "",
  cel: "",
  acceptCel: false,
  email: "",
  acceptEmail: false,
};

export interface UserRole {
  name: string;
  privileges: string[];
}

export interface GroceryPreference {
  accepted_payment_methods?: EnumType[];
}

export interface GroceryDetails {
  id?: string;
  name?: string;
  company_name?: string;
  email?: string;
  rg?: string;
  cpf?: string;
  cnpj?: string;
  person_type?: EnumType;
  billing_email?: string;
  active: boolean;
  logo?: Attachment;
  address?: Address;
  charge_address?: Address;
  state_registration?: string;
  municipal_registration?: string;
  telephone_number?: string;
  cellphone_number?: string;
  business_telephone_number?: string;
  segment?: EnumType;
  credit_limit?: number;
  region_key?: string;
  contact_peoples?: GroceryPersonOfContact[];
  // main_contact?: GroceryPersonOfContact;
  // secundary_contact?: GroceryPersonOfContact;
}

export interface AccountDetails {
  id: string;
  avatar?: Attachment;
  name?: string;
  email?: string;
  phone?: string;
  regions?: string[];
  //   trade_name?: string
  //   company_name?: string
  //   email?: string
  //   rg?: string
  //   cpf?: string
  //   cnpj?: string
  //   person_type?: string
  //   billing_email?: string
  //   active: boolean
  //   logo?: Attachment
  //   address?: Address
  // password: string | undefined
  // email: string
  // first_name: string
  // last_name: string
  // fullname?: string
  // occupation?: string
  // companyName?: string
  // phone?: string
  // roles?: Array<number>
  // pic?: string
  // language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  // timeZone?: string
  // website?: 'https://keenthemes.com'
  // emailSettings?: UserEmailSettingsModel
  // auth?: AuthModel
  // communication?: UserCommunicationModel
  // address?: UserAddressModel
  // socialNetworks?: UserSocialNetworksModel
}

export const initialGroceryDetailsValues: GroceryDetails = {
  active: true,
  email: "",
  cpf: "",
  cnpj: "",
  billing_email: "",
  telephone_number: "",
  cellphone_number: "",
  business_telephone_number: "",
  address: {
    cep: "",
    city: "",
    neighborhood: "",
    street: "",
    number: 0,
    complement: "",
  },
  // main_contact: {
  //   name: "",
  //   telephone_number: "",
  //   email: "",
  //   active: true,
  //   grocery_contact_type: {
  //     value: "1",
  //     description: "Dono",
  //   },
  //   is_main_contact: true,
  // },
  // secundary_contact: {
  //   name: "",
  //   active: true,
  //   telephone_number: "",
  //   email: "",
  //   grocery_contact_type: {
  //     value: "4",
  //     description: "Funcionário",
  //   },
  //   is_main_contact: false,
  // },
};
