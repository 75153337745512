import PageTitle from 'components/common/PageTitle/PageTitle'
import {useEffect, useRef, useState} from 'react'
import {HiOutlinePencilAlt} from 'react-icons/hi'
import {IoTrashOutline} from 'react-icons/io5'
import {MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp} from 'react-icons/md'
import {useNavigate, useParams} from 'react-router-dom'
import Sheet, { SheetRef } from 'react-modal-sheet';
import 'react-spring-bottom-sheet/dist/style.css'
import fakeData from 'resources/data/fake-data'
import creditPage from './CreditCardPage.module.css'
import { ACCOUNT_CARDS_ADD_PAGE, toUrl } from 'utils/Links'
import { toAbsoluteUrl } from 'utils/Functions'
import { toast } from 'react-toastify'

function CreditCardPage() {
  const [openCard, setOpenCard] = useState(true)
  const [selected, setSelected] = useState(null)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [isModalNicknameOpen, setIsModalNicknameOpen] = useState(false)
  const [isModalAddCardOpen, setIsModalAddCardOpen] = useState(false)
  const [selectedCard, setSelectedCard] = useState<any>(null)
  const {cards} = fakeData
  const navigate = useNavigate()
  const [filteredCards, setFilteredCards] = useState(cards)
  const ref = useRef<SheetRef>();

  let {card = '', cardChild = ''} = useParams()

  useEffect(() => {
    card = cardChild ? cardChild : card

    if (card) {
      const filteredByCard = cards.filter((item) => {
        return item.slug_slug.includes(card)
      })
      console.log(filteredByCard)
      setFilteredCards(filteredByCard)
    }
  }, [card, cardChild])

  const toggle = (index: any) => {
    if (selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }

  function deleteSelectedCard() {
    const newList = filteredCards.filter((card) => card.id !== selectedCard?.id)
    setFilteredCards(newList)
    setIsModalDeleteOpen(false)
    toast.error('Cartão excluído', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      });
  }

  function changeCardNickname() {
    onDismiss()
    toast.success('Apelido atualizado', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }

  function showModalDelete(card:any) {
    setIsModalDeleteOpen(true)
    setSelectedCard(card)
  }

  function showNicknameOpen(card:any) {
    setIsModalNicknameOpen(true)
    setSelectedCard(card)
  }

  function showAddCardOpen() {
    setIsModalAddCardOpen(true)
  }

  function onDismiss() {
    setIsModalDeleteOpen(false)
    setIsModalNicknameOpen(false)
    setIsModalAddCardOpen(false)
  }

  return (
    <>
      <PageTitle title={`MEUS CARTÕES`} />

      <div className='padding-bottom-mobile'>
        {filteredCards.length >= 1 &&
          <>
          {filteredCards.map((card, index) => (
            <div key={index} className='container'>
              <div>
                {/* BEGIN OPEN CARD */}
                {selected === index ? (
                  <div
                    className={`${creditPage.orderBubble} d-flex flex-column justify-content-between align-items-center my-3`}
                  >
                    <div
                      className={`${creditPage.orderBubbleSpans} d-flex flex-column justify-content-between col-12 px-3 py-3`}
                    >
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex flex-column'>
                          <span style={{textTransform: 'uppercase'}}>{card.issuing_network}</span>
                          <span>{`"${card.nickname}"`}</span>
                          <span>{card.name}</span>
                        </div>

                        <div className={creditPage.cardPencilIcon}>
                          <div className='d-flex justify-content-end black-color'>
                            {selected === index ? (
                              <MdOutlineKeyboardArrowUp onClick={() => toggle(index)} />
                            ) : (
                              <MdOutlineKeyboardArrowDown />
                            )}{' '}
                          </div>
                          <HiOutlinePencilAlt onClick={() => showNicknameOpen(card)} />
                        </div>
                      </div>
                      <div className='d-flex flex-row justify-content-between align-items-center'>
                        <span className={creditPage.cardThinInfo}>
                          Final - {card.card_number.slice(-4)}
                        </span>
                        <span
                          className={`${creditPage.orderBubbleCard} ${
                            creditPage['bubbleStatus' + card.status]
                          }`}
                        >
                          {card.status}
                        </span>
                        <span className={creditPage.cardThinInfo}>VENC. - {card.exp_date}</span>
                      </div>
                    </div>

                    <div
                      className={`${creditPage.cardRemoveButton} d-flex flex-direction-row align-item-center justify-content-center col-12 py-2`}
                      onClick={() => showModalDelete(card)}
                    >
                      <span className='d-flex align-items-center'>
                        {' '}
                        Remover Cartão <IoTrashOutline className='font12rem' />
                      </span>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${creditPage.orderBubble} ${creditPage.bubbleClosed} d-flex flex-row justify-content-between align-items-center p-3 my-3`}
                    onClick={() => toggle(index)}
                  >
                    <span className={`${creditPage.cardBoldCapital} col-3`}>
                      {card.issuing_network}
                    </span>
                    <span
                      className={`${creditPage.orderBubbleCard} ${
                        creditPage['bubbleStatus' + card.status]
                      } col-3 d-flex justify-content-center`}
                    >
                      {card.status}
                    </span>
                    <span className='col-3'>FINAL - {card.card_number.slice(-4)}</span>
                    <MdOutlineKeyboardArrowDown className='col-1 font15rem' />
                  </div>
                )}
              </div>
            </div>
          ))}
          <div className={creditPage.addButton} onClick={() => navigate(toUrl(ACCOUNT_CARDS_ADD_PAGE))}>
            {/* PLACEHOLDER FOR ADDITION TO CARDS INTO DB */}
            <div className='btn-main'>Adicionar cartão +</div>{' '}
          </div>
          </>
        }
        {filteredCards.length < 1 &&
          <>
            <div className='d-flex flex-column align-items-center mb-5 mt-3'>
              <div className='d-flex flex-column align-items-center'>
                <img src={process.env.PUBLIC_URL + '/media/menuPage/Big-Cards.svg'} alt="card" />
                <p className='font500 mb-2'>Você ainda não tem nenhum cartão adicionado...</p>
                <p className='text-center font300 font09rem'>
                  Clique no botão abaixo para realizar o cadastro dos seus cartões
                </p>
              </div>

            </div>
          
            <div className={creditPage.addButton} onClick={() => navigate(toUrl(ACCOUNT_CARDS_ADD_PAGE))}>
              {' '}
              {/* PLACEHOLDER TO ADD CARD DATA */}
              Adicionar cartão +
            </div>
          </>
        }
        
      </div>

      <Sheet
        ref={ref}
        isOpen={isModalDeleteOpen}
        onClose={onDismiss}
        initialSnap={0}
        snapPoints={[-50, 100, 50]}
        detent="content-height"
      >
          <Sheet.Container>
              <Sheet.Scroller>
                <Sheet.Content>  
                  <div className={`d-flex align-items-center justify-content-around px-3 py-5`}>
                    <div className='d-flex align-items-center'>
                      <img src={toAbsoluteUrl('/media/general/X-Sign.svg')} alt='' className='me-1'/>
                      <span className='font500 font12rem'>Deseja excluir o cartão?</span>
                    </div>
                  
                  <div>
                      <button
                        tabIndex={-1}
                        className={`${creditPage.creditCardDeletionExclude} ${creditPage.deletionNo} me-3`}
                        onClick={onDismiss}>
                        Não
                      </button>
                      <button
                        tabIndex={-1}
                        className={`${creditPage.creditCardDeletionExclude} ${creditPage.deletionYes}`}
                        onClick={deleteSelectedCard}>
                        Sim
                      </button>
                  </div>

                  </div>
                  </Sheet.Content>
              </Sheet.Scroller>  
          </Sheet.Container>
        <Sheet.Backdrop onTap={onDismiss}/>
      </Sheet>

      <Sheet
        ref={ref}
        isOpen={isModalNicknameOpen}
        onClose={onDismiss}
        initialSnap={0}
        snapPoints={[-50, 100, 50]}
        className={creditPage.bottomsheet}
        detent="content-height"
      >
          <Sheet.Container>
              <Sheet.Scroller>
                <Sheet.Content>    
                    <div className={`${creditPage.changeNicknameModal} d-flex flex-column align-items-center container pt-3`}>
                      <div className={creditPage.changeNicknameTitle}>
                        <span>Editar apelido do cartão</span>
                        <img src={'/media/general/X-icon.svg'} alt='' onClick={onDismiss}/>
                      </div>
                      <div className='d-flex flex-column align-items-start align-content-start px-4 w-100'>
                        <span className={creditPage.nicknameNewTitle}> Novo apelido </span>
                        <input type='text' className={creditPage.changeNicknameInput}></input>
                      </div>
                    </div>
                    <div className='pt-4'>
                      <button
                        className={`${creditPage.creditCardDeletion} ${creditPage.deletionSave}`}
                        onClick={changeCardNickname}>
                        Salvar
                      </button>
                    </div>
                    </Sheet.Content>
              </Sheet.Scroller>  
          </Sheet.Container>
        <Sheet.Backdrop onTap={onDismiss}/>
      </Sheet>

      <Sheet
        ref={ref}
        isOpen={isModalAddCardOpen}
        onClose={onDismiss}
        initialSnap={0}
        snapPoints={[-50, 100, 50]}
        className={creditPage.bottomsheet}
        detent="content-height"
      >
          <Sheet.Container>
              <Sheet.Scroller>
                <Sheet.Content>  
                  <form className='p-3 container'>
                    <div className='mb-3 row'>
                      <div className='col-12 d-flex flex-column mb-3'>
                        <label htmlFor='exampleInputEmail1' className={creditPage.formLabel}>
                          Número do cartão
                        </label>
                        <input
                          type='email'
                          className={creditPage.formControl}
                          id='exampleInputEmail1'
                          aria-describedby='emailHelp'
                          placeholder='1234 5678 9012 3456 '
                        />
                      </div>
                      <div className='col-12 d-flex flex-column '>
                        <label htmlFor='exampleInputEmail1' className={creditPage.formLabel}>
                          Nome do(a) titular
                        </label>
                        <input
                          type='email'
                          className={creditPage.formControl}
                          id='exampleInputEmail1'
                          aria-describedby='emailHelp'
                          placeholder='Nome no cartão'
                        />
                      </div>
                    </div>

                    <div className='mb-3 d-flex justify-content-between'>
                      <div className='d-flex flex-column'>
                        <label htmlFor='exampleInputPassword1' className={creditPage.formLabel}>
                          Mês/Ano
                        </label>
                        <input
                          type='password'
                          className={creditPage.formControl}
                          id='exampleInputPassword1'
                          placeholder='MM/AA'
                        />
                      </div>

                      <div className='d-flex flex-column'>
                        <label htmlFor='exampleInputPassword1' className={creditPage.formLabel}>
                          CVV
                        </label>
                        <input
                          type='password'
                          className={creditPage.formControl}
                          id='exampleInputPassword1'
                          placeholder='3 digitos'
                        />
                      </div>
                    </div>

                    <div className='col-12 d-flex flex-row'>
                      <div className=''>
                        <label htmlFor='exampleInputAdress' className={creditPage.formLabel}>
                          Endereço de cobrança
                        </label>
                        <input
                          type='email'
                          className={`${creditPage.formControl} col-11`}
                          id='exampleInputPassword1'
                          placeholder='Nome da rua'
                        />
                      </div>
                      <div className='d-flex flex-column'>
                        <label htmlFor='exampleInputAdress' className={creditPage.formLabel}>
                          Número
                        </label>
                        <input
                          type='email'
                          className={`${creditPage.formControl} col-6`}
                          id='exampleInputPassword1'
                          placeholder='#'
                        />
                      </div>
                    </div>

                    <div className='my-3 form-check d-flex aling-item-center justify-content-center'>
                      <div className='button btn-main'>Adicionar cartão +</div>
                    </div>
                  </form>
                  </Sheet.Content>
              </Sheet.Scroller>  
          </Sheet.Container>
        <Sheet.Backdrop onTap={onDismiss}/>
      </Sheet>
    </>
  )
}

export default CreditCardPage
