import clsx from 'clsx'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from 'utils/Functions'
import styles from './CheckoutStep.module.css'

type Props = {
    active: boolean
    checked: boolean
    label: string
    link?: string
    icon: string
    beforeFunction?: () => boolean
    afterFunction?: () => void
    replace?: boolean
}

const CheckoutStep: FC<Props> = ({ checked, active, label, link, icon, beforeFunction, afterFunction, replace = false }) => {
    const navigate = useNavigate()

    const handleLinkClick = () => {
        if (beforeFunction) {
            if (!beforeFunction()) {
                return
            }
        }
        if (link) {
            navigate(link, { replace: replace })
        }
        if (afterFunction) {
            afterFunction()
        }
    }

    return (
        <div className='float-right col-4' onClick={handleLinkClick}>

            <div className={clsx(
                'd-flex flex-column align-items-center',
                { [styles.arrowBox]: active },
                { [styles.arrowBox]: checked },
                { [styles.arrowBoxInactive]: !active },
                { [styles.hasLink]: !!link },
            )}>
                <div className={clsx({ 'main-filter': checked })}>
                    <img src={toAbsoluteUrl(icon)} alt='Caixa' height={'18px'} />
                </div>
                <div className={clsx(styles.step, { [styles.activeStep]: checked }, { [styles.inactiveStep]: !active })}>
                    {label}
                </div>
            </div>
        </div>
    )
}

export default CheckoutStep
