import { useQuery } from '@tanstack/react-query';
import PageTitle from 'components/common/PageTitle/PageTitle'
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_SUGGESTED_WHISHLIST_DETAIL_PAGE, STORE_FRONT_PAGE, toUrl } from 'utils/Links';
import { Wishlist } from 'models/Wishlist';
import Image from 'components/common/Image/Image';
import { list } from './core/_requests';
import listStyle from './SuggestedList.module.css'
import { KTSVG } from 'components/common/KTSVG';
import { toAbsoluteUrl } from 'utils/Functions';
import { useRef } from 'react';

function SuggestedListPage() {
    const navigate = useNavigate();
    const videoRef = useRef<HTMLVideoElement>(null);
    const { data: wishlists, isLoading: isWishlistLoading } = useQuery({
      queryFn: async () => {
        const response = await list();
        return response.data;
      },
      queryKey: ["wishlists"],
    });
  
  
    const handleListClick = (list: Wishlist) => {
      navigate(toUrl(ACCOUNT_SUGGESTED_WHISHLIST_DETAIL_PAGE, list.id));
    };

  return (
    <>
        <PageTitle title={`LISTAS SUGERIDAS`} />
        <div className='container'>
          {wishlists && wishlists.length > 0 && (
            <div className='mt-3'>
              {wishlists.map((wishlist:Wishlist, idx:any) => (
                <div key={idx} className={listStyle.listContainer}>
                  <div className='d-flex'>
                    <Image
                      src={wishlist.image}
                      alt={wishlist.name}
                      fallbackSrc='https://fakeimg.pl/72x72'
                      className={listStyle.listImage}
                    />
                    <div className='d-flex flex-column justify-content-center ps-3'>
                      <span className='font500 font11rem'>{wishlist.name}</span>
                      <span>{wishlist.items.length} produtos</span>
                    </div>
                  </div>
                  <div className={listStyle.proceedButton} onClick={() => handleListClick(wishlist)}>
                    <span>Ver lista</span>
                    <img src={'/media/ChevronsAndArrows/Arrow-Right.svg'} alt='' className='main-filter ms-2'/>
                  </div>
                </div>
              ))}
            </div>
          )}

          {isWishlistLoading &&
            <div className='d-flex flex-column align-items-center'>
                <video ref={videoRef} autoPlay loop muted>
                    <source src={toAbsoluteUrl('/media/Animations/Animação-Carrinho.webm')} type="video/webm" />
                </video>
                    <span className='main-color font500 font12rem mt-4'>Carregando...</span>
            </div>
          }

          {(!wishlists || wishlists.length === 0) && !isWishlistLoading && (
            <div className="d-flex justify-content-center mt-4">
              <div className={`${listStyle.emptyPage}  w-100`}>
                <div className="d-flex flex-column align-items-center">
                  <KTSVG path="/media/general/List-Drawing.svg" className="pb-5" />
                  <span className="pb-3 main-color font500 font12rem">Ainda nao temos nenhuma sugestao para voce...</span>
                  <span className="pb-3 main-color font500 font12rem">
                    Mas é bem fácil criar uma! Basta seguir o passo a passo abaixo:
                  </span>
                  <ol className={listStyle.list}>
                    <li>Escolha qualquer um dos produtos disponíveis para criar a sua primeira lista</li>
                    <li>
                      Clique neste ícone{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/media/general/Small-List-Outline-Icon.svg"}
                        alt=""
                        className={listStyle.listIcon}
                      />{" "}
                      para criar a lista de compras
                    </li>
                    <li>
                      Assim que o ícone mudar de cor e ficar preenchido{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/media/general/Small-List-Filled-Icon.svg"}
                        alt=""
                        className={listStyle.listIcon}
                      />{" "}
                      significa que a sua primeira lista foi criada com sucesso
                    </li>
                    <li>
                      Para conferir a lista que você acabou de criar, clique na opção “Listas” no canto superior da sua tela
                    </li>
                    <li>E pronto! Você já pode continuar fazendo suas compras ou até mesmo criar mais listas!</li>
                  </ol>

                  <div
                    className={`${listStyle.outlineButton} button my-3`}
                    onClick={() => navigate(toUrl(STORE_FRONT_PAGE))}
                  >
                    Adicione os primeiros itens +
                  </div>
                </div>
              </div>
            </div>
          )}  
        </div>
    </>
  )
}

export default SuggestedListPage