import { useQuery } from "@tanstack/react-query";
import AddToFavorites from "components/common/AddToFavorites/AddToFavorites";
import Image from "components/common/Image/Image";
import ProductBadge from "components/common/ProductBadge/ProductBadge";
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import { Product } from "models/Product";
import { EnumType } from "modules/api/core/_models";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { CgClose } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { ITEM_TYPE_BOX, ITEM_TYPE_UNIT } from "utils/Consts";
import { toAbsoluteUrl, toBRL } from "utils/Functions";
import { STORE_FRONT_SEARCH_TAG_PAGE, toUrl } from "utils/Links";
import BtnBox from "../BtnCard/BtnBox";
import BtnUnit from "../BtnCard/BtnUnit";
import ProductCounter from "../ProductCounter/ProductCounter";
import ProductModalSkeleton from "../Skeletons/ProductModalSkeleton";
import detailStyle from "./ProductDetailModal.module.css";
import { detail } from "./core/_requests";

type Props = {
  show: boolean;
  handleClose: () => void;
  id?: string;
};

function ProductDetailModal({ show, handleClose, id }: Props) {

  const navigate = useNavigate();
  const { productId } = useParams();
  const [product, setProduct] = useState<Product>();
  const { addItemCart, updateCartItem, removeItemCart } = useStoreCart();
  const initialItemType = useMemo(() => {
    if (!product || !product.supply_type) return ITEM_TYPE_UNIT;
    return product.supply_type.value === "3" ? ITEM_TYPE_UNIT : product.supply_type;
  }, [product]);
  const [itemType, setItemType] = useState<EnumType>(initialItemType);
  const quantity = useMemo(
    () => (itemType?.value === ITEM_TYPE_UNIT.value ? 1 : product?.quantity_per_package || 1),
    [itemType, product]
  );
  const itemId = useMemo(() => productId || id, [productId, id]);
  const isAddButtonDisabled = useMemo(() => {
    return !product || !product.supply_type || product.unavailable
  }, [product])

  const { isLoading: isProductLoading, error } = useQuery({
    queryFn: async () => {
      const response = await detail(itemId!!);
      setProduct(response.data);
      return response.data;
    },
    queryKey: ["productDetail", itemId],
    enabled: !!itemId,
  });

  const handleProductAdd = (product: Product) => {
    addItemCart(product, quantity);
  };

  const handleProductRemove = (product: Product) => {
    removeItemCart(product, 1);
  };

  const handleProductChange = (product: Product, quantity: number) => {
    updateCartItem(product, quantity);
  };

  useEffect(() => {
    if (!product?.supply_type || (!product.sell_price && !product.package_sell_price)) return;
    setItemType(
      product.supply_type.value === "3" || product.supply_type.value === "1" ? ITEM_TYPE_UNIT : ITEM_TYPE_BOX
    );
  }, [product]);

  return (
    <Modal size={`lg`} show={show} onHide={handleClose} className={detailStyle.modalRoot} centered>
      <div className={`${detailStyle.modalBodyItem}`}>
        {isProductLoading && <ProductModalSkeleton />}
        {!isProductLoading && error && <div className="text-center">{error.message}</div>}
        {!isProductLoading && !!product && !error && (
          <>
            <div className="d-flex justify-content-between mb-4">
              <span className="font500 font14rem ">Detalhes do produto</span>
              <div className={detailStyle.exitButton} onClick={handleClose}>
                <CgClose className="cursor-pointer" />
              </div>
            </div>

            <div className={detailStyle.productListModal}>
              <AddToFavorites product={product} quantity={quantity} />
            </div>

            <div className={`${detailStyle.itemBody} row mt-3 mb-3`}>
              <div className="col-lg-5 col-md-12 d-flex mt-2">
                <Image
                  src={product.thumbnail?.url}
                  alt={product.thumbnail?.title}
                  height={300}
                  width={300}
                  className={detailStyle.productCardImage}
                  placeholderColor="#ffffff"
                  fallbackSrc="https://fakeimg.pl/180x180/cccccc/033858?font=bebas"
                />
              </div>
              <div className={`${detailStyle.allInfoModal} col-lg-7 col-md-12 d-flex flex-column`}>
                <div className="d-flex justify-content-start ms-2">
                  {/* {product.price_list_product && (
                    <Link to={toUrl(STORE_FRONT_SEARCH_PRICELIST_PAGE, product.price_list_product.id)}>
                      <span className={detailStyle.discountModal}>{product.price_list_product.price_list.name}</span>
                    </Link>
                  )} */}
                  {product.tags &&
                    product.tags.map((tag, idx) => (
                      <div onClick={() => navigate(toUrl(STORE_FRONT_SEARCH_TAG_PAGE, tag.id))} key={idx}>
                        <ProductBadge label={tag.name} />
                      </div>
                    ))}
                </div>
                <div className={`${detailStyle.itemName} ms-2`}>{product?.description}</div>
                <div className={detailStyle.itemInfo}>
                  <div className={`${detailStyle.buttons} d-flex flex-column mt-2 ms-2 mb-2`}>
                    <div className="mb-2">
                    <BtnUnit
                      product={product}
                      loading={isProductLoading}
                      selected={itemType?.value === ITEM_TYPE_UNIT.value}
                      onClick={() => setItemType(ITEM_TYPE_UNIT)}
                    />
                    </div>
                    <BtnBox
                      product={product}
                      loading={isProductLoading}
                      selected={itemType?.value === ITEM_TYPE_BOX.value}
                      onClick={() => setItemType(ITEM_TYPE_BOX)}
                    />
                    <div className="d-flex justify-content-center mt-3">
                      {!isAddButtonDisabled && <ProductCounter
                        product={product}
                        loading={isProductLoading}
                        onAdd={handleProductAdd}
                        onRemove={handleProductRemove}
                        onChange={handleProductChange}
                        title="ADICIONAR AO CARRINHO"
                      />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {product.providers && product.providers.length > 0 && (
              <div className={`${detailStyle.comparisonTable} row`}>
                <span className="font12rem font500 mb-3"> Mais preços desse mesmo produto </span>
                {product.providers.map((productProvider, idx) => (
                  <div key={idx} className={detailStyle.marketValue}>
                    <div>
                      <img
                        src={toAbsoluteUrl("/media/ChevronsAndArrows/Chevron-Cheaper.svg")}
                        alt="chevron"
                        className="mb-1"
                      />
                      <span> {idx === 0 ? 'Vupty' : (productProvider.provider?.name || '--')} </span>
                    </div>
                    <span>
                      {toBRL(productProvider.sell_price)}/{product.measurement_unit}.
                    </span>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

export default ProductDetailModal;

// Chevron-Cheaper
