import { EnumType } from 'modules/api/core/_models'
import { Brand } from './Brand'
import { Category } from './Category'
import { Contact } from './Contact'
import { ItemImage } from './ItemImage'
import { PriceList } from './PriceList'

export type Product = {
    id: string
    description?: string
    category?: Category
    images: ItemImage[]
    thumbnail?: ItemImage
    registration_date: string
    modification_date: string
    active: boolean
    providers: ProductProvider[]
    on_demand?: boolean
    brand?: Brand
    sku?: string
    ncm?: string
    gtin_ean?: string
    gtin_ean_package?: string
    measurement_unit?: string
    package_measurement_unit?: string
    short_description?: string
    long_description?: string
    video_url?: string
    slug?: string
    keywords?: string
    seo_title?: string
    seo_description?: string
    tags?: ProductTag[]
    quantity_per_package?: number
    quantity_available?: number
    price_ranges?: ProductPriceRange[]
    price_list_product?: ProductPriceList
    supply_type: EnumType
    is_available_for_sale: boolean
    sell_price: number
    original_price: number
    package_unit_price?: number
    package_sell_price?: number
    is_wishlist_product?: boolean
    provision?: string
    unavailable?: boolean
}

export const determineIfIsProduct = (product: any): product is Product => {
    return product && product.id && product.description
}

export type ProductPriceRange = {
    id: string
    min_quantity: number
    max_quantity: number
    unit_price: number
}

export type ProductPriceList = {
    id: string
    price_list: PriceList
    price: number
}

export type ProductProvider = {
    id?: string
    sell_price: number
    promo_price: number
    package_unit_price: number
    quantity_per_package: number
    package_price: number
    price_ranges?: ProductPriceRange[]
    provider?: Contact
    quantity_available?: number
    tag?: string
    expiration_date?: string
    default_choice: boolean
}

export type ProductTag = {
    id: string
    name: string
    slug: string
    active: boolean
    registration_date: string
    modification_date: string
}

export type ProductBrand = {
    id: string;
    name: string;
}


export type ProductLayoutProps = {
    loading?: boolean;
    products?: Product[];
    title?: string;
}
