import { useMutation } from "@tanstack/react-query";
import PageTitle from "components/common/PageTitle/PageTitle";
import { useFormik } from "formik";
import { GroceryPersonOfContact, initialGrocerySecundaryContact } from "models/PersonOfContact";
import { ApiResponseError } from "modules/api/core/_models";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "utils/Functions";
import * as Yup from 'yup';
import contactAddStyle from './ProfileContactStyle.module.css';
import { createGroceryContact } from "./core/_requests";

const secondaryContactValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Digite o nome do contato'),
    grocery_contact_type: Yup.object()
        .required('Digite a função do contato'),
    telephone_number: Yup.string()
        .required('Digite um telefone valido')
        .test(
            'Digite um telefone valido',
            (value: any) => /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/g.test(value)
        ),
    email: Yup.string()
        .email('Digite um e-mail válido')
        .min(3, 'Minimo de 3 símbolos')
        .max(50, 'Máximo de 50 símbolos')
        .required('Digite um e-mail válido'),
})

function ProfileContactAddPage() {

    const navigate = useNavigate()
    const [contact, setContact] = useState<GroceryPersonOfContact>(initialGrocerySecundaryContact)

    const { mutate: sendCreateRequest } = useMutation({
        mutationFn: async ({ id, ...values }: GroceryPersonOfContact) => {
            const response = await createGroceryContact(values)
            return response.data
        },
        mutationKey: ['createGroceryContact'],
        onSuccess: () => {
            toast.success('Contato adiconado')
            navigate(-1)
        },
        onError: (error: any) => {
            if (error instanceof ApiResponseError) {
                toast.error(error.getErrorMessage())
            } else {
                toast.error('Erro ao adicionar novo contato')
            }
        }
    })

    const formik = useFormik({
        initialValues: contact,
        validationSchema: secondaryContactValidationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            sendCreateRequest(values)
        },
    })

    const handleTypeChange = (event: any) => {
        const updatedType = {
            value: event.target.value,
            label: event.target.options[event.target.selectedIndex].text
        }
        formik.setFieldValue('grocery_contact_type', updatedType)
    }

    return (
        <>
            <PageTitle title={`Novo contato adicional`} />
            {/* ADDING NEW CONTACT */}
            <div className='d-flex justify-content-center mb-4'>
                <div className={contactAddStyle.warningBubble}>
                    <img src={toAbsoluteUrl('/media/general/Alert-Icon.svg')} alt='' className='me-2' />
                    <span>Só é permitido adicionar <strong>até dois (2)</strong> contatos adicionais</span>
                </div>
            </div>
            <form
                onSubmit={formik.handleSubmit}
                noValidate
                id='contact_form'
                className='container'>
                <div className='mb-3'>
                    <label htmlFor='name' className='sr-only'>
                        Nome
                    </label>
                    <input
                        type='text'
                        className='form-control'
                        {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                        <span className='text-danger'>{formik.errors.name}</span>
                    )}
                </div>
                <div className="mb-3">
                    <label htmlFor="text" className="sr-only">
                        Função
                    </label>
                    <select className="form-control"
                        value={formik.values.grocery_contact_type.value}
                        onChange={handleTypeChange}>
                        <option value="2">Diretor</option>
                        <option value="3">Gerente</option>
                        <option value="4">Funcionário</option>
                        <option value="5">Outro</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="text" className="sr-only">
                        Telefone
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="(+55) 83 ***** ****"
                        {...formik.getFieldProps('telephone_number')}
                    />
                    {formik.touched.telephone_number && formik.errors.telephone_number && (
                        <span className="text-danger">{formik.errors.telephone_number}</span>
                    )}
                </div>

                <div className="mb-3">
                    <label htmlFor="text" className="sr-only">
                        E-mail
                    </label>
                    <input type="text" className="form-control" {...formik.getFieldProps('email')} />
                    {formik.touched.email && formik.errors.email && (
                        <span className="text-danger">{formik.errors.email}</span>
                    )}
                </div>
            </form>

            <div className={`${contactAddStyle.addButton}`} onClick={() => formik.handleSubmit()}>
                <span>
                    Salvar Informações
                    <img src={toAbsoluteUrl('/media/general/Check-Green-Icon.svg')} alt='' className={contactAddStyle.checkicon} />
                </span>
            </div>
        </>
    )
}

export default ProfileContactAddPage
