/* eslint-disable react-hooks/exhaustive-deps */
import { useStoreCart } from "layouts/common/core/StoreCartProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./core/AuthProvider";

export function Logout() {
  const { logout } = useAuth();
  const { clearCart } = useStoreCart();
  const navigate = useNavigate();

  useEffect(() => {
    logout(() => {
      clearCart();
      // navigate(toUrl(STORE_FRONT_PAGE))
    });
  }, []);

  return <></>;
}
