import { useQuery } from '@tanstack/react-query'
import PageTitle from 'components/common/PageTitle/PageTitle'
import { SalesOrder } from 'models/SalesOrder'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toAbsoluteUrl, toBRL } from 'utils/Functions'
import { RECEIPT_PRINT, TRACKING_PAGE, toUrl } from 'utils/Links'
import orderDetailStyle from './OrderDetailPage.module.css'
import { orderDetails } from './core/_requests'
import ClipLoader from 'react-spinners/ClipLoader'

function OrderDetailPage() {
    const { id : orderId } = useParams()
    const navigate = useNavigate()
    const [order, setOrder] = useState<SalesOrder>()


    const orderLabel = useMemo(() => {
        if (!order) return ''
        return '#' + order.number
    }, [order])

    const { isLoading: isOrderDetailLoading } = useQuery({
        queryFn: async () => {
            const response = await orderDetails(orderId!!)
            setOrder(response.data)
            return response
        },
        queryKey: ['order', orderId],
        enabled: !!orderId,
    })

    return (
        <>
            <PageTitle title={`RESUMO DO PEDIDO ${orderLabel}`} />
            {isOrderDetailLoading && 
                <div className='d-flex justify-content-center align-items-center'>
                    <ClipLoader color="#033858" size={20} />
                <span className='font500 ms-2'>Carregando...</span>
                </div>
            }

            {order && (
            <>
                <div className={`${orderDetailStyle.orderContainer} container`}>
                    <div className={orderDetailStyle.summary}>
                        <div className='d-flex justify-content-between'>
                            <span className='d-flex align-items-center'>Situação</span>
                            <span className={order.status.description === 'Entregue' ? orderDetailStyle.approved : orderDetailStyle.notApproved }>
                                {/* NEEDS TO CHANGE */}
                                {order.status.description}
                            </span>
                        </div>
                        <div className='d-flex justify-content-between mt-1'>
                            <span>Quantidade total de itens</span>
                            <span>{order.items_quantity}</span>
                        </div>
                        <div className='d-flex justify-content-between mt-1'>
                            <span>Quantidade total de produtos</span>
                            <span>{order.items_count}</span>
                        </div>
                        <div className='d-flex justify-content-between mt-1'>
                            <span>Pagamento via 
                                {/* {order.form_of_payment.payment_method?.description} */}
                            </span>
                            {/* NEEDS CORRECTION */}
                            {/* <span className={order.form_of_payment?.active ? orderDetailStyle.approved : orderDetailStyle.notApproved}>
                                {order.form_of_payment?.active && 'Aprovado'}
                                {!order.form_of_payment?.active && 'Analisando'}
                            </span> */}
                        </div>
                    </div>

                    {/* TABLES */}
                    
                    <div className={orderDetailStyle.tableContainer}>
                        <div className={orderDetailStyle.tableTitle}>
                            PRODUTOS
                        </div>
                        <div className='table-responsive'>
                            <table className={`${orderDetailStyle.tableStyle} table mb-0`}>
                            <thead>
                                <tr className='text-center'>
                                <th scope='col'>DESCRIÇÃO</th>
                                <th scope='col'>UNIDADE</th>
                                <th scope='col'>QTD</th>
                                <th scope='col'>TOTAL</th>
                                </tr>
                            </thead>
                            <colgroup>
                                <col style={{width:'45%'}}/>
                                <col style={{width:'20%'}}/>
                                <col style={{width:'15%'}}/>
                                <col style={{width:'20%'}}/>
                            </colgroup>
                            <tbody>
                                {order?.items?.map((item: any, idx: any) => (
                                <tr key={idx}>
                                    <td className={orderDetailStyle.productDescription}>
                                        <span className={orderDetailStyle.descriptionStyle}>{item.product.description}</span>
                                        {/* CHANGE TO BARCODE LATER */}
                                        <span className='font08rem font300'>{item.product.sku}</span>
                                    </td>
                                    <td className='text-center'>{toBRL(item.unit_price)}</td>
                                    <td className='text-center'>{item.quantity}</td>
                                    <td className='text-center'>{toBRL(item.total_price)}</td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>
                    </div>

                    {/* <div className='mt-4'>
                        <div className={orderDetailStyle.tableTitle}>
                            ITEMS NÃO ENTREGUES
                        </div>
                        <div className={orderDetailStyle.table}>
                            <table className="w-100">
                            <thead>
                                <tr className={orderDetailStyle.tdStyles}>
                                    <td className="ps-2">DESCRIÇÃO</td>
                                    <td>UNIDADE</td>
                                    <td>QTD</td>
                                    <td>TOTAL</td>
                                </tr>
                            </thead>
                            <tbody>
                            {order.products?.map((row : any) => (
                            <tr key={row.id} >
                                <td className={orderDetailStyle.itemDescription}>
                                    <div className="d-flex flex-column">
                                        <span>{row.description}</span>
                                        <span className="font08rem faded-color">{row.bar_code}</span>
                                    </div>
                                </td>
                                <td className={orderDetailStyle.tableContent}>{toBRL(row.unit_price)}</td>
                                <td className={orderDetailStyle.tableContent}>{row.quantity}</td>
                                <td className={orderDetailStyle.tableContent}>{toBRL((row.unit_price * row.quantity).toFixed(2))}</td>
                            </tr>
                            ))}
                            </tbody>
                            </table>
                        </div>
                    </div>  */}
                   

                    {/* SUMMARY */}
                    <div className='font600 mt-4'>
                      <div className='d-flex justify-content-between'>
                        <span className='font11rem'>SUBTOTAL (R$)</span>
                        <span>{order.total_price}</span>
                      </div>
                      {order.total_delivery_price > 0 &&
                        <div className='d-flex justify-content-between'>
                            <span>ENTREGA (R$)</span>
                            <span>{order.total_delivery_price}</span>
                        </div>
                      }
                      {/* DONATION NEEDS TO CHANGE */}
                      {order.store_cart.total_mission_discount > 0 &&
                        <div className='d-flex justify-content-between'>
                            <span>DOAÇÃO (R$)</span>
                            <span>{order.store_cart.total_mission_discount}</span>
                        </div>
                      }
                      {order.store_cart.total_mission_discount > 0 &&
                        <div className='d-flex justify-content-between'>
                            <span>DESCONTO (R$)</span>
                            <span className='cheaper-color'>
                                -{order.store_cart.total_mission_discount}
                            </span>
                        </div>
                      }
                    </div>
                </div>

                <div className={`${orderDetailStyle.buttons}`}>
                    <div className='d-flex px-2'>
                        <div className='col-6 d-flex justify-content-start'>
                            <div
                                className={`d-flex ${orderDetailStyle.summaryButton}`}
                                onClick={() => navigate(toUrl(TRACKING_PAGE, order.id))}
                            >
                                <span className=''>Acompanhar pedido</span>
                                <img src={toAbsoluteUrl('/media/general/Pin-Icon.svg')} alt='' className='transform-1px ms-2' />
                            </div>
                        </div>
                        <div className='col-6 d-flex justify-content-end' onClick={() => navigate(toUrl(RECEIPT_PRINT, order.id))}>
                            <div className={`d-flex align-items-center justify-content-center col-11 ${orderDetailStyle.summaryButton}`}>
                                <span className=''>Baixar nota fiscal</span>
                                <img src={toAbsoluteUrl('/media/general/Download-Icon.svg')} alt='' className='transform-2px ms-2' />
                            </div>
                        </div>
                    </div>
                    <div className={orderDetailStyle.totalValue}>
                        <span>TOTAL DA COMPRA</span>
                        <span>{toBRL(order.total_price)}</span>
                    </div>
                </div>
            </>
            )}

            {(!isOrderDetailLoading && !order) &&
                <div className='container'>
                    <div className='d-flex flex-column align-items-center mt-4'>
                        <img src={toAbsoluteUrl('/media/Illustrations/Doubt-Illustration.svg')} alt=''/>
                        <span className='mt-4 font500 font11rem'>Não encontramos o pedido desejado.</span>
                        <span>Por favor, tente a busca novamente.</span>
                    </div>
                </div>
            }
        </>
    )
}

export default OrderDetailPage
