import { FC, InputHTMLAttributes, useState } from 'react';
import inputStyle from './PasswordInput.module.css'
import { toAbsoluteUrl } from 'utils/Functions';
interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
    customStyle: 'desktop' | 'mobile';
  }

const PasswordInput: FC<PasswordInputProps> = ({customStyle, ...props}) => {
    const [passwordShown, setPasswordShown] = useState(false)

    return (<div className='d-flex position-relative'>
        <input
            type={passwordShown ? 'text' : 'password'}
            {...props} 
        />
        <img src={toAbsoluteUrl(passwordShown ? '/media/general/Eye-Opened.svg' : '/media/general/Eye-Closed.svg')}
            className={inputStyle[customStyle]} 
            onClick={() => setPasswordShown(!passwordShown)}
            alt='' 
        />
    </div>
    );
}

export default PasswordInput;
