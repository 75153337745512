import ErrorFooter from 'layouts/desktop/ErrorFooter/ErrorFooter';
import ErrorHeader from 'layouts/desktop/ErrorHeader/ErrorHeader'
import styled from 'styled-components'

const Bubble = styled.div`
    border: 1px solid #c0cdd5;
    border-radius: 16px;
    padding: 1.5rem;
    min-height: 225px;
`;

const Button = styled.a`
    background-color: #d9e1e6;
    border-radius: 8px;
    text-align: center;
    font-weight: 500;
    color: #033858;
    padding-inline: 0.5rem;
    padding-block: 0.4rem;
`;

function SupportPagePage() {
  return (
    <>
        <ErrorHeader/>

        <div className='container mt-5'>
            <div>
                <span className='font600 font15rem'>FALE CONOSCO</span>
            </div>

            <div>
                <p>Olá, Como vai?!</p>
                <p>
                Escolha uma das opções abaixo para dar início ao seu atendimento através do nosso suporte.
                Em seguida, você irá se comunicar com um de nossos atendentes, que irá prosseguir com a
                solução do seu problema.
                </p>
            </div>

            <div className='row'>
                <div className={`col-md-4 col-12 py-3`}>
                    <Bubble className='d-flex flex-column justify-content-center'>
                        <div className='d-flex flex-column align-items-center mb-3'>
                        <img
                            src={process.env.PUBLIC_URL + '/media/general/Whatsapp-Icon.svg'}
                            alt='img'
                            width='35px'
                            className='py-1'
                        />
                        <span className='font500'>WhatsApp</span>
                        </div>
                        <span className='d-flex justify-content-center mb-3 font500'>+55 (XX) XXX-XXX-XXX</span>
                        {/* NEED TO CHANGE PHONE NUMBER EVENTUALY */}
                        <Button
                            href={'https://wa.me/5583987458956?text=Hello+World'}
                            target='_blank'
                            rel='noreferrer'
                        >
                        Iniciar conversa no WhatsApp
                        </Button>
                    </Bubble>
                </div>

                <div className={`col-md-4 col-12 py-3`}>
                    <Bubble className='d-flex flex-column justify-content-center'>
                        <div className='d-flex flex-column align-items-center mb-3'>
                        <img
                            src={process.env.PUBLIC_URL + '/media/general/Mail-Icon.svg'}
                            alt='img'
                            width='35px'
                            className='py-1'
                        />
                        <span className='font500'>E-mail</span>
                        </div>
                        <span className='d-flex justify-content-center mb-3 font500'>contato@vupty.com.br</span>
                        <Button href='mailto:contato@vupty.com.br'>
                            Escrever e-mail
                        </Button>
                    </Bubble>
                </div>

                <div className={`col-md-4 col-12 py-3`}>
                    <Bubble className='d-flex flex-column justify-content-center'>
                        <div className='d-flex flex-column align-items-center mb-3'>
                            <img
                                src={process.env.PUBLIC_URL + '/media/general/UserCall-Icon.svg'}
                                alt='img'
                                width='35px'
                                className='py-1'
                            />
                            <span className='font500'>Telefone para contato</span>
                        </div>
                        <span className='d-flex justify-content-center mb-3 font500'>+55 (XX) XXX-XXX-XXX</span>
                        <div className='text-center px-3'>De Segunda a Sexta, das 8h00 às 18h00</div>
                    </Bubble>
                </div>
            </div>
        </div>

        <ErrorFooter/>
    </>
  )
}

export default SupportPagePage