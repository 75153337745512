import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import PageTitle from 'components/common/PageTitle/PageTitle'
import { Notification } from 'models/Notification'
import moment from 'moment'
import 'moment/locale/pt-br'

import { useMemo, useState } from 'react'
import { BiCheckDouble } from 'react-icons/bi'
import InfiniteScroll from 'react-infinite-scroller'
import { useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { toast } from 'react-toastify'
import notificationStyle from './NotificationPage.module.css'
import { list, markAllRead } from './core/_requests'
import Image from 'components/common/Image/Image'
import { toAbsoluteUrl } from 'utils/Functions'


function NotificationPage() {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [notifications, setNotifications] = useState<Notification[]>([])
    const [totalElements, setTotalElements] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalPages, setTotalPages] = useState(-1)
    const [isLoadingMore, setIsLoadingMore] = useState(false)

    const { isLoading: isNotificationLoading, isFetching: isNotificationFetching } = useQuery({
        queryFn: async () => {
            const response = await list(currentPage)
            setNotifications((prev) => {
                if (currentPage === 0) {
                    return response?.data.content
                }
                return [...prev, ...response?.data.content]
            })
            setTotalElements(response.data.total_elements)
            setTotalPages(response.data.total_pages)
            setIsLoadingMore(false)
            return response
        },
        queryKey: ['listNotification', currentPage],
        refetchOnWindowFocus: false,
    })

    const hasMoreContent = useMemo(() => {
        return totalPages <= 1 ? false : totalPages >= (currentPage + 1)
    }, [currentPage, totalPages])

    const onLoadMoreContent = (page: number) => {
        if (isNotificationLoading || currentPage >= totalPages - 1) return
        setCurrentPage((prev) => prev + 1)
        setIsLoadingMore(true)
    }

    const { mutate: sendMarkAllReadRequest } = useMutation({
        mutationFn: async () => {
            const response = await markAllRead()
            return response
        },
        mutationKey: ['markAllRead'],
        onSuccess: () => {
            toast.success('Notificações lidas com sucesso!')
            queryClient.invalidateQueries({
                queryKey: ['listNotification', currentPage],
            })
            navigate(-1)
        },
        onError: (error) => {
            toast.error('Erro ao marcar notificações como lidas!')
        }
    })

    const handleMarkAllReadClick = () => {
        sendMarkAllReadRequest()
    }

    return (
        <>
            <PageTitle title={`NOTIFICAÇÕES`} />

            <div className='container padding-bottom-mobile mt-2'>
                {(notifications && notifications.length > 0) && (
                    <InfiniteScroll
                        className='w-100'
                        pageStart={0}
                        initialLoad={false}
                        loadMore={onLoadMoreContent}
                        hasMore={hasMoreContent}
                        loader={<div className="loader d-flex justify-content-center" key={0}>
                            <ClipLoader color="#033858" size={18} /></div>}>

                        {notifications.map((notification, idx) => (
                            <div className='d-flex flex-row justify-content-between py-3 bottom-line' key={idx}>
                                <div className='d-flex align-items-center col-9'>
                                    <div className={`${notification.type} ${notificationStyle.dotSize}`}></div>
                                    <div className='d-flex flex-column'>
                                        <span style={{ fontWeight: '500' }}>{notification.title}</span>
                                        <span className='font09rem faded-color lh-1'>{notification.description}</span>
                                    </div>
                                </div>

                                <div className='col-3 d-flex align-items-center justify-content-center'>
                                    <span style={{ color: '#464646' }}>{moment(notification.registration_date).toNow()}</span>
                                </div>
                            </div>))}
                    </InfiniteScroll>)}
            </div>

            {(notifications && notifications.length > 0) && (
                <button
                    className={`col-12 d-flex justify-content-center align-items-center ${notificationStyle.notificationsBottom}`}
                    onClick={handleMarkAllReadClick}
                >
                    Marcar como lidas &nbsp; <BiCheckDouble style={{ fontSize: '1.5rem' }} />
                </button>
            )}

            {(!isNotificationLoading && !isNotificationFetching && notifications.length === 0) && (
                <div className='container noResult main-color d-flex flex-column align-items-center p-4'>
                  <div className='d-flex flex-column align-items-center'>
                    <Image
                      src={toAbsoluteUrl('/media/Illustrations/Notification-Illustration.svg')}
                      alt=''
                      fallbackSrc='https://fakeimg.pl/140x140/cccccc/033858'
                      className={notificationStyle.image}
                    />
                    <span className='main-color font500 font12rem text-center'>
                      Você não possui nenhuma notificação no momento.
                    </span>
                  </div>
                </div>
            )}

            {isNotificationFetching && 
                <div className='d-flex justify-content-center align-items-center'>
                    <ClipLoader color="#033858" size={20} />
                    <span className='font500 ms-2'>Carregando...</span>
                </div>
            }
        </>
    )
}

export default NotificationPage
