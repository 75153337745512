import { useQuery } from '@tanstack/react-query';
import PageTitle from 'components/common/PageTitle/PageTitle';
import { GroceryPersonOfContact } from 'models/PersonOfContact';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from 'utils/Functions';
import { ACCOUNT_PROFILE_REGISTRY_EDIT, ACCOUNT_PROFILE_REGISTRY_NEW, toUrl } from 'utils/Links';
import contactStyle from './ProfileContactStyle.module.css';
import { listSecundaryContacts } from './core/_requests';


function ProfileContactPage() {
    const [contacts, setContacts] = useState<GroceryPersonOfContact[]>();
    const navigate = useNavigate();

    const { isLoading: isContactsLoading } = useQuery({
        queryFn: async () => {
            const response = await listSecundaryContacts()
            setContacts(response.data)
            return response
        },
        queryKey: ['contacts'],
    })

    const handleContactEditClick = (contact: GroceryPersonOfContact) => {
        navigate(toUrl(ACCOUNT_PROFILE_REGISTRY_EDIT, contact.id))
    }

    return (
        <>
            <PageTitle title={`Contato adicional`} />
            <div>
                {isContactsLoading && <>Carregando...</>}
                {(!isContactsLoading && (!contacts || contacts?.length === 0)) &&
                    <>
                        <div className="container">
                            <div className={`${contactStyle.emptyValue}`}>
                                <img src={toAbsoluteUrl('/media/menuPage/Avatar-With-Stripes-Big.svg')} alt="" />
                                <span className="pt-3 main-color">
                                    Você ainda não cadastrou seu contato de suporte
                                </span>
                            </div>
                        </div>

                        <div className={`${contactStyle.addButton}`} onClick={() => navigate(toUrl(ACCOUNT_PROFILE_REGISTRY_NEW))}>
                            <span>Adicionar contato +</span>
                        </div>
                    </>
                }

                {(contacts && contacts?.length > 0) &&
                    <div className="container">
                        {contacts.map((contact, idx) => (
                            <div key={idx} className={contactStyle.additionalContactBubble}>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <img
                                            src={toAbsoluteUrl('/media/general/Avatar-Rounded.svg')}
                                            alt=""
                                            className="me-2"
                                        />
                                        <span className="main-color font500">{contact.name || '---'}</span>
                                    </div>
                                    <div onClick={() => handleContactEditClick(contact)}>
                                        <span className="font500 main-color">Editar</span>
                                        <img
                                            src={toAbsoluteUrl('/media/general/Edit-Filled-Icon.svg')}
                                            alt=""
                                            className={contactStyle.editIcon}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="d-flex flex-column main-color mt-3">
                                        <span className="font500">Função</span>
                                        <span>
                                            {contact.grocery_contact_type?.description || '---'}
                                        </span>
                                    </div>

                                    <div className="d-flex flex-column main-color mt-3">
                                        <span className="font500">Telefone</span>
                                        <span>{contact.telephone_number || '---'}</span>
                                    </div>

                                    <div className="d-flex flex-column main-color mt-3">
                                        <span className="font500">E-mail</span>
                                        <span>{contact.email || '---'} </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {contacts.length < 2 && (
                            <div className={contactStyle.addNewButton} onClick={() => navigate(toUrl(ACCOUNT_PROFILE_REGISTRY_NEW))}>
                                Adicionar mais um contato +
                            </div>
                        )}
                    </div>
                }
            </div>
        </>
    );
}

export default ProfileContactPage;
