import { useStoreCart } from 'layouts/common/core/StoreCartProvider'
import { Product } from 'models/Product'
import { ChangeEvent, useMemo } from 'react'
import ProductCounterSkeleton from '../Skeletons/ProductCounterSkeleton'
import styleCard from './ProductCounterCard.module.css'
import styleCart from './ProductCounterCart.module.css'

type Props = {
  product: Product
  loading?: boolean
  title?: string
  style?: string
  background?: string
  onAdd: (product: Product) => void
  onRemove: (product: Product) => void
  onChange: (product: Product, quantity: number) => void
}

function ProductCounter({product, loading = false, title = 'ADICIONAR', style = 'styleCard', background, onChange, onAdd, onRemove}: Props) {
  const {getItemCart} = useStoreCart()
  const cartItem = useMemo(() => {
      return getItemCart(product.id)
    },[getItemCart, product])
  const styles = style === 'styleCard' ? styleCard : styleCart

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Check if the value is a valid number using a regular expression
    const isValidNumber = /^-?\d+$/.test(value);

    let newQty = 1;
    if (isValidNumber) {
      newQty = parseInt(value, 10);
    }

    if (newQty <= 0){
      onRemove(product);
      return;
    }

    if (newQty !== cartItem?.quantity) {
      onChange(product, newQty);
    }
  };

  return (
    <>
      {loading && <ProductCounterSkeleton />}
      {!loading && !cartItem && (
        <button onClick={() => onAdd(product)} className={styles.addNew}>
          <div className={styles.addToCart}>
            <img src={process.env.PUBLIC_URL + '/media/general/Cart.svg'} alt='img' />
            <p>{title}</p>
          </div>
        </button>
      )}
      {!loading && cartItem && (
        <div className={styles.itemCardCounter} style={{backgroundColor: `${background}`}}>
          <button onClick={() => onRemove(product)} className={styles.remove}>
            -
          </button>
          <input type='number' min={1} className={styles.barCounter} value={cartItem?.quantity} onChange={handleQuantityChange} />
          <button onClick={() => onAdd(product)} className={styles.add}>
            +
          </button>
        </div>
      )}
    </>
  )
}

export default ProductCounter
