import { useStoreConfig } from 'layouts/common/core/StoreConfigProvider';
import { ProductLayoutProps } from 'models/Product';
import { ProductCardType } from 'models/StoreConfig';
import { FC } from 'react';
import ProductLayoutHorizontal from './ProductLayoutHorizontal';
import ProductLayoutVertical from './ProductLayoutVertical';

const layouts: Map<ProductCardType, FC<ProductLayoutProps>> = new Map([
    ["horizontal", ProductLayoutHorizontal],
    ["vertical", ProductLayoutVertical]
])

const ProductLayoutFactory: FC<ProductLayoutProps> = ({ products, loading, title }) => {
    const { storeConfig } = useStoreConfig()
    const CurrentLayout = layouts.get(storeConfig.content.productCardType) || ProductLayoutHorizontal

    return <CurrentLayout products={products} loading={loading} title={title} />;
}

export default ProductLayoutFactory;
