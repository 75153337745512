import dateStyle from './DateSelector.module.css';
import { useRef, useState } from 'react';
import { toAbsoluteUrl } from 'utils/Functions';
import Sheet, { SheetRef } from 'react-modal-sheet';

type Props = {
  onChange: Function,
}

function DateSelector({ onChange }: Props) {
  const [filter, setFilter] = useState('');
  const options = [
    { label: 'todos', value: '' },
    { label: 'Últimos 6 meses', value: 'halfYear' },
    { label: 'Último ano', value: 'oneYear' },
    { label: 'Últimos dois anos', value: 'twoYears' },
  ];
  const [open, setOpen] = useState(false);
  const ref = useRef<SheetRef>();

  function handleFilterChange(e: any) {
    const value = e.target.value
    setFilter(value);
    onChange(value);
    hideModal();
  }

  function showModal() {
    setOpen(true)
  }

  function hideModal() {
    setOpen(false)
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <div className={dateStyle.ordersFormSelector} onClick={showModal}>
          Filtrar por:
          <img
            src={process.env.PUBLIC_URL + '/media/general/Filter-Icon.svg'}
            alt="arrows"
            className="ms-2"
          />
        </div>
      </div>

      <Sheet
        ref={ref}
        isOpen={open}
        onClose={hideModal}
        initialSnap={0}
        snapPoints={[-50, 100, 50]}
        detent="content-height"
      >
          <Sheet.Container>
              <Sheet.Scroller>
                <Sheet.Content className={dateStyle.bottomSheet}>
                  <div className="px-5 pt-3">
                    <div className='d-flex justify-content-between'>
                      <span className="main-color font12rem font500">Filtrar por</span>
                      <img src={toAbsoluteUrl('/media/ChevronsAndArrows/Chevron-Down-Gray.svg')} alt='' className='main-filter'/>
                    </div>
                    <form className={dateStyle.bottomSheetContent}>
                      {options.map((option, idx) => (
                        <div key={idx} className={dateStyle.dateOption}>
                          <input
                            type="radio"
                            value={option.value}
                            name="dateFilter"
                            checked={filter === option.value}
                            onChange={handleFilterChange}
                            className="me-1"
                          />
                          {option.label}
                        </div>
                      ))}
                    </form>
                  </div>
                </Sheet.Content>
              </Sheet.Scroller>  
          </Sheet.Container>
        <Sheet.Backdrop onTap={hideModal}/>
      </Sheet>
    </>
  );
}

export default DateSelector;

{
  /* 
<label>Filtrar por: &nbsp;</label>
<select>
  <option>nos últimos 15 dias</option>
  <option value=''>últimos 30 dias</option>
  <option value=''>3 meses</option>
  <option value=''>6 meses</option>
  <option value=''>{new Date().getFullYear()}</option>
  <option value=''>{new Date().getFullYear() + 1}</option>
  <option value=''>{new Date().getFullYear() + 2}</option>
  <option value=''>{new Date().getFullYear() + 3}</option>
  <option value=''>{new Date().getFullYear() + 4}</option>
</select> 
*/
}
