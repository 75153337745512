import { regionalClient } from "index";
import { CheckoutRequest } from "models/CheckoutRequest";
import { CheckoutResult } from "models/CheckoutResult";
import { CheckoutEndpoints } from "modules/api/endpoints/CheckoutEndpoints";

export const processCheckout = (checkoutRequest : CheckoutRequest) => {
    return regionalClient.post<CheckoutResult>(CheckoutEndpoints.process, checkoutRequest)
}

export const getCheckoutStatus = async (orderId: string) => {
  return regionalClient.get(CheckoutEndpoints.result(orderId))
}
