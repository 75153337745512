import {useState} from 'react'
import {BsCheckCircleFill} from 'react-icons/bs'
import PageTitle from 'components/common/PageTitle/PageTitle'
import DisableStyle from './StepEnablePage.module.css'
import { toAbsoluteUrl } from 'utils/Functions'
import Sheet from 'react-modal-sheet'

function DisablePage() {
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false)
  const [isModalConcludedOpen, setIsModalConcludedOpen] = useState(false)

  function showConfirmationOpen() {
    setIsModalConfirmationOpen(true)
  }

  function showConcludeOpen() {
    setIsModalConcludedOpen(true)
    setIsModalConfirmationOpen(false)
  }

  function onDismiss() {
    setIsModalConfirmationOpen(false)
    setIsModalConcludedOpen(false)
  }

  return (
    <>
      <PageTitle title={`AUTENTICAÇÃO DE DOIS FATORES`} />
      <div className='container px-4'>
        <p className='mb-3 font600'> A autenticação de dois fatores está ativada! </p>
        <p className='font09rem'>
          Para desabilitar a autenticação de dois fatores, clique no botão abaixo e digite o código
          disponívell no aplicativo Google Autheticator para confirmar.
        </p>
        <button onClick={showConfirmationOpen} className='button btn-danger'>
          {' '}
          Desabilitar{' '}
        </button>
      </div>

      <Sheet
        isOpen={isModalConfirmationOpen}
        onClose={onDismiss}
        initialSnap={0}
        snapPoints={[-50, 100, 50]}
        className={DisableStyle.bottomSheet}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Content className={`px-3 py-4`}>

            <div className={`d-flex flex-column mb-3 mx-4 font500`}>
              <div className='d-flex justify-content-around font12rem w-100 mb-3'>
                <span>Digite o código de confirmação</span>
                <img src={toAbsoluteUrl('/media/general/X-icon.svg')} alt='' onClick={onDismiss} className='transform-1px'/>
              </div>
              <div className='d-flex flex-column'>
                <span className={DisableStyle.authenticationCheckText}>Código de confirmação</span>
                <input
                  type='text'
                  placeholder='000 000'
                  className={DisableStyle.authenticationDisable}
                  onFocus={(e) => (e.target.placeholder = '')}
                  onBlur={(e) => (e.target.placeholder = '000 000')}
                  required
                />
              </div>
              <div className='d-flex justify-content-center'>
                <button className='button btn-danger mt-3' onClick={showConcludeOpen}>
                  Desabilitar 2FA
                </button>
              </div>
            </div>

            </Sheet.Content>
        </Sheet.Container>
      <Sheet.Backdrop onTap={onDismiss}/>
      </Sheet>

      <Sheet
        isOpen={isModalConcludedOpen}
        onClose={onDismiss}
        initialSnap={0}
        snapPoints={[-50, 100, 50]}
        className={DisableStyle.bottomSheet}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Content className={`px-3 py-4`}>
            <div className={`${DisableStyle.authenticationConfirmation} d-flex flex-column align-items-center pt-4`}>
              <BsCheckCircleFill className={DisableStyle.cancelationCheckIcon} />
              <span className={`${DisableStyle.authenticationCheckText} text-center`}>
                A autenticação de dois fatores foi desabilitada!
              </span>
              <div>
                <button className='button btn-danger mt-3' onClick={onDismiss}>
                  OK
                </button>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
      <Sheet.Backdrop onTap={onDismiss}/>
      </Sheet>
    </>
  )
}

export default DisablePage
