import React from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import AccountOrderPage from './AccountOrderPage';
import OrderSummaryModal from './modal/OrderSummaryModal/OrderSummaryModal';
import ModalTracking from '../AccountOutlets/modals/ModalTracking/TrackingModal';

// import { Container } from './styles';

const AccountOrderWrapper: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route
        path="lista"
        element={
          <>
            <AccountOrderPage />
            <Outlet />
          </>
        }
      >
        <Route path=":id" element={<OrderSummaryModal show={true} onHide={() => navigate("lista")} />} />
        <Route path="rastreio/:id" element={<ModalTracking show={true} onHide={() => navigate("lista")} />} />
      </Route>
      <Route path="*" element={<Navigate to="lista" />} />
    </Routes>
  ); 
}

export default AccountOrderWrapper;
